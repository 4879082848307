import { Select } from '@chakra-ui/react'
import React from 'react'

const ProcedimentosCadastrados = React.memo(function PagamentoPorID({ info, handleSetValue }) {
  return (
    <Select onChange={(e) => handleSetValue(e.target.value)}>
      <option selected>{info.length} procedimentos encontrados</option>
      {info.map((procedimento) => (
        <option value={procedimento.DS_CIRURGIA}>{procedimento.DS_CIRURGIA}</option>
      ))}
    </Select>
  )
})

export { ProcedimentosCadastrados }
