import {
  Alert,
  AlertIcon,
  Button,
  Center,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  List,
  ListIcon,
  ListItem,
  Progress,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { FiArrowLeft, FiArrowRight, FiFile, FiSave } from 'react-icons/fi'
import { MdCheckCircle } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { ModalComponent } from '../../_styles/ModalComponent '
import ImgExemplo from '../../assets/exemplo-foto.png'
import api from '../../services/api'

function AgendamentosEnvio() {
  const toast = useToast()
  const [Loading, setLoading] = useState(false)
  const [documentoEnviado, setdocumentoEnviado] = useState(false)
  const { id } = useParams()
  const [numeroCarteira, setNumeroCarteira] = useState('')
  const [cep, setCep] = useState('')
  const [endereco, setEndereco] = useState('')
  const [tabIndex, setTabIndex] = React.useState(0)
  const [checkedItems, setCheckedItems] = React.useState([false, false, false])
  const allChecked = checkedItems.every(Boolean)
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked
  const [ImagemDocumento, setImagemDocumento] = useState('')
  const [fileUpload, setFileUpload] = useState('')

  function BotaoProximoNivel({ nvl, disabled }) {
    return (
      <Button
        rightIcon={<FiArrowRight />}
        variant="outline"
        onClick={() => setTabIndex(nvl)}
        colorScheme="teal"
        disabled={disabled}
        size="lg"
        maxW="50%"
      >
        Continuar
      </Button>
    )
  }

  function BotaoVoltarNivel({ nvl }) {
    return (
      <Button
        leftIcon={<FiArrowLeft />}
        variant="outline"
        onClick={() => setTabIndex(nvl)}
        colorScheme="orange"
        size="lg"
        maxW="30%"
      >
        Voltar
      </Button>
    )
  }

  async function handleAnexar(e) {
    e.preventDefault()
    setLoading(true)

    const formData = new FormData()
    formData.append('file', fileUpload)
    formData.append('id', id)
    formData.append('numeroCarteira', numeroCarteira)
    formData.append('cep', cep)
    formData.append('endereco', endereco)
    formData.append('img_base64', 'img_base64')

    api
      .post('agendamentos/update/envio/paciente', formData, {})
      .then(() => {
        setLoading(false)
        setdocumentoEnviado(true)
        toast({
          title: 'Documentos Enviados.',
          description: 'Recebemos os seus documentos..',
          position: 'top',
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      })
      .catch((err) => {
        setLoading(false)
        toast({
          title: 'Erro ao enviar arquivo.',
          description: 'Tamanho do arquivo inválido, tente reduzir a qualidade da imagem...',
          position: 'top',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      })
  }

  return (
    <>
      {/* Modal para carregar um spinner quando estiver carregadno */}
      <ModalComponent isOpen={Loading} onClose={() => setLoading(false)} size="full">
        <Center h="100vh">
          <Spinner />
        </Center>
      </ModalComponent>

      {Loading && <Progress w="100%" size="xs" isIndeterminate colorScheme={'blue'} minH="8px" />}
      <VStack p={8}>
        <input type="range" min="0" max="2" value={tabIndex} />

        {!documentoEnviado && (
          <Tabs index={tabIndex}>
            <TabList>
              <Tab>Informações Pessoais</Tab>
              <Tab>Orientações</Tab>
              <Tab>Anexar Documentos</Tab>
            </TabList>
            <form onSubmit={handleAnexar} encType="multipart/form-data">
              <TabPanels>
                <TabPanel>
                  <VStack spacing={4} my={8}>
                    <FormControl isRequired>
                      <FormLabel>Número da Carteira</FormLabel>
                      <Input placeholder="Número da Carteira" onChange={(e) => setNumeroCarteira(e.target.value)} />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel>CEP</FormLabel>
                      <Input placeholder="CEP" required="Campo Obrigatório" onChange={(e) => setCep(e.target.value)} />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel>Endereço</FormLabel>
                      <Input
                        placeholder="Endereço"
                        required="Campo Obrigatório"
                        onChange={(e) => setEndereco(e.target.value)}
                      />
                    </FormControl>
                    <BotaoProximoNivel nvl={1} />
                  </VStack>
                </TabPanel>
                <TabPanel>
                  <VStack w="100%" spacing={4}>
                    <Checkbox
                      isChecked={allChecked}
                      isIndeterminate={isIndeterminate}
                      onChange={(e) => setCheckedItems([e.target.checked, e.target.checked, e.target.checked])}
                    >
                      Estou ciente que o envio dos documentos abaixo é de extrema importância para a coleta de
                      autorizações junto a operadora (quando aplicável).
                    </Checkbox>
                    <Stack pl={6} mt={1} spacing={2} alignItems="left" w="100%">
                      <Checkbox
                        isChecked={checkedItems[0]}
                        onChange={(e) => setCheckedItems([e.target.checked, checkedItems[1], checkedItems[2]])}
                      >
                        Documento pessoal com foto.
                      </Checkbox>
                      <Checkbox
                        isChecked={checkedItems[1]}
                        onChange={(e) => setCheckedItems([checkedItems[0], e.target.checked, checkedItems[2]])}
                      >
                        Carteira do convênio.
                      </Checkbox>
                      <Checkbox
                        isChecked={checkedItems[2]}
                        onChange={(e) => setCheckedItems([checkedItems[0], checkedItems[1], e.target.checked])}
                      >
                        Pedido Médico.
                      </Checkbox>
                    </Stack>
                    <HStack w="100%" alignItems="left">
                      <BotaoVoltarNivel nvl={0} />

                      {allChecked && <BotaoProximoNivel nvl={2} />}
                    </HStack>
                  </VStack>
                </TabPanel>
                <TabPanel>
                  <VStack spacing={4} my={8}>
                    <Alert status="warning">
                      <AlertIcon />
                      Tire uma única foto dos seus documentos, caso seu cartão do convênio seja virtual, por favor,
                      priorize o envio da cópia de seu pedido médico, no formato de foto simples (PJG, PNG, PJEG)
                    </Alert>
                    <HStack>
                      <List>
                        <ListItem>
                          <ListIcon as={MdCheckCircle} color="green.500" />
                          Documento pessoal com foto.
                        </ListItem>
                        <ListItem>
                          <ListIcon as={MdCheckCircle} color="green.500" />
                          Carteira do convênio.
                        </ListItem>
                        <ListItem>
                          <ListIcon as={MdCheckCircle} color="green.500" />
                          Pedido Médico (apenas para exames).
                        </ListItem>
                      </List>
                      <VStack alignItems="left">
                        <Text>Exemplo: </Text>
                        <Image alt="Imagem Modelo" src={ImgExemplo} width="300px" />
                      </VStack>
                    </HStack>

                    <InputGroup>
                      <InputLeftElement pointerEvents="none" children={<Icon as={FiFile} />} />
                      <Input
                        type="file"
                        id="input_docs_paciente"
                        accept="image/*, .pdf"
                        // accept="image/*"
                        name="arquivo"
                        required="Campo Obrigatório"
                        onChange={(e) => setFileUpload(e.target.files[0])}
                      />
                    </InputGroup>
                  </VStack>
                  <HStack>
                    <BotaoVoltarNivel nvl={1} />
                    <Button type="submit" leftIcon={<FiSave />} variant="outline" colorScheme="blue" size="lg">
                      Enviar dados
                    </Button>
                  </HStack>
                  <Text fontSize="12px">Formatos permitidos: jpg, jpeg, png.</Text>
                </TabPanel>
              </TabPanels>
            </form>
          </Tabs>
        )}

        {documentoEnviado && (
          <VStack spacing={4} py={16}>
            <Heading>Documento enviado com sucesso.</Heading>
            <img id="output" />
            <Image src={ImagemDocumento} width="300px" />
          </VStack>
        )}
      </VStack>
    </>
  )
}

export default React.memo(AgendamentosEnvio)
