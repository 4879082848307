import { SimpleGrid } from '@chakra-ui/react'
import React from 'react'

export function GridPageTemplate({ children }) {
  return (
    <SimpleGrid columns={[1, 1, 3]} spacing={4} mt={4}>
      {children}
    </SimpleGrid>
  )
}
