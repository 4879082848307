/* eslint-disable */
import api from '../../services/api'

export async function FindRamalUsuario(usuario) {
  return await api
    .post('ligacoes/find/usuario/ramal', { usuario })
    .then((response) => {
      return response.data[0]
    })
    .catch((err) => {
      return err
    })
}
