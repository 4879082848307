import { Box, HStack, Image, SimpleGrid, Spinner, Text, VStack, useBreakpointValue, useToast } from '@chakra-ui/react'
import { parseCookies } from 'nookies'
import React, { useContext, useEffect, useState } from 'react'
import { ModalAtendimentoSelecionado } from '../../Components/Atendimentos/ModalAtendimentoSelecionado'
import { ModalCriarPagamento } from '../../Components/Pagamentos/ModalCriarPagamento'
import { Context as ContextUser } from '../../Context/UserContext'
import api from '../../services/api'
import { ContainerPageTemplate } from '../../styles/ContainerPageTemplate'

const AtendimentosDia = function () {
  const [isOpenCriarPagamento, setisOpenCriarPagamento] = useState(false)
  const { data, setLoading, Loading, User } = useContext(ContextUser)
  const [atendimentos, setatendimentos] = useState([])
  const [atendimentoSelecionado, setAtendimentoSelecionado] = useState([])
  const [isOpenAtendimentoSelecionado, setisOpenAtendimentoSelecionado] = useState(false)
  const { 'dragendamento.clienteId': clienteId } = parseCookies()
  const isDeviceSmall = useBreakpointValue({ sm: 1, md: 2, lg: 2 })
  const sizeGap = useBreakpointValue({ sm: 1, md: 1, lg: 4 })
  const toast = useToast()

  const handleChangeModalIsOpen = (data) => {
    if (data) {
      setAtendimentoSelecionado(data)
    }
    setisOpenAtendimentoSelecionado(!isOpenAtendimentoSelecionado)
  }

  function handleModalCriarPagamento() {
    setisOpenCriarPagamento(true)
    setisOpenAtendimentoSelecionado(false)
  }

  function closeAllModals() {
    setisOpenAtendimentoSelecionado(false)
  }

  // Buscar os atendimentos por data selecionada.
  useEffect(() => {
    if (!data) return
    if (!User) return
    setLoading(true)
    if (clienteId)
      if (data !== null && User?.cd_cliente !== '') {
        setatendimentos([])
        api
          .post('atendimentos/finda/data', { cd_cliente: clienteId, data })
          .then((response) => {
            setatendimentos(response.data)
          })
          .catch(() => {
            setatendimentos([])
            toast({
              title: 'Erro ao buscar os atendimentos.',
              description: 'Tente novamente mais tarde.',
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'top-right',
            })
          })
          .finally(() => {
            setLoading(false)
          })
      }
  }, [data, User])

  return (
    <>
      <ContainerPageTemplate isLoading={Loading} isVisibleInputDate>
        {!Loading && (
          <SimpleGrid
            templateColumns={`repeat(${isDeviceSmall}, 1fr)`}
            gap={sizeGap}
            borderRadius={4}
            mt={8}
            py={4}
            className="grid-view-schedules"
          >
            {atendimentos.map((atendimento, index) => (
              <Box
                onClick={() => handleChangeModalIsOpen(atendimento)}
                p={4}
                cursor={'pointer'}
                key={index}
                fontSize="12px"
                boxShadow="base"
                className="box-atendimento-item"
              >
                <VStack alignItems={'left'}>
                  <HStack justifyContent={'space-between'}>
                    <Text bgClip="text" bgGradient="linear(to-l, #5b73e8, #2C5282)" fontWeight={'bold'}>
                      {atendimento.NM_PACIENTE}
                    </Text>
                    <Text bgClip="text" bgGradient="linear(to-l, #5b73e8, #2C5282)" fontWeight={'bold'}>
                      {atendimento.CD_ATENDIMENTO + ' (' + atendimento.TP_ATENDIMENTO + ')'}
                    </Text>
                  </HStack>

                  <Text>{atendimento.NM_CONVENIO}</Text>
                  <Text>{atendimento.NM_PRESTADOR}</Text>
                </VStack>
              </Box>
            ))}
          </SimpleGrid>
        )}

        {/* Se não houver atendimentos */}
        {atendimentos.length === 0 && (
          <VStack
            justifyContent={'center'}
            w="100%"
            p={8}
            alignItems={'center'}
            textAlign={'center'}
            id="imagem-item-quantidade-vazia"
          >
            <Image src="/book.svg" alt="Pesquisar" h="300px" w="300px" />
            <Text>Não foram encontrados novos atendimentos.</Text>
            {Loading && <Spinner />}
          </VStack>
        )}

        <ModalAtendimentoSelecionado
          isOpen={isOpenAtendimentoSelecionado}
          info={atendimentoSelecionado}
          setisOpenAtendimentoSelecionado={setisOpenAtendimentoSelecionado}
          handleChangeModalIsOpen={handleChangeModalIsOpen}
          nm_paciente={atendimentoSelecionado.NM_PACIENTE}
          cd_atendimento={atendimentoSelecionado.CD_ATENDIMENTO}
          nm_prestador={atendimentoSelecionado.NM_PRESTADOR}
          cd_convenio={atendimentoSelecionado.CD_CONVENIO}
          nm_convenio={atendimentoSelecionado.NM_CONVENIO}
          cd_paciente={atendimentoSelecionado.CD_PACIENTE}
          sn_retorno={atendimentoSelecionado.SN_RETORNO}
          ds_con_pla={atendimentoSelecionado.DS_CON_PLA}
          email={atendimentoSelecionado.EMAIL}
          ds_leito={atendimentoSelecionado.DS_LEITO}
          cd_ped_rx=""
          cd_aviso_cirurgia=""
          cd_it_agenda_central=""
          cpf={atendimentoSelecionado.NR_CPF}
          cd_prestador={atendimentoSelecionado.CD_PRESTADOR}
          phone_prestador={atendimentoSelecionado.CELULAR}
          usuario_atendimento={localStorage.getItem('usuario')}
          handleModalCriarPagamento={handleModalCriarPagamento}
          closeAllModals={closeAllModals}
        />

        <ModalCriarPagamento
          isOpen={isOpenCriarPagamento}
          onClose={() => setisOpenCriarPagamento(false)}
          nm_paciente={atendimentoSelecionado.NM_PACIENTE}
          cd_atendimento={atendimentoSelecionado.CD_ATENDIMENTO}
          nm_prestador={atendimentoSelecionado.NM_PRESTADOR}
          cd_convenio={atendimentoSelecionado.CD_CONVENIO}
          nm_convenio={atendimentoSelecionado.NM_CONVENIO}
          cd_paciente={atendimentoSelecionado.CD_PACIENTE}
          sn_retorno={atendimentoSelecionado.SN_RETORNO}
          ds_con_pla={atendimentoSelecionado.DS_CON_PLA}
          email={atendimentoSelecionado.EMAIL}
          ds_leito={atendimentoSelecionado.DS_LEITO}
          cd_ped_rx=""
          cd_aviso_cirurgia=""
          cd_it_agenda_central=""
          cpf={atendimentoSelecionado.NR_CPF}
          cd_prestador={atendimentoSelecionado.CD_PRESTADOR}
          phone_prestador={atendimentoSelecionado.CELULAR}
          cd_cliente={localStorage.getItem('cd_cliente')}
          closeAllModals={closeAllModals}
        />
      </ContainerPageTemplate>
    </>
  )
}

export { AtendimentosDia }
