/* eslint-disable */
import api from '../../services/api'

export async function FindPedidosAtendimento(cd_cliente, cd_ped_rx) {
  return await api
    .post('pedidos/find/cd_pedido', { cd_cliente, cd_ped_rx })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}
