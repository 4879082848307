import md5 from 'md5'
import { setCookie } from 'nookies'
import { toast } from 'react-toastify'
import api from '../../services/api'
import { queryClient } from '../../services/queryClient'
import { NotificaSuccess } from '../Toast/success'

export async function AgendamentosFindConferenciaMV(cd_cliente, data, setData) {
  if (data !== null && cd_cliente !== undefined) {
    const ArrayCodigosAgendamentosMV = []
    const ArrayCodigosAgendamentosMVID = []

    const info = await queryClient.fetchQuery(
      ['agendamentos-data', data],
      async () => {
        const response = await api.post('agendamentos/conferencia/mv', {
          cd_cliente,
          data,
        })

        response.data.map((data) => {
          ArrayCodigosAgendamentosMV.push(data.CD_IT_AGENDA_CENTRAL)
          ArrayCodigosAgendamentosMVID.push(
            md5(md5(data.CD_IT_AGENDA_CENTRAL + process.env.REACT_APP_APP_CRIPTO_ID_AGENDAMENTO))
          )
        })

        localStorage.setItem('list_itens', ArrayCodigosAgendamentosMV)
        localStorage.setItem('list_itens_ids', ArrayCodigosAgendamentosMVID)

        setCookie(undefined, 'dragendamento.list_itens_ids', ArrayCodigosAgendamentosMVID, {
          maxAge: 60 * 60 * 6, // 6 hour
        })

        const info = response.data.sort(function (a, b) {
          return a.HR_AGENDAMENTO.localeCompare(b.HR_AGENDAMENTO)
        })

        return info
      },
      {
        staleTime: 1000 * 30 * 5,
      }
    )

    const groupArray = (arr) => {
      const finalData = []
      const result = arr.reduce((r, a) => {
        r[a.CD_CLIENTE] = r[a.CD_CLIENTE] || []
        r[a.CD_CLIENTE].push(a)
        return r
      }, Object.create(null))
      const data = Object.entries(result)
      for (let i = 0; i < data.length; i++) {
        const result2 = data[i][1].reduce((r, a) => {
          r[a.NM_PRESTADOR] = r[a.NM_PRESTADOR] || []
          r[a.NM_PRESTADOR].push(a)
          return r
        }, Object.create(null))
        const value = {
          data: data[i][0],
          nomes: Object.keys(result2),
          agendamentos: Object.values(result2),
        }
        finalData.push(value)
      }
      return finalData
    }

    const agendamentosGroupBy = await groupArray(info)

    setData(agendamentosGroupBy)
    return info
  }
  return []
}

export async function AgendamentosCreateAgendamento(
  cd_cliente,
  cd_it_agenda_central,
  dt_agenda,
  cd_item_agendamento,
  cd_convenio,
  nr_celular,
  cd_prestador,
  nm_paciente
) {
  return await api
    .post('agendamentos/conferencia/add', {
      cd_cliente,
      cd_it_agenda_central,
      dt_agenda,
      nm_paciente,
      cd_item_agendamento,
      cd_convenio,
      nr_celular,
      url_reuniao: null,
      sn_consulta_online: 'n',
      cd_prestador,
      numerocarteira: '',
      cep: '',
      endereco: '',
      img_documentos: '',
      img_autorizacao: '',
      dt_ult_envio: '',
      status: '',
      status_autorizacao: '',
      email: '',
      obs_agendamento: '',
      img_base64: '',
      img_elegibilidade: null,
      sn_presenca_confirmada: null,
      sn_presenca_enviada: null,
      img_presenca_whatsapp: null,
      sn_impresso: null,
      sn_paciente_local: null,
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}

export async function AgendamentosCreatePresencaConfirmada(id_agendamento, nm_paciente) {
  const today = new Date()
  const h = today.getHours()
  const m = (today.getMinutes() < 10 ? '0' : '') + today.getMinutes()
  const hora = `${h}:${m}`

  return await api
    .post('agendamentos/presenca/create', {
      cd_cliente: localStorage.getItem('cd_cliente'),
      id_agendamento,
      nm_paciente,
      hora,
      nm_usuario: localStorage.getItem('usuario'),
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}

export async function AgendamentosFindAgendaCompleta(cd_cliente, cd_item_agendamento, cd_convenio, data) {
  const groupArray = (arr) => {
    const finalData = []
    const result = arr.reduce(function (r, a) {
      r[a.dt_agendamento] = r[a.dt_agendamento] || []
      r[a.dt_agendamento].push(a)
      return r
    }, Object.create(null))
    const data = Object.entries(result)
    for (let i = 0; i < data.length; i++) {
      const result2 = data[i][1].reduce(function (r, a) {
        r[a.nm_prestador] = r[a.nm_prestador] || []
        r[a.nm_prestador].push(a)
        return r
      }, Object.create(null))
      const value = {
        data: data[i][0],
        nomes: Object.keys(result2),
        medicos: Object.values(result2),
      }
      finalData.push(value)
    }
    return finalData
  }

  return await api
    .post('agendamentosweb/agenda/completa', {
      cd_cliente,
      cd_item_agendamento,
      cd_convenio,
      data,
    })
    .then((response) => {
      const data = groupArray(response.data)
      return data
    })
    .catch((err) => {
      return err
    })
}

export async function AgendamentosFindBalcao(cd_cliente) {
  return await api
    .post('agendamentos/balcao', {
      cd_cliente,
      cd_it_agenda_central: localStorage.getItem('list_itens'),
    })
    .then((response) => {
      return response.data
    })
    .catch(() => {
      return []
    })
}

export async function AgendamentosFindAgendamentosOrientacoes(celular, cd_cliente) {
  return await api
    .post('agendamentos/orientacoes/mv', { cd_cliente, celular })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}

export async function AgendamentosFindPorID(cd_cliente, cd_it_agenda_central) {
  return await api
    .post('agendamentos/find/id', {
      cd_cliente,
      cd_it_agenda_central: `${cd_it_agenda_central}`,
    })
    .then((response) => {
      return response.data[0]
    })
    .catch(() => {
      return []
    })
}

export async function AgendamentosFindPorItAgendaCentral(cd_cliente, cd_it_agenda_central) {
  return await api
    .post('agendamentos/busca/cd_it_agenda_central', {
      cd_cliente,
      cd_it_agenda_central: `${cd_it_agenda_central}`,
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return []
    })
}
export async function AgendamentosUpdatePresenca(id) {
  return await api
    .post('agendamentos/balcao/atualizar', { id })
    .then((response) => {
      NotificaSuccess('Presença confirmada com sucesso...')
      return response.data
    })
    .catch((err) => {
      return []
    })
}

export async function AgendamentosUpdateCelularPorID(id, nr_celular, cd_convenio) {
  return await api
    .post('agendamentos/update/nr_celular', { id, nr_celular, cd_convenio })
    .then((response) => {
      return response.data
    })
    .catch(() => {
      return []
    })
}

export async function AgendamentosLinkAnexarDocumentoPacienteAgendamento(id) {
  window.open(`https://dragendamento.com/agendamentos/anexar/${id}`, '_blank')
}

export async function AgendamentosUpdateCancelarPresenca(id) {
  return await api
    .post('agendamentos/cancelar/envio/presenca', { id })
    .then((response) => {
      toast.error('Presença cancelada', {
        position: 'top-center',
        autoClose: 1500,
      })
      return response.data
    })
    .catch((err) => {
      return []
    })
}

export async function FindAgendamentosStatusAutorizacao(status_autorizacao) {
  return await api
    .post('autorizacoes', {
      cd_it_agenda_central: localStorage.getItem('list_itens'),
      status_autorizacao,
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}

export async function FindConferenciaMVbyPrestador(cd_cliente, data, cd_prestador) {
  localStorage.setItem('data_selecionada_consultas', data)
  const ArrayCodigosAgendamentosMV = []
  return await api
    .post('agendamentos/conferencia/mv/prestador', {
      cd_cliente,
      data,
      cd_prestador,
    })
    .then((response) => {
      let i = 0
      while (i < response.data.length) {
        ArrayCodigosAgendamentosMV.push(response.data[i].CD_IT_AGENDA_CENTRAL)
        i++
      }
      localStorage.setItem('list_itens', ArrayCodigosAgendamentosMV)
      return response.data
    })
    .catch((err) => {
      return err
    })
}

export async function FindPresencas() {
  return await api
    .post('agendamentos/presenca/find', {
      cd_cliente: localStorage.getItem('cd_cliente'),
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}

export async function FindPresencasByID(id_agendamento) {
  return await api
    .post('agendamentos/presenca/find/id', {
      cd_cliente,
      id_agendamento,
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}
