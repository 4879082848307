import React, { useEffect, useState } from 'react'

import {
  Center,
  FormControl,
  FormLabel,
  HStack,
  Input,
  List,
  ListItem,
  OrderedList,
  Select,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { useContext } from 'react'
import { Context as ContextUser } from '../../Context/UserContext'
import { FindLogsByTag, FindLogsGroup } from '../../Functions/FuncoesSistemaLog/_logs'
import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api'
import { ContainerPageTemplate } from '../../styles/ContainerPageTemplate'

function Logs() {
  const { User } = useContext(ContextUser)
  const [dataInicial, setdataInicial] = useState(new Date().toISOString().slice(0, 10))
  const [dataFinal, setdataFinal] = useState(new Date().toISOString().slice(0, 10))
  const [logs, setlogs] = useState([])
  const [logsFilter, setlogsFilter] = useState([])
  const [isOpenLogFilter, setisOpenLogFilter] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [typesLogs, setTypesLogs] = useState([])
  const [logsV2, setlogsV2] = useState([])
  const [isOpenModalLogsV2, setisOpenModalLogsV2] = useState(false)
  const toast = useToast()

  useEffect(() => {
    if (User.profiles?.isAdmin) window.location.href = '/logs/admin'
  }, [User])

  // Filtrar os logs!
  const handleFilterLogs = (data) => {
    window.scrollTo(0, 0)
    setIsLoading(true)
    setisOpenLogFilter(true)
    FindLogsByTag(dataInicial, dataFinal, data).then((res) => {
      setlogsFilter(res)

      setIsLoading(false)
    })
  }

  const handleFindLogsType = (id) => {
    setisOpenModalLogsV2(true)
    api
      .get(`/logs/types/${id}/${dataInicial}/${dataFinal}`)
      .then((res) => {
        setlogsV2(res.data)
      })
      .catch((err) => {
        toast({
          title: 'Erro ao buscar os logs!',
          description: 'Tente novamente mais tarde.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  // Filtrar todos os logs por data!
  useEffect(() => {
    if (!dataFinal) return
    setlogs([])
    setIsLoading(true)
    FindLogsGroup(dataInicial, dataFinal).then((res) => {
      setIsLoading(false)
      if (!res) return // Se não houver dados, retorne.
      setlogs(res)
    })

    api.get('/logs/types').then((res) => {
      setTypesLogs(res.data)
    })
  }, [dataInicial, dataFinal])

  return (
    <ContainerPageTemplate>
      {/* Modal para visualizar os dados. */}
      <ModalComponent isOpen={isOpenLogFilter} onClose={() => setisOpenLogFilter(false)} title="LOGS">
        <Center minH={'5vh'}>{isLoading && <Spinner />}</Center>
        <Table>
          <Thead>
            <Tr>
              <Th>Tipo</Th>
              <Th>Usuário</Th>
              <Th>Descrição</Th>
              <Th>Data</Th>
            </Tr>
          </Thead>
          <Tbody>
            {logsFilter.map((item, index) => (
              <Tr key={index}>
                <Td>{item.desc_funcao}</Td>
                <Td>{item.nm_usuario}</Td>
                <Td>{item.descricao}</Td>
                <Td>{item.data}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </ModalComponent>

      {/* Modal dos logs v2 */}
      <ModalComponent isOpen={isOpenModalLogsV2} onClose={() => setisOpenModalLogsV2(false)} title="LOGS">
        <Table>
          <Thead>
            <Tr>
              <Th>Paciente</Th>
              <Th>DT / HR agendamento</Th>
              <Th>Código MV</Th>
              <Th>Usuário</Th>
            </Tr>
          </Thead>
          <Tbody>
            {logsV2.map((item, index) => (
              <Tr key={index}>
                <Td>{item.schedules?.nm_paciente}</Td>
                <Td>
                  {item.schedules?.dt_agenda} - {item.schedules?.hr_agenda}
                </Td>
                <Td>{item.schedules?.cd_it_agenda_central}</Td>
                <Td>{item.users?.name}</Td>
                <Td>
                  <List>
                    {Object.entries(item?.data?.data).map(([key, value]) => (
                      <ListItem key={key}>
                        <strong>{key}: </strong>
                        {value}
                      </ListItem>
                    ))}
                  </List>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </ModalComponent>

      <HStack alignItems="center">
        <FormControl isRequired>
          <FormLabel>Selecione uma data</FormLabel>
          <Input type="date" value={dataInicial} onChange={(e) => setdataInicial(e.target.value)} />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Selecione uma data</FormLabel>
          <Input type="date" value={dataFinal} onChange={(e) => setdataFinal(e.target.value)} />
        </FormControl>
        <FormControl>
          <FormLabel>Tipode de Log...</FormLabel>
          <Select placeholder="Selecione..." onChange={(e) => handleFindLogsType(e.target.value)}>
            {typesLogs.map((item, index) => (
              <option key={index} value={item.id}>
                {item.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </HStack>

      <Center w="100%" mt={4} minH={6}>
        {isLoading && <Spinner />}
      </Center>

      <OrderedList spacing={3} marginTop={4}>
        {logs.map((item, index) => (
          <ListItem
            cursor="pointer"
            key={index}
            p={1}
            onClick={(e) => handleFilterLogs(item.desc_funcao)}
            _hover={{ bg: 'gray.100' }}
          >
            {item.desc_funcao} - {item.QTD}
          </ListItem>
        ))}
      </OrderedList>
    </ContainerPageTemplate>
  )
}

export { Logs }
