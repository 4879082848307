import { HStack, Icon } from '@chakra-ui/react'
import React from 'react'
import { FaFlagCheckered } from 'react-icons/fa'
import { FiShield, FiUserPlus } from 'react-icons/fi'

interface Info {
  status: string;
  sn_paciente_local: string;
  sn_presenca_confirmada: string;
}

export function InfoStatusAutorizacao({ status, sn_paciente_local, sn_presenca_confirmada }: Info) {
  let color
  let status_desc

  if (status === '') {
    color = 'gray'
    status_desc = 'Agendamento sem status de autorização.'
  }

  if (status === 'E') {
    color = 'orange'
    status_desc = 'Agendamento em análise de autorização.'
  }

  if (status === 'S') {
    color = 'blue'
    status_desc = 'Agendamento não necessita de guia.'
  }

  if (status === 'N') {
    color = 'red'
    status_desc = 'Agendamento negado.'
  }

  if (status === 'A') {
    color = 'green'
    status_desc = 'Agendamento autorizado.'
  }

  return (
    <HStack>
      <Icon as={FiShield} h="8" w="10" title={status} color={color} />
      {sn_presenca_confirmada === 'S' && (
        <Icon as={FaFlagCheckered} h="8" w="10" title={'Presença confirmada.'} color={'green'} />
      )}
      {sn_presenca_confirmada === 'N' && (
        <Icon as={FaFlagCheckered} h="8" w="10" title={'Paciente negou a presença.'} color={'red'} />
      )}

      {sn_presenca_confirmada === null && (
        <Icon as={FaFlagCheckered} h="8" w="10" title={'Paciente não respondeu.'} color={'gray'} />
      )}

      {sn_paciente_local === 'S' ? (
        <Icon as={FiUserPlus} h="8" w="10" title={'O balcão confirmou a presença do paciente.'} color={'green'} />
      ) : (
        <Icon as={FiUserPlus} h="8" w="10" title={'O balcão não confirmou a presença do paciente.'} color={'red'} />
      )}
    </HStack>
  )
}
