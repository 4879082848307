import { Center, ListItem, OrderedList, Spinner, Tag, Text, VStack, useToast } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { Context as ContextAgendamento } from '../../Context/AgendamentosContext'
import { Context as ContextUser } from '../../Context/UserContext'
import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api'
import { AgendamentoSelecionadoModal } from './AgendamentoSelecionadoModal'

function AgendamentosPresentes({ isOpen, handleCloseModal }) {
  const [presencasConfirmadas, setpresencasConfirmadas] = useState([])
  const [isOpenModal, setisOpenModal] = useState(false)
  const { User } = useContext(ContextUser)
  const { cd_it_agenda_central, setcd_it_agenda_central } = useContext(ContextAgendamento)
  const [isLoading, setisLoading] = useState(false)
  const toast = useToast()

  // Buscar os agendamentos por data selecionada.
  useEffect(() => {
    if (!isOpen) return
    if (!User) return
    setpresencasConfirmadas([])

    setisLoading(true)
    api
      .post('agendamentos/presenca/find', {
        cd_cliente: User.clients?.cd_cliente,
      })
      .then((response) => {
        setpresencasConfirmadas(response.data)
      })
      .catch(() => {
        toast({
          title: 'Erro ao buscar as presenças confirmadas.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
        setpresencasConfirmadas([])
      })
      .finally(() => {
        setisLoading(false)
      })
  }, [isOpen, User])

  const groupByDay = (data) => {
    const groupedData = {}

    data.forEach((item) => {
      const date = new Date(item.data)
      const day = date.toLocaleDateString('pt-BR')

      if (!groupedData[day]) {
        groupedData[day] = []
      }

      groupedData[day].push(item)
    })

    return groupedData
  }

  // Agrupa os dados por dia
  const groupedData = groupByDay(presencasConfirmadas)

  return (
    <>
      <AgendamentoSelecionadoModal
        isOpen={isOpenModal}
        handleChangeModalIsOpen={() => setisOpenModal(false)}
        cd_it_agenda_central={cd_it_agenda_central}
        cd_cliente={User.cd_cliente}
      />

      <ModalComponent isOpen={isOpen} onClose={() => handleCloseModal(false)}>
        <Center>{isLoading && <Spinner />}</Center>
        {/* Lista agrupada por data; */}

        {Object.entries(groupedData).map(([day, items]) => (
          <VStack key={day} textAlign={'left'} alignItems={'left'}>
            <Text fontWeight={'bold'} fontSize={'12px'}>
              {day}
            </Text>
            <OrderedList>
              {items.map((item) => (
                <ListItem
                  fontSize={'12px'}
                  className="listItemPresenca"
                  key={item.id}
                  onClick={() => {
                    setcd_it_agenda_central(item.id_agendamento)
                    setisOpenModal(true)
                  }}
                >
                  {item.nm_paciente}
                  <Tag size="md">{item.hora}</Tag>
                </ListItem>
              ))}
            </OrderedList>
          </VStack>
        ))}
      </ModalComponent>
    </>
  )
}

export default React.memo(AgendamentosPresentes)
