import {
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  Select,
  Spinner,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Context as ContextUser } from '../../Context/UserContext'
import api from '../../services/api'
import { ContainerPageTemplate } from '../../styles/ContainerPageTemplate'

function Pendencias() {
  const { User } = useContext(ContextUser)
  const [Pendencias, setPendencias] = useState([])
  const [sectors, setSectors] = useState([])
  const [isLoading, setisLoading] = useState(true)
  const [pendenciesMotiveId, setpendenciesMotiveId] = useState('')
  const [sectorDestinyId, setSectorDestinyId] = useState('')
  const [obsCreated, setobsCreated] = useState('')
  const toast = useToast()
  const { namePatient } = useParams()
  const history = useHistory()
  const Router = require('react-router')

  // Verificar os motivos de pendencias do cliente.
  useEffect(() => {
    // Se o código do cliente for diferente de null, então faça a requisição.
    if (User?.clientId) {
      setisLoading(true)
      api
        .get('/api/v2/pendencies/motives')
        .then((res) => {
          setPendencias(res.data)
          setisLoading(false)
        })
        .catch((err) => {
          setisLoading(false)
          toast({
            title: 'Erro ao buscar as pendências.',
            description: err.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        })
    }

    // Verificar os setores do cliente.
    if (User?.clientId) {
      api
        .get('/api/v2/sectors')
        .then((res) => {
          setSectors(res.data)
        })
        .catch((err) => {
          toast({
            title: 'Erro ao buscar os setores.',
            description: err.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        })
    }
  }, [User])

  // Form para atualizar a pendencia.
  const handleCreatePendencia = (e) => {
    e.preventDefault()
    setisLoading(true)

    api
      .post('/api/v2/pendencies/create', { obsCreated, pendenciesMotiveId, namePatient, sectorDestinyId })
      .then(() => {
        toast({
          title: 'Pendência criada com sucesso sobre o(a) paciente ' + namePatient + '.',
          description: obsCreated,
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
        setisLoading(false)
        history.push('/agendamentos')
      })
      .catch((err) => {
        toast({
          title: 'Erro ao criar a pendência.',
          description: err.error,
          status: 'error',
          duration: 3000,
          isClosable: false,
        })
        setisLoading(false)
      })
  }

  return (
    <>
      <ContainerPageTemplate>
        {isLoading ? (
          <Center h="80vh" flexDir={'column'}>
            <Spinner />
            <Text ml={4}>Consultando as pendências...</Text>
          </Center>
        ) : (
          <form onSubmit={handleCreatePendencia}>
            <VStack>
              <FormControl>
                <FormLabel fontSize={'sm'}>Nome do paciente</FormLabel>
                <Input value={namePatient} isDisabled />
              </FormControl>
              <FormControl isRequired>
                <FormLabel fontSize={'sm'}>Qual o motivo da pendência?</FormLabel>
                <Select placeholder="Selecione..." onChange={(e) => setpendenciesMotiveId(e.target.value)}>
                  {Pendencias.map((pendencia) => (
                    <option key={pendencia.id} value={pendencia.id}>
                      {pendencia.descMotive}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl isRequired>
                <FormLabel fontSize={'sm'}>Qual o setor de destino?</FormLabel>
                <Select placeholder="Selecione..." onChange={(e) => setSectorDestinyId(e.target.value)}>
                  {sectors.map((sector) => (
                    <option key={sector.id} value={sector.id}>
                      {sector.nm_setor}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl isRequired>
                <FormLabel fontSize={'sm'}>Descreva o motivo</FormLabel>
                <Textarea onChange={(e) => setobsCreated(e.target.value)}></Textarea>
              </FormControl>
              <Button type="submit" colorScheme={'blue'}>
                Salvar
              </Button>
            </VStack>
          </form>
        )}
      </ContainerPageTemplate>
    </>
  )
}

export default React.memo(Pendencias)
