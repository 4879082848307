import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  VStack,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BiMap } from 'react-icons/bi'
import { FiSearch } from 'react-icons/fi'
import { GoAlert } from 'react-icons/go'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import AgendamentosPresentes from '../../Components/Agendamentos/AgendamentosPresentes'
import { ModalAgendamentosPresencas } from '../../Components/Agendamentos/ModalAgendamentosPresencas'
import { ModalConferenciaAgendamentos } from '../../Components/Agendamentos/ModalConferenciaAgendamentos'
import { LoadingModal } from '../../Components/LoadingModal'
import { ModalScheduleSelected, SchedulesItem } from '../../Components/Schedules'
import api from '../../services/api'
import { queryClient } from '../../services/queryClient'
import { ContainerPageTemplate } from '../../styles/ContainerPageTemplate'

function StatsCard({ title, stat }) {
  return (
    <Stat py={'2'} bg="gray.300" borderRadius={4} boxShadow="base">
      <VStack pl={{ base: 2, md: 2 }} justifyContent={'space-between'}>
        <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
          {stat}
        </StatNumber>
        <StatLabel fontWeight={'medium'} isTruncated>
          {title}
        </StatLabel>
      </VStack>
    </Stat>
  )
}

const AgendamentosV2 = function (props) {
  const { data_filter } = props.match.params
  const [data, setData] = useState(data_filter ? data_filter : new Date().toISOString().slice(0, 10))
  const [agendamentosStatus, setagendamentosStatus] = useState([])
  const [agendamentos, setAgendamentos] = useState([])
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenModalEdit, setisOpenModalEdit] = useState(false)
  const [agendamentoSelecionado, setAgendamentoSelecionado] = useState(null)
  const isDeviceSmall = useBreakpointValue({ base: true, md: false })
  const [isOpenAgendamentosPresentes, setIsOpenAgendamentosPresentes] = useState(false)
  const history = useHistory()

  const handleFilterData = (data) => {
    setIsLoading(true)
    history.push(`/agendamentos/v2/${data}`)
    setData(data)
    setIsLoading(false)
  }

  // Abrir o modal para visualizar o agendamento.
  const handleOpenModal = (data) => {
    setisOpenModalEdit(true)
    setAgendamentoSelecionado(data)
  }

  function groupArray(arr) {
    const finalData = []
    const result = arr.reduce(function (r, a) {
      r[a.clientId] = r[a.clientId] || []
      r[a.clientId].push(a)
      return r
    }, Object.create(null))
    const data = Object.entries(result)
    for (let i = 0; i < data.length; i++) {
      const result2 = data[i][1].reduce(function (r, a) {
        r[a.doctors.nm_prestador] = r[a.doctors.nm_prestador] || []
        r[a.doctors.nm_prestador].push(a)
        return r
      }, Object.create(null))
      const value = {
        data: data[i][0],
        nomes: Object.keys(result2),
        medicos: Object.values(result2),
      }
      finalData.push(value)
    }
    return finalData
  }

  function groupArrayAutorizacao(arr) {
    const finalData = []
    const result = arr.reduce(function (r, a) {
      r[a.clientId] = r[a.clientId] || []
      r[a.clientId].push(a)
      return r
    }, Object.create(null))
    const data = Object.entries(result)
    for (let i = 0; i < data.length; i++) {
      const result2 = data[i][1].reduce(function (r, a) {
        r[a.schedules_status.status] = r[a.schedules_status.status] || []
        r[a.schedules_status.status].push(a)
        return r
      }, Object.create(null))
      const value = {
        data: data[i][0],
        nomes: Object.keys(result2),
        medicos: Object.values(result2),
      }
      finalData.push(value)
    }
    return finalData
  }

  function groupArrayConferenciaOrigins(arr) {
    const finalData = []
    const result = arr.reduce(function (r, a) {
      r[a.clientId] = r[a.clientId] || []
      r[a.clientId].push(a)
      return r
    }, Object.create(null))
    const data = Object.entries(result)
    for (let i = 0; i < data.length; i++) {
      const result2 = data[i][1].reduce(function (r, a) {
        r[a.schedules_origin.description] = r[a.schedules_origin.description] || []
        r[a.schedules_origin.description].push(a)
        return r
      }, Object.create(null))
      const value = {
        data: data[i][0],
        nomes: Object.keys(result2),
        medicos: Object.values(result2),
      }
      finalData.push(value)
    }
    return finalData
  }

  function groupArrayInHospital(arr) {
    const finalData = []
    const result = arr.reduce(function (r, a) {
      r[a.clientId] = r[a.clientId] || []
      r[a.clientId].push(a)
      return r
    }, Object.create(null))
    const data = Object.entries(result)
    for (let i = 0; i < data.length; i++) {
      const result2 = data[i][1].reduce(function (r, a) {
        r[a.inHospital] = r[a.inHospital] || []
        r[a.inHospital].push(a)
        return r
      }, Object.create(null))
      const value = {
        data: data[i][0],
        nomes: Object.keys(result2),
        medicos: Object.values(result2),
      }
      finalData.push(value)
    }
    return finalData
  }

  // Filtrar pelo item do agendamento.
  async function handleFilter(filter) {
    var searchResult = agendamentos.filter(
      (colaborador) => colaborador.schedule_item.ds_item_agendamento.indexOf(filter.toUpperCase()) > -1
    )

    const cirurgiasGroup = await groupArray(searchResult)
    setagendamentosStatus(cirurgiasGroup)
  }
  // Filtrar pelo nome do paciente.
  async function handleFilterPaciente(filter) {
    var searchResult = agendamentos.filter((colaborador) => colaborador.nm_paciente.indexOf(filter.toUpperCase()) > -1)

    const cirurgiasGroup = await groupArray(searchResult)
    setagendamentosStatus(cirurgiasGroup)
  }

  // Função para filtrar os agendamentos pelo status de envio de confirmação.
  async function handleFilterSendPreparo(filter) {
    if (filter === '') {
      setagendamentosStatus(await groupArray(agendamentos))
      return
    }

    const status = filter === 'TRUE' ? true : false

    var searchResult = agendamentos.filter((colaborador) => colaborador.isEnvioConfirmacao === status)

    const cirurgiasGroup = await groupArray(searchResult)
    setagendamentosStatus(cirurgiasGroup)
  }

  const handleSetFormaApresencao = async (value) => {
    if (value === 'AGENDA') {
      setagendamentosStatus(await groupArray(agendamentos))
      return
    }
    if (value === 'AUTORIZACOES') {
      setagendamentosStatus(await groupArrayAutorizacao(agendamentos))
      return
    }

    if (value === 'CONFERENCIA') {
      setagendamentosStatus(await groupArrayConferenciaOrigins(agendamentos))
      return
    }
    if (value === 'BALCÃO') {
      // ordenar pelo nm_paciente
      const dataOrder = agendamentos.sort(function (a, b) {
        return a.nm_paciente.localeCompare(b.nm_paciente)
      })

      setagendamentosStatus(await groupArrayInHospital(dataOrder))
      return
    }
  }

  // Filtrar se é consulta ou exame.
  const handleFilterTipoAgendamento = async (value) => {
    // Filtrar pelo campo schedule_item.isConsulta
    if (value === 'TRUE') {
      var searchResult = agendamentos.filter((agendamento) => agendamento.schedule_item.isConsulta === true)
      const cirurgiasGroup = await groupArrayAutorizacao(searchResult)
      setagendamentosStatus(cirurgiasGroup)
      return
    }
    if (value === 'FALSE') {
      var searchResult = agendamentos.filter((agendamento) => agendamento.schedule_item.isConsulta === false)
      const cirurgiasGroup = await groupArrayAutorizacao(searchResult)
      setagendamentosStatus(cirurgiasGroup)
      return
    }

    if (value === '') {
      setagendamentosStatus(await groupArray(agendamentos))
    }
  }

  async function FindSchedules(data) {
    const info = await queryClient.fetchQuery(
      ['schedules-data', data],
      async () => {
        return await api.get(`/api/v2/schedules/${data}/true`)
      },
      {
        staleTime: 1000 * 30 * 5,
      }
    )

    if (info) {
      const dataOrder = info.data.sort(function (a, b) {
        return a.hr_agenda.localeCompare(b.hr_agenda)
      })
      const cirurgiasGroup = await groupArray(dataOrder)
      setagendamentosStatus(cirurgiasGroup)
      setAgendamentos(dataOrder)
    }
  }

  // Consultar os agendamentos após alterar no input;
  useEffect(() => {
    if (data === '') return

    setIsLoading(true)
    FindSchedules(data).finally(() => setIsLoading(false))
  }, [data])

  return (
    <>
      {isLoading && <LoadingModal isLoading={isLoading} />}

      <ModalScheduleSelected
        isOpen={isOpenModalEdit}
        onClose={() => setisOpenModalEdit(false)}
        scheduleSelected={agendamentoSelecionado}
        setScheduleSelected={setAgendamentoSelecionado}
        setArrayAgendamentos={setAgendamentos}
        arrayAgendamentos={agendamentos}
      />

      <AgendamentosPresentes
        isOpen={isOpenAgendamentosPresentes}
        handleCloseModal={() => setIsOpenAgendamentosPresentes(false)}
      />

      <ContainerPageTemplate isLoading={isLoading} isVisibleInputDate={false}>
        <FormControl isRequired>
          <FormLabel htmlFor="date">Selecione a data</FormLabel>
          <Input
            type={'date'}
            value={data}
            id="dataSelecionadaInput"
            onChange={(e) => {
              handleFilterData(e.target.value)
            }}
          />
        </FormControl>

        <HStack py={4} alignItems={'end'}>
          <FormControl size="sm">
            <FormLabel>Informe o nome do paciente.</FormLabel>
            <InputGroup size="sm">
              <InputLeftElement pointerEvents="none" children={<FiSearch color="gray.300" />} />
              <Input
                size="sm"
                type="text"
                placeholder="..."
                onChange={(e) => handleFilterPaciente(e.target.value)}
                id="input-agendamentos-nome-paciente"
                borderRadius={8}
              />
            </InputGroup>
          </FormControl>
          {!isDeviceSmall && (
            <FormControl size="sm">
              <FormLabel size="sm">Pesquisar por item.</FormLabel>
              <InputGroup size="sm">
                <InputLeftElement pointerEvents="none" children={<FiSearch color="gray.300" />} />
                <Input size="sm" onChange={(e) => handleFilter(e.target.value)} placeholder="..." borderRadius={8} />
              </InputGroup>
            </FormControl>
          )}

          {!isDeviceSmall && (
            <FormControl>
              <FormLabel>Forma de apresentação</FormLabel>
              <Select
                size="sm"
                placeholder="Selecione..."
                onChange={(e) => handleSetFormaApresencao(e.target.value)}
                borderRadius={8}
              >
                <option value={'AGENDA'} selected>
                  Agenda
                </option>
                <option value={'AUTORIZACOES'}> Autorizações</option>
                <option value={'BALCÃO'}> Balcão</option>
                <option value={'CONFERENCIA'}> Conferência</option>
              </Select>
            </FormControl>
          )}
          {!isDeviceSmall && (
            <FormControl>
              <FormLabel>Tipo de agendamento</FormLabel>
              <Select
                size="sm"
                placeholder="Todos..."
                onChange={(e) => handleFilterTipoAgendamento(e.target.value)}
                borderRadius={8}
              >
                <option value={'TRUE'}> Consultas</option>
                <option value={'FALSE'}> Exames</option>
              </Select>
            </FormControl>
          )}

          {!isDeviceSmall && (
            <FormControl>
              <FormLabel>Confirmação enviada</FormLabel>
              <Select
                borderRadius={8}
                size="sm"
                placeholder="Todos..."
                onChange={(e) => handleFilterSendPreparo(e.target.value)}
              >
                <option value={'TRUE'}> Sim</option>
                <option value={'FALSE'}> Não</option>
              </Select>
            </FormControl>
          )}
        </HStack>

        <Stack direction={isDeviceSmall ? 'column' : 'row'} spacing={3}>
          <ModalAgendamentosPresencas data={data} schedules={agendamentos} />
          <ModalConferenciaAgendamentos data={data} agendamentos={agendamentos} />
          <Button
            onClick={() => setIsOpenAgendamentosPresentes(true)}
            leftIcon={<BiMap />}
            variant="links"
            id="btn-pacientes-hp"
          >
            Pacientes no Hospital
          </Button>
        </Stack>

        {agendamentosStatus.map((dt, index) => (
          <Grid key={index} templateColumns={`repeat(${isDeviceSmall ? '1' : '3'}, 1fr)`} gap={2} id="GridAgendamentos">
            {dt.medicos.map((dtMedicos, index2) => (
              <GridItem
                key={index2}
                p={4}
                borderRadius={4}
                alignItems="center"
                justifyContent={'center'}
                textAlign={'center'}
              >
                <Grid alignItems={'left'} w="100%" textAlign={'left'} spacing={2}>
                  <StatsCard title={dt.nomes[index2]} stat={dtMedicos.length} icon={<GoAlert />} />
                  <VStack spacing={2}>
                    {dtMedicos.map((ht, indht) => (
                      <SchedulesItem schedule={ht} key={indht} onClick={() => handleOpenModal(ht)} />
                    ))}
                  </VStack>
                </Grid>
              </GridItem>
            ))}
          </Grid>
        ))}
      </ContainerPageTemplate>
    </>
  )
}

export { AgendamentosV2 }
