/* eslint-disable */
import api from '../../services/api'

export async function FindItensDoPagamento(id_pagamento) {
  return await api
    .post('pagamentos/busca/itens', { id_pagamento })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}
