import {
  Button,
  ButtonGroup,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Input,
  List,
  ListItem,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import moment from 'moment/moment'
import { useEffect, useState } from 'react'
import { FaFileExcel } from 'react-icons/fa'
import { CreateFuncoesLogs } from '../../Functions/FuncoesSistemaLog/CreateFuncoesLogs'
import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api'

export function RelatoriosLigacoeseWhatsapp({ isOpen, handleChangeModalIsOpen }) {
  const [Loading, setLoading] = useState(false)
  const cd_cliente = localStorage.getItem('cd_cliente')
  const [dataInicial, setdataInicial] = useState(new Date().toISOString().split('T')[0])
  const [dataFinal, setdataFinal] = useState(new Date().toISOString().split('T')[0])
  const [RelatorioMotivoLigacao, setRelatorioMotivoLigacao] = useState([])
  const [RelatorioGeralLigacoes, setRelatorioGeralLigacoes] = useState([])
  const [RelatorioGeralWhatsApp, setRelatorioGeralWhatsApp] = useState([])
  const [RelatorioGeralPendencias, setRelatorioGeralPendencias] = useState([])

  // Consultar os dados do relatório
  useEffect(() => {
    if (isOpen) {
      CreateFuncoesLogs(cd_cliente, 'RELATORIOS_LIGACOES_WPP', 'RELATORIOS_LIGACOES_WPP').then(() => {
        setLoading(true)
        if (dataInicial && dataFinal) {
          api
            .get(`api/v2/calls/periodo/${dataInicial}/${dataFinal}`)
            .then((res) => {
              setRelatorioGeralLigacoes(res.data)
            })
            .catch((err) => {
              toast({
                title: 'Erro ao buscar ligações',
                description: err.response.data.error,
                status: 'error',
                duration: 5000,
                isClosable: true,
              })
              setRelatorioGeralLigacoes([])
            })
            .finally(() => {
              setLoading(false)
            })
        }
        if (dataInicial && dataFinal) {
          api
            .post('api/protocolos/find/data', {
              dataInicial,
              dataFinal,
              id_setor: localStorage.getItem('cd_setor') || 0,
            })
            .then((response) => {
              setRelatorioGeralWhatsApp(response.data)
            })
            .catch(() => {
              setRelatorioGeralWhatsApp([])
            })
        }

        api
          .post('api/v2/pendencies', {
            isActive: 'false',
          })
          .then((response) => {
            setRelatorioGeralPendencias(response.data)
          })
          .catch((err) => {
            return err
          })

        api
          .post('ligacoes/find/report', {
            dataInicial,
            dataFinal,
            sn_pendente: 'N',
            sn_agendado: '',
          })
          .then((response) => {
            setRelatorioMotivoLigacao(response.data)
          })
          .catch((err) => {
            return err
          })
      })
    }
  }, [isOpen, dataInicial, dataFinal])

  const handleDataInicial = (data) => {
    setdataInicial(data)
  }

  const handleDataFinal = (data) => {
    setdataFinal(data)
  }

  // Gera a planilha de acordo com os dados passados
  function gerarPlanilha(dados) {
    const name = 'Ligações_' + dataInicial + '_a_' + dataFinal
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet(name)

    const dataFormat = dados.map((item) => {
      return {
        ...item,
        desc_motivo: item.callings_reasons.desc_motivo,
        edited_at: moment(item.edited_at).format('YYYY-MM-DD'),
        edited_hr: moment(item.edited_at).format('HH:mm:ss'),
      }
    })

    worksheet.columns = [
      { header: 'Data Criação', key: 'data', width: 20 },
      { header: 'Hora Criação', key: 'hora', width: 30 },
      { header: 'Data Edição', key: 'edited_at', width: 30 },
      { header: 'Hora Edição', key: 'edited_hr', width: 30 },
      { header: 'Grupo', key: 'grupo', width: 15 },
      { header: 'Numero', key: 'num_origem', width: 15 },
      { header: 'Agendado', key: 'sn_agendado', width: 15 },
      { header: 'Ligação Perdida', key: 'sn_perdida', width: 15 },
      { header: 'Pendente', key: 'sn_pendente', width: 15 },
      { header: 'Usuario', key: 'usuario', width: 15 },
      {
        header: 'Item de agendamento',
        key: 'ds_item_agendamento',
        width: 15,
      },
      { header: 'Convênio', key: 'nm_convenio', width: 15 },
      {
        header: 'Motivo',
        key: 'desc_motivo',
        width: 15,
      },
      { header: 'Observação', key: 'obs_ligacao', width: 15 },
    ]

    worksheet.addRows(dataFormat)

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      saveAs(blob, name.toUpperCase() + '.xlsx')
    })
  }

  // Gera a planilha de acordo com os dados passados
  function gerarPlanilhaWpp(dados) {
    const name = 'wpp_' + dataInicial + '_a_' + dataFinal
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet(name)

    const dataFormat = dados.map((item) => {
      return {
        ...item,
      }
    })

    worksheet.columns = [
      { header: 'Data', key: 'data', width: 20 },
      { header: 'Usuário', key: 'nm_usuario_atendimento', width: 30 },
      { header: 'nome', key: 'nome', width: 30 },
      { header: 'sn_atendido', key: 'sn_atendido', width: 15 },
      { header: 'sn_manual', key: 'sn_manual', width: 15 },
      { header: 'sn_pendente', key: 'sn_pendente', width: 15 },
      { header: 'ultimo_envio', key: 'ultimo_envio', width: 15 },
    ]

    worksheet.addRows(dataFormat)

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      saveAs(blob, name.toUpperCase() + '.xlsx')
    })
  }

  // Gera a planilha de acordo com os dados passados
  function gerarPlanilhaPendencias(dados) {
    const name = 'pendencias_' + dataInicial + '_a_' + dataFinal
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet(name)

    const dataFormat = dados.map((item) => {
      return {
        ...item,
        created_at: moment(item.created_at).format('YYYY-MM-DD'),
        motivo: item.pendencies_motives.descMotive,
        setor_destino: item.nameSectorDestiny.nm_setor,
        setor_origem: item.nameSectorCreated.nm_setor,
        ultima_alteracao: moment(item.last_update).format('YYYY-MM-DD'),
      }
    })

    worksheet.columns = [
      { header: 'Data', key: 'created_at', width: 20 },
      { header: 'Data ultima alteracao', key: 'ultima_alteracao', width: 20 },
      { header: 'paciente', key: 'namePatient', width: 30 },
      { header: 'Criado por', key: 'userCreated', width: 30 },
      { header: 'setor_origem', key: 'setor_origem', width: 30 },
      { header: 'setor_destino', key: 'setor_destino', width: 30 },
      { header: 'Concluido por', key: 'userConclusion', width: 30 },
      { header: 'Observação', key: 'obsCreated', width: 30 },
      { header: 'Observação Conclusão', key: 'obsConclusion', width: 90 },
      { header: 'Motivo', key: 'motivo', width: 30 },
    ]

    worksheet.addRows(dataFormat)

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      saveAs(blob, name.toUpperCase() + '.xlsx')
    })
  }

  return (
    <ModalComponent isOpen={isOpen} onClose={handleChangeModalIsOpen}>
      <VStack spacing={4} w="100%">
        <HStack w="100%">
          <FormControl isRequired>
            <FormLabel>Data Inicial</FormLabel>
            <Input type="date" onChange={(e) => handleDataInicial(e.target.value)} value={dataInicial} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Data Final</FormLabel>
            <Input type="date" onChange={(e) => handleDataFinal(e.target.value)} value={dataFinal} />
          </FormControl>
        </HStack>
        {dataFinal ? (
          <ButtonGroup w="100%">
            <Button
              variant={'outline'}
              size={'xs'}
              onClick={() => gerarPlanilha(RelatorioGeralLigacoes)}
              leftIcon={<FaFileExcel />}
              isDisabled={Loading}
            >
              Download Ligações ({RelatorioGeralLigacoes.length})
            </Button>

            <Button
              variant={'outline'}
              size={'xs'}
              onClick={() => gerarPlanilhaWpp(RelatorioGeralWhatsApp)}
              leftIcon={<FaFileExcel />}
              isDisabled={Loading}
            >
              Download WhatsApp ({RelatorioGeralWhatsApp.length})
            </Button>

            <Button
              variant={'outline'}
              size={'xs'}
              isDisabled={Loading}
              onClick={() => gerarPlanilhaPendencias(RelatorioGeralPendencias)}
              leftIcon={<FaFileExcel />}
            >
              Download Pendências ({RelatorioGeralPendencias.length})
            </Button>
          </ButtonGroup>
        ) : (
          <p>Selecione um período para visualizar os relatórios</p>
        )}

        <Center w="100%">{Loading && <Spinner />}</Center>

        {/* Lista dos motivos do wpp */}
        <List spacing={3} marginTop={4}>
          {RelatorioMotivoLigacao.map((ligacao, index) => (
            <ListItem key={index}>
              <Text>
                {ligacao.qtd} - {ligacao.desc_motivo}
              </Text>
            </ListItem>
          ))}
        </List>
      </VStack>
    </ModalComponent>
  )
}
