import {
  Avatar,
  Button,
  Center,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  Input,
  Spinner,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useToast,
  VStack,
} from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { AiOutlineFieldTime } from 'react-icons/ai'
import { BiCheckDouble, BiEditAlt } from 'react-icons/bi'
import { FiCalendar } from 'react-icons/fi'
import { ModalComponent } from '../../_styles/ModalComponent '
import { Context as ContextUser } from '../../Context/UserContext'
import api from '../../services/api'
import { ContainerPageTemplate } from '../../styles/ContainerPageTemplate'

function Pendencias() {
  const { User } = useContext(ContextUser)
  const [Pendencias, setPendencias] = useState([])
  const [pendenciaSelecionada, setPendenciaSelecionada] = useState([])
  const [isActive, setIsActive] = useState(true)
  const [modalIsOpenPendenciaById, setmodalIsOpenPendenciaById] = useState(false)
  const [isLoading, setisLoading] = useState(true)
  const [obsConclusion, setObsConclusion] = useState('')
  const toast = useToast()

  // Verificar as pendencias do cliente.
  useEffect(() => {
    // Se o código do cliente for diferente de null, então faça a requisição.
    if (User?.clientId) {
      setisLoading(true)
      api
        .post('/api/v2/pendencies', { isActive: isActive ? 'true' : 'false' })
        .then((res) => {
          setPendencias(res.data)
          setisLoading(false)
        })
        .catch((err) => {
          setisLoading(false)
          toast({
            title: 'Erro ao buscar as pendências.',
            description: err.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        })
    }
  }, [isActive, User])

  // Abre o modal de pendencias por id.
  const handleOpenModalPendencia = (id) => {
    setisLoading(true)
    api
      .post('/api/v2/pendencies/id', { id: id })
      .then((res) => {
        setPendenciaSelecionada(res.data)
        setisLoading(false)
        setmodalIsOpenPendenciaById(true)
      })
      .catch((err) => {
        setisLoading(false)
        toast({
          title: 'Erro ao buscar a pendência.',
          description: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  // Form para atualizar a pendencia.
  const handleUpdatePendencia = (e) => {
    e.preventDefault()
    setisLoading(true)
    setmodalIsOpenPendenciaById(false)
    api
      .post('/api/v2/pendencies/update', { id: pendenciaSelecionada.id, obsConclusion, isActive: false })
      .then(() => {
        setmodalIsOpenPendenciaById(false)
        toast({
          title: 'Pendência atualizada com sucesso.',
          description: 'A pendência foi atualizada com sucesso.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setisLoading(false)
      })
      .catch((err) => {
        setmodalIsOpenPendenciaById(true)
        toast({
          title: 'Erro ao atualizar a pendência.',
          description: err.error,
          status: 'error',
          duration: 3000,
          isClosable: false,
        })
        setisLoading(false)
      })
  }

  return (
    <>
      {/* Modal de pendencia selecionada */}
      <ModalComponent
        isOpen={modalIsOpenPendenciaById}
        onClose={() => setmodalIsOpenPendenciaById(false)}
        title={pendenciaSelecionada.namePatient}
      >
        <FormControl>
          <FormLabel fontSize={'sm'}>Motivo da pendência:</FormLabel>
          <Input value={pendenciaSelecionada.pendencies_motives?.descMotive} isDisabled />
        </FormControl>
        <FormControl>
          <FormLabel fontSize={'sm'}>Observações:</FormLabel>
          <Textarea value={pendenciaSelecionada.obsCreated} isDisabled />
        </FormControl>

        {/* Apresenta apenas quando a pendência não foi concluida */}
        {!pendenciaSelecionada.isActive && (
          <FormControl>
            <FormLabel fontSize={'sm'}>Observações da conclusão:</FormLabel>
            <Textarea value={pendenciaSelecionada.obsConclusion} isDisabled />
          </FormControl>
        )}

        <Divider h={2} my={4} />

        {pendenciaSelecionada.isActive && (
          <form onSubmit={handleUpdatePendencia}>
            <VStack>
              <FormControl isRequired>
                <FormLabel fontSize={'sm'}>Observações da conclusão:</FormLabel>
                <Textarea onChange={(e) => setObsConclusion(e.target.value)} />
                <FormHelperText>Após concluído, não poderá ser desfeito.</FormHelperText>
              </FormControl>
              <Button type="submit" colorScheme={'blue'}>
                Concluir pendência
              </Button>
            </VStack>
          </form>
        )}

        <HStack fontSize={'10px'} justifyContent="space-between" w="100%" id={pendenciaSelecionada.id}>
          <Text>Criado por: {pendenciaSelecionada.userCreated}</Text>
          {pendenciaSelecionada.userConclusion && <Text>Concluído por: {pendenciaSelecionada.userConclusion}</Text>}
        </HStack>
      </ModalComponent>
      <ContainerPageTemplate>
        {isLoading ? (
          <Center h="80vh" flexDir={'column'}>
            <Spinner />
            <Text ml={4}>Consultando as pendências...</Text>
          </Center>
        ) : (
          <>
            <HStack py={4}>
              <Button onClick={() => setIsActive(!isActive)} variant="link">
                {isActive ? 'Apresentando as pendentes' : 'Apresentando as concluídas'}
              </Button>
            </HStack>

            {Pendencias.length > 0 ? (
              <TableContainer borderRadius={8} p={8} boxShadow="base" bg="gray.50">
                <Table variant="unlysted" size="sm">
                  <TableCaption>{Pendencias.length} pendências encontradas.</TableCaption>
                  <Thead>
                    <Tr>
                      <Th />
                      <Th>De</Th>
                      <Th>Para</Th>
                      <Th>Motivo</Th>
                      <Th>Paciente</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {Pendencias.map((pendencia) => (
                      <Tr key={pendencia.id}>
                        <Td>
                          <Avatar
                            name={pendencia.userCreated}
                            title={pendencia.userCreated}
                            size="sm"
                            cursor={'pointer'}
                          />
                        </Td>
                        <Td>{pendencia.nameSectorCreated?.nm_setor}</Td>
                        <Td>{pendencia.nameSectorDestiny?.nm_setor}</Td>
                        <Td>{pendencia.pendencies_motives?.descMotive}</Td>
                        <Td>{pendencia.namePatient}</Td>
                        <Td>
                          <HStack spacing={4}>
                            <Icon
                              cursor={'pointer'}
                              as={FiCalendar}
                              title={
                                'Criado em ' +
                                new Date(pendencia.created_at).toLocaleDateString('pt-BR', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                })
                              }
                              w={5}
                              h={5}
                            />
                            <Icon
                              cursor={'pointer'}
                              as={AiOutlineFieldTime}
                              title={
                                'Última edição:' +
                                new Date(pendencia.last_update).toLocaleDateString('pt-BR', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                })
                              }
                              w={5}
                              h={5}
                            />

                            <Icon
                              cursor={'pointer'}
                              as={BiEditAlt}
                              title={pendencia.obsCreated}
                              w={5}
                              h={5}
                              onClick={() => handleOpenModalPendencia(pendencia.id)}
                            />
                          </HStack>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            ) : (
              <Center h="60vh">
                <VStack>
                  <Icon as={BiCheckDouble} w={6} h={6} />
                  <Text>Não foram encontradas pendências...</Text>
                </VStack>
              </Center>
            )}
          </>
        )}
      </ContainerPageTemplate>
    </>
  )
}

export default React.memo(Pendencias)
