import { useState } from 'react'

import { Button, FormControl, Input, VStack } from '@chakra-ui/react'
import { FiSave } from 'react-icons/fi'
import { CreateFuncoesLogs } from '../../Functions/FuncoesSistemaLog/CreateFuncoesLogs'
import { NotificaError } from '../../Functions/Toast/error'
import { NotificaSuccess } from '../../Functions/Toast/success'
import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api'

export function LaudosLaudar({ isOpen, handleChangeModalIsOpen, info }) {
  const [File, setFile] = useState('')
  const cd_cliente = localStorage.getItem('cd_cliente')

  async function handleLaudar(e) {
    e.preventDefault()

    const formData = new FormData()
    formData.append('image', File)
    formData.append('id', info.id)

    const descricao = `Laudo inserido ${info.nm_paciente} - Atendimento ${info.nm_paciente} Pedido ${info.cd_ped_rx}`

    CreateFuncoesLogs(cd_cliente, descricao, 'LAUDAR').then((res) => {
      api
        .post('pedidos/update/laudar', formData, {})
        .then(() => {
          handleChangeModalIsOpen()
          NotificaSuccess('Laudado com sucesso!')
        })
        .catch(() => {
          NotificaError('Erro ao anexar laudo')
        })
    })
  }

  return (
    <ModalComponent isOpen={isOpen} onClose={() => handleChangeModalIsOpen()} title={info.nm_paciente}>
      <form onSubmit={handleLaudar} encType="multipart/form-data">
        <VStack spacing={8}>
          <FormControl isRequired>
            <Input type="file" name="arquivo" required="" onChange={(e) => setFile(e.target.files[0])} />
          </FormControl>
          <Button size={'lg'} colorScheme="blue" type="submit" leftIcon={<FiSave />}>
            Salvar Laudo
          </Button>
        </VStack>
      </form>
    </ModalComponent>
  )
}
