import { Box, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FindMotivosLigacoes } from '../../Functions/Ligacoes/FindMotivosLigacoes'
import { UpdateStatusLigacoes } from '../../Functions/Ligacoes/UpdateStatusLigacoes'
import { NotificaSuccess } from '../../Functions/Toast/success'
import { ModalComponent } from '../../_styles/ModalComponent '

export function ModalLigacoesPorID({ isOpen, handleChangeModalIsOpen, info }) {
  const [MotivosLigacoes, setmotivosLigacoes] = useState([])
  const [sn_agendado, setsn_agendado] = useState('')
  const [MotivoLigacaoSelecionado, setMotivoLigacaoSelecionado] = useState('')
  const [obs_ligacao, setobs_ligacao] = useState('')
  const [nm_convenio, setnm_convenio] = useState('')
  const [nm_con_pla, setnm_con_pla] = useState('')
  const [ds_item_agendamento, setds_item_agendamento] = useState('')

  useEffect(() => {
    if (isOpen) {
      FindMotivosLigacoes().then((res) => {
        setmotivosLigacoes(res)
      })
    }
  }, [isOpen])

  async function handleAtualizarAgendamento(e) {
    e.preventDefault()
    UpdateStatusLigacoes(
      info.id,
      sn_agendado,
      MotivoLigacaoSelecionado,
      obs_ligacao,
      nm_convenio,
      nm_con_pla,
      ds_item_agendamento
    ).then(() => {
      NotificaSuccess('Registrado com sucesso...')
      handleChangeModalIsOpen()
    })
  }

  return (
    <ModalComponent isOpen={isOpen} onClose={handleChangeModalIsOpen}>
      <Box>
        <VStack as="form" onSubmit={handleAtualizarAgendamento}>
          <p>Houve agendamento de consulta e/ou exame?</p>
          <select onChange={(e) => setsn_agendado(e.target.value)} id="select">
            <option selected="">Selecione uma opção</option>
            <option value="S">Sim</option>
            <option value="N">Não</option>
          </select>
          <p>Qual o motivo da ligação? </p>
          <select onChange={(e) => setMotivoLigacaoSelecionado(e.target.value)} id="select">
            <option>Selecione uma opção</option>
            {MotivosLigacoes.map((motivo, index) => (
              <option value={motivo.id}>{motivo.desc_motivo}</option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Observações da ligação"
            id="input-mensagem"
            required=""
            onChange={(e) => setobs_ligacao(e.target.value)}
          />
          <input
            type="text"
            placeholder="Convênio"
            id="input-mensagem"
            required=""
            onChange={(e) => setnm_convenio(e.target.value)}
          />
          <input
            type="text"
            placeholder="Plano"
            id="input-mensagem"
            required=""
            onChange={(e) => setnm_con_pla(e.target.value)}
          />
          <input
            type="text"
            placeholder="Item de Agendamento"
            id="input-mensagem"
            required=""
            onChange={(e) => setds_item_agendamento(e.target.value)}
          />
          <button id="buttonDefault" className="link-primary" type="submit">
            Atualizar Ligação
          </button>
        </VStack>
      </Box>
    </ModalComponent>
  )
}
