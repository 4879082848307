/* eslint-disable */
import api from '../../services/api'

export async function UpdateStatusLigacoes(
  id,
  sn_agendado,
  id_motivo_ligacao,
  obs_ligacao,
  nm_convenio,
  nm_con_pla,
  ds_item_agendamento
) {
  return await api
    .post('ligacoes/update', {
      id,
      sn_agendado,
      sn_pendente: 'N',
      id_motivo_ligacao,
      obs_ligacao,
      usuario: localStorage.getItem('usuario'),
      nm_convenio,
      nm_con_pla,
      ds_item_agendamento,
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}
