import api from '../services/api'

async function CreateLog(functionId, scheduleId, data) {
  if (!functionId) throw new Error('FunctionId is required')

  const info = await api
    .post('/logs', {
      functionId,
      scheduleId,
      data: { data },
    })
    .then(() => {
      return true
    })
    .catch((err) => {
      return false
    })
  return info
}

export { CreateLog }
