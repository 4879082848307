import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Select,
  Stack,
  Text,
  Textarea,
  VStack,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AiOutlineVideoCamera } from 'react-icons/ai'
import { BiImages } from 'react-icons/bi'
import { FaHospital, FaUserCheck } from 'react-icons/fa'
import { FiImage, FiPlus, FiSave, FiSend } from 'react-icons/fi'
import { ImProfile } from 'react-icons/im'
import { WiDayCloudyWindy } from 'react-icons/wi'
import { AgendamentosCreatePresencaConfirmada } from '../Functions/Agendamentos/agendamentosData'
import { AddMensagem } from '../Functions/Mensagens/AddMensagem'
import { MensagensProntasAgendamentoAutorizado } from '../Functions/MensagensProntas/AgendamentoAutorizado'
import { ListDocumentsComponent } from '../_styles/DocumentosComponent'
import { ModalComponent } from '../_styles/ModalComponent '
import api from '../services/api'
import { ModalScheduleSendPreparo } from './Agendamentos/ModalScheduleSendPreparo'
import { LoadingModal } from './LoadingModal'

function InfoViewCopy({ label, value }) {
  const toast = useToast()

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(function () {
      toast({
        title: 'Copiado com sucesso!',
        description: 'Pressione CTRL+V para colar.',
        status: 'info',
        duration: 1500,
        isClosable: true,
        position: 'top-right',
      })
    })
  }

  return (
    <Box size="xs">
      <Text fontSize="sm" color="gray.400">
        {label}
      </Text>
      <HStack>
        <Text fontSize="sm" onClick={() => copyToClipboard(value)} cursor={'pointer'}>
          {value}
        </Text>
      </HStack>
    </Box>
  )
}

const IconInHospital = ({ inHospital }) => {
  return (
    <>
      <Icon
        as={FaHospital}
        w={5}
        h={5}
        color={inHospital ? 'green.500' : 'gray.400'}
        title={inHospital ? 'Presença no Hospital confirmada pelo balcão' : 'Presença não confirmada pelo balcão'}
        cursor={'pointer'}
      />
    </>
  )
}

const IconDocumentos = ({ documentos }) => {
  // Se não existir documentos não exibe o ícone.
  if (!documentos) return <></>

  return (
    <>
      <Icon
        as={BiImages}
        id="icon-documentos"
        title={'Documentos encontrados: ' + documentos.length}
        w={5}
        h={5}
        color={documentos.length > 0 ? 'green.500' : 'gray.400'}
        cursor={'pointer'}
      />
    </>
  )
}

const IconAtendimento = ({ atendimento }) => {
  return (
    <Icon
      w={5}
      h={5}
      id="icon-atendimento"
      as={FaUserCheck}
      color={atendimento ? 'green.500' : 'gray.400'}
      title={'Atendimento aberto: ' + atendimento}
      cursor={'pointer'}
    />
  )
}

const IconProntuario = ({ atendimento }) => {
  return (
    <>
      {atendimento && (
        <Icon
          as={ImProfile}
          w={5}
          h={5}
          color="green.500"
          id="icon-prontuario"
          title="Agendamento com Prontuário"
          cursor={'pointer'}
        />
      )}
    </>
  )
}

const IconSendPresencaEnviada = ({ presence }) => {
  return (
    <Icon
      as={FiSend}
      w={5}
      h={5}
      color={presence ? 'green.500' : 'gray.500'}
      id="icon-prontuario"
      title={presence ? 'Mensagem de confirmação enviada.' : 'Mensagem de confirmação não enviada.'}
      cursor={'pointer'}
    />
  )
}

const IconConfirmacaoAgendamentoPresenca = ({ presence }) => {
  if (presence === null) return null

  return (
    <Icon
      as={WiDayCloudyWindy}
      w={5}
      h={5}
      color={presence ? 'green.500' : 'red.500'}
      title="Informa se o paciente confirmou sua presença para o dia do agendamento"
    />
  )
}

export function SchedulesItem({ schedule, index, onClick }) {
  const direction = useBreakpointValue({ base: 'row', md: 'row' })

  return (
    <VStack
      p={1}
      key={index}
      w="full"
      alignItems={'left'}
      borderRadius={5}
      cursor="pointer"
      bg="gray.50"
      _hover={{ boxShadow: 'base' }}
      onClick={() => onClick(schedule)}
      textAlign={'center'}
      border={'1px solid #ccc !important'}
    >
      <Text fontSize={'12px'} fontWeight="bold">
        {schedule.nm_paciente}
      </Text>
      <Text fontSize={'10px'}>{schedule.hr_agenda}</Text>

      <Text fontSize={'10px'}>{schedule.schedule_item?.ds_item_agendamento}</Text>
      <Text fontSize={'10px'}>{schedule.covenants?.nm_convenio}</Text>
      <Text fontSize={'10px'}>{schedule.schedules_status?.status}</Text>
      <Stack direction={direction} spacing={5} px={5}>
        {/* <IconDocumentos documentos={schedule?.documentos} /> */}
        <IconInHospital inHospital={schedule.inHospital} />
        <IconAtendimento atendimento={schedule.attendanceId} />
        <IconProntuario atendimento={schedule.patientId} />
        <IconSendPresencaEnviada presence={schedule.isEnvioConfirmacao} />
        <IconConfirmacaoAgendamentoPresenca presence={schedule.isConfirmedPresence} />
      </Stack>
    </VStack>
  )
}

export function ModalScheduleSelected({
  isOpen,
  onClose,
  scheduleSelected,
  setScheduleSelected,
  setArrayAgendamentos,
  arrayAgendamentos,
}) {
  const toast = useToast()
  const [documentos, setDocumentos] = useState([])
  const [agendamentoSelecionadoOld, setAgendamentoSelecionadoOld] = useState([])
  const [isOpenModalInsertAutorizacao, setisOpenModalInsertAutorizacao] = useState(false)
  const [isOpenModalCreatePendencia, setisOpenModalCreatePendencia] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [File, setFile] = useState('')
  const [status, setStatus] = useState('')
  const [motivosPendencias, setmotivosPendencias] = useState([])
  const [setores, setSetores] = useState([])
  const { handleSubmit, register } = useForm()
  const direction = useBreakpointValue({ base: 'column', md: 'row' })
  const [isOpenModalSendPreparo, setisOpenModalSendPreparo] = useState(false)
  const [documentosProntuario, setDocumentosProntuario] = useState([])
  const isDeviceSmall = useBreakpointValue({ base: true, md: false })

  // Anexar autorização e enviar mensagem ao paciente!
  async function handleAnexar(e) {
    e.preventDefault()
    setIsLoading(true)
    const formData = new FormData()
    formData.append('image', File)
    formData.append('id', scheduleSelected.id)
    formData.append('status', status)
    api
      .post('autorizacoes/anexar', formData, {})
      .then(() => {
        toast({
          title: 'Autorização anexada com sucesso...',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })

        // Quando o status for de autorização a mensagem deve ser enviada ao paciente.
        /* DRSOL-8 */
        if (status === 'A') {
          MensagensProntasAgendamentoAutorizado(
            scheduleSelected.nm_paciente,
            scheduleSelected.schedule_item.ds_item_agendamento
          ).then((res) => {
            AddMensagem(scheduleSelected.nr_celular, '', scheduleSelected.id, res).then((res) => {
              toast({
                title: 'Mensagem enviada com sucesso.',
                description: res,
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'top-right',
              })
            })
          })
        }
      })
      .catch(() => {
        toast({
          title: 'Erro ao anexar autorização...',
          description: 'Tente novamente mais tarde.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
      .finally(() => {
        api
          .get('aws/documentos/schedule/' + scheduleSelected.id)
          .then((res) => {
            setDocumentos(res.data)
          })
          .catch(() => {
            toast({
              title: 'Erro ao buscar documentos.',
              description: 'Tente novamente mais tarde.',
              status: 'error',
              duration: 3000,
              isClosable: true,
              position: 'top-right',
            })
          })
        setisOpenModalInsertAutorizacao(false)
        setIsLoading(false)
      })
  }

  const handleOpenAutorizacao = (url) => {
    if (url.includes('data:image')) {
      let w = window.open('about:blank')
      let image = new Image()
      image.src = url
      setTimeout(function () {
        w.document.write(image.outerHTML)
      }, 0)
    } else {
      window.open(url, '_blank')
    }
  }

  // Após formulário deve ser enviado para a API
  async function handleCreatePendencia(data) {
    setIsLoading(true)
    const dataInsert = {
      obsCreated: data.obs,
      sectorDestinyId: data.setorId,
      pendenciesMotiveId: data.motivoId,
      namePatient: scheduleSelected?.nm_paciente,
    }

    api
      .post('api/v2/pendencies/create', dataInsert)
      .then((res) => {
        toast({
          title: 'Pendência criada com sucesso.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
      .catch((err) => {
        toast({
          title: 'Erro ao criar pendência.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
      .finally(() => {
        setisOpenModalCreatePendencia(false)
        setIsLoading(false)
      })
  }

  // DRA-10 - Eu como agente de atendimetno preciso consultar os documentos antes da abertura do atendimento. 📝 DR-38
  const handleFindDocumentosProntuario = (schedule) => {
    setDocumentosProntuario([])
    if (!schedule.patientId) {
      toast({
        title: 'Paciente não encontrado.',
        description: 'Agendamento não possui prontuário associado.',
        status: 'info',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      })

      return
    }
    setIsLoading(true)

    api
      .get('/aws/documentos/patient/' + schedule.patientId)
      .then((res) => {
        setDocumentosProntuario(res.data)
        toast({
          title: 'Documentos encontrados.',
          description: res.data.length + ' documentos encontrados.',
          status: 'info',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
      .catch(() => {
        toast({
          title: 'Erro ao buscar documentos.',
          description: 'Tente novamente mais tarde.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  // Migração para confirmar presença de agendamento.
  const handleConfirmarPresenca = (scheduleId) => {
    api
      .post('agendamentos/balcao/atualizar', { id: scheduleId })
      .then(() => {
        arrayAgendamentos.map((agendamento) => {
          if (agendamento.id === scheduleId) {
            agendamento.inHospital = true
          }
        })
        setArrayAgendamentos(arrayAgendamentos)
        setScheduleSelected({ ...scheduleSelected, inHospital: true })
        toast({
          title: 'Presença confirmada com sucesso...',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
        AgendamentosCreatePresencaConfirmada(scheduleSelected.cd_it_agenda_central, scheduleSelected.nm_paciente).then(
          (res) => {
            if (res) {
              toast({
                title: 'Horário criado com sucesso.',
                description: 'O horário foi criado com sucesso.',
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'top-right',
              })
            }
          }
        )
      })
      .catch(() => {
        toast({
          title: 'Erro ao confirmar presença...',
          description: 'Tente novamente mais tarde.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
  }

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(function () {
      toast({
        title: 'Copiado com sucesso!',
        description: 'Pressione CTRL+V para colar.',
        status: 'info',
        duration: 1500,
        isClosable: true,
        position: 'top-right',
      })
    })
  }

  const handleCreateZoom = (schedule) => {
    api
      .post('reuniao', { scheduleId: schedule.id, userId: '1K68bmcVRLagINZJVuKOVA' })
      .then((res) => {
        setScheduleSelected({ ...scheduleSelected, isOnline: true, urlOnline: res.data.meeting.start_url })
        toast({
          title: 'Reunião criada com sucesso.',
          description: 'A consulta foi criada com sucesso.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
      .catch(() => {
        toast({
          title: 'Erro ao criar reunião.',
          description: 'Tente novamente mais tarde.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
  }

  // Consultar os documentos do agendamento selecionado
  useEffect(() => {
    if (!isOpen) return
    if (!scheduleSelected) return
    setDocumentosProntuario([])
    setDocumentos([])

    api
      .post('agendamentos/busca/id', {
        id: scheduleSelected.id,
      })
      .then((res) => {
        setAgendamentoSelecionadoOld(res.data[0])
      })
      .catch(() => {
        toast({
          title: 'Erro ao buscar agendamento.',
          description: 'Tente novamente mais tarde.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })

    api
      .get('aws/documentos/schedule/' + scheduleSelected.id)
      .then((res) => {
        setDocumentos(res.data)
      })
      .catch(() => {
        toast({
          title: 'Erro ao buscar documentos.',
          description: 'Tente novamente mais tarde.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [scheduleSelected, isOpen])

  // Consultas que devem ser feitas quando abrir o modal de criar pendência
  useEffect(() => {
    if (!isOpenModalCreatePendencia) return

    setIsLoading(true)

    api
      .get('api/v2/sectors')
      .then((res) => {
        setSetores(res.data)
      })
      .catch(() => {
        toast({
          title: 'Erro ao buscar setores.',
          description: 'Tente novamente mais tarde.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })

    api
      .get('api/v2/pendencies/motives', {})
      .then((res) => {
        setmotivosPendencias(res.data)
      })
      .catch((err) => {
        return err
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [isOpenModalCreatePendencia])

  return (
    <>
      {isLoading && <LoadingModal isLoading={isLoading} />}

      <ModalScheduleSendPreparo
        isOpen={isOpenModalSendPreparo}
        onClose={() => setisOpenModalSendPreparo(false)}
        id={scheduleSelected?.id}
      />

      {/* Modal para criar novas pendências */}
      <ModalComponent isOpen={isOpenModalCreatePendencia} onClose={() => setisOpenModalCreatePendencia(false)}>
        <VStack as="form" onSubmit={handleSubmit(handleCreatePendencia)}>
          <FormControl isRequired>
            <FormLabel>Selecione o setor </FormLabel>
            <Select {...register('setorId')} placeholder="Selecione...">
              {setores.map((setor) => (
                <option value={setor.id}>{setor.nm_setor}</option>
              ))}
            </Select>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Qual o motivo?</FormLabel>
            <Select onChange={(e) => setid_motivo(e.target.value)} placeholder="Selecione..." {...register('motivoId')}>
              {motivosPendencias.map((motivo) => (
                <option value={motivo.id}>{motivo.descMotive}</option>
              ))}
            </Select>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Descreva detalhadamente o motivo da pendência.</FormLabel>
            <Textarea type="text" placeholder="Digite aqui uma observação..." {...register('obs')} />
          </FormControl>
          <Button type="submit" pr={5} leftIcon={<FiSave />} colorScheme="blue" size={'md'}>
            Salvar
          </Button>
        </VStack>
      </ModalComponent>

      {/* Modal para inserir autorização no agendamento */}
      <ModalComponent
        isOpen={isOpenModalInsertAutorizacao}
        title="Inserir Autorização"
        onClose={() => setisOpenModalInsertAutorizacao(false)}
      >
        <VStack alignItems={'left'} spacing={1}>
          <HStack>
            <InfoViewCopy label="Nome" value={scheduleSelected?.nm_paciente} />
          </HStack>

          <form onSubmit={handleAnexar} encType="multipart/form-data">
            <VStack spacing={8}>
              <FormControl isRequired>
                <FormLabel> Status</FormLabel>
                <Select onChange={(e) => setStatus(e.target.value)} size="lg" id="select-status-autorizacao">
                  <option value="DEFAULT" selected disabled>
                    Escolha uma opção...
                  </option>
                  <option value="A">Autorizado</option>
                  <option value="E">Em Análise</option>
                  <option value="N">Negado</option>
                  <option value="S">Sem Guia</option>
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel> Documento da autorização</FormLabel>
                <Input
                  type="file"
                  name="arquivo"
                  id="input-file-autorizacao"
                  required=""
                  onChange={(e) => setFile(e.target.files[0])}
                  size="lg"
                />
              </FormControl>

              <Button colorScheme={'blue'} type="submit" leftIcon={<FiSave />} size="lg">
                Anexar
              </Button>
            </VStack>
          </form>
        </VStack>
      </ModalComponent>

      {/* Modal dos agendamentos */}
      <ModalComponent isOpen={isOpen} onClose={onClose} title={scheduleSelected?.nm_paciente}>
        <VStack alignItems={'left'} spacing={1} w="full">
          <HStack justifyContent={'space-between'}>
            <Stack direction={isDeviceSmall ? 'row' : 'row'} spacing={3}>
              <IconDocumentos documentos={documentos} />
              <IconInHospital inHospital={scheduleSelected?.inHospital} />
              <IconAtendimento atendimento={scheduleSelected?.attendanceId} />
              <IconSendPresencaEnviada presence={scheduleSelected?.isEnvioConfirmacao} />
              <IconConfirmacaoAgendamentoPresenca presence={scheduleSelected?.isConfirmedPresence} />
            </Stack>
            <Button
              size="sm"
              cursor={'pointer'}
              variant="link"
              leftIcon={<FaHospital />}
              title={scheduleSelected?.inHospital ? 'Presença no Hospital confirmada' : 'Confirmar Presença'}
              onClick={() => handleConfirmarPresenca(scheduleSelected?.id)}
              color={scheduleSelected?.inHospital ? 'green.500' : 'gray.400'}
              isDisabled={scheduleSelected?.inHospital ? true : false}
            >
              {scheduleSelected?.inHospital
                ? 'Confirmado às ' + moment(scheduleSelected?.dh_chegada).format('HH:mm')
                : 'Confirmar Presença'}
            </Button>
          </HStack>
          <Stack direction={isDeviceSmall ? 'column' : 'column'} spacing={1}>
            <InfoViewCopy
              label="Idade"
              value={`${
                scheduleSelected?.dt_nascimento && moment().diff(scheduleSelected?.dt_nascimento, 'years')
              } anos (${moment(scheduleSelected?.dt_nascimento).format('DD/MM/YYYY')})`}
            />

            <InfoViewCopy label="Celular" value={scheduleSelected?.nr_celular} />
          </Stack>

          <Stack direction={isDeviceSmall ? 'column' : 'column'} spacing={1}>
            <InfoViewCopy label="Atendimento" value={scheduleSelected?.attendance?.cd_atendimento} />
            <InfoViewCopy label="Data/Hora" value={scheduleSelected?.hr_agenda} />
          </Stack>
          <Stack direction={isDeviceSmall ? 'column' : 'column'} spacing={1}>
            <InfoViewCopy label="Convênio" value={scheduleSelected?.covenants?.nm_convenio} />
            <InfoViewCopy label="Carteira" value={scheduleSelected?.nr_carteira} />
          </Stack>
          <Stack direction={isDeviceSmall ? 'column' : 'column'} spacing={1}>
            <InfoViewCopy label="Prestador" value={scheduleSelected?.doctors?.nm_prestador} />
            <InfoViewCopy label="Item" value={scheduleSelected?.schedule_item?.ds_item_agendamento} />
            <InfoViewCopy label="Status" value={scheduleSelected?.schedules_status?.status} />
          </Stack>

          <InfoViewCopy label="Observação" value={scheduleSelected?.observacao} />

          <Stack direction={direction} spacing={1}>
            <Button
              size="sm"
              variant="primary"
              id="button-anexar-autorizacao"
              leftIcon={<FiPlus />}
              onClick={() => setisOpenModalInsertAutorizacao(true)}
            >
              Autorizar
            </Button>

            <Button
              size="sm"
              isDisabled={agendamentoSelecionadoOld?.img_base64 ? false : true}
              cursor={agendamentoSelecionadoOld?.img_base64 ? 'pointer' : 'not-allowed'}
              onClick={() => handleOpenAutorizacao(agendamentoSelecionadoOld?.img_base64)}
              leftIcon={<FiImage />}
            >
              Anexo do paciente
            </Button>
            <Button
              size="sm"
              onClick={() => handleOpenAutorizacao(agendamentoSelecionadoOld?.img_autorizacao)}
              isDisabled={agendamentoSelecionadoOld?.img_autorizacao ? false : true}
              cursor={agendamentoSelecionadoOld?.img_autorizacao ? 'pointer' : 'not-allowed'}
              leftIcon={<FiImage />}
            >
              Autorização
            </Button>
            <Button
              size="sm"
              leftIcon={<FiPlus />}
              onClick={() => window.open('/agendamentos/anexar/' + scheduleSelected?.id, '_blank')}
            >
              Anexar (Paciente)
            </Button>

            <Button size="sm" onClick={() => setisOpenModalCreatePendencia(true)} leftIcon={<FiPlus />}>
              Pendência
            </Button>
            <Button size="sm" onClick={() => setisOpenModalSendPreparo(true)} leftIcon={<FiSend />}>
              Preparo
            </Button>
            <Button size="sm" leftIcon={<ImProfile />} onClick={() => handleFindDocumentosProntuario(scheduleSelected)}>
              Documentos Prontuario
            </Button>

            {scheduleSelected?.isOnline ? (
              <Button
                leftIcon={<AiOutlineVideoCamera />}
                size="sm"
                onClick={() => handleCopy(scheduleSelected?.urlOnline)}
              >
                Copiar Link
              </Button>
            ) : (
              <Button leftIcon={<AiOutlineVideoCamera />} onClick={() => handleCreateZoom(scheduleSelected)} size="sm">
                Criar Telemedicina
              </Button>
            )}
          </Stack>

          <ListDocumentsComponent documentos={documentosProntuario} isLoading={isLoading} />

          <ListDocumentsComponent documentos={documentos} isLoading={isLoading} />

          {!isDeviceSmall && (
            <Stack direction={isDeviceSmall ? 'column' : 'row'} spacing={1} w="100%" justifyContent={'space-between'}>
              <Text>{scheduleSelected?.cd_it_agenda_central}</Text>
              <Text>{scheduleSelected?.id}</Text>
            </Stack>
          )}
        </VStack>
      </ModalComponent>
    </>
  )
}
