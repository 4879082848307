import { useState } from 'react'

import { Button, Flex, FormControl, FormHelperText, FormLabel, Input, useToast, VStack } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import api from '../../services/api'

export default function EnvioDocumentosPeloChat() {
  const [File, setFile] = useState('')
  const [loading, setloading] = useState(false)
  const { id, cd_cliente } = useParams()
  const toast = useToast()

  async function handleAnexar(e) {
    e.preventDefault()
    setloading(true)
    const formData = new FormData()
    formData.append('id_contato', id)
    formData.append('cd_cliente', cd_cliente)
    formData.append('image', File)

    api
      .post('contatos/documentos/add', formData, {})
      .then(() => {
        setloading(false)
        toast({
          title: 'Documento Enviado.',
          description: 'Documento enviado com sucesso...',
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
      })
      .catch(() => {
        setloading(false)

        toast({
          title: 'Documento.',
          description: 'Erro ao enviar documento, verifique a extensão e tamanho do documento.',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top',
        })
      })
  }

  return (
    <Flex w="100vw" h="100vh" flexDir={'column'} placeContent={'center'} alignItems="center">
      <VStack maxW="80%" alignItems={'center'}>
        <form onSubmit={handleAnexar} id="formPacienteDocumento" encType="multipart/form-data">
          <VStack spacing={8}>
            <FormControl isRequired>
              <FormLabel>Tire uma foto do documento solicitado</FormLabel>
              <Input
                type="file"
                accept=".png, .jpg, .jpeg"
                name="arquivo"
                isRequired
                onChange={(e) => setFile(e.target.files[0])}
              />
              <FormHelperText>Formatos permitidos: jpg, jpeg, png.</FormHelperText>
            </FormControl>

            <Button id="buttonDefault" colorScheme={'blue'} type="submit" isLoading={loading} w="100%">
              Enviar
            </Button>
          </VStack>
        </form>
      </VStack>
    </Flex>
  )
}
