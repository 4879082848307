import { Center, HStack, Input, ListItem, OrderedList, Spinner, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FindOpcoesBotDdata } from '../../Functions/WhatsApp/FindOpcoesBotDdata'
import { ModalComponent } from '../../_styles/ModalComponent '

export function ModalOpcoesBotData({ isOpen, handleChangeModalIsOpen }) {
  const [dataInicial, setdataInicial] = useState(new Date().toISOString().slice(0, 10))
  const [dataFinal, setdataFinal] = useState(new Date().toISOString().slice(0, 10))
  const [Loading, setLoading] = useState(false)
  const [mapeamentoWhatsapp, setmapeamentoWhatsapp] = useState([])

  // Consultar as opções por data.
  useEffect(() => {
    if (!isOpen) return
    if (!dataInicial || !dataFinal) return
    if (isOpen) {
      setLoading(true)
      setmapeamentoWhatsapp([])
      FindOpcoesBotDdata(dataInicial, dataFinal)
        .then((result) => {
          setmapeamentoWhatsapp(result)
          setLoading(false)
        })
        .catch(() => {
          setmapeamentoWhatsapp([])
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [isOpen, dataInicial, dataFinal])

  return (
    <ModalComponent isOpen={isOpen} onClose={() => handleChangeModalIsOpen()} size="md" closeOnOverlayClick={false}>
      <HStack spacing={4}>
        <Input type="date" onChange={(e) => setdataInicial(e.target.value)} value={dataInicial} />
        <Input type="date" onChange={(e) => setdataFinal(e.target.value)} value={dataFinal} />
      </HStack>

      <Center p={4}>{Loading && <Spinner />}</Center>
      {!Loading && (
        <OrderedList mt={2}>
          {mapeamentoWhatsapp.map((item) => (
            <ListItem p={2} fontSize={'sm'}>
              <Text>
                {item.descricao} ({item.id_opcao})
              </Text>
              <Text>Quantidade: {item.QTD}</Text>
            </ListItem>
          ))}
        </OrderedList>
      )}
    </ModalComponent>
  )
}
