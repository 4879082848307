import { HStack, ListItem, Tag, TagLabel, TagLeftIcon, Text, VStack } from '@chakra-ui/react'
import { useContext } from 'react'
import { FiKey, FiPhone, FiUserCheck } from 'react-icons/fi'
import { Context as ContextUser } from '../../Context/UserContext'

export function AgendamentoItemDefault({
  id,
  nm_paciente,
  nr_celular,
  nm_prestador,
  ds_item_agendamento,
  nm_convenio,
  hr_agendamento,
  sn_paciente_local,
  cd_atendimento,
  handleOpen,
}) {
  const { Loading } = useContext(ContextUser)

  return (
    <ListItem
      onClick={() => handleOpen(id)}
      borderRadius={8}
      flexDir="row"
      key={id}
      cursor="pointer"
      w="100%"
      justifyContent={'space-between'}
      title={ds_item_agendamento}
      className="agendamento-item-default"
    >
      <HStack justifyContent={'space-between'} w="100%">
        <VStack alignItems={'left'} spacing={1}>
          <Text _hover={{ color: 'text' }}>{nm_paciente ? nm_paciente.substring(0, 40) + '...' : 'LIVRE'}</Text>
          <Text fontSize={'10px'}>{ds_item_agendamento}</Text>
        </VStack>
        <VStack>
          <Text>{hr_agendamento}</Text>
          {cd_atendimento && (
            <Tag size="sm" colorScheme="green" variant="outline">
              <TagLeftIcon boxSize="12px" as={FiKey} />
              <TagLabel>{cd_atendimento}</TagLabel>
            </Tag>
          )}
        </VStack>

        {sn_paciente_local === 'S' && <FiUserCheck color="green" size="32" />}
      </HStack>
      {!Loading && (
        <HStack>
          {nr_celular && nr_celular?.length !== 11 && (
            <Tag size={'sm'} key={nr_celular} variant="subtle" colorScheme="red">
              <TagLeftIcon boxSize="12px" as={FiPhone} />
              <TagLabel>{nr_celular}</TagLabel>
            </Tag>
          )}
        </HStack>
      )}
    </ListItem>
  )
}
