// DRA-FUNC-25 - Visualizar os exames criados no dia.
// DRA-HIST-12 - Eu como recepcionista preciso ver e liberar os exames do dia.
import {
  Center,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Spinner,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

import api from '../../services/api'
import { ContainerPageTemplate } from '../../styles/ContainerPageTemplate'

function ExamesV2() {
  const [exames, setexames] = useState([])
  const [data, setData] = useState(new Date().toISOString().slice(0, 10))
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()

  // Consultar os exames do dia!
  useEffect(() => {
    if (!data) return
    setIsLoading(true)
    api
      .get('v2/pedidos/data/' + data)
      .then(async (res) => {
        function groupArray(arr) {
          const finalData = []
          const result = arr.reduce(function (r, a) {
            r[a.clientId] = r[a.clientId] || []
            r[a.clientId].push(a)
            return r
          }, Object.create(null))
          const data = Object.entries(result)
          for (let i = 0; i < data.length; i++) {
            const result2 = data[i][1].reduce(function (r, a) {
              r[a.isLiberado] = r[a.isLiberado] || []
              r[a.isLiberado].push(a)
              return r
            }, Object.create(null))
            const value = {
              data: data[i][0],
              nomes: Object.keys(result2),
              medicos: Object.values(result2),
            }
            finalData.push(value)
          }
          return finalData
        }

        const examesGroup = await groupArray(res.data)

        setexames(examesGroup)
      })
      .catch((err) => {
        toast({
          title: 'Erro ao buscar os exames',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [data])

  return (
    <ContainerPageTemplate>
      <FormControl isRequired>
        <FormLabel htmlFor="date">Selecione a data.</FormLabel>
        <Input type={'date'} onChange={(e) => setData(e.target.value)} value={data} />
      </FormControl>

      <Center w="100%" minH={'10vh'}>
        {isLoading && <Spinner />}
      </Center>

      {exames.map((dt, index) => (
        <Grid key={index} templateColumns={`repeat(3, 1fr)`} gap={2}>
          {dt.medicos.map((dtMedicos, index2) => (
            <GridItem key={index2}>
              <Text>{dt.nomes[index2] === 'true' ? 'Liberados' : 'Pendentes'}</Text>
              <Grid alignItems={'left'} w="100%" textAlign={'left'} spacing={2}>
                {dtMedicos.map((ht, indht) => (
                  <VStack key={indht} p={4} bg="gray.50" borderRadius={4} boxShadow={'base'}>
                    <Text fontSize={'12px'} fontWeight="bold">
                      {ht.nm_paciente}
                    </Text>
                  </VStack>
                ))}
              </Grid>
            </GridItem>
          ))}
        </Grid>
      ))}
    </ContainerPageTemplate>
  )
}

export { ExamesV2 }
