// DD-2 - O prestador externo deve visualizar os exames da base nova, tela deve atualizar automaticamente
import { SearchIcon } from '@chakra-ui/icons'
import {
  Alert,
  AlertIcon,
  Button,
  Center,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  List,
  ListIcon,
  ListItem,
  Select,
  Stat,
  StatLabel,
  StatNumber,
  Tag,
  TagLabel,
  Text,
  VStack,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { BiImages } from 'react-icons/bi'
import { BsFilePlus } from 'react-icons/bs'
import { FiSave } from 'react-icons/fi'
import { GiHeartPlus } from 'react-icons/gi'
import { MdCheckCircle } from 'react-icons/md'
import { ModalAtendimentoSolicitaInternacao } from '../../Components/Atendimentos/ModalAtendimentoSolicitaInternacao'
import { LoadingModal } from '../../Components/LoadingModal'
import { Context as ContextUser } from '../../Context/UserContext'
import { ListDocumentsComponent } from '../../_styles/DocumentosComponent'
import { SimpleGridComponent } from '../../_styles/GridComponent'
import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api'
import { queryClient } from '../../services/queryClient'
import { supabase } from '../../services/supabase'
import { ContainerPageTemplate } from '../../styles/ContainerPageTemplate'

function InfoView({ label, value }) {
  return (
    <VStack fontSize={'xs'} alignItems={'left'} textAlign={'left'} spacing={0}>
      <Text fontSize="xs" color="gray.500" fontWeight={'bold'}>
        {label?.toUpperCase()}
      </Text>
      <HStack>
        <Text fontSize="xs" fontWeight="medium">
          {value}
        </Text>
      </HStack>
    </VStack>
  )
}

function InfoViewCopy({ label, value }) {
  const toast = useToast()

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(function () {
      toast({
        title: 'Copiado com sucesso!',
        description: 'Pressione CTRL+V para colar.',
        status: 'info',
        duration: 1500,
        isClosable: true,
        position: 'top-right',
      })
    })
  }

  return (
    <GridItem fontFamily={'Montserrat'} alignItems={'left'}>
      <Text fontSize="sm" color="gray.400">
        {label}
      </Text>
      <HStack>
        <Text fontSize="sm" onClick={() => copyToClipboard(value)}>
          {value}
        </Text>
      </HStack>
    </GridItem>
  )
}

// Apresenta o total de atendimentos por tipo.
function StatsCard({ title, stat }) {
  return (
    <Stat py={'2'} bg="gray.300" borderRadius={4} boxShadow="base" id="stats-card">
      <VStack pl={{ base: 2, md: 2 }} justifyContent={'space-between'}>
        <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
          {stat}
        </StatNumber>
        <StatLabel fontWeight={'medium'} isTruncated>
          {title === 'A' && 'AMB'}
          {title === 'E' && 'EXT'}
          {title === 'I' && 'INT'}
          {title === 'U' && 'URG'}
        </StatLabel>
      </VStack>
    </Stat>
  )
}

function SchedulesView({ schedules, isLoading, handleOpenModal }) {
  // Primeiro, ordene os schedules pelo campo tp_atendimento
  const sortedSchedules = [...schedules].sort((a, b) => {
    if (a.tp_atendimento < b.tp_atendimento) return -1
    if (a.tp_atendimento > b.tp_atendimento) return 1

    // Se tp_atendimento for igual, ordene por cd_atendimento de forma decrescente
    return b.cd_atendimento - a.cd_atendimento
  })

  // Agrupe os schedules pelo campo tp_atendimento
  const groupedSchedules = sortedSchedules.reduce((acc, schedule) => {
    const type = schedule.tp_atendimento
    if (!acc[type]) {
      acc[type] = []
    }
    acc[type].push(schedule)
    return acc
  }, {})

  // Crie um array de tipos de atendimento
  const types = Object.keys(groupedSchedules)
  return (
    <>
      <Grid templateColumns="repeat(auto-fill, minmax(250px, 1fr))" gap={2} id="grid-atendimentos">
        {types.map((type) => (
          <GridItem key={type} py={4} borderRadius={4} alignItems="center" justifyContent="center" textAlign="center">
            <StatsCard title={type} stat={groupedSchedules[type].length} />
            <VStack spacing={2} alignItems="left" w="100%" textAlign="left" id="it-grid-atendimento">
              {groupedSchedules[type].map((item, index) => (
                <VStack
                  index={index}
                  key={item.id}
                  onClick={() => handleOpenModal(item)}
                  boxShadow={'base'}
                  p={4}
                  w="full"
                  alignItems={'left'}
                  borderRadius={5}
                  cursor="pointer"
                  bg="gray.50"
                  _hover={{ boxShadow: 'base' }}
                  textAlign={'center'}
                  border={'1px solid #ccc !important'}
                >
                  <InfoView label={'Paciente'} value={item?.patient?.name} />
                  <InfoView label={'Prestador'} value={item?.doctors.nm_prestador} />
                  <InfoView label={'Atendimento'} value={item?.cd_atendimento} />

                  <HStack py={2}>
                    {item.patient?.documentos?.length > 0 && (
                      <Icon as={BiImages} w={6} h={6} color="green.500" ml={2} id="icon-documentos" />
                    )}
                    {item.isSolicitaInternacao && <Icon as={GiHeartPlus} w={6} h={6} color="green.500" ml={2} />}
                  </HStack>
                  <List size="sm" alignItems={'left'} textAlign={'left'}>
                    {item.attendance_exams.map((item) => (
                      <ListItem key={item.id} fontSize={'xs'}>
                        <ListIcon as={MdCheckCircle} color={item.isLiberado ? 'green.500' : 'red.500'} />
                        {item.sectors_exams?.nm_setor}

                        {/* DD-6  A atendente deve conseguir visualizar quando um prestador iniciar um atendimento.*/}
                        {item.isAtendido && ' - ATEND. MÉD CONF. '}
                      </ListItem>
                    ))}
                  </List>
                  <Tag size="sm" colorScheme="blue" id="tag-nome-usuario-atendimento">
                    <TagLabel>{item?.user}</TagLabel>
                  </Tag>
                </VStack>
              ))}
            </VStack>
          </GridItem>
        ))}
      </Grid>
    </>
  )
}

function AttendanceV2() {
  const [mensagemAtendimentoAtualizado, setMensagemAtendimentoAtualizado] = useState('')
  const [dataInicial, setdataInicial] = useState(new Date().toISOString().slice(0, 10))
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenModal, setisOpenModal] = useState(false)
  const [schedules, setSchedules] = useState([])
  const [schedulesFull, setSchedulesFull] = useState([])
  const [scheduleSelected, setScheduleSelected] = useState(null)
  const toast = useToast()
  const [docsTypes, setDocsTypes] = useState([])
  const [File, setFile] = useState('')
  const [docTypeId, setDocTypeId] = useState('')
  const [documentos, setDocumentos] = useState([])
  const [isVisibleInserDocumentos, setisVisibleInserDocumentos] = useState(false)
  const [isOpenModalSolicitarInternacao, setisOpenModalSolicitarInternacao] = useState(false)
  const isDeviceSmall = useBreakpointValue({ base: true, md: false })
  const [liberarExame, setLiberarExame] = useState('N')
  const [handleSelectPedido, setHandleSelectPedido] = useState(null)
  const { User } = useContext(ContextUser)
  const [opcaoLiberaDocumento, setOpcaoLiberaDocumento] = useState(false)

  // Abrir e pesquisar o modal para encontrar os atendimentos;
  const handleOpenModal = (item) => {
    setDocumentos([])
    setScheduleSelected(null)
    setIsLoading(true)

    api
      .get('api/v2/attendance/' + item.id)
      .then((res) => {
        setScheduleSelected(res.data)
        setisOpenModal(true)
      })
      .catch(() => {
        toast({
          title: 'Atendimento não encontrado',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleFilterPacienteName = (value) => {
    if (value === '') {
      setSchedules(schedulesFull)
      return
    }

    const filter = schedules.filter((item) => item.patient?.name.toLowerCase().includes(value.toLowerCase()))
    setSchedules(filter)
  }

  // Insere o documento no prontuario do paciente.
  async function handleInserirImagemProntuario(e) {
    setIsLoading(true)
    e.preventDefault()
    const formData = new FormData()
    formData.append('file', File)
    formData.append('patientId', scheduleSelected?.patientId)
    formData.append('attendanceId', scheduleSelected?.id)
    formData.append('docTypeId', docTypeId)
    formData.append('liberarExame', liberarExame === 'S' ? true : false)
    formData.append('attendanceExamId', handleSelectPedido)

    api
      .post('/aws/upload', formData)
      .then(() => {
        // Atualiza os documentos do paciente.
        toast({
          title: 'Anexado com sucesso',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false)
        setisVisibleInserDocumentos(false)
        setisOpenModal(false)
        setisOpenModal(true)
        setLiberarExame('N')
        setHandleSelectPedido(null)
      })
  }

  // Consultar os atendimentos do dia selecionado
  async function FindAttendance(data) {
    const info = await queryClient.fetchQuery(
      ['attendance-data', data],
      async () => {
        return await api.get('/api/v2/attendance/periodo/' + dataInicial + '/' + dataInicial)
      },
      {
        staleTime: 1000 * 30 * 5,
      }
    )

    if (info) {
      setSchedules(info?.data)
      setSchedulesFull(info?.data)
    }
  }

  // Verifica se a opção selecionada libera o exame.
  const handleSelectDocumento = (value) => {
    const doc = docsTypes.find((item) => item.id == value)

    setDocTypeId(value)
    setOpcaoLiberaDocumento(doc?.isLiberaExame)
    // Se a opção do documento não liberar o exame, então não exibe o select para selecionar o pedido.
    if (!doc?.isLiberaExame) {
      setLiberarExame('N')
      setHandleSelectPedido(null)
    }
  }

  useEffect(() => {
    api
      .get('api/docs/types')
      .then((res) => {
        setDocsTypes(res.data)
      })
      .catch(() => {})
      .finally(() => {})
  }, [])

  // Consultar os documentos do paciente. -> Lentidão na apresentação dos documentos 📝 DRSOL-12
  useEffect(() => {
    if (!scheduleSelected) return
    if (!isOpenModal) return
    setIsLoading(true)
    api
      .get('/aws/documentos/patient/' + scheduleSelected?.patientId)
      .then((res) => {
        setDocumentos(res.data)
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false)
      })
  }, [isOpenModal])

  // Consultar os atendimentos do dia.
  useEffect(() => {
    setIsLoading(true)
    FindAttendance(dataInicial)
    setSchedulesFull([])
    setSchedules([])

    FindAttendance(dataInicial).finally(() => {
      setIsLoading(false)
    })
  }, [dataInicial])

  // Atualização automática dos atendimentos.
  useEffect(() => {
    const channel = supabase
      .channel('attendance')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'attendance',
        },
        async (payload) => {
          const { data, error } = await supabase
            .from('attendance')
            .select(
              'id, tp_atendimento,user,isReturn,nr_carteira,isSolicitaInternacao,dhSolicitaInternacao,cd_atendimento, covenants(cd_convenio,nm_convenio ), attendance_exams(id, cd_ped_rx,hr_liberacao,nm_paciente,exames,sn_laudado,userPedido,userLiberacao,dhLiberacao,isLiberado,isAtendido,dhAtendimento, sectors_exams(cd_setor_exame,nm_setor)),patient(*, documentos(id)), doctors(cd_prestador, nm_prestador)'
            )
            .single()
            .eq('id', payload.new.id)
            .eq('clientId', User?.clientId)

          if (!error) {
            setMensagemAtendimentoAtualizado(
              'Atendimento ' +
                payload.new.cd_atendimento +
                ' (' +
                payload.new.tp_atendimento +
                ') atualizado em ' +
                new Date().toLocaleTimeString()
            )
          }

          // Remove o atendimento existente pelo id
          setSchedules((old) => old.filter((item) => item.id !== payload.new.id))

          // Adiciona o novo atendimento
          setSchedules((old) => [data, ...old])
        }
      )
      .subscribe()
    return () => {
      supabase.removeChannel(channel)
    }
  }, [supabase, User])

  return (
    <ContainerPageTemplate>
      {isLoading && <LoadingModal isOpen={isLoading} />}

      {/* Modal para solicitar a internaçao. */}
      <ModalAtendimentoSolicitaInternacao
        isOpen={isOpenModalSolicitarInternacao}
        cd_atendimento={scheduleSelected?.cd_atendimento}
        nm_prestador={scheduleSelected?.doctors?.nm_prestador}
        handleChangeModalIsOpen={() => setisOpenModalSolicitarInternacao(false)}
        nm_paciente={scheduleSelected?.patient?.name}
        setIsLoading={setIsLoading}
        attendanceId={scheduleSelected?.id}
        patientId={scheduleSelected?.patientId}
      />

      {/* Modal do atendimento selecionado. */}
      <ModalComponent
        isOpen={isOpenModal}
        onClose={() => setisOpenModal(false)}
        title={'Atendimento ' + scheduleSelected?.cd_atendimento}
      >
        <HStack spacing4={4}>
          <Button
            variant="primary"
            leftIcon={<BsFilePlus />}
            onClick={() => setisVisibleInserDocumentos(!isVisibleInserDocumentos)}
            id="button-anexar-documento"
            size="xs"
            fontFamily={'Montserrat'}
          >
            Anexar Documento
          </Button>
          <Button
            variant="link"
            leftIcon={<GiHeartPlus />}
            onClick={() => setisOpenModalSolicitarInternacao(true)}
            id="button-solicitar-internacao"
            size="xs"
            fontFamily={'Montserrat'}
          >
            Sol. Internação
          </Button>
          {scheduleSelected?.attendance_exams.length > 0 && (
            <Tag size="sm" colorScheme="orange">
              <TagLabel>Exames</TagLabel>
            </Tag>
          )}
        </HStack>
        <VStack alignItems={'left'}>
          <SimpleGridComponent>
            <InfoViewCopy
              label={'Paciente'}
              value={'( ' + scheduleSelected?.patient?.codPatient + ' ) ' + scheduleSelected?.patient?.name}
            />

            <InfoViewCopy label={'Atendimento'} value={scheduleSelected?.cd_atendimento} />

            <InfoViewCopy
              label={'Internação Solicitada'}
              value={scheduleSelected?.isSolicitaInternacao ? 'SIM' : 'NÃO'}
            />
          </SimpleGridComponent>
          <SimpleGridComponent>
            <InfoViewCopy label={'Convênio'} value={scheduleSelected?.covenants?.nm_convenio} />
            <InfoViewCopy label={'Carteira'} value={scheduleSelected?.nr_carteira} />

            <InfoViewCopy label={'Médico'} value={scheduleSelected?.doctors?.nm_prestador} />
          </SimpleGridComponent>

          {/* Lista dos documentos encontrados no atendimento */}
          <ListDocumentsComponent documentos={documentos} isLoading={isLoading} />
        </VStack>

        <Text fontSize={'10px'}>Id {scheduleSelected?.id}</Text>
        <Text fontSize={'10px'}>Paciente {scheduleSelected?.patient?.id}</Text>
      </ModalComponent>

      {/* Anexar documentos via modal */}
      <ModalComponent
        isOpen={isVisibleInserDocumentos}
        onClose={() => setisVisibleInserDocumentos(false)}
        title={'Anexar documento'}
      >
        <Center>
          <VStack
            as="form"
            onSubmit={handleInserirImagemProntuario}
            encType="multipart/form-data"
            alignItems={'end'}
            spacing={4}
          >
            <FormControl isRequired>
              <FormLabel>Selecione o tipo de documento</FormLabel>

              <Select
                placeholder="Selecione uma opção..."
                onChange={(e) => handleSelectDocumento(e.target.value)}
                size="lg"
                id="select-documento-anexar"
              >
                {docsTypes.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.description}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Selecione o arquivo</FormLabel>
              <Input
                type="file"
                name="arquivo"
                onChange={(e) => setFile(e.target.files[0])}
                size="lg"
                id="input-file-documento"
              />
            </FormControl>
            {/* Verifica se exitem exames associado a esse atendimento */}
            {/* Verifica se a opção selecionada libera o exame */}
            {opcaoLiberaDocumento && scheduleSelected?.attendance_exams.length > 0 && (
              <FormControl isRequired>
                <FormLabel>Deseja liberar o exame?</FormLabel>
                <Select onChange={(e) => setLiberarExame(e.target.value)} placeholder="Selecione...">
                  <option value="S">Sim</option>
                  <option value="N">Não</option>
                </Select>
              </FormControl>
            )}

            {liberarExame === 'S' && (
              <FormControl>
                <FormLabel>Selecione o setor</FormLabel>
                <Select onChange={(e) => setHandleSelectPedido(e.target.value)} placeholder="Selecione...">
                  {scheduleSelected?.attendance_exams.map((item) => (
                    <option value={item.id} key={item.id}>
                      {'Pedido: ' + item.cd_ped_rx + ' -  ' + item.sectors_exams?.nm_setor}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}

            {liberarExame === 'S' && (
              <Alert status="warning">
                <AlertIcon />
                Você marcou que sim, então o setor externo poderá visualizar o documento.
              </Alert>
            )}
            <Button type="submit" variant="primary" w="100%" leftIcon={<FiSave />} size="lg">
              Salvar
            </Button>
          </VStack>
        </Center>
      </ModalComponent>

      <VStack w="100%" alignItems={'left'} py={4}>
        <HStack flexDir={isDeviceSmall ? 'column' : 'row'}>
          {/* Input para preencher a data inicial */}
          <FormControl isRequired>
            <FormLabel> Data Inicial</FormLabel>
            <Input
              type="date"
              value={dataInicial}
              onChange={(e) => setdataInicial(e.target.value)}
              id="input-atendimentos-data-inicial"
            />
          </FormControl>

          {/* Formulário para filtrar os atendimentos pelo nome do paciente */}
          <FormControl>
            <FormLabel>Pesquisar Paciente</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
              <Input
                type="search"
                placeholder="Pesquisar paciente"
                onChange={(e) => handleFilterPacienteName(e.target.value)}
                id="input-pesquisar-paciente"
              />
            </InputGroup>
          </FormControl>
        </HStack>

        {/* Mensagem para informar quando o atendimento for atualizado  */}
        <HStack w="100%" justifyContent={'end'}>
          <Text id="txt-atendimento-atualizado">Atualizações: {mensagemAtendimentoAtualizado}</Text>
        </HStack>
      </VStack>

      {/* Apresentar os atendimentos selecionado */}
      <SchedulesView schedules={schedules} isLoading={isLoading} handleOpenModal={handleOpenModal} />
    </ContainerPageTemplate>
  )
}

export { AttendanceV2 }
