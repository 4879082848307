import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react'
import { BsSearch } from 'react-icons/bs'
import { FiPrinter, FiSave } from 'react-icons/fi'
import { PagamentoPorID } from '.'
import { ModalComponent } from '../../_styles/ModalComponent '
import { Context as ContextUser } from '../../Context/UserContext'
import { FindContatosPrestadores } from '../../Functions/Prestadores/FindContatosPrestadores'
import api from '../../services/api'
import { ButtonPageTemplate } from '../../styles/ButtonPageTemplate'
import { TextInfoTemplate } from '../../styles/FontsTemplate'
import { LoadingPage } from '../LoadingPage'
export function ModalCriarPagamento({
  isOpen,
  onClose,
  nm_paciente,
  cd_atendimento,
  nm_prestador,
  cd_convenio,
  nm_convenio,
  cd_paciente,
  sn_retorno,
  ds_con_pla,
  email,
  ds_leito,
  cd_ped_rx,
  cd_aviso_cirurgia,
  cd_it_agenda_central,
  cpf,
  cd_prestador,
  phone_prestador,
  cd_cliente,
}) {
  const [contatosPrestadores, setContatosPrestadores] = useState([])
  const [prestadoresEncontrados, setprestadoresEncontrados] = useState([])
  const [prestadorSelecionado, setprestadorSelecionado] = useState([])
  const [pagamentos, setpagamentos] = useState([])
  const [isOpenPagamentoID, setisOpenPagamentoID] = useState(false)
  const [id_pagamento, setid_pagamento] = useState('')
  const toast = useToast()
  const { setLoading } = useContext(ContextUser)

  useEffect(() => {
    if (cd_cliente)
      FindContatosPrestadores(cd_cliente).then((res) => {
        setContatosPrestadores(res)
      })
  }, [cd_cliente])

  useEffect(() => {
    if (cd_atendimento) {
      setLoading(true)
      api
        .post('pagamentos/busca/atendimento', {
          cd_atendimento,
        })
        .then((res) => {
          setpagamentos(res.data)
          setLoading(false)
        })
        .catch(() => {
          setpagamentos([])
          setLoading(false)
        })
    }
  }, [cd_atendimento, isOpen])

  useEffect(() => {
    setprestadoresEncontrados([])
    setprestadorSelecionado([])
  }, [isOpen])

  function findPrestador(nm_prestador) {
    var searchResult = contatosPrestadores.filter(
      (prestador) => prestador.NM_PRESTADOR.indexOf(nm_prestador.toUpperCase()) > -1
    )
    setprestadoresEncontrados(searchResult)
  }

  function liberarBotaoCriarPagamento(idPrestadorSelecionado) {
    var newArray = contatosPrestadores.filter((d) => d.CD_PRESTADOR == idPrestadorSelecionado)
    setprestadorSelecionado(newArray)
  }

  function openPagamento(id) {
    setid_pagamento(id)
    setisOpenPagamentoID(true)
    onClose(false)
  }

  async function handleCreatePagamento() {
    api
      .post('pagamentos/add', {
        cd_cliente: localStorage.getItem('cd_cliente'),
        cd_paciente,
        cd_atendimento,
        cd_ped_rx,
        cd_aviso_cirurgia,
        cd_it_agenda_central,
        cd_prestador: prestadorSelecionado[0].CD_PRESTADOR,
        sn_retorno: sn_retorno || 'N',
        nm_convenio,
        ds_con_pla,
        nm_paciente,
        email,
        cpf,
        nm_prestador: prestadorSelecionado[0].NM_PRESTADOR,
        phone_prestador: prestadorSelecionado[0].CELULAR,
        usuario_atendimento: localStorage.getItem('usuario'),
      })
      .then((res) => {
        setid_pagamento(res.data.id)
        setisOpenPagamentoID(true)
        onClose(false)
        toast({
          title: 'Pagamento.',
          description: 'Pagamento criado com sucesso.',
          position: 'top',
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      })
      .catch((err) => {
        alert('Erro ao criar pagamento.')
      })
  }

  return (
    <>
      <PagamentoPorID
        isOpen={isOpenPagamentoID}
        id_pagamento={id_pagamento}
        handleChangeModalIsOpen={() => setisOpenPagamentoID(!isOpenPagamentoID)}
        cd_atendimento={cd_atendimento}
        nm_paciente={nm_paciente}
      />
      <ModalComponent isOpen={isOpen} onClose={() => onClose(false)}>
        <LoadingPage />
        <Text fontSize="sm">CRIAR PAGAMENTO</Text>
        <Text fontSize="sm"> {nm_paciente}</Text>

        <VStack spacing={2} alignItems="left">
          <TextInfoTemplate info={'Prontuário: ' + cd_paciente} />
          <TextInfoTemplate info={'Convênio: ' + nm_convenio} />
          <TextInfoTemplate info={'Plano: ' + ds_con_pla} />

          <InputGroup>
            <InputLeftElement pointerEvents="none" children={<BsSearch color="gray.300" />} />
            <Input
              onChange={(e) => findPrestador(e.target.value)}
              variant="flushed"
              placeholder="Digite o nome do prestador"
            />
          </InputGroup>
          {prestadoresEncontrados.length > 0 && (
            <Select
              placeholder={prestadoresEncontrados.length + ' prestador(es) econtrado(s).'}
              onChange={(e) => liberarBotaoCriarPagamento(e.target.value)}
            >
              {prestadoresEncontrados.map((prestador) => (
                <option value={prestador.CD_PRESTADOR}>{prestador.NM_PRESTADOR}</option>
              ))}
            </Select>
          )}

          {prestadorSelecionado.length > 0 && (
            <ButtonPageTemplate
              onClick={() => handleCreatePagamento()}
              icon={<FiSave />}
              variant="solid"
              title={'Criar Pagamento'}
            />
          )}
        </VStack>

        {pagamentos.length > 0 && (
          <Table w="100%">
            <Thead>
              <Th>Prestador</Th>
              <Th>VALOR</Th>
              <Th>PAGO</Th>
              <Th>AÇÕES</Th>
            </Thead>
            <Tbody>
              {pagamentos.map((pagamento) => (
                <Tr>
                  <Td>
                    <Text fontSize={'sm'}>{pagamento.nm_prestador.substring(0, 18)}</Text>
                  </Td>
                  <Td>{pagamento.vl_pago}</Td>
                  <Td>{pagamento.sn_pago}</Td>
                  <Td>
                    <Button
                      title="Visualizar Pagamento"
                      leftIcon={<FiPrinter />}
                      onClick={() => openPagamento(pagamento.id)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </ModalComponent>
    </>
  )
}
