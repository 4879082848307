import {
  Button,
  FormControl,
  FormLabel,
  GridItem,
  HStack,
  Image,
  Input,
  Select,
  SimpleGrid,
  Tag,
  TagLeftIcon,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react'
import Axios from 'axios'
import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FiClock, FiKey, FiSearch, FiShield } from 'react-icons/fi'
import { Context as ContextUser } from '../../Context/UserContext'
import { ContainerPageTemplate } from '../../styles/ContainerPageTemplate'

const Neoh = function () {
  const [schdulesFull, setschdulesFull] = useState([])
  const [schedulesQtd, setschedulesQtd] = useState([])
  const { User } = useContext(ContextUser)
  const { handleSubmit, register } = useForm()
  const toast = useToast()
  const [filtro, setFiltro] = useState('TODOS')

  // Função para formatar a data no formato "hh24:mi"
  const formatTime = (dateString) => {
    const date = new Date(dateString)
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    return `${hours}:${minutes}`
  }

  const findCirurgiasNeoh = async (data) => {
    Axios.post('https://neohapi.intelectah.com.br/api/NeohData/ObterDadosBI', {
      Token: User.clients.neoh,
      DataInicio: data.dataSelecionadaInput,
      DataFim: data.dataSelecionadaInput,
      TipoPeriodo: '3',
    })
      .then((response) => {
        setschedulesQtd(response.data)

        // Função para agrupar e ordenar os elementos por horário
        const groupAndSortByTime = (array) => {
          const grouped = groupByDataAgendada(array)
          const sortedKeys = Object.keys(grouped).sort()
          return sortedKeys.map((key) => ({
            data: key,
            agendamentos: grouped[key].sort((a, b) => a.DataAgendada.localeCompare(b.DataAgendada)),
          }))
        }
        const groupByDataAgendada = (array) => {
          return array.reduce((result, item) => {
            const key = item.DataAgendada
            if (!result[key]) {
              result[key] = []
            }
            result[key].push(item)
            return result
          }, {})
        }

        const groupedAgendamentos = groupAndSortByTime(response.data)

        setschdulesFull(groupedAgendamentos)
      })
      .catch(() => {
        toast({
          title: 'Erro ao buscar os dados',
          description: 'Erro ao buscar os dados',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        })
      })
  }

  const nomeFaseAtualOptions = ['TODOS', ...Array.from(new Set(schedulesQtd.map((item) => item.NomeFaseAtual)))]

  const handleFiltroChange = (event) => {
    setFiltro(event.target.value)

    // Função para agrupar e ordenar os elementos por horário
    const groupAndSortByTime = (array) => {
      const grouped = groupByDataAgendada(array)
      const sortedKeys = Object.keys(grouped).sort()
      return sortedKeys.map((key) => ({
        data: key,
        agendamentos: grouped[key].sort((a, b) => a.DataAgendada.localeCompare(b.DataAgendada)),
      }))
    }
    const groupByDataAgendada = (array) => {
      return array.reduce((result, item) => {
        const key = item.DataAgendada
        if (!result[key]) {
          result[key] = []
        }
        result[key].push(item)
        return result
      }, {})
    }

    if (event.target.value === 'TODOS') {
      const groupedAgendamentos = groupAndSortByTime(schedulesQtd)
      setschdulesFull(groupedAgendamentos)
    } else {
      const groupedAgendamentos = groupAndSortByTime(
        schedulesQtd.filter((item) => item.NomeFaseAtual === event.target.value)
      )
      setschdulesFull(groupedAgendamentos)
    }
  }

  return (
    <>
      <ContainerPageTemplate>
        <form onSubmit={handleSubmit(findCirurgiasNeoh)}>
          <HStack align="center" alignItems={'flex-end'}>
            <FormControl isRequired>
              <FormLabel htmlFor="date">Selecione a data</FormLabel>
              <Input type={'date'} id="dataSelecionadaInput" {...register('dataSelecionadaInput')} />
            </FormControl>
            <Button type="submit" leftIcon={<FiSearch />} variant="primary" id="btnPesquisarAgendamentos">
              Pesquisar
            </Button>
          </HStack>
        </form>

        <HStack w="100%" justifyContent={'flex-end'} pt={4}>
          {schedulesQtd.length > 0 && (
            <Select
              borderRadius={8}
              value={filtro}
              onChange={handleFiltroChange}
              maxW="200px"
              placeholder="Filtrar por fase"
              size="xs"
              variant="filled"
            >
              {nomeFaseAtualOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          )}
        </HStack>

        <VStack spacing={4} align="stretch">
          {schedulesQtd.length > 0 && <Text mt={4}> {schedulesQtd.length} avisos cirúrgicos.</Text>}

          <SimpleGrid boxShadow={'base'} borderRadius={8}>
            {schdulesFull.map(({ data, agendamentos }) => (
              <GridItem key={data} p={4} borderRadius="md">
                <HStack>
                  <Tag colorScheme="blue" variant="solid">
                    <TagLeftIcon as={FiClock} />
                    {formatTime(data)}
                  </Tag>
                  <Text>{agendamentos.length} agendamentos</Text>
                </HStack>

                <VStack spacing={1} alignItems={'left'} py={2}>
                  {agendamentos.map((agendamento) => (
                    <HStack
                      key={agendamento.NomePaciente}
                      _hover={{ bg: 'gray.100' }}
                      borderRadius="md"
                      cursor={'pointer'}
                      justifyContent={'space-between'}
                    >
                      <HStack>
                        <Tag variant="outline" colorScheme="blue">
                          <TagLeftIcon as={FiKey} />
                          {agendamento.AvisoCirurgia}
                        </Tag>
                        <Text> {agendamento.NomePaciente}</Text>
                      </HStack>
                      <Tag variant="outline">
                        <TagLeftIcon as={FiShield} />
                        {agendamento.NomeFaseAtual}
                      </Tag>
                    </HStack>
                  ))}
                </VStack>
              </GridItem>
            ))}
          </SimpleGrid>
        </VStack>

        {schdulesFull.length === 0 && (
          <VStack justifyContent={'center'} w="100%" p={8} alignItems={'center'} textAlign={'center'} id="imageLoading">
            <Image src="/book.svg" alt="Pesquisar" h="300px" w="300px" />
            <Text>Não foram encontrados novos agendamentos.</Text>
          </VStack>
        )}
      </ContainerPageTemplate>
    </>
  )
}

export { Neoh }
