import { ValorTotal } from './styles'

interface Action {
  infoGeral: Array;
}

export const InfoValorTotal = ({ infoGeral }: Action) => (
  <ValorTotal>
    <h1>Valor total R$ {infoGeral.vl_pago},00</h1>
  </ValorTotal>
)
