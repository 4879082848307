import { Box, Center, Flex, FormControl, Icon, Input, Spinner } from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react'
import { RiHospitalLine } from 'react-icons/ri'
import { Context as ContextUser } from '../../Context/UserContext'
import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api'
import { TextInfoTemplate } from '../../styles/FontsTemplate'

export function ModalChatMensagensAnteriores({ isOpen, handleChangeModalIsOpen, id_contato }) {
  const { setLoading, Loading } = useContext(ContextUser)
  const [MensagensAnteriores, setMensagensAnteriores] = useState([])
  const [mensagensFull, setMensagensFull] = useState([])

  const filterByMessage = (message: string) => {
    if (message.length === 0) return setMensagensAnteriores(mensagensFull)
    const filtered = mensagensFull.filter((m) => m.mensagem.toLowerCase().includes(message.toLowerCase()))
    setMensagensAnteriores(filtered)
  }

  useEffect(() => {
    if (isOpen && id_contato) {
      setLoading(true)
      setMensagensAnteriores([])
      setMensagensFull([])
      api
        .post('mensagens/enviadas/id/contato', {
          id_contato: id_contato,
        })
        .then((res) => {
          setMensagensAnteriores(res.data)
          setMensagensFull(res.data)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }, [id_contato, isOpen])

  return (
    <ModalComponent isOpen={isOpen} onClose={() => handleChangeModalIsOpen(false)} title="Mensagens Anteriores">
      <Center p={4}>{Loading && <Spinner />}</Center>

      {!Loading && (
        <FormControl>
          <Input
            onChange={(e) => filterByMessage(e.target.value)}
            placeholder="O que deseja encontrar?"
            borderRadius={8}
          />
        </FormControl>
      )}

      <Box p={4}>
        {MensagensAnteriores.map((mensagem, index) => (
          <Flex
            align={'center'}
            key={index}
            w="100%"
            justifyContent={mensagem.sn_paciente === 'S' ? 'left' : 'flex-end'}
            p={2}
          >
            <TextInfoTemplate info={mensagem.mensagem} title={mensagem.nm_usuario + ' - ' + mensagem.data} />
            {mensagem.sn_paciente === 'N' && <Icon as={RiHospitalLine} />}
          </Flex>
        ))}
      </Box>
    </ModalComponent>
  )
}
