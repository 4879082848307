import { Button, HStack, Text, VStack } from '@chakra-ui/react'
import { FaWhatsapp } from 'react-icons/fa'
import { FiHome } from 'react-icons/fi'
import { useParams } from 'react-router-dom'
import LogoHP from '../../assets/clientes/1.png'
import { ContainerAgendamentosWebTemplate } from '../../styles/ContainerPageTemplate'

export default function AgendamentoWeb() {
  const { codBloqueio, cd_cliente } = useParams()

  async function Redirect(cd_cliente) {
    window.location.href = `/agendamentos/web/${cd_cliente}`
  }

  const handleRedirectWpp = () => {
    window.location.href = 'https://api.whatsapp.com/send?phone=551150878700&text=Ola'
  }
  return (
    <ContainerAgendamentosWebTemplate logoImg={LogoHP}>
      {codBloqueio === 'CALL_CENTER' && (
        <VStack>
          <Text>
            Para continuar o agendamento será necessário entrar em contato com nosso call center pelo número (011)
            5087-8787.
          </Text>
          <HStack>
            <Button leftIcon={<FaWhatsapp />} colorScheme={'green'} onClick={() => handleRedirectWpp()}>
              Iniciar Conversa
            </Button>
            <Button leftIcon={<FiHome />} colorScheme={'blue'} onClick={() => Redirect(cd_cliente)}>
              Voltar
            </Button>
          </HStack>
        </VStack>
      )}

      {codBloqueio === 'NAO_AUTORIZADO' && (
        <VStack>
          <Text>O procedimento selecionado não é autorizado pelo convênio e/ou plano.</Text>

          <Button leftIcon={<FiHome />} colorScheme={'blue'} onClick={() => Redirect(cd_cliente)}>
            Voltar
          </Button>
        </VStack>
      )}
    </ContainerAgendamentosWebTemplate>
  )
}
