/* eslint-disable */
import { NotificaSuccess } from '../Toast/success'
import api from '../../services/api'

export async function CriarContatoEProtocolo(nome, nr_celular) {
  const id_setor = localStorage.getItem('cd_setor')
  return await api
    .post('contatos/add', { nome, phone: '55' + nr_celular + '@c.us' })
    .then((res) => {
      api
        .post('protocolos/add', { id_contato: res.data[0].id, id_setor })
        .then((res) => {
          NotificaSuccess('Protocolo criado com sucesso....')
          return res.data
        })
      return res.data[0].id
    })
}
