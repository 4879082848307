/* eslint-disable */
import api from '../../services/api'

export async function FindLaudosPeriodo(dataInicial, dataFinal, sn_laudado) {
  return await api
    .post('pedidos/find/laudos/setor', {
      cd_cliente: localStorage.getItem('cd_cliente'),
      dataInicial,
      dataFinal,
      cd_set_exa: '',
      sn_laudado,
      sn_requer_laudo: 'S',
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}
