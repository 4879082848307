import { Tbody } from '@chakra-ui/react'
import { ChatItem } from './ChatItem'

export const ChatGroup = ({ info, handleOpenChatID, loading, ViewdocumentosRecebidos }) => (
  <Tbody>
    {info.map((agendamento, index) => (
      <ChatItem
        info={agendamento}
        handleOpenChatID={handleOpenChatID}
        loading={loading}
        ViewdocumentosRecebidos={ViewdocumentosRecebidos}
        id={agendamento.id || index} // Adicionando uma propriedade key única
      />
    ))}
  </Tbody>
)
