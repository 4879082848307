/* eslint-disable */
import api from '../../services/api'

export async function FindPendenciasMotivos(cd_cliente, sn_ativo) {
  return await api
    .post('pendencias/motivo/find', {
      cd_cliente,
      sn_ativo,
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}
