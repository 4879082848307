/* eslint-disable */
import api from '../../services/api'

export async function CreateFuncoesLogs(cd_cliente, descricao, desc_funcao) {
  return await api
    .post('funcoes/logs/create/tag', {
      cd_cliente,
      descricao,
      desc_funcao,
      nm_usuario: localStorage.getItem('usuario'),
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}
