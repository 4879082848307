import { Button, FormControl, FormLabel, HStack, Input, Textarea, VStack, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FiSend } from 'react-icons/fi'
import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api'
import { LoadingModal } from '../LoadingModal'

export function ModalScheduleSendPreparo({ isOpen, onClose, id }) {
  const [agendamento, setAgendamento] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()
  const [mensagem, setMensagem] = useState('')

  // Verifica se o usuário está ativo, em seguida envia mensagem usando os dados do banco de dados.
  const handleSendPreparoPaciente = () => {
    api
      .put(`api/v2/schedules/${id}`, {
        data: {
          isEnvioConfirmacao: true,
          dhEnvioConfirmacao: new Date(),
        },
      })
      .then(() => {})
      .catch(() => {
        toast({
          position: 'top-right',
          title: 'Erro',
          description: 'Ocorreu um erro ao notificar o paciente.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })

    setIsLoading(true)
    api
      .post('mensagens/send/direto', {
        phone: '55' + agendamento?.nr_celular + '@c.us',
        mensagem,
        cd_cliente: agendamento?.clients?.cd_cliente,
      })
      .then(() => {
        toast({
          title: 'Mensagem enviada com sucesso',
          description: 'A mensagem foi enviada com sucesso.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
        onClose()
      })
      .catch(() => {
        toast({
          title: 'Erro ao enviar mensagem',
          description: 'Ocorreu um erro ao enviar a mensagem.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function substituirVariaveis(mensagem, objeto) {
    const data = {
      ...objeto,
      ds_item_agendamento: objeto.schedule_item?.ds_item_agendamento,
      nm_prestador: objeto.doctors?.nm_prestador,
      ds_orientacao: objeto.schedule_item?.ds_orientacao,
      dt_agenda: objeto.calendar?.dt_agenda,
    }
    let mensagemAtualizada = mensagem

    for (const prop in data) {
      if (data.hasOwnProperty(prop)) {
        const valor = data[prop]
        const placeholder = `{${prop}}`

        mensagemAtualizada = mensagemAtualizada.replace(placeholder, valor)
      }
    }

    setMensagem(mensagemAtualizada)
    return mensagemAtualizada
  }

  // Carrega as orientações do paciente
  useEffect(() => {
    if (!isOpen) return
    if (!id) return

    setIsLoading(true)

    api
      .post('api/v2/schedules/calendar/id', { id })
      .then((response) => {
        setAgendamento(response.data)
        substituirVariaveis(response.data?.clients.message_confirmation, response.data)
      })
      .catch(() => {
        toast({
          title: 'Erro ao carregar orientações',
          description: 'Ocorreu um erro ao carregar as orientações.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [isOpen])

  return (
    <ModalComponent isOpen={isOpen} onClose={onClose} size="sm" title="Enviar Preparo">
      {isLoading && <LoadingModal isLoading={isLoading} />}

      <VStack as="form" alignItems={'left'}>
        <FormControl>
          <FormLabel>Nome do paciente</FormLabel>
          <Input type={'text'} id="nomePaciente" value={agendamento?.nm_paciente} isDisabled />
        </FormControl>
        <HStack>
          <FormControl>
            <FormLabel>Data</FormLabel>
            <Input type={'date'} id="dataAgendamento" value={agendamento?.calendar?.dt_agenda} isDisabled />
          </FormControl>

          <FormControl>
            <FormLabel>Horário</FormLabel>
            <Input type={'time'} id="ItemAgendamento" value={agendamento?.hr_agenda} isDisabled />
          </FormControl>
        </HStack>
        <FormControl>
          <FormLabel>Item de agendamento</FormLabel>
          <Input
            type={'text'}
            id="ItemAgendamento"
            value={agendamento?.schedule_item?.ds_item_agendamento}
            isDisabled
          />
        </FormControl>
        <FormControl>
          <FormLabel>Mensagem</FormLabel>
          <Textarea value={mensagem} isDisabled />
        </FormControl>

        <Button onClick={handleSendPreparoPaciente} leftIcon={<FiSend />} variant="primary" size="lg">
          Enviar Mensagem
        </Button>
      </VStack>
    </ModalComponent>
  )
}
