import { Button, HStack, Text, useToast, VStack } from '@chakra-ui/react'
import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api'

export function AgendamentoCriarTelemedicina({ isOpen, handleChangeModalIsOpen, nm_paciente, id }) {
  const toast = useToast()

  const handleCriarTelemedicina = () => {
    api
      .post('reuniao', {
        id,
        nm_paciente,
      })
      .then((res) => {
        api
          .post('agendamentos/reuniao', {
            id,
            url_reuniao: res.data.join_url,
          })
          .then(() => {
            handleChangeModalIsOpen()
            toast({
              title: 'Telemedicina',
              description: 'Telemedicina criada com sucesso.',
              status: 'success',
              position: 'top',
              duration: 9000,
              isClosable: true,
            })
          })
          .catch(() => {
            toast({
              title: 'Telemedicina',
              description: 'Não foi possível criar o link da telemedicina.',
              status: 'error',
              position: 'top',
              duration: 9000,
              isClosable: true,
            })
          })
      })
      .catch(() => {
        handleChangeModalIsOpen()
        toast({
          title: 'Telemedicina',
          description: 'Não foi possível criar o link da telemedicina.',
          status: 'error',
          position: 'top',
          duration: 9000,
          isClosable: true,
        })
      })
  }

  return (
    <ModalComponent isOpen={isOpen} onClose={handleChangeModalIsOpen}>
      <VStack>
        <Text>Tem certeza que deseja criar a telemedicina ?</Text>
        <HStack>
          <Button colorScheme={'green'} size="sm" variant="outline" onClick={() => handleCriarTelemedicina()}>
            Sim, criar telemedicina
          </Button>
          <Button colorScheme={'red'} size="sm" variant="outline" onClick={() => handleChangeModalIsOpen()}>
            Não.
          </Button>
        </HStack>
      </VStack>
    </ModalComponent>
  )
}
