import './styles.css'

import React, { Fragment, useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import { FindItensDoPagamento } from '../../Functions/Pagamentos/FindItensDoPagamento'
import { FindPagamentosPorID } from '../../Functions/Pagamentos/FindPagamentosPorID'
import { HeaderComprovante } from './Components/Header'
import { InfoAtendimento } from './Components/InfoAtendimento'
import { InfoItensPagamento } from './Components/InfoItensPagamento'
import { InfoRodape } from './Components/infoRodape'
import { InfoValorTotal } from './Components/InfoValorTotal'

function ComprovantePagamento() {
  const { cd_atendimento, id } = useParams()
  const [pagamento, setPagamento] = useState([])
  const [pagamentoItens, setPagamentoItens] = useState([])

  useEffect(() => {
    if (id)
      FindPagamentosPorID(cd_atendimento, id).then((res) => {
        if (res) {
          setPagamento(res[0])
        }
        FindItensDoPagamento(id).then((res) => {
          setPagamentoItens(res)
        })
      })
  }, [id])

  return (
    <Fragment>
      <HeaderComprovante />
      <InfoAtendimento infoGeral={pagamento} />
      <InfoValorTotal infoGeral={pagamento} />
      <InfoItensPagamento itensPagamento={pagamentoItens} />
      <InfoRodape infoGeral={pagamento} />
    </Fragment>
  )
}

export default React.memo(ComprovantePagamento)
