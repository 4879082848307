import { Alert, AlertDescription, AlertIcon, Box, HStack, Icon, VStack } from '@chakra-ui/react'
import { FiClock } from 'react-icons/fi'
import { TextBgTemplate, TextInfoTemplate } from '../../styles/FontsTemplate'

export const CirurgiasItem = ({
  nm_paciente,
  ds_tip_acom,
  cd_aviso_cirurgia,
  nm_prestador,
  ds_idade,
  ds_cirurgia,
  obs_guia,
  nm_convenio,
  handleChangeModalIsOpen,
  hora,
}) => (
  <Box
    onClick={() => handleChangeModalIsOpen(cd_aviso_cirurgia)}
    w="100%"
    boxShadow="base"
    cursor={'pointer'}
    fontSize="12px"
    p={2}
    borderRadius={8}
  >
    <VStack alignItems={'left'} w="100%" spacing={'2px'}>
      <HStack w="100%" justifyContent="space-between">
        <TextBgTemplate info={nm_paciente} />

        <HStack spacing={'2px'}>
          <Icon as={FiClock} />
          <TextInfoTemplate info={hora} />
        </HStack>
      </HStack>

      <TextInfoTemplate info={nm_prestador + ' '} />
      <TextInfoTemplate info={ds_cirurgia + ' '} />
      <TextInfoTemplate info={ds_idade + ' anos'} />
      <TextInfoTemplate info={nm_convenio} />
      <TextInfoTemplate info={ds_tip_acom} />
      {obs_guia && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{obs_guia}.</AlertDescription>
        </Alert>
      )}
    </VStack>
  </Box>
)
