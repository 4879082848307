// https://femoraes.atlassian.net/browse/DR-11 - INTERESSE REMARCAR
import { Center, Spinner, Text, VStack, useToast } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import api from '../../services/api'

function FaltasInteresseRemarcar() {
  const { id, token } = useParams()
  const toast = useToast()
  const [isLoading, setIsLoading] = React.useState(true)

  useEffect(() => {
    api.defaults.headers.Authorization = `Bearer ${token}`
    api
      .put(`/patients/remarcar/${id}`)
      .then(() => {
        toast({
          title: 'Sucesso',
          description: 'Interesse registrado com sucesso.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      })
      .catch(() => {
        toast({
          title: 'Erro',
          description: 'Não foi possível registrar o interesse.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return (
    <VStack p={4} bg="white" fontSize={'sm'} minW="90vw" minH={'100vh'} flexDir={'column'} justifyContent={'center'}>
      <Center>
        {isLoading ? (
          <Spinner />
        ) : (
          <VStack>
            <Text>Registramos o seu interesse</Text>
            <Text>Em breve entraremos em contato.</Text>
          </VStack>
        )}
      </Center>
    </VStack>
  )
}

export { FaltasInteresseRemarcar }
