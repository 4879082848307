import React, { useState } from 'react'

import { Button, FormControl, FormLabel, Input, Select, VStack } from '@chakra-ui/react'
import { FiSave } from 'react-icons/fi'
import { RedirectViewPagamentos } from '../../../Functions/Pagamentos/RedirectViewPagamentos'
import { UpdatePagamento } from '../../../Functions/Pagamentos/UpdatePagamento'
import { NotificaError } from '../../../Functions/Toast/error'
import { NotificaSuccess } from '../../../Functions/Toast/success'
import { ModalComponent } from '../../../_styles/ModalComponent '

const PagamentoFinalizar = React.memo(function PagamentoFinalizar({
  isOpen,
  handleChangeModalIsOpen,
  id_pagamento,
  cd_atendimento,
  nm_paciente,
  phone_prestador,
  vl_pago,
}) {
  const [desc_forma_pagamento, setdesc_forma_pagamento] = useState([])
  const [sn_parcelado, setsn_parcelado] = useState([])
  const [qtd_parcelas, setqtd_parcelas] = useState([])
  const [idPagamentoExterno, setidPagamentoExterno] = useState([])
  const cd_cliente = localStorage.getItem('cd_cliente')

  const handleFormasDePagamento = (e) => {
    e.preventDefault()
    UpdatePagamento(id_pagamento, sn_parcelado, qtd_parcelas, idPagamentoExterno, desc_forma_pagamento)
      .then(() => {
        NotificaSuccess('Pagamento confirmado com sucesso...')
        handleChangeModalIsOpen()
        RedirectViewPagamentos(cd_atendimento, id_pagamento)
      })
      .catch(() => {
        NotificaError('Confitra se todos os campos foram preenchidos...')
      })
  }

  return (
    <ModalComponent isOpen={isOpen} onClose={() => handleChangeModalIsOpen()}>
      <form onSubmit={handleFormasDePagamento} encType="multipart/form-data">
        <VStack>
          <FormControl isRequired>
            <FormLabel>Qual a forma de pagamento?</FormLabel>
            <Select onChange={(e) => setdesc_forma_pagamento(e.target.value)}>
              <option selected>Selecione a forma de pagamento</option>
              <option value="DEBITO">DEBITO</option>
              <option value="DINHEIRO">DINHEIRO</option>
              <option value="CREDITO">CREDITO</option>
              <option value="CREDITO PARCELADO">CREDITO PARCELADO</option>
              <option value="CHEQUE">CHEQUE</option>
              <option value="CORTESIA">CORTESIA</option>
            </Select>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Pagamento parcelado?</FormLabel>
            <Select onChange={(e) => setsn_parcelado(e.target.value)}>
              <option selected>Pagamento parcelado?</option>
              <option value="S">SIM</option>
              <option value="N">NAO</option>
            </Select>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Digite a quantidade de parcelas, caso não tenha, digite 0.</FormLabel>
            <Input type="number" onChange={(e) => setqtd_parcelas(e.target.value)} />
          </FormControl>

          <FormControl isRequired marginTop={2}>
            <FormLabel>Digite o código externo e/ou observações.</FormLabel>
            <Input type="text" onChange={(e) => setidPagamentoExterno(e.target.value)} />
          </FormControl>

          <Button type="submit" size={'100%'} colorScheme="blue" p={4} leftIcon={<FiSave />}>
            Finalizar
          </Button>
        </VStack>
      </form>
    </ModalComponent>
  )
})

export { PagamentoFinalizar }
