// DRA-FUNC-25 - Visualizar os exames criados no dia.
import {
  Avatar,
  Button,
  Center,
  Grid,
  GridItem,
  HStack,
  Spinner,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FiCheck } from 'react-icons/fi'
import { ModalExameSelecionado } from '../../Components/Exames/ModalExameSelecionado'
import { ModalExamesLiberados } from '../../Components/Exames/ModalExamesLiberados'
import api from '../../services/api'
import { ContainerPageTemplate } from '../../styles/ContainerPageTemplate'

function Exames() {
  const cd_cliente = localStorage.getItem('cd_cliente')
  const [exames, setexames] = useState([])
  const [exameSelecionado, setexameSelecionado] = useState([])
  const [cd_atendimento, setcd_atendimento] = useState('')
  const [cd_ped_rx, setcd_ped_rx] = useState('')
  const [isOpenExameSelecionado, setisOpenExameSelecionado] = useState(false)
  const [nm_paciente, setnm_paciente] = useState(false)
  const [isOpenExamesLiberados, setisOpenExamesLiberados] = useState(false)
  const [reload, setreload] = useState(false)
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)

  function StatsCard({ title, stat }) {
    return (
      <Stat py={'2'} bg="gray.300" borderRadius={4} boxShadow="base">
        <VStack pl={{ base: 2, md: 2 }} justifyContent={'space-between'}>
          <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
            {stat}
          </StatNumber>
          <StatLabel fontWeight={'medium'} isTruncated>
            {title}
          </StatLabel>
        </VStack>
      </Stat>
    )
  }

  // Consultar os exames do dia
  useEffect(() => {
    setIsLoading(true)
    setexames([])

    const data = new Date().toISOString().slice(0, 10)

    // Agora, após preencher examesLiberados, podemos buscar os exames da API.
    api.get('/v2/pedidos/data/' + data, { cd_cliente }).then((res) => {
      api
        .post('atendimentos/exames/completo', { cd_cliente, data })
        .then((response) => {
          const examesLiberados = []

          response.data.forEach((item) => {
            examesLiberados.push(item.cd_ped_rx)
          })

          const dataFormat = res.data
            .filter((item) => {
              const isLiberado = examesLiberados.includes(item.cd_ped_rx.toString())
              return !isLiberado // Retorna true para manter itens não liberados, false para excluí-los.
            })
            .map((item) => ({
              ...item,
              CD_PED_RX: item.cd_ped_rx,
              NM_PACIENTE: item.patient?.name,
              NM_PRESTADOR: item.doctors?.nm_prestador,
              NM_USUARIO: item.userPedido,
              isLiberado: item.isLiberado,
              CD_ATENDIMENTO: item.attendance?.cd_atendimento,
              CD_PRESTADOR: item.doctors?.cd_prestador,
              DT_ATENDIMENTO: item.created_at,
              CD_SET_EXA: item.sectors_exams?.cd_setor_exame,
              NM_SET_EXA: item.sectors_exams?.nm_setor,
              CD_CONVENIO: item.covenants?.cd_convenio,
              NM_CONVENIO: item.covenants?.nm_convenio,
              NM_PACIENTE: item.patient?.name,
              NM_PRESTADOR: item.doctors?.nm_prestador,
              EXAMES: item.exames,
              PHONE_PACIENTE: item.patient?.phone,
            }))

          const dataOrder = dataFormat.sort((a, b) => {
            if (a.CD_PED_RX < b.CD_PED_RX) {
              return 1
            }
            if (a.CD_PED_RX > b.CD_PED_RX) {
              return -1
            }
            return 0
          })

          // Filtra os elementos nulos (exames liberados) antes de definir o estado.
          setexames(dataOrder.filter((item) => item !== null))
        })
        .catch((error) => {
          toast({
            title: 'Erro ao consultar exames',
            description: error.response.data.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    })

    // localStorage.setItem('exames_anexados', ArrayExamesAnexados);
  }, [])

  const handleOpenExameSelecionado = (data) => {
    if (data) {
      setexameSelecionado(data)
      setnm_paciente(data.NM_PACIENTE)
      setcd_atendimento(data.CD_ATENDIMENTO)
      setcd_ped_rx(data.CD_PED_RX)
    }
    setisOpenExameSelecionado(!isOpenExameSelecionado)
  }

  const groupedData = {}

  exames.forEach((item) => {
    if (!groupedData[item.NM_SET_EXA]) {
      groupedData[item.NM_SET_EXA] = []
    }
    groupedData[item.NM_SET_EXA].push(item)
  })

  return (
    <ContainerPageTemplate>
      {isLoading && (
        <Center>
          <Spinner />
        </Center>
      )}

      <HStack spacing="4" mt={4} w="100%" justifyContent={'flex-end'}>
        <HStack>
          <Button
            leftIcon={<FiCheck />}
            onClick={() => setisOpenExamesLiberados(!isOpenExamesLiberados)}
            variant="primary"
          >
            Liberados
          </Button>
        </HStack>
      </HStack>

      <Grid templateColumns="repeat(auto-fill, minmax(300px, 1fr))" gap={4}>
        {Object.keys(groupedData).map((setExa) => (
          <GridItem key={setExa} p={4} borderRadius={4} alignItems="center" justifyContent="center" textAlign="center">
            <StatsCard title={setExa} stat={groupedData[setExa].length} />
            <VStack spacing={2} alignItems="left" w="100%" textAlign="left">
              {groupedData[setExa].map((info) => (
                <VStack
                  key={info.CD_PED_RX}
                  boxShadow={'base'}
                  p={1}
                  w="full"
                  alignItems={'left'}
                  borderRadius={5}
                  cursor="pointer"
                  bg="gray.50"
                  _hover={{ boxShadow: 'base' }}
                  textAlign={'center'}
                  border={'1px solid #ccc !important'}
                  onClick={() => handleOpenExameSelecionado(info)}
                >
                  <Text fontSize={'12px'} fontWeight="bold">
                    {info.NM_PACIENTE}
                  </Text>
                  <Text fontSize={'10px'}>{info.NM_PRESTADOR}</Text>
                  <Text fontSize={'10px'}>{info.CD_PED_RX}</Text>
                  <Center w="100%">
                    <Avatar size="xs" name={info.NM_USUARIO} src={info.NM_USUARIO} title={info.NM_USUARIO} />
                  </Center>
                </VStack>
              ))}
            </VStack>
          </GridItem>
        ))}
      </Grid>

      <ModalExameSelecionado
        isOpen={isOpenExameSelecionado}
        handleChangeModalIsOpen={() => setisOpenExameSelecionado(false)}
        info={exameSelecionado}
        cd_cliente={cd_cliente}
        nm_paciente={nm_paciente}
        cd_atendimento={cd_atendimento}
        cd_ped_rx={cd_ped_rx}
      />

      <ModalExamesLiberados
        isOpen={isOpenExamesLiberados}
        handleChangeModalIsOpen={() => setisOpenExamesLiberados(false)}
      />
    </ContainerPageTemplate>
  )
}

export default React.memo(Exames)
