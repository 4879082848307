import { Heading, Text } from '@chakra-ui/react'

export function TextInfoTemplate({ info, fontWeight, onClick, title }) {
  return (
    <Text fontSize={'12px'} fontWeight={fontWeight || ''} onClick={onClick} title={title || ''}>
      {info}
    </Text>
  )
}

export function TextBgTemplate({ info }) {
  return (
    <Text bgClip="text" bgGradient="linear(to-l, #5b73e8, #2C5282)" fontWeight={'bold'}>
      {info}
    </Text>
  )
}

export function HeadingInModal({ info }) {
  return (
    <Heading as="h2" size="md" fontWeight={'bold'} bgClip="text" bgGradient="linear(to-l, #5b73e8, #2C5282)">
      {info}
    </Heading>
  )
}
