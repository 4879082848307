import { Center, FormControl, Input, SimpleGrid, Spinner, VStack } from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react'
import { Context as ContextLoading } from '../../Context/LoaderContext'
import { FindPedidosLiberados } from '../../Functions/Pedidos/FindPedidosLiberados'
import { ModalComponent } from '../../_styles/ModalComponent '
import { ExamesItemInterno } from './ExamesItemInterno'
import { ModalExameSelecionado } from './ModalExameSelecionado'

export function ModalExamesLiberados({ isOpen, handleChangeModalIsOpen }) {
  const { cd_cliente } = useContext(ContextLoading)
  const [nm_paciente, setnm_paciente] = useState('')
  const [exameSelecionado, setexameSelecionado] = useState([])
  const [exames, setexames] = useState([])
  const [cd_atendimento, setcd_atendimento] = useState('')
  const [cd_ped_rx, setcd_ped_rx] = useState('')
  const [hr_liberacao, sethr_liberacao] = useState('')
  const [isOpenExameSelecionado, setisOpenExameSelecionado] = useState(false)
  const [Loading, setLoading] = useState(false)
  const [data, dataSelecionada] = useState(new Date().toISOString().slice(0, 10))

  useEffect(() => {
    setexames([])
    if (isOpen) {
      setLoading(true)
      FindPedidosLiberados(cd_cliente, data).then((res) => {
        setexames(res)
        setLoading(false)
      })
    }
  }, [data, isOpen])

  const handleOpenExameSelecionado = (data) => {
    if (data) {
      sethr_liberacao(data.hr_liberacao)
      setexameSelecionado(data)
      setnm_paciente(data.nm_paciente)
      setcd_atendimento(data.cd_atendimento)
      setcd_ped_rx(data.cd_ped_rx)
    }
    setisOpenExameSelecionado(!isOpenExameSelecionado)
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={handleChangeModalIsOpen}
      title="Exames Liberados"
      buttonLabel="Exames Liberados"
      buttonView={false}
    >
      <VStack spacing={4}>
        <FormControl>
          <Input type="date" value={data} onChange={(e) => dataSelecionada(e.target.value)} />
        </FormControl>

        {Loading && (
          <Center>
            <Spinner />
          </Center>
        )}

        {/* <Loader display={Loading} /> */}

        <SimpleGrid columns={[1, 3, 4]} spacing={4}>
          {exames.map((exame) => (
            <ExamesItemInterno info={exame} handleOpen={handleOpenExameSelecionado} />
          ))}
        </SimpleGrid>
      </VStack>
      <ModalExameSelecionado
        isOpen={isOpenExameSelecionado}
        handleChangeModalIsOpen={() => setisOpenExameSelecionado(false)}
        info={exameSelecionado}
        cd_cliente={cd_cliente}
        nm_paciente={nm_paciente}
        cd_atendimento={cd_atendimento}
        cd_ped_rx={cd_ped_rx}
        hr_liberacao={hr_liberacao}
      />
    </ModalComponent>
  )
}
