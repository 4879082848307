import { Box, HStack, Icon, VStack } from '@chakra-ui/react'
import { FiClock } from 'react-icons/fi'
import { TextBgTemplate, TextInfoTemplate } from '../../styles/FontsTemplate'

export const ExamesItemInterno = ({ info, handleOpen }) => (
  <Box onClick={() => handleOpen(info)} bg="white" boxShadow="md" p={4} rounded="md" cursor={'pointer'}>
    <VStack alignItems={'left'}>
      <HStack>
        <Icon as={FiClock} h={4} w={4} color="gray.500" />
        <TextInfoTemplate info={info.hr_liberacao} />
      </HStack>

      <TextBgTemplate info={info.nm_paciente} />
      <TextInfoTemplate info={info.cd_ped_rx} />
      <TextInfoTemplate info={info.nm_prestador} />
      <TextInfoTemplate info={info.nm_set_exa} />
    </VStack>
  </Box>
)
