// HIST-29
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BiMessageRoundedDots } from 'react-icons/bi'
import { BsCheck } from 'react-icons/bs'
import { FiDownload, FiFilter, FiSearch, FiXCircle } from 'react-icons/fi'
import { LoadingModal } from '../../Components/LoadingModal'
import { ModalEnvioMensagemManual } from '../../Components/Mensagens/ModalEnvioMensagemManual'
import { Context as ContextUser } from '../../Context/UserContext'
import { UpdatedInfoSchedule } from '../../Functions/Agendamentos/schedules'
import { gerarPlanilha } from '../../Functions/Excel'
import { CreateLog } from '../../Functions/logs'
import { GridComponent, SimpleGridComponent } from '../../_styles/GridComponent'
import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api'
import { ContainerPageTemplate } from '../../styles/ContainerPageTemplate'

function InfoView({ label, value }) {
  return (
    <Box>
      <Text fontSize="sm" color="gray.500">
        {label}
      </Text>
      <HStack>
        <Text fontSize="md" fontWeight="bold">
          {value}
        </Text>
      </HStack>
    </Box>
  )
}

const Dashboard = ({ schedules }) => {
  const quantRemarcados = schedules.filter((schedule) => schedule.isRemarcado).length
  const quantPendentesRemarcar = schedules.filter((schedule) => !schedule.isRemarcado && schedule.isPacRemarcar).length
  const quantInteressesRemarcar = schedules.filter((schedule) => schedule.isPacRemarcar).length
  return (
    <Box display="flex" justifyContent="space-between" gap={4} w="100%" py={4}>
      <Box
        p={4}
        bg="blue.200"
        borderRadius="md"
        w="full"
        border={'1px solid'}
        borderColor={'gray.50'}
        _hover={{ border: '1px solid', borderColor: 'gray.200' }}
      >
        <Text fontSize="xl" fontWeight="bold">
          {quantRemarcados}
        </Text>
        <Text fontSize="sm">Remarcados</Text>
        <Text fontSize="sm">{schedules.length} agendamentos</Text>
      </Box>
      <Box
        p={4}
        bg="green.200"
        borderRadius="md"
        w="full"
        border={'1px solid'}
        borderColor={'gray.50'}
        _hover={{ border: '1px solid', borderColor: 'gray.200' }}
      >
        <Text fontSize="xl" fontWeight="bold">
          {quantPendentesRemarcar}
        </Text>
        <Text fontSize="sm">Pendentes de Remarcar</Text>
        <Text fontSize="sm">{schedules.length} agendamentos</Text>
      </Box>
      <Box
        p={4}
        bg="yellow.200"
        borderRadius="md"
        w="full"
        border={'1px solid'}
        borderColor={'gray.50'}
        _hover={{ border: '1px solid', borderColor: 'gray.200' }}
      >
        <Text fontSize="xl" fontWeight="bold">
          {quantInteressesRemarcar}
        </Text>
        <Text fontSize="sm">Interesses em Remarcar</Text>
        <Text fontSize="sm">{schedules.length} agendamentos</Text>
      </Box>
    </Box>
  )
}

function Faltantes() {
  const [dataInicial, setdataInicial] = useState(new Date().toISOString().slice(0, 10))
  const [dataFinal, setdataFinal] = useState(new Date().toISOString().slice(0, 10))
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenModal, setisOpenModal] = useState(false)
  const { handleSubmit, register } = useForm()
  const [schedules, setSchedules] = useState([])
  const [schedulesFull, setSchedulesFull] = useState([])
  const [scheduleSelected, setScheduleSelected] = useState(null)
  const [celular, setCelular] = useState(null)
  const [isOpenModalEnvioMensagemManual, setIsOpenModalEnvioMensagemManual] = useState(false)
  const { User } = useContext(ContextUser)
  const [mensagem, setMensagem] = useState('')
  const toast = useToast()
  const [dataFormatExcel, setDataFormatExcel] = useState([])

  // Buscar os agendamentos faltantes por data.
  const handleFindFaltas = (data) => {
    setIsLoading(true)
    setdataFinal(data.dataFinal)
    setdataInicial(data.dataInicial)
    api
      .post('/api/v2/schedules/calendar/period/faltas', {
        date_start: data.dataInicial,
        date_end: data.dataFinal,
      })
      .then((res) => {
        setSchedules(res.data)
        setSchedulesFull(res.data)
      })
      .catch((err) => {
        toast({
          title: 'Erro',
          description: err.response.data.error,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleOpenModal = (item) => {
    setScheduleSelected(item)
    setisOpenModal(true)
  }

  const handleOpenModalMensagem = async (data) => {
    const dataLog = {
      info: 'Enviar mensagem de falta no agendamento - ' + data.nm_paciente,
    }

    const dataUpdated = {
      isEnvioMsgFalta: true,
      dhEnvioMsgFalta: new Date(),
    }

    await CreateLog(10, data.id, dataLog)
    await UpdatedInfoSchedule(data.id, dataUpdated)

    const mensagemDefault = `Olá ${data.nm_paciente}, tudo bem? \n\nSentimos sua falta no agendamento do dia ${data.dt_agenda}.\n\nCaso queira remarcar, clique no link a seguir:\nhttps://dragendamento.com/pacientes/remarcar/${data.id}`
    setMensagem(mensagemDefault)
    setIsOpenModalEnvioMensagemManual(true)
    setCelular(data.nr_celular)
  }

  const handleConfirmarRemarcado = async (data, isRemarcado) => {
    const dataUpdated = {
      isRemarcado: isRemarcado,
    }
    const dataLog = {
      info: 'Confirmou remarcação - ' + data.nm_paciente,
    }

    await CreateLog(10, data.id, dataLog)
    await UpdatedInfoSchedule(data.id, dataUpdated).then((res) => {
      if (res) {
        setScheduleSelected({ ...scheduleSelected, ...dataUpdated })

        toast({
          title: 'Sucesso',
          description: 'Agendamento atualizado com sucesso...',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      } else {
        toast({
          title: 'Erro',
          description: 'Erro ao confirmar remarcação.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      }
    })
  }

  const handleFilterInteresseRemarcar = (value) => {
    if (value === 'true') {
      const data = schedulesFull.filter((item) => item.isPacRemarcar === true)
      setSchedules(data)
    } else {
      setSchedules(schedulesFull)
    }
  }

  useEffect(() => {
    setDataFormatExcel(
      schedules.map((item) => {
        return {
          id: item.id,
          nm_paciente: item.nm_paciente,
          cd_it_agenda_central: item.cd_it_agenda_central,
          dt_agenda: item.dt_agenda,
          hr_agenda: item.hr_agenda,
          falta: item.isFalta,
          msgEnviada: item.isEnvioMsgFalta,
          isPacRemarcar: item.isPacRemarcar,
          dhPacRemarcar: item.dhPacRemarcar,
          isRemarcado: item.isRemarcado,
          dhEnvioMsgFalta: item.dhEnvioMsgFalta,
          ds_item_agendamento: item.schedule_item.ds_item_agendamento,
          nm_convenio: item.covenants.nm_convenio,
          observacao: item.observacao,
        }
      })
    )
  }, [schedules])

  return (
    <ContainerPageTemplate>
      {isLoading && <LoadingModal isLoading={isLoading} />}

      <ModalEnvioMensagemManual
        isOpen={isOpenModalEnvioMensagemManual}
        onClose={() => setIsOpenModalEnvioMensagemManual(false)}
        celular={celular}
        User={User}
        scheduleId={scheduleSelected?.id}
        mensagem={mensagem}
      />

      <ModalComponent isOpen={isOpenModal} onClose={() => setisOpenModal(false)}>
        <VStack spacing={4} alignItems={'left'}>
          <InfoView label={'ID'} value={scheduleSelected?.id} />
          <InfoView label={'Nome'} value={scheduleSelected?.nm_paciente} />
          <InfoView label={'Item de agendamento'} value={scheduleSelected?.schedule_item?.ds_item_agendamento} />
          <InfoView label={'Data'} value={scheduleSelected?.dt_agenda} />
          <InfoView label={'Prestador'} value={scheduleSelected?.doctors?.nm_prestador} />
          <InfoView label={'Convênio'} value={scheduleSelected?.covenants?.nm_convenio} />
          <HStack alignItems={'end'}>
            <InfoView
              label={'Mensagem enviada'}
              value={
                scheduleSelected?.isEnvioMsgFalta ? (
                  <Icon as={BsCheck} color="gray.700" title={scheduleSelected?.dhEnvioMsgFalta} w={6} h={6} />
                ) : (
                  'NÃO'
                )
              }
            />

            <IconButton
              title="Enviar mensagem"
              onClick={() => handleOpenModalMensagem(scheduleSelected)}
              cursor={'pointer'}
              colorScheme="blue"
            >
              <Icon as={BiMessageRoundedDots} />
            </IconButton>
          </HStack>
          <Flex alignItems={'end'}>
            <InfoView label={'Interesse em remarcar'} value={scheduleSelected?.isPacRemarcar ? 'SIM' : ''} />
          </Flex>

          <HStack alignItems={'end'}>
            <InfoView label={'Remarcado: '} value={scheduleSelected?.isRemarcado} />

            <HStack>
              <IconButton onClick={() => handleConfirmarRemarcado(scheduleSelected, true)} colorScheme="green">
                <Icon as={BsCheck} title={scheduleSelected?.dhEnvioMsgFalta} />
              </IconButton>
              <IconButton onClick={() => handleConfirmarRemarcado(scheduleSelected, false)} colorScheme="red">
                <Icon as={FiXCircle} title={scheduleSelected?.dhEnvioMsgFalta} />
              </IconButton>
            </HStack>
          </HStack>
        </VStack>
      </ModalComponent>

      <VStack w="100%" alignItems={'left'}>
        <HStack as="form" onSubmit={handleSubmit(handleFindFaltas)} pb={8} alignItems={'end'}>
          <FormControl isRequired maxW={'40%'}>
            <FormLabel> Data Inicial</FormLabel>
            <Input
              type="date"
              {...register('dataInicial')}
              value={dataInicial}
              onChange={(e) => setdataInicial(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired maxW={'40%'}>
            <FormLabel> Data Final</FormLabel>
            <Input
              type="date"
              {...register('dataFinal')}
              value={dataFinal}
              onChange={(e) => setdataFinal(e.target.value)}
            />
          </FormControl>
          <Button type="submit" colorScheme="blue" leftIcon={<FiSearch />}>
            Buscar
          </Button>
        </HStack>
        <HStack w="100%" justifyContent={'flex-end'}>
          <InputGroup px={2}>
            <InputLeftElement pointerEvents="none" children={<FiFilter />} mr={4} />
            <Select
              onChange={(e) => handleFilterInteresseRemarcar(e.target.value)}
              placeholder="Selecione..."
              px={1} // Adicione essa propriedade para definir o espaçamento horizontal
            >
              <option value="">Todos</option>
              <option value="true">Interessados em remarcar</option>
            </Select>
          </InputGroup>
          <IconButton
            onClick={() => gerarPlanilha(dataFormatExcel, 'Faltas')}
            variant="unstyled"
            cursor={'pointer'}
            title="Download"
          >
            <Icon as={FiDownload} w={6} h={6} />
          </IconButton>
        </HStack>
      </VStack>

      <Dashboard schedules={schedules} />

      <SimpleGridComponent title={''} isLoading={isLoading}>
        {schedules.map((item, index) => (
          <GridComponent index={index} onClick={() => handleOpenModal(item)}>
            <InfoView label={'Nome'} value={item?.nm_paciente} />
            <InfoView
              label={'Mensagem enviada'}
              value={item.isEnvioMsgFalta ? <Icon as={BsCheck} color="green.700" w={6} h={6} /> : 'NÃO'}
            />

            {item.isRemarcado === null && <InfoView label={'Remarcado: '} value={'PENDENTE'} />}
            {item.isRemarcado === true && <InfoView label={'Remarcado: '} value={'SIM'} />}
            {item.isRemarcado === false && <InfoView label={'Remarcado: '} value={'NÃO'} />}

            {item.isEnvioMsgFalta && (
              <InfoView
                label={'Interesse em remarcar: '}
                value={item?.isPacRemarcar === true ? <Icon as={BsCheck} color="green.700" w={6} h={6} /> : ''}
              />
            )}
          </GridComponent>
        ))}
      </SimpleGridComponent>
    </ContainerPageTemplate>
  )
}

export { Faltantes }
