import { HStack, Input, Select, SimpleGrid } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { ExamesItemInterno } from '../../Components/Exames/ExamesItemInterno'
import { ModalExameSelecionado } from '../../Components/Exames/ModalExameSelecionado'
import { Context as ContextLoading } from '../../Context/LoaderContext'
import { Context as ContextUser } from '../../Context/UserContext'
import { FindLaudosPeriodo } from '../../Functions/Laudos/FindLaudosPeriodo'
import { ContainerPageTemplate } from '../../styles/ContainerPageTemplate'

const Laudos = function () {
  const { cd_cliente } = useContext(ContextLoading)
  const { setLoading } = useContext(ContextUser)
  const [exames, setexames] = useState([])
  const [sn_laudado, setsn_laudado] = useState('')
  const [dataInicial, setDataInicial] = useState('')
  const [dataFinal, setdataFinal] = useState('')
  const [exameSelecionado, setexameSelecionado] = useState([])
  const [cd_atendimento, setcd_atendimento] = useState('')
  const [cd_ped_rx, setcd_ped_rx] = useState('')
  const [isOpenExameSelecionado, setisOpenExameSelecionado] = useState(false)
  const [nm_paciente, setnm_paciente] = useState('')

  useEffect(() => {
    if (sn_laudado) {
      setLoading(true)
      FindLaudosPeriodo(dataInicial, dataFinal, sn_laudado)
        .then((res) => {
          setexames(res)
          setLoading(false)
        })
        .catch(() => {
          setexames([])
          setLoading(false)
        })
    }
  }, [dataInicial, dataFinal, sn_laudado])

  const handleOpenExameSelecionado = (data) => {
    if (data) {
      setexameSelecionado(data)
      setnm_paciente(data.nm_paciente)
      setcd_atendimento(data.cd_atendimento)
      setcd_ped_rx(data.cd_ped_rx)
    }
    setisOpenExameSelecionado(!isOpenExameSelecionado)
  }

  return (
    <ContainerPageTemplate>
      <HStack>
        <Input type="date" onChange={(e) => setDataInicial(e.target.value)} />
        <Input type="date" onChange={(e) => setdataFinal(e.target.value)} />

        <Select onChange={(e) => setsn_laudado(e.target.value)}>
          <option value="DEFAULT" selected disabled>
            Situação do Laudo
          </option>
          <option value="S">Laudados</option>
          <option value="N">Sem laudo </option>
        </Select>
      </HStack>

      <SimpleGrid columns={[1, 3, 4]} spacing={8} mt={4}>
        {exames.map((exame) => (
          <ExamesItemInterno info={exame} handleOpen={handleOpenExameSelecionado} />
        ))}
      </SimpleGrid>

      <ModalExameSelecionado
        isOpen={isOpenExameSelecionado}
        handleChangeModalIsOpen={() => setisOpenExameSelecionado(false)}
        info={exameSelecionado}
        cd_cliente={cd_cliente}
        nm_paciente={nm_paciente}
        cd_atendimento={cd_atendimento}
        cd_ped_rx={cd_ped_rx}
      />
    </ContainerPageTemplate>
  )
}

export default React.memo(Laudos)
