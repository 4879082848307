import { Button, Checkbox, CheckboxGroup, Select, VStack, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { CreateLog } from '../../Functions/logs'
import api from '../../services/api'

function ScheduleStatusUpdater({ schedulesFull, origens, onClose, setReloadingData, reloadingData }) {
  const [selectedSchedules, setSelectedSchedules] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [selectedOrigem, setSelectedOrigem] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()

  useEffect(() => {
    if (selectAll) {
      setSelectedSchedules(schedulesFull.map((schedule) => schedule.id))
    } else {
      setSelectedSchedules([])
    }
  }, [selectAll])

  const handleCheckboxChange = (values) => {
    if (values.includes('all')) {
      setSelectAll(true)
      setSelectedSchedules(schedulesFull.map((schedule) => schedule.id))
    } else {
      setSelectAll(false)
      setSelectedSchedules(values)
    }
  }

  const handleButtonClick = async () => {
    setIsLoading(true)
    const dataLog = {
      info:
        'Conferência de agendamentos - Alteração de status em massa de ' + selectedSchedules.length + ' agendamentos',
    }
    await CreateLog(8, null, dataLog)

    api
      .put('/api/v2/schedules/origens', {
        originId: selectedOrigem,
        ids: selectedSchedules,
      })
      .then(() => {
        toast({
          title: 'Status atualizado com sucesso!',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
      .catch((err) => {
        toast({
          title: 'Erro ao atualizar status!',
          description: err.response.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
      .finally(() => {
        setReloadingData(!reloadingData)
        onClose()
        setIsLoading(false)
        window.location.reload()
      })
  }

  return (
    <VStack spacing={4} alignItems={'left'}>
      <CheckboxGroup
        colorScheme="green"
        defaultValue={[]}
        value={selectAll ? ['all', ...selectedSchedules] : selectedSchedules}
        onChange={handleCheckboxChange}
      >
        <VStack align="start">
          <Checkbox value="all">Todos</Checkbox>
          {schedulesFull.map((schedule) => (
            <Checkbox key={schedule.id} value={schedule.id}>
              {schedule.nm_paciente}
            </Checkbox>
          ))}
        </VStack>
      </CheckboxGroup>

      <Select onChange={(e) => setSelectedOrigem(e.target.value)} placeholder="Selecione o status">
        {origens.map((item, index) => (
          <option key={index} value={item.id}>
            {item.description}
          </option>
        ))}
      </Select>

      <Button colorScheme="blue" onClick={handleButtonClick} isDisabled={!selectedOrigem} isLoading={isLoading}>
        Atualizar status
      </Button>
    </VStack>
  )
}

export { ScheduleStatusUpdater }
