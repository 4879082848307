import { List } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { PagamentoPorID } from '..'
import { CreatePagamento } from '../../../Functions/Pagamentos/CreatePagamento'
import { FindPagamentosPorAtendimento } from '../../../Functions/Pagamentos/FindPagamentosPorAtendimento'
import { NotificaError } from '../../../Functions/Toast/error'
import { NotificaSuccess } from '../../../Functions/Toast/success'
import { CloseModal } from '../../Others/CloseModal'
import { LinkDefault } from '../../Others/LinkDefault'
import { PagamentoItem } from '../PagamentoItem'
import { InfoCriarPagamento } from './Info'

export function CriarPagamento({
  isOpen,
  handleChangeModalIsOpen,
  cd_paciente,
  nm_paciente,
  cd_prestador,
  nm_prestador,
  cd_atendimento,
  nm_convenio,
  ds_con_pla,
  email,
  cpf,
  sn_retorno,
  phone_prestador,
  id_atendime,
}) {
  const [id_pagamento, setid_pagamento] = useState('')
  const [isOpenPagamentoID, setisOpenPagamentoID] = useState(false)
  const [PagamentosDoAtendimento, setPagamentosDoAtendimento] = useState([])

  let phone = phone_prestador

  const handleCreatePagamento = () => {
    if (phone_prestador === null) {
      phone = 1223
    }
    CreatePagamento(
      cd_paciente,
      cd_atendimento,
      '',
      '',
      '',
      cd_prestador,
      'N',
      nm_convenio,
      ds_con_pla,
      nm_paciente,
      email,
      cpf,
      nm_prestador,
      phone
    )
      .then((res) => {
        NotificaSuccess('Pagamento criado com sucesso...')
        if (res) {
          setid_pagamento(res.id)
          setisOpenPagamentoID(true)
        }
      })
      .catch(() => {
        NotificaError('Erro ao criar pagamento...')
      })
  }

  const handleOpenPagamentoID = (data) => {
    if (data) {
      setid_pagamento(data.id)
    }
    setisOpenPagamentoID(!isOpenPagamentoID)
  }

  useEffect(() => {
    if (isOpen) {
      FindPagamentosPorAtendimento(cd_atendimento).then((res) => {
        setPagamentosDoAtendimento(res)
      })
    }
  }, [isOpen, isOpenPagamentoID])

  return (
    <Modal transparent isOpen={isOpen}>
      <CloseModal handleCloseModal={handleChangeModalIsOpen} />
      <h3>Revise todas as informações antes de criar...</h3>
      <InfoCriarPagamento info={cd_paciente} title="Prontuário" />
      <InfoCriarPagamento info={nm_paciente} title="Paciente" />
      <InfoCriarPagamento info={nm_prestador} title="Prestador" />
      <InfoCriarPagamento info={cd_atendimento} title="Atendimento" />
      <InfoCriarPagamento info={nm_convenio} title="Convênio" />
      <InfoCriarPagamento info={ds_con_pla} title="Plano" />
      <InfoCriarPagamento info={id_atendime} title="ID" />

      <LinkDefault titleLink="Criar Pagamento" FunctionClick={() => handleCreatePagamento()} tipoBotao="link-green" />

      <List>
        {PagamentosDoAtendimento.map((pagamento) => (
          <PagamentoItem info={pagamento} handleChangeModalIsOpen={handleOpenPagamentoID} />
        ))}
      </List>

      <PagamentoPorID
        isOpen={isOpenPagamentoID}
        id_pagamento={id_pagamento}
        handleChangeModalIsOpen={() => setisOpenPagamentoID(!isOpenPagamentoID)}
        cd_atendimento={cd_atendimento}
        nm_paciente={nm_paciente}
      />
    </Modal>
  )
}
