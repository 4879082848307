import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  Spinner,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react'
import { AiOutlineCloseSquare } from 'react-icons/ai'
import { BiMessageAltDots } from 'react-icons/bi'
import { FaExchangeAlt } from 'react-icons/fa'
import { FiFile, FiPaperclip, FiRefreshCcw, FiSend } from 'react-icons/fi'
import { Context as ContextUser } from '../../Context/UserContext'
import { FindDocumentosEnviados } from '../../Functions/Chat/FindDocumentosEnviados'
import { CreateFuncoesLogs } from '../../Functions/FuncoesSistemaLog/CreateFuncoesLogs'
import { AddMensagem } from '../../Functions/Mensagens/AddMensagem'
import { MensagensProntasFinalizarProtocolo } from '../../Functions/MensagensProntas/MensagensProntasFinalizarProtocolo'
import { ModalComponent } from '../../_styles/ModalComponent '
import imgUser from '../../assets/user.svg'
import api from '../../services/api'
import { ButtonPageTemplate, ButtonPageTemplateSubmit } from '../../styles/ButtonPageTemplate'
import { ChatFinalizarProtocolo } from './ChatFinalizarProtocolo'
import { ModalChatAlterarNome } from './ModalChatAlterarNome'
import { ModalChatDocumentosRecebidos } from './ModalChatDocumentosRecebidos'
import { ModalChatMensagensAnteriores } from './ModalChatMensagensAnteriores'
import { ModalChatTransferirSetor } from './ModalChatTransferirSetor'

export function ModalChatselecionado({ isOpen, handleChangeModalIsOpen, id, nm_paciente, phone, id_contato }) {
  const { User } = useContext(ContextUser)
  const cd_cliente = localStorage.getItem('cd_cliente')
  const isVisibleAgendamentosPaciente = true
  const [Mensagens, setMensagens] = useState([])
  const [AgendamentosDoPaciente, setAgendamentosDoPaciente] = useState([])
  const [DocumentosEnviadosPaciente, setDocumentosEnviadosPaciente] = useState([])
  const [modalIsOpenDocumentosRecebidos, setmodalIsOpenDocumentosRecebidos] = useState(false)
  const [modalIsOpenFinalizarProtocolo, setmodalIsOpenFinalizarProtocolo] = useState(false)
  const [modalIsOpenTransferirSetor, setmodalIsOpenTransferirSetor] = useState(false)
  const [modalIsOpenMensagensAnteriores, setmodalIsOpenMensagensAnteriores] = useState(false)
  const [MensagemEnviar, setMensagemEnviar] = useState('')
  const [reloadInChat, setreloadInChat] = useState(false)
  const [modalIsOpenAlterarNome, setmodalIsOpenAlterarNome] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()

  const LinkAnexar = () => {
    CreateFuncoesLogs(cd_cliente, 'LINK_ANEXAR', 'PROTOCOLO_LINK_ANEXAR').then(() => {
      setMensagemEnviar(`https://dragendamento.com/contatos/documentos/anexar/${id_contato}/${cd_cliente}`)
      document.getElementById('input-mensagem-chat').focus()
    })
  }

  const handleChangeModalIsOpenDocumentosEnviadosPaciente = () => {
    CreateFuncoesLogs(cd_cliente, 'DOCS_RECEBIDOS', 'PROTOCOLO_DOCS_RECEBIDOS').then(() => {
      handleChangeModalIsOpen()
      setmodalIsOpenDocumentosRecebidos(!modalIsOpenDocumentosRecebidos)
    })
  }

  const handleReload = () => {
    setreloadInChat(!reloadInChat)
  }

  const handleChangeModalIsOpenTransferirSetor = () => {
    CreateFuncoesLogs(cd_cliente, 'TRANSF_SETOR', 'PROTOCOLO_TRANSF_SETOR').then(() => {
      handleChangeModalIsOpen()
      setmodalIsOpenTransferirSetor(!modalIsOpenTransferirSetor)
    })
  }

  const handleChangeModalIsOpenFinalizarProtocolo = () => {
    CreateFuncoesLogs(cd_cliente, 'PROTOCOLO_FINALIZAR', 'PROTOCOLO_FINALIZAR').then(() => {
      handleChangeModalIsOpen()
      setmodalIsOpenFinalizarProtocolo(!modalIsOpenFinalizarProtocolo)
    })
  }

  const handleChangeModalIsOpenMensagensAnteriores = () => {
    CreateFuncoesLogs(cd_cliente, 'PROTOCOLO_MSG_ANTERIOR', 'PROTOCOLO_MSG_ANTERIOR').then(() => {
      handleChangeModalIsOpen()
      setmodalIsOpenMensagensAnteriores(!modalIsOpenMensagensAnteriores)
      setreloadInChat(!reloadInChat)
    })
  }

  const handleChangeModalAlterarNome = () => {
    handleChangeModalIsOpen()
    setmodalIsOpenAlterarNome(!modalIsOpenAlterarNome)
    if (modalIsOpenAlterarNome) handleChangeModalIsOpen()
  }

  // Validar se o WhatsApp está ativo no momento antes de enviar.
  async function handleEnviarMensagem(e) {
    e.preventDefault()

    // Retorna se a mensagem for nula!
    if (MensagemEnviar == '') return

    api
      .get(`/api/v2/clients/${User.clientId}`)
      .then((response) => {
        if (response.data.whatsappAtivo) {
          setMensagens([
            ...Mensagens,
            {
              id_protocolo: id,
              id_cirurgia: '',
              id_agendamento: '',
              id_atendimento: '',
              sn_paciente: 'N',
              mensagem: MensagemEnviar,
              nm_usuario: localStorage.getItem('usuario'),
            },
          ])
          api
            .post('mensagens/add', {
              phone: phone,
              cd_cliente: cd_cliente,
              id_protocolo: id,
              id_cirurgia: '',
              id_agendamento: '',
              id_atendimento: '',
              sn_paciente: 'N',
              mensagem: MensagemEnviar,
              nm_usuario: localStorage.getItem('usuario'),
            })
            .then(() => {
              setMensagemEnviar('')

              api
                .post('protocolos/update/status/envio', {
                  id,
                  ultimo_envio: 'H',
                })
                .then(() => {})
              document.getElementById('input-mensagem-chat').focus()
            })
            .catch(() => {
              toast({
                title: 'Atenção',
                description: 'Ocorreu um erro ao enviar a mensagem.',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
              })
            })
            .finally(() => {
              document.getElementById('input-mensagem-chat').focus()
            })
          return
        } else {
          toast({
            title: 'Atenção',
            description: 'O WhatsApp está inativo no momento.',
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          })
        }
      })
      .catch(() => {})
  }

  // Função para finalizar o protocolo.
  async function handleFinalizarProtocolo() {
    if (window.confirm('Deseja realmente finalizar o protocolo?')) {
      setIsLoading(true)
      api
        .post('api/chat/update/protocolo', {
          id,
          sn_pendente: 'N',
          phone: '1',
        })
        .then(() => {
          handleChangeModalIsOpen()

          MensagensProntasFinalizarProtocolo().then((res) => {
            AddMensagem(phone, id, '', res)
              .then(() => {
                toast({
                  title: 'Sucesso',
                  description: 'Protocolo finalizado com sucesso.',
                  status: 'success',
                  duration: 5000,
                  isClosable: true,
                  position: 'top-right',
                })
              })
              .catch(() => {})
          })
        })
        .catch(() => {
          handleChangeModalIsOpen()
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  // Função para consultas as mensagens do chat.
  useEffect(() => {
    if (!isOpen) return
    setIsLoading(true)
    api
      .post('mensagens/enviadas/id/protocolos', { id_protocolo: id })
      .then((response) => {
        setMensagens(response.data)
        document.getElementById('input-mensagem-chat').focus()
      })
      .catch(() => {
        setMensagens([])
        document.getElementById('input-mensagem-chat').focus()
      })
      .finally(() => {
        setIsLoading(false)
      })

    api
      .post('agendamentos/orientacoes/mv', { cd_cliente, celular: phone })
      .then((response) => {
        setAgendamentosDoPaciente(response.data)
      })
      .catch(() => {
        setAgendamentosDoPaciente([])
      })

    FindDocumentosEnviados(id_contato, cd_cliente)
      .then((res) => {
        setDocumentosEnviadosPaciente(res)
      })
      .catch(() => {
        setDocumentosEnviadosPaciente([])
      })
  }, [isOpen, id, reloadInChat, modalIsOpenFinalizarProtocolo, User])

  return (
    <>
      {/* Se estiver carregando apareça o modal  */}
      <Modal isOpen={isLoading} size={'full'}>
        <ModalContent>
          <ModalBody>
            <Center h="100vh">
              <VStack w="100%">
                <Spinner />
                <Text>Carregando...</Text>
              </VStack>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>

      <ModalComponent isOpen={isOpen} onClose={() => handleChangeModalIsOpen(false)}>
        <HStack align={'center'}>
          <Avatar src={imgUser} name={nm_paciente} mr={4} />
          <Box onClick={() => handleChangeModalAlterarNome()} cursor="pointer">
            <Text fontSize={14}>{nm_paciente}</Text>
            <Text fontSize={12}>{phone}</Text>
          </Box>
        </HStack>

        <HStack spacing={4} color="gray.600" w="100%" justifyContent={'flex-end'}>
          <Button variant="links" onClick={() => LinkAnexar()} title="Link para anexar">
            <FiPaperclip />
          </Button>
          <Button
            variant="links"
            onClick={() => handleChangeModalIsOpenDocumentosEnviadosPaciente()}
            title="Documentos recebidos"
          >
            <FiFile />
          </Button>

          <Button variant="links" onClick={() => handleChangeModalIsOpenTransferirSetor()} title="Transferir">
            <FaExchangeAlt />
          </Button>

          <Button variant="links" onClick={() => handleChangeModalIsOpenMensagensAnteriores()} title="Histórico">
            <BiMessageAltDots />
          </Button>

          <Button variant="links" onClick={() => handleFinalizarProtocolo(id)} title="Finalizar" colorScheme="red">
            <AiOutlineCloseSquare />
          </Button>
        </HStack>

        <Flex flexDir={'column'}>
          {isVisibleAgendamentosPaciente && (
            <Flex mb={2}>
              {AgendamentosDoPaciente.map((agendamento) => (
                <Box p={4} boxShadow={'base'} borderRadius={8}>
                  <Text fontWeight={'bold'}>{agendamento.NM_PACIENTE}</Text>
                  <Text fontSize={'12px'}>{agendamento.HR_AGENDA}</Text>
                  <Text fontSize={'10px'}>{agendamento.DS_ITEM_AGENDAMENTO}</Text>
                </Box>
              ))}
            </Flex>
          )}

          <VStack p={2} flex={'column'} fontSize="14px">
            {Mensagens.map((mensagem, index) => (
              <Flex
                w="100%"
                key={index}
                align={'center'}
                title={mensagem.nm_usuario + ' - ' + mensagem.data}
                justifyContent={mensagem.sn_paciente === 'S' ? 'flex-start' : 'flex-end'}
              >
                <Text
                  maxW={'60%'}
                  borderRadius={8}
                  p={2}
                  bg={mensagem.sn_paciente === 'S' ? 'gray.200' : '#0A80FF'}
                  color={mensagem.sn_paciente === 'S' ? 'gray.700' : 'white'}
                >
                  {mensagem.mensagem}
                </Text>
              </Flex>
            ))}
          </VStack>

          <form onSubmit={handleEnviarMensagem}>
            <FormControl mb={4}>
              <FormLabel>O que deseja enviar? </FormLabel>
              <Input
                bg="white"
                type="text"
                value={MensagemEnviar}
                onChange={(e) => setMensagemEnviar(e.target.value)}
                placeholder="Digite aqui a mensagem para o paciente."
                minH="100px"
                id="input-mensagem-chat"
                maxLength={1000}
              />
              <Text>{MensagemEnviar.length}/1000</Text>
            </FormControl>

            <HStack>
              <ButtonPageTemplateSubmit title="Enviar" icon={<FiSend />} />
              <ButtonPageTemplate
                icon={<FiRefreshCcw />}
                variant="link"
                onClick={() => setreloadInChat(!reloadInChat)}
              />
            </HStack>
          </form>
        </Flex>
      </ModalComponent>
      <ModalChatDocumentosRecebidos
        isOpen={modalIsOpenDocumentosRecebidos}
        handleChangeModalIsOpen={handleChangeModalIsOpenDocumentosEnviadosPaciente}
        nm_paciente={nm_paciente}
        documentos={DocumentosEnviadosPaciente}
      />
      <ChatFinalizarProtocolo
        isOpen={modalIsOpenFinalizarProtocolo}
        handleChangeModalIsOpen={handleChangeModalIsOpenFinalizarProtocolo}
        nm_paciente={nm_paciente}
        id={id}
        handleReload={handleReload}
        phone={phone}
        handleChageModalMain={handleChangeModalIsOpen}
      />
      <ModalChatTransferirSetor
        isOpen={modalIsOpenTransferirSetor}
        handleChangeModalIsOpen={handleChangeModalIsOpenTransferirSetor}
        id={id}
        handleChageModalMain={handleChangeModalIsOpen}
        handleChangeModalChatSelecionado={handleChangeModalIsOpen}
      />

      <ModalChatMensagensAnteriores
        isOpen={modalIsOpenMensagensAnteriores}
        handleChangeModalIsOpen={handleChangeModalIsOpenMensagensAnteriores}
        id_contato={id_contato}
      />
      <ModalChatAlterarNome
        isOpen={modalIsOpenAlterarNome}
        handleChangeModalIsOpen={handleChangeModalAlterarNome}
        id={id}
        nm_paciente={nm_paciente}
        phone={phone}
      />
    </>
  )
}
