/* eslint-disable */
import api from '../../services/api'

export async function FindPagamentosPorID(cd_atendimento, id) {
  return await api
    .post('pagamentos/busca/id', { cd_atendimento, id })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}
