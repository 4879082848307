import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'

function gerarPlanilha(dados, name) {
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet(name)

  // Verificamos se temos dados e se o primeiro item é um objeto
  if (!dados || !dados.length || typeof dados[0] !== 'object') {
    throw new Error('Dados inválidos fornecidos para gerarPlanilha')
  }

  // Usamos as chaves do primeiro item para gerar as colunas
  const columns = Object.keys(dados[0]).map((key) => ({
    header: key,
    key,
    width: 15, // Você pode ajustar a largura conforme necessário
  }))

  worksheet.columns = columns

  worksheet.addRows(dados)

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    saveAs(blob, `${name.toUpperCase()}.xlsx`)
  })
}

export { gerarPlanilha }
