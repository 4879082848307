// DRA-HIST-19
import {
  Box,
  Center,
  Divider,
  FormControl,
  FormLabel,
  GridItem,
  HStack,
  Icon,
  Input,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BsCheckAll, BsXCircle } from 'react-icons/bs'
import { FiCalendar, FiCopy } from 'react-icons/fi'
import { GetRequestByPeriod } from '../../Functions/Agendamentos/schedules'
import { GridComponent, SimpleGridComponent } from '../../_styles/GridComponent'
import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api'
import { ContainerPageTemplate } from '../../styles/ContainerPageTemplate'

function InfoView({ label, value }) {
  const toast = useToast()

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(function () {
      toast({
        title: 'Copiado com sucesso!',
        description: 'Pressione CTRL+V para colar.',
        status: 'info',
        duration: 1500,
        isClosable: true,
        position: 'top-right',
      })
    })
  }

  return (
    <Box>
      <Text fontSize="sm" color="gray.500">
        {label}
      </Text>
      <HStack>
        <Text fontSize="md" fontWeight="bold">
          {value}
        </Text>
        {value && <Icon as={FiCopy} onClick={() => copyToClipboard(value)} />}
      </HStack>
    </Box>
  )
}

function groupSchedulesByPatient(schedules) {
  return schedules.reduce((acc, schedule) => {
    const patientName = schedule.patient.name
    if (!acc[patientName]) {
      acc[patientName] = []
    }
    acc[patientName].push(schedule)
    return acc
  }, {})
}

const SolicitacoesAgendamentos = function () {
  const [data, setData] = useState(new Date().toISOString().slice(0, 10))
  const [scheduleSelected, setScheduleSelected] = useState(null)
  const [schedulesGroup, setSchedulesGroup] = useState([])
  const [schedulesProntuario, setSchedulesProntuario] = useState([])
  const [isOpenModalEdit, setIsOpenModalEdit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [patientId, setPatientId] = useState(null)

  useEffect(() => {
    if (!patientId) return
    setIsLoading(true)
    setSchedulesProntuario([])
    api
      .get('api/v2/schedules/patient/' + patientId)
      .then((res) => {
        setSchedulesProntuario(res.data)
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false)
      })
  }, [patientId])

  const handleOpenModalEdit = (schedule) => {
    setScheduleSelected(schedule)
    setPatientId(schedule.patientId)
    setIsOpenModalEdit(true)
  }

  useEffect(() => {
    if (!data) return
    setIsLoading(true)
    GetRequestByPeriod(data, data)
      .then((res) => {
        if (res) {
          const dataGroup = groupSchedulesByPatient(res)
          setSchedulesGroup(dataGroup)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [data])

  return (
    <>
      {/* Modal de edição */}
      <ModalComponent
        isOpen={isOpenModalEdit}
        onClose={() => setIsOpenModalEdit(false)}
        title={scheduleSelected?.exame}
      >
        <VStack spacing={6} textAlign={'left'} alignItems={'left'}>
          <HStack w="100%" spacing={8}>
            <InfoView label="Prontuário" value={scheduleSelected?.patient?.codPatient} />
            <InfoView label="Paciente" value={scheduleSelected?.patient?.name} />
            <InfoView label="Contato" value={scheduleSelected?.patient?.phone} />
          </HStack>

          <HStack w="100%" spacing={8}>
            <InfoView label="Convênio" value={scheduleSelected?.covenants?.nm_convenio} />
            <InfoView label="Plano" value={scheduleSelected?.covenantsPlan} />
            <InfoView label="Carteira" value={scheduleSelected?.attendance?.nr_carteira} />
          </HStack>

          <HStack w="100%" spacing={8}>
            <InfoView label="Atendimento" value={scheduleSelected?.attendance?.cd_atendimento} />
            <InfoView label="Exame" value={scheduleSelected?.exame} />
            <InfoView label="Solicitante" value={scheduleSelected?.userRequest} />
          </HStack>

          <HStack w="100%" spacing={8}>
            <InfoView label="Data Agendada" value={scheduleSelected?.schedules?.dt_agenda} />
            <InfoView label="Hora Agendada" value={scheduleSelected?.schedules?.hr_agenda} />
          </HStack>

          <Divider />
          <SimpleGridComponent
            isLoading={isLoading}
            title={schedulesProntuario.length + ' agendamentos no prontuário.'}
          >
            {schedulesProntuario.map((schedule, index) => (
              <GridComponent index={index} title={schedule.observacao}>
                <InfoView label="Item" value={schedule.schedule_item?.ds_item_agendamento} />
                <InfoView label="Data" value={schedule.dt_agenda} />
                <InfoView label="Hora" value={schedule.hr_agenda} />
              </GridComponent>
            ))}
          </SimpleGridComponent>
        </VStack>
      </ModalComponent>

      <ContainerPageTemplate>
        <HStack align="center" alignItems={'flex-end'}>
          <FormControl isRequired>
            <FormLabel htmlFor="date">Selecione a data</FormLabel>
            <Input type={'date'} value={data} onChange={(e) => setData(e.target.value)} />
          </FormControl>
        </HStack>

        <Center py={4}>{isLoading && <Spinner />}</Center>

        {!isLoading && (
          <SimpleGrid columns={[1, 2, 3, 3]} spacing={6} align="left" justifyContent={'left'} w="100%" py={2}>
            {Object.entries(schedulesGroup).map(([patientName, patientSchedules], index) => (
              <GridItem key={index} boxShadow={'base'} p={4} borderRadius={8}>
                <Text fontSize="sm">{patientName}</Text>

                <List spacing={2} py={2}>
                  {patientSchedules.map((schedule) => (
                    <ListItem
                      key={schedule.id}
                      cursor={'pointer'}
                      onClick={() => handleOpenModalEdit(schedule)}
                      fontSize={'xs'}
                      title={schedule.covenants?.nm_convenio + ' - ' + schedule.covenantsPlan}
                    >
                      <ListIcon
                        as={schedule.isConferido ? BsCheckAll : BsXCircle}
                        color={schedule.isConferido ? 'gree' : 'red'}
                      />

                      {schedule.exame}
                      {schedule.scheduleId && <Icon as={FiCalendar} h={5} w={5} />}
                    </ListItem>
                  ))}
                </List>
              </GridItem>
            ))}
          </SimpleGrid>
        )}
      </ContainerPageTemplate>
    </>
  )
}

export { SolicitacoesAgendamentos }
