import { useToast } from '@chakra-ui/react'
import { destroyCookie, parseCookies, setCookie } from 'nookies'
import { createContext, useEffect, useState } from 'react'
import api from '../services/api'

const Context = createContext()

const UserProvider = function ({ children }) {
  const [User, setUser] = useState([])
  const [data, setData] = useState(new Date().toISOString().slice(0, 10))
  const [agendamentosBalcao, setagendamentosBalcao] = useState([])
  const [Loading, setLoading] = useState(false)
  const toast = useToast()

  useEffect(() => {
    const { 'dragendamento.token': token } = parseCookies()

    if (token) {
      api
        .post('/me')
        .then((response) => {
          const perfil = response.data.profiles

          if (perfil.migrate_app) {
            window.location.href = 'https://app.dragendamento.com'
          }

          const user = {
            ...response.data,
            token: response.data.token,
            clienteId: response.data.clients?.cd_cliente,
            cd_cliente: response.data.clients?.cd_cliente,
            clientId: response.data.clientId,
            userId: response.data.id,
            userNome: response.data.name,
            usuario: response.data.usuario,
            nr_ramal: response.data.nr_ramal,
            cd_prestador: response.data.doctors?.cd_prestador,
            cd_setor_exame: response.data.sectors_exams?.cd_setor_exame || 0,
            cd_setor: response.data.sectors?.cd_setor,
            sn_gestor: response.data.sn_gestor ? 'S' : 'N',
          }
          setCookie(undefined, 'dragendamento.nr_ramal', user.nr_ramal, {
            maxAge: 60 * 60 * 6, // 6 hour
          })
          setCookie(undefined, 'dragendamento.sn_gestor', user.sn_gestor, {
            maxAge: 60 * 60 * 6, // 6 hour
          })
          localStorage.setItem('usuario', user.usuario)
          setUser(user)
        })
        .catch(() => {
          destroyCookie(undefined, 'dragendamento.token')
          setUser([])
        })
    }
  }, [])

  function HandleLogin(cd_cliente, usuario, senha) {
    return api
      .post('/api/v2/users/auth ', { cd_cliente, usuario: usuario.toLowerCase(), senha })
      .then((response) => {
        const user = {
          ...response.data,
          token: response.data.token,
          clienteId: response.data.clients?.cd_cliente,
          cd_cliente: response.data.clients?.cd_cliente,
          clientId: response.data.clientId,
          userId: response.data.id,
          userNome: response.data.name,
          usuario: response.data.usuario,
          nr_ramal: response.data.nr_ramal,
          cd_prestador: response.data.doctors?.cd_prestador,
          cd_setor_exame: response.data.sectors_exams?.cd_setor_exame || 0,
          cd_setor: response.data.sectors?.cd_setor,
          sn_gestor: response.data.sn_gestor ? 'S' : 'N',
        }

        setCookie(undefined, 'dragendamento.token', user.token, {
          maxAge: 60 * 60 * 6, // 6 hour
        })
        setCookie(undefined, 'dragendamento.sn_gestor', user.sn_gestor, {
          maxAge: 60 * 60 * 6, // 6 hour
        })

        setCookie(undefined, 'dragendamento.clienteId', user.clienteId, {
          maxAge: 60 * 60 * 6, // 6 hour
        })

        setCookie(undefined, 'dragendamento.userId', user.userId, {
          maxAge: 60 * 60 * 6, // 6 hour
        })

        setCookie(undefined, 'dragendamento.userNome', user.userNome, {
          maxAge: 60 * 60 * 6, // 6 hour
        })

        setCookie(undefined, 'dragendamento.userPrestador', user.cd_prestador, {
          maxAge: 60 * 60 * 6, // 6 hour
        })

        setCookie(undefined, 'dragendamento.nr_ramal', user.nr_ramal, {
          maxAge: 60 * 60 * 6, // 6 hour
        })

        localStorage.setItem('token', `Bearer ${user.token}`)
        localStorage.setItem('cd_cliente', user.cd_cliente)
        localStorage.setItem('cd_setor_exame', user.cd_setor_exame)
        localStorage.setItem('cd_prestador', user.cd_prestador)
        localStorage.setItem('cd_setor', user.cd_setor)
        localStorage.setItem('nr_ramal', user.nr_ramal)
        localStorage.setItem('usuario', user.usuario)

        setUser(user)

        window.location.href = '/' + response.data.profiles.redirect_login

        return true
      })
      .catch(() => {
        toast({
          title: 'Erro ao fazer login',
          description: 'Usuário ou senha inválidos.',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top-center',
        })
        return false
      })
  }

  return (
    <Context.Provider
      value={{
        User,
        setUser,
        data,
        setData,
        HandleLogin,
        setagendamentosBalcao,
        agendamentosBalcao,
        Loading,
        setLoading,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export { Context, UserProvider }
