/* eslint-disable */

import api from '../../services/api'

export async function UpdatePagamento(
  id_pagamento,
  sn_parcelado,
  qtd_parcelas,
  idPagamentoExterno,
  desc_forma_pagamento
) {
  return await api
    .post('pagamentos/atualizar', {
      id: id_pagamento,
      sn_pago: 'S',
      sn_parcelado,
      desc_forma_pagamento,
      qtd_parcelas,
      id_pagamento: idPagamentoExterno,
      usuario: localStorage.getItem('usuario'),
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}
