import { useEffect, useState } from 'react'

import { Button, ButtonGroup, Center, Input, Spinner } from '@chakra-ui/react'
import { parseCookies } from 'nookies'
import { CreateFuncoesLogs } from '../../Functions/FuncoesSistemaLog/CreateFuncoesLogs'
import { FindLigacoesPorData } from '../../Functions/Ligacoes/FindLigacoesPorData'
import { GroupArrayLigacoes } from '../../Functions/Ligacoes/GroupArrayLigacoes'
import { FindRamalUsuario } from '../../Functions/Usuarios/FindRamalUsuario'
import { ModalComponent } from '../../_styles/ModalComponent '
import { LigacoesItem } from './LigacoesItem'
import { ModalLigacoesPorID } from './ModalLigacoesPorID'
import { RelatoriosLigacoeseWhatsapp } from './RelatoriosLigacoeseWhatsapp'

export function ModalLigacoesPorData({ isOpen, handleChangeModalIsOpen }) {
  const cd_cliente = localStorage.getItem('cd_cliente')
  const [data, setData] = useState(localStorage.getItem('data_consulta'))
  const [ligacoes, setLigacoes] = useState([])
  const [isOpenLigacoesID, setisOpenLigacoesID] = useState(false)
  const [LigacaoSelecionada, setLigacaoSelecionada] = useState([])
  const [loading, setloading] = useState(false)
  const [isOpenRelatorios, setisOpenRelatorios] = useState(false)
  const [reload, setreload] = useState(false)
  const { 'dragendamento.nr_ramal': nr_ramal, 'dragendamento.sn_gestor': sn_gestor } = parseCookies()

  useEffect(() => {
    localStorage.setItem('data_consulta', data)
    if (isOpen) {
      CreateFuncoesLogs(cd_cliente, 'LIGACOES_VIEW', 'LIGACOES_VIEW').then((result) => {
        setloading(true)
        FindRamalUsuario(localStorage.getItem('usuario')).then((res) => {
          FindLigacoesPorData('S', data, nr_ramal, sn_gestor).then((res) => {
            GroupArrayLigacoes(res).then((res) => {
              setLigacoes(res)
              setloading(false)
            })
          })
        })
      })
    }
  }, [isOpen, data, isOpenLigacoesID, reload])

  const handleData = (data) => {
    setData(data)
  }

  const handleOpen = (data) => {
    if (data) {
      setLigacaoSelecionada(data)
    }
    setisOpenLigacoesID(!isOpenLigacoesID)
  }

  return (
    <ModalComponent isOpen={isOpen} onClose={handleChangeModalIsOpen} title="Mapeamento de ligações">
      <ButtonGroup>
        <Button onClick={() => setisOpenRelatorios(!isOpenRelatorios)}>Relatórios</Button>

        <Button onClick={() => setreload(!reload)}>Atualizar página</Button>
      </ButtonGroup>

      <Center w="100%" py={4}>
        <Input type="date" value={data} onChange={(e) => handleData(e.target.value)} />
      </Center>

      {loading && (
        <Center w="100%" mt={4}>
          <Spinner />
        </Center>
      )}

      {ligacoes.map((dt) => (
        <LigacoesItem dt={dt} handleOpen={handleOpen} />
      ))}
      <ModalLigacoesPorID
        isOpen={isOpenLigacoesID}
        handleChangeModalIsOpen={() => setisOpenLigacoesID(!isOpenLigacoesID)}
        info={LigacaoSelecionada}
      />

      {/* Relatórios de ligações */}
      <RelatoriosLigacoeseWhatsapp
        isOpen={isOpenRelatorios}
        handleChangeModalIsOpen={() => setisOpenRelatorios(false)}
      />
    </ModalComponent>
  )
}
