import api from '../../services/api'

export async function CriarProtocoloSistema(id_contato) {
  const id_setor = localStorage.getItem('cd_setor')
  return await api
    .post('protocolos/add', { id_contato, id_setor })
    .then((res) => {
      return res.data
    })
    .catch(() => {
      return 'erro'
    })
}
