import { Select, Text, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api'

export function ModalChatTransferirSetor({
  isOpen,
  handleChangeModalIsOpen,
  handleChageModalMain,
  handleChangeModalChatSelecionado,
  id,
  nm_paciente,
}) {
  const [ListaSetores, setListaSetores] = useState([])
  const cd_cliente = localStorage.getItem('cd_cliente')

  useEffect(() => {
    if (isOpen) {
      api.post('setores', { cd_cliente }).then((res) => setListaSetores(res.data))
    }
  }, [isOpen])

  const AtualizarSetor = (data) => {
    api
      .post('protocolos/update/setor', {
        cd_protocolo: id,
        id_setor: data,
        cd_cliente,
        usuario: localStorage.getItem('usuario'),
      })
      .then(() => {
        handleChangeModalChatSelecionado()
        handleChageModalMain()
        window.location.href = '/chat'
      })
  }

  return (
    <ModalComponent isOpen={isOpen} onClose={handleChangeModalIsOpen} title="Transferir para outro setor">
      <VStack spacing={4} align="left">
        <Text>Para qual setor deseja transferir o paciente {nm_paciente} ? </Text>
        <Select onChange={(e) => AtualizarSetor(e.target.value)}>
          <option selected="">Selecione uma opção</option>
          {ListaSetores.map((setor) => (
            <option value={setor.id}>
              {setor.id} - {setor.nm_setor}
            </option>
          ))}
        </Select>
      </VStack>
    </ModalComponent>
  )
}
