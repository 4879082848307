import api from '../../services/api'

async function FindLogsGroup(data, dataFim) {
  return await api
    .post('funcoes/logs/find/group', {
      data,
      dataFim,
    })
    .then((response) => {
      return response.data
    })
    .catch(() => {
      return false
    })
}

async function FindLogs(data, dataFim) {
  return await api
    .post('funcoes/logs/find', {
      data,
      dataFim,
    })
    .then((response) => {
      return response.data
    })
    .catch(() => {
      return false
    })
}

async function FindLogsByTag(data, dataFim, desc_funcao) {
  return await api
    .post('funcoes/find/tag', {
      data,
      dataFim,
      desc_funcao,
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return false
    })
}

export { FindLogs, FindLogsByTag, FindLogsGroup }
