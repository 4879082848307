import React from 'react'
import { FiImage } from 'react-icons/fi'

const ConferenciaValidaImagem = function ({ info }) {
  const CarregarInfos = () => {
    window.open(info.url_documento, '_blank')
  }

  let color
  if (info.url_documento) {
    color = 'green'
  } else {
    color = 'red'
  }

  return <FiImage size={20} color={color} onClick={() => CarregarInfos()} />
}

export default React.memo(ConferenciaValidaImagem)
