import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stat,
  StatLabel,
  StatNumber,
  VStack,
  useToast,
} from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { FiSave, FiSearch, FiShield } from 'react-icons/fi'
import { GoAlert } from 'react-icons/go'
import { LoadingModal } from '../../Components/LoadingModal'
import { ModalScheduleSelected, SchedulesItem } from '../../Components/Schedules'
import { Context as ContextUser } from '../../Context/UserContext'
import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api'
import { ContainerPageTemplate } from '../../styles/ContainerPageTemplate'

function StatsCard({ title, stat, icon }) {
  return (
    <Stat py={'2'} bg="gray.300" borderRadius={4} boxShadow="base">
      <VStack pl={{ base: 2, md: 2 }} justifyContent={'space-between'}>
        <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
          {stat}
        </StatNumber>
        <StatLabel fontWeight={'medium'} isTruncated>
          {title}
        </StatLabel>
      </VStack>
    </Stat>
  )
}

const Agendamentos = function () {
  const { data } = useContext(ContextUser)
  const [agendamentosStatus, setagendamentosStatus] = useState([])
  const [agendamentos, setAgendamentos] = useState([])
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenModalEdit, setisOpenModalEdit] = useState(false)
  const [isOpenEditarEmMassa, setIsOpenEditarEmMassa] = useState(false)
  const [agendamentoSelecionado, setAgendamentoSelecionado] = useState(null)
  const [checkedIds, setCheckedIds] = useState([])
  const [statusOptions, setStatusOptions] = useState([])
  const [statusSelected, setStatusSelected] = useState(null)

  const handleCheckboxChange = (id) => {
    setCheckedIds((prevCheckedIds) => {
      if (prevCheckedIds.includes(id)) {
        // remove id from array if it's already there
        return prevCheckedIds.filter((existingId) => existingId !== id)
      } else {
        // add id to array if it's not already there
        return [...prevCheckedIds, id]
      }
    })
  }

  // Abrir o modal para visualizar o agendamento.
  const handleOpenModal = (data) => {
    setisOpenModalEdit(true)
    setAgendamentoSelecionado(data)
  }

  function groupArray(arr) {
    const finalData = []
    const result = arr.reduce(function (r, a) {
      r[a.clientId] = r[a.clientId] || []
      r[a.clientId].push(a)
      return r
    }, Object.create(null))
    const data = Object.entries(result)
    for (let i = 0; i < data.length; i++) {
      const result2 = data[i][1].reduce(function (r, a) {
        r[a.schedules_status.status] = r[a.schedules_status.status] || []
        r[a.schedules_status.status].push(a)
        return r
      }, Object.create(null))
      const value = {
        data: data[i][0],
        nomes: Object.keys(result2),
        medicos: Object.values(result2),
      }
      finalData.push(value)
    }
    return finalData
  }

  // Filtrar pelo item do agendamento.
  async function handleFilter(filter) {
    var searchResult = agendamentos.filter(
      (colaborador) => colaborador.schedule_item.ds_item_agendamento.indexOf(filter.toUpperCase()) > -1
    )

    const cirurgiasGroup = await groupArray(searchResult)
    setagendamentosStatus(cirurgiasGroup)
  }
  // Filtrar pelo nome do paciente.
  async function handleFilterPaciente(filter) {
    var searchResult = agendamentos.filter((colaborador) => colaborador.nm_paciente.indexOf(filter.toUpperCase()) > -1)

    const cirurgiasGroup = await groupArray(searchResult)
    setagendamentosStatus(cirurgiasGroup)
  }

  // Agrupando os agendamentos pelo status e covenants
  const groupedAgendamentos = agendamentos.reduce((grouped, agendamento) => {
    const statusKey = agendamento.schedules_status.status
    const convenioKey = agendamento.covenants.nm_convenio

    if (!grouped[statusKey]) {
      grouped[statusKey] = {}
    }

    if (!grouped[statusKey][convenioKey]) {
      grouped[statusKey][convenioKey] = []
    }

    grouped[statusKey][convenioKey].push(agendamento)
    return grouped
  }, {})

  // Buscar agendamentos por data.
  useEffect(() => {
    if (!data) return

    setIsLoading(true)

    api
      .post('api/v2/schedules/calendar/auth', {
        data,
      })
      .then(async (res) => {
        const removeConsulta = res.data.filter((item) => !item.schedule_item.isConsulta)

        const dataOrder = removeConsulta.sort(function (a, b) {
          return a.schedules_status.status.localeCompare(b.schedules_status.status)
        })
        const cirurgiasGroup = await groupArray(dataOrder)
        setagendamentosStatus(cirurgiasGroup)
        setAgendamentos(dataOrder)
      })
      .catch(() => {
        toast({
          title: 'Erro ao buscar agendamentos.',
          description: 'Tente novamente mais tarde.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [data])

  // Consultar os status disponiveis.
  useEffect(() => {
    api
      .get('/api/v2/schedules/status')
      .then((res) => {
        // filtre os status que igual requiredFile false
        const status = res.data.filter((item) => !item.requiredFile)
        setStatusOptions(status)
      })
      .catch(() => {
        setStatusOptions([])
      })
  }, [])

  // Atualizar em massa os status.
  const handleUpdatedStatus = () => {
    setIsLoading(true)
    api
      .post('api/v2/schedules/status', {
        statusId: statusSelected,
        ids: checkedIds,
      })
      .then(() => {
        toast({
          title: 'Status atualizado com sucesso!',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
      .catch(() => {
        toast({
          title: 'Erro ao atualizar status.',
          description: 'Tente novamente mais tarde.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
      .finally(() => {
        setIsLoading(false)
        setIsOpenEditarEmMassa(false)
        setStatusSelected(null)
        setCheckedIds([])
      })
  }

  return (
    <>
      {isLoading && <LoadingModal isLoading={isLoading} />}

      {/* Modal para editar as autorizações e massa. */}
      <ModalComponent isOpen={isOpenEditarEmMassa} onClose={() => setIsOpenEditarEmMassa(false)}>
        <FormControl isRequired>
          <FormLabel>Selecione o status</FormLabel>
          <Select onChange={(e) => setStatusSelected(e.target.value)} placeholder="Selecione...">
            {statusOptions.map((item) => (
              <option key={item.id} value={item.id}>
                {item.status}
              </option>
            ))}
          </Select>
          <FormHelperText>Apresentando apenas os status que não necessitam de arquivo.</FormHelperText>
        </FormControl>

        {statusSelected && (
          <VStack alignItems={'left'} mt={2}>
            <VStack spacing={4} align="stretch">
              {Object.entries(groupedAgendamentos).map(([status, convenios]) => (
                <VStack key={status} spacing={2} align="stretch">
                  <Heading size="md">{status.toUpperCase()}</Heading>
                  {Object.entries(convenios).map(([convenio, agendamentos]) => (
                    <VStack key={convenio} spacing={2} align="stretch" mt={2} boxShadow={'base'} p={2} borderRadius={8}>
                      <Heading size="sm" ml={4}>
                        {convenio}
                      </Heading>
                      {agendamentos.map((agendamento) => (
                        <Checkbox
                          key={agendamento.id}
                          value={agendamento.id}
                          isChecked={checkedIds.includes(agendamento.id)}
                          onChange={() => handleCheckboxChange(agendamento.id)}
                        >
                          {agendamento.nm_paciente} - {agendamento.schedule_item.ds_item_agendamento}
                        </Checkbox>
                      ))}
                    </VStack>
                  ))}
                </VStack>
              ))}
            </VStack>

            {checkedIds.length > 0 && (
              <Button leftIcon={<FiSave />} colorScheme="blue" onClick={() => handleUpdatedStatus()}>
                Salvar
              </Button>
            )}
          </VStack>
        )}
      </ModalComponent>

      <ModalScheduleSelected
        isOpen={isOpenModalEdit}
        onClose={() => setisOpenModalEdit(false)}
        scheduleSelected={agendamentoSelecionado}
        setScheduleSelected={setAgendamentoSelecionado}
      />

      <ContainerPageTemplate isLoading={isLoading} isVisibleInputDate>
        <HStack py={4} alignItems={'end'}>
          <FormControl size="sm">
            <FormLabel>Informe o nome do paciente.</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none" children={<FiSearch color="gray.300" />} />
              <Input
                type="text"
                placeholder="..."
                onChange={(e) => handleFilterPaciente(e.target.value)}
                id="idFilterPacienteAgendamentosAutorizacoes"
              />
            </InputGroup>
          </FormControl>
          <FormControl size="sm">
            <FormLabel size="sm">Pesquisar por item.</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none" children={<FiSearch color="gray.300" />} />
              <Input onChange={(e) => handleFilter(e.target.value)} placeholder="..." />
            </InputGroup>
          </FormControl>
          <Button onClick={() => setIsOpenEditarEmMassa(true)}>
            <Icon as={FiShield} w={6} h={6} />
          </Button>
        </HStack>

        {agendamentosStatus.map((dt, index) => (
          <Grid
            key={index}
            templateColumns={`repeat(${agendamentosStatus[0].nomes?.length}, 1fr)`}
            gap={2}
            id="GridAgendamentosAutorizacoes"
          >
            {dt.medicos.map((dtMedicos, index2) => (
              <GridItem
                key={index2}
                p={4}
                borderRadius={4}
                alignItems="center"
                justifyContent={'center'}
                textAlign={'center'}
              >
                <Grid alignItems={'left'} w="100%" textAlign={'left'} spacing={2}>
                  <StatsCard title={dt.nomes[index2]} stat={dtMedicos.length} icon={<GoAlert />} />
                  <VStack spacing={2}>
                    {dtMedicos.map((ht, indht) => (
                      <SchedulesItem schedule={ht} key={indht} onClick={() => handleOpenModal(ht)} />
                    ))}
                  </VStack>
                </Grid>
              </GridItem>
            ))}
          </Grid>
        ))}
      </ContainerPageTemplate>
    </>
  )
}

export default React.memo(Agendamentos)
