import { Container, VStack } from '@chakra-ui/react'
import { DateInput } from '../Components/DateInput'
import Header from '../Components/Header'

export function ContainerPageTemplate({ children, isVisibleInputDate }) {
  return (
    <VStack>
      <Header />
      <Container p={4} bg="white" fontSize={'sm'} minW="90vw">
        {isVisibleInputDate && <DateInput />}

        {children}
      </Container>
    </VStack>
  )
}

export function ContainerAgendamentosWebTemplate({ children, logoImg }) {
  return (
    <>
      <VStack spacing={2} minH="100vh" justifyContent={'space-between'} placeContent="center" mt={2}>
        <VStack
          spacing={8}
          maxW="6xl"
          py={6}
          fontSize={'sm'}
          color="gray.800"
          bg="white"
          p={8}
          borderRadius={16}
          boxShadow="base"
        >
          {children}
        </VStack>
      </VStack>
    </>
  )
}
