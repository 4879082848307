import { Button, ButtonGroup, Heading, Text, VStack, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaMoneyCheckAlt } from 'react-icons/fa'
import { GiHeartPlus } from 'react-icons/gi'
import { RiMailSendFill } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import { CreateFuncoesLogs } from '../../Functions/FuncoesSistemaLog/CreateFuncoesLogs'
import { MensagensProntasNotificaMedicoPacienteInternado } from '../../Functions/MensagensProntas/MensagensProntasNotificaMedicoPacienteInternado'
import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api'
import { CriarPagamento } from '../Pagamentos/CriarPagamento/CriarPagamento'
import { ModalAtendimentoSolicitaInternacao } from './ModalAtendimentoSolicitaInternacao'

export function ModalAtendimentoSelecionado({
  isOpen,
  nm_prestador,
  handleChangeModalIsOpen,
  nm_paciente,
  cd_atendimento,
  cd_convenio,
  nm_convenio,
  cd_paciente,
  sn_retorno,
  ds_con_pla,
  email,
  cpf,
  cd_prestador,
  phone_prestador,
  usuario_atendimento,
  ds_leito,
  handleModalCriarPagamento,
  closeAllModals,
}) {
  const cd_cliente = localStorage.getItem('cd_cliente')
  const [solicitaInternacao, setsolicitaInternacao] = useState(false)
  const [Loading, setLoading] = useState(false)
  const [id_atendime, setid_atendime] = useState('')
  const [isOpenPagamentos, setisOpenPagamentos] = useState(false)
  const [reload, setreload] = useState(false)
  const toast = useToast()
  const history = useHistory()

  useEffect(() => {
    if (isOpen && cd_atendimento !== '') {
      setLoading(true)
      api
        .post('atendime/find', { cd_atendimento })
        .then((response) => {
          setLoading(false)
          if (response.data.length === 0) {
            api
              .post('atendime/create', {
                cd_atendimento,
                cd_paciente,
                nm_paciente,
                cd_convenio,
                nm_convenio,
                cd_it_agenda_central: '',
                cd_ped_rx: '',
                cd_aviso_cirurgia: '',
                cd_cliente: localStorage.getItem('cd_cliente'),
              })
              .then(() => {
                setreload(!reload)
              })
              .catch(() => {
                toast({
                  title: 'Erro.',
                  position: 'top',
                  description: 'Erro para inserir atendimento.',
                  status: 'error',
                  duration: 9000,
                  isClosable: true,
                })
              })
          } else {
            setid_atendime(response.data[0].id)
          }
        })
        .catch(() => {})
    }
  }, [isOpen, reload])

  function closeAllModals() {
    setisOpenPagamentos(false)
    handleChangeModalIsOpen(false)
  }

  function closeAtendimentoSelecionada() {
    handleChangeModalIsOpen(false)
    setsolicitaInternacao(true)
  }

  const NotificaPacienteInternado = () => {
    const descricao = `Prestador ${nm_prestador} Paciente ${nm_paciente} Leito: ${ds_leito}`
    CreateFuncoesLogs(cd_cliente, descricao, 'MENSAGENS_ENVIO_PACIENTE_INTERNADO').then(() => {
      MensagensProntasNotificaMedicoPacienteInternado(nm_prestador, nm_paciente, phone_prestador, ds_leito).then(
        (res) => {
          api
            .post('mensagens/send/direto', {
              phone: '55' + phone_prestador + '@c.us',
              mensagem: res,
              cd_cliente,
            })
            .then(() => {
              toast({
                title: 'Mensagem enviada com sucesso.',
                position: 'top-right',
                description: res,
                status: 'success',
              })
              return
            })
            .catch(() => {
              toast({
                title: 'A mensagem não pode ser enviada.',
                position: 'top-right',
                status: 'error',
              })
            })
        }
      )
    })
  }

  return (
    <>
      <ModalAtendimentoSolicitaInternacao
        isOpen={solicitaInternacao}
        handleChangeModalIsOpen={() => setsolicitaInternacao(!solicitaInternacao)}
        nm_paciente={nm_paciente}
        cd_atendimento={cd_atendimento}
        nm_prestador={nm_prestador}
      />

      <ModalComponent isOpen={isOpen} onClose={() => handleChangeModalIsOpen(false)}>
        <VStack>
          <ButtonGroup>
            <Button
              variant="icon"
              title="Pagamentos"
              onClick={() =>
                handleModalCriarPagamento(
                  nm_prestador,
                  nm_paciente,
                  cd_atendimento,
                  cd_convenio,
                  nm_convenio,
                  cd_paciente,
                  sn_retorno,
                  ds_con_pla,
                  email,
                  cpf,
                  cd_prestador,
                  phone_prestador,
                  usuario_atendimento,
                  ds_leito
                )
              }
            >
              <FaMoneyCheckAlt size="24" />
            </Button>

            <Button
              variant="icon"
              onClick={() => {
                history.push('/atendimentos/v2')
                toast({
                  title: 'Você está sendo redirecionado.',
                  position: 'top-right',
                  description: 'Para a nova versão de atendimentos.',
                  status: 'info',
                  duration: 3000,
                  isClosable: true,
                })
              }}
              title="Sol. Internação"
            >
              <GiHeartPlus size="24" />
            </Button>
            <Button variant="icon" onClick={() => NotificaPacienteInternado()} title="Mensagem Paciente Internado">
              <RiMailSendFill size="24" />
            </Button>
          </ButtonGroup>
          <Heading as="h3" size="sm">
            {nm_paciente}
          </Heading>

          <Text fontWeight={'bold'}>ATENDIMENTO: {cd_atendimento}</Text>
          <Text>Convênio: {nm_convenio}</Text>
          <Text>Leito: {ds_leito}</Text>
        </VStack>

        <CriarPagamento
          isOpen={isOpenPagamentos}
          closeAllModals={() => closeAllModals(false)}
          nm_paciente={nm_paciente}
          nm_prestador={nm_prestador}
          cd_atendimento={cd_atendimento}
          cd_convenio={cd_convenio}
          nm_convenio={nm_convenio}
          cd_paciente={cd_paciente}
          sn_retorno={sn_retorno}
          ds_con_pla={ds_con_pla}
          email={email}
          cd_ped_rx={''}
          cd_aviso_cirurgia={''}
          cd_it_agenda_central={''}
          cpf={cpf}
          cd_prestador={cd_prestador}
          phone_prestador={phone_prestador}
          usuario_atendimento={usuario_atendimento}
          id_atendime={id_atendime}
        />
      </ModalComponent>
    </>
  )
}
