import { Box, Center, Input, SimpleGrid, VStack, useToast } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { Context as ContextUser } from '../../Context/UserContext'
import GerarData from '../../Functions/Others/Gerardata'
import api from '../../services/api'
import { ContainerPageTemplate } from '../../styles/ContainerPageTemplate'
import { TextBgTemplate, TextInfoTemplate } from '../../styles/FontsTemplate'

const PagamentosV2 = function () {
  const { setLoading } = useContext(ContextUser)
  const [dataInicial, setdataInicial] = useState(GerarData())
  const [dataFinal, setdataFinal] = useState(GerarData())
  const [sn_pago, setsn_pago] = useState('N')
  const [Pagamentos, setPagamentos] = useState([])
  const toast = useToast()

  useEffect(() => {
    api
      .get('api/v2/payments/period/' + dataInicial + '/' + dataInicial)
      .then((res) => {
        setPagamentos(res.data)
      })
      .catch(() => {
        toast({
          title: 'Erro ao carregar os pagamentos',
          description: 'Tente novamente mais tarde',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [dataInicial])

  return (
    <ContainerPageTemplate>
      <Center>
        <Input type="date" onChange={(e) => setdataInicial(e.target.value)} value={dataInicial} />
      </Center>

      <SimpleGrid columns={[1, 3, 4]} spacing={8} marginTop={4}>
        {Pagamentos.map((pagamento, index) => (
          <Box
            key={index}
            onClick={() => handleOpenPagamentoID(pagamento)}
            w="100%"
            boxShadow="md"
            p={3}
            rounded="md"
            cursor="pointer"
          >
            <VStack alignItems={'left'}>
              <TextBgTemplate info={`R$ ${pagamento.vl_pago}`} />
              <TextInfoTemplate info={pagamento.nm_paciente.substr(0, 30)} />
              <TextInfoTemplate info={'Atendimento: ' + pagamento.cd_atendimento} />
              <TextInfoTemplate info={pagamento.nm_prestador.substr(0, 30)} />
              <TextInfoTemplate info={'Comprovante: ' + pagamento.id_pagamento} />
            </VStack>
          </Box>
        ))}
      </SimpleGrid>
    </ContainerPageTemplate>
  )
}

export { PagamentosV2 }
