// DRA-HIST-15 - 10 anos fazendo do mesmo jeito, eu preciso de uma forma mais fácil para conferir as agendas.
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  IconButton,
  Input,
  Select,
  Spinner,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { BiBlock, BiCheckDouble, BiMessageRoundedDots, BiTimer } from 'react-icons/bi'
import { FaUserEdit, FaUserSlash } from 'react-icons/fa'
import { FiCheck, FiEye } from 'react-icons/fi'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Context as ContextUser } from '../../Context/UserContext'
import { CreateLog } from '../../Functions/logs'
import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api'
import { ModalEnvioMensagemManual } from '../Mensagens/ModalEnvioMensagemManual'
import { ModalViewScheduleId } from './ModalViewScheduleId'
import { ScheduleStatusUpdater } from './ScheduleStatusUpdater'

export function ModalConferenciaAgendamentos({ data, agendamentos }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [schedules, setschedules] = useState(agendamentos ? agendamentos : [])
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenModalEnvioMensagemManual, setIsOpenModalEnvioMensagemManual] = useState(false)
  const [isOpenEditMassa, setisOpenEditMassa] = useState(false)
  const [isOpenModalScheduleId, setisOpenModalScheduleId] = useState(false)
  const [celular, setCelular] = useState(null)
  const { User } = useContext(ContextUser)
  const [pacientesDuplicados, setPacientesDuplicados] = useState([])
  const [scheduleId, setScheduleId] = useState(null)
  const [origens, setOrigens] = useState([])
  const toast = useToast()
  const [reloadingData, setReloadingData] = useState(false)
  const history = useHistory()

  function groupArray(arr) {
    // Ordenar por hr_agenda
    const sortedArr = arr.sort((a, b) => a.hr_agenda.localeCompare(b.hr_agenda))
    const sortName = sortedArr.sort((a, b) => a.doctors.nm_prestador.localeCompare(b.doctors.nm_prestador))

    const finalData = []
    const result = sortName.reduce(function (r, a) {
      r[a.clientId] = r[a.clientId] || []
      r[a.clientId].push(a)
      return r
    }, Object.create(null))

    const data = Object.entries(result)
    for (let i = 0; i < data.length; i++) {
      const result2 = data[i][1].reduce(function (r, a) {
        r[a.doctors.nm_prestador] = r[a.doctors.nm_prestador] || []
        r[a.doctors.nm_prestador].push(a)
        return r
      }, Object.create(null))

      const value = {
        data: data[i][0],
        nomes: Object.keys(result2),
        medicos: Object.values(result2),
      }
      finalData.push(value)
    }
    return finalData
  }

  function TimeAlert({ updatedAt }) {
    const hoursSinceUpdate = moment().diff(moment(updatedAt), 'hours')
    const showAlert = hoursSinceUpdate < 24

    return (
      <>
        {showAlert && (
          <IconButton variant="unstyled" title={'Atualizado em menos de 24hs: ' + moment(updatedAt).format('LLL')}>
            <Icon h={5} w={5} as={BiTimer} color="yellow.500" cursor="pointer"  />
          </IconButton>
        )}
      </>
    )
  }

  function FaltaInfo({ isFalta }) {
    return (
      <>
        {isFalta && (
          <IconButton variant="unstyled" title="Falta">
            <Icon as={FaUserSlash} color="red.500" title="Falta" cursor="pointer" />
          </IconButton>
        )}
      </>
    )
  }

  const handleOpenModalMensagem = (data) => {
    setCelular(data.nr_celular)
    setScheduleId(data.id)
    setIsOpenModalEnvioMensagemManual(true)
  }

  // Filtrar os agendamentos por nome do paciente.
  const handleFilterPacientesName = async (value) => {
    if (!value) {
      const agendamentosGroup = await groupArray(schedulesFull)
      setschedules(agendamentosGroup)
      return
    }

    if (value.length < 3) {
      return
    }

    const agendamentosAtivos = schedulesFull.filter((item) => item.isActived)

    if (value.length === 0) {
      const agendamentosGroup = await groupArray(schedulesFull)
      setschedules(agendamentosGroup)
      return
    }

    const filter = agendamentosAtivos.filter((item) => item.nm_paciente.toUpperCase().includes(value.toUpperCase()))
    const agendamentosGroup = await groupArray(filter)
    setschedules(agendamentosGroup)
  }

  const handleFilterPacientesMultiplus = async (name) => {
    const dataLog = {
      nm_paciente: name,
      info: 'Conferência de agendamentos - Pacientes duplicados',
    }
    await CreateLog(6, null, dataLog)
    handleFilterPacientesName(name)
  }

  const handleOpenScheduleIdView = (id) => {
    setScheduleId(id)
    setisOpenModalScheduleId(true)
  }

  const handleUpdateStatus = async (value, id) => {
    api
      .post('api/v2/schedules/origens', {
        id,
        originId: value,
      })
      .then(() => {
        toast({
          title: 'Status atualizado com sucesso',
          status: 'success',
          duration: 1500,
          isClosable: true,
          position: 'top-right',
        })
      })
      .catch(() => {
        toast({
          title: 'Erro ao atualizar a origem',
          status: 'error',
          duration: 1500,
          isClosable: true,
          position: 'top-right',
        })
      })
  }

  const handleFilterFaltas = async (value) => {
    setIsLoading(true)
    // Filtrar todos os agendamentos que estão com os campo isFalta caso o value seja true.
    if (value === 'true') {
      const filter = schedulesFull.filter((item) => item.isFalta)
      const agendamentosGroup = await groupArray(filter)
      setschedules(agendamentosGroup)
      setIsLoading(false)
      return
    } else {
      const agendamentosGroup = await groupArray(schedulesFull)
      setschedules(agendamentosGroup)
      setIsLoading(false)
      return
    }
  }

  const handleFilterByStatus = async (value) => {
    setIsLoading(true)

    if (value === '') {
      const agendamentosGroup = await groupArray(schedulesFull)
      setschedules(agendamentosGroup)
      setIsLoading(false)
      return
    } else {
      const filter = schedulesFull.filter((item) => {
        return item.originId == parseInt(value, 10)
      })
      const agendamentosGroup = await groupArray(filter)
      setschedules(agendamentosGroup)
      setIsLoading(false)
      return
    }
  }

  const schedulesFull = agendamentos

  // Buscar os agendamentos por data selecionada.
  useEffect(() => {
    if (!data) return
    if (!isOpen) return

   const grouped =  groupArray(agendamentos)
    setschedules(grouped)
  }, [data, reloadingData,isOpen, agendamentos])

  useEffect(() => {
    if (!schedulesFull) return

    const pacientes = schedulesFull.map((item) => item.nm_paciente)
    const pacientesNew = pacientes.filter((item, index) => pacientes.indexOf(item) !== index)
    // Remover os que estão com o nm_paciente nulo.
    const pacientesDuplicados = pacientesNew.filter((item) => item !== null)
    // Ordene de forma alfabética.
    pacientesDuplicados.sort()

    // Remover os duplicados.
    const pacientesDuplicadosNew = pacientesDuplicados.filter(
      (item, index) => pacientesDuplicados.indexOf(item) === index
    )

    setPacientesDuplicados(pacientesDuplicadosNew)
  }, [schedulesFull])

  useEffect(() => {
    api
      .get('api/v2/schedules/origens')
      .then((res) => {
        const data = res.data.filter((item) => item.isAtivo === true)
        setOrigens(data)
      })
      .catch(() => {
        toast({
          title: 'Erro ao buscar as origens',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
  }, [])

  return (
    <>
      <ModalEnvioMensagemManual
        isOpen={isOpenModalEnvioMensagemManual}
        onClose={() => setIsOpenModalEnvioMensagemManual(false)}
        celular={celular}
        User={User}
        scheduleId={scheduleId}
      />

      <ModalViewScheduleId
        isOpen={isOpenModalScheduleId}
        onClose={() => setisOpenModalScheduleId(false)}
        id={scheduleId}
      />

      <ModalComponent
        buttonLabel="Edição em massa"
        title="Edição em massa"
        isOpen={isOpenEditMassa}
        onClose={() => setisOpenEditMassa(false)}
        onOpen={onOpen}
        leftIcon={<BiCheckDouble />}
        buttonView={false}
      >
        <ScheduleStatusUpdater
          schedulesFull={schedulesFull}
          origens={origens}
          onClose={() => setisOpenEditMassa(false)}
          setReloadingData={setReloadingData}
          reloadingData={reloadingData}
        />
      </ModalComponent>

      <ModalComponent
        buttonLabel="Conferências"
        title={'Conferências: ' + (data ? ' ' + moment(data).format('DD/MM/YYYY') : '')}
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        leftIcon={<BiCheckDouble />}
        buttonView={true}
      >
        <Text>{agendamentos?.length} agendamentos</Text>

        <VStack w="100%">
          <HStack alignItems={'end'} justifyContent={'end'} w="100%">
            <FormControl>
              <FormLabel>Encontrar paciente</FormLabel>
              <Input onChange={(e) => handleFilterPacientesName(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel>Pacientes com mais de um agendamento ({pacientesDuplicados.length})</FormLabel>
              <Select
                placeholder="Selecione um paciente"
                onChange={(e) => handleFilterPacientesMultiplus(e.target.value)}
              >
                <option>Mostrar todos</option>
                {pacientesDuplicados.map((paciente, index) => (
                  <option key={index} value={paciente}>
                    {paciente}
                  </option>
                ))}
              </Select>
            </FormControl>
          </HStack>
          <HStack alignItems={'end'} justifyContent={'end'} w="100%">
            <FormControl>
              <FormLabel>Faltas</FormLabel>
              <Select onChange={(e) => handleFilterFaltas(e.target.value)}>
                <option value="">Mostrar todos</option>
                <option value={'true'}>Faltantes</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Por Status</FormLabel>
              <Select
                onClick={async () => {
                  const dataLog = {
                    info: 'Filtrar agendamentos por status.',
                  }
                  await CreateLog(9, null, dataLog)
                }}
                onChange={(e) => handleFilterByStatus(e.target.value)}
                placeholder="Mostrar todos"
              >
                {origens.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.description}
                  </option>
                ))}
              </Select>
            </FormControl>
            <IconButton title="Edição de status em massa" onClick={() => setisOpenEditMassa(true)}>
              <Icon as={FaUserEdit} />
            </IconButton>
            <IconButton title="Ir para faltas." onClick={() => history.push('/agendamentos/faltas')} colorScheme="red">
              <Icon as={FaUserSlash} />
            </IconButton>
          </HStack>
        </VStack>

        <Center my={4}>{isLoading && <Spinner />}</Center>

        {schedules.map((dt, index) => (
          <Accordion key={index} templatecolumns={`repeat(1, 1fr)`} gap={2} id="GridCirurgias">
            {dt.medicos.map((dtMedicos, index2) => (
              <AccordionItem
                key={index2}
                py={4}
                borderRadius={4}
                alignItems="center"
                justifyContent={'center'}
                textAlign={'center'}
              >
                <AccordionButton w={'100%'} alignItems={'left'} textAlign={'left'}>
                  <HStack w={'100%'} justifyContent={'space-between'}>
                    <Heading size="sm">{dt.nomes[index2]}</Heading>
                    <Text> ({dtMedicos.length}) horários</Text>
                  </HStack>
                </AccordionButton>

                <AccordionPanel pb={4}>
                  <TableContainer bg="gray.50" py={2} boxShadow={'base'}>
                    <Table variant="simple" size="xs">
                      <TableCaption>Apresentação dos dados em teste.</TableCaption>
                      <Thead p={4}>
                        <Tr>
                          <Th></Th>
                          <Th>Hora</Th>
                          <Th>Nome</Th>
                          <Th>Idade</Th>
                          <Th>Convênio</Th>
                          <Th>Autorização</Th>
                          <Th>Item</Th>
                          <Th>Status</Th>
                          <Th>Presença</Th>
                          <Th></Th>
                        </Tr>
                      </Thead>
                      <Tbody p={4}>
                        {dtMedicos.map((ht, indht) => (
                          <Tr p={1} key={indht} fontSize={'12px'}>
                            <Td>{ht.isHrBloqueado && <Icon color="red" as={BiBlock} />}</Td>
                            <Td>{ht.hr_agenda.substring(0, 5)}</Td>
                            <Td>{ht.nm_paciente?.substring(0, 20)}</Td>
                            <Td title={ht.dt_nascimento}>
                              {ht.dt_nascimento && moment().diff(ht.dt_nascimento, 'years') + ' anos'}{' '}
                            </Td>
                            <Td>{ht.covenants?.nm_convenio?.substring(0, 15)}</Td>
                            <Td>{ht.schedules_status?.status?.substring(0, 20)}...</Td>
                            <Td>{ht.schedule_item?.ds_item_agendamento?.substring(0, 15)}...</Td>
                            <Td>
                              {/* Select para veriricar se a origme esta igual */}
                              <Select onChange={(e) => handleUpdateStatus(e.target.value, ht.id)} variant="unstyled">
                                {origens.map((item, index) => (
                                  <option key={index} value={item.id} selected={ht.originId === item.id}>
                                    {item.description}
                                  </option>
                                ))}
                              </Select>
                            </Td>
                            <Td>{ht.isConfirmedPresence && <Icon as={FiCheck} color="green.800" />}</Td>

                            <Td>
                              <IconButton
                                variant="unstyled"
                                title="Enviar mensagem"
                                onClick={() => handleOpenModalMensagem(ht)}
                              >
                                <Icon as={BiMessageRoundedDots} />
                              </IconButton>

                              <FaltaInfo isFalta={ht.isFalta} />
                              <TimeAlert updatedAt={ht.updated_at} />

                              <IconButton
                                variant="unstyled"
                                title="Ver agendamento"
                                onClick={() => handleOpenScheduleIdView(ht.id)}
                              >
                                <Icon as={FiEye} />
                              </IconButton>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        ))}
      </ModalComponent>
    </>
  )
}
