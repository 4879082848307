import LogoHP from '../../../assets/2.png'
import { Header } from './styles'

export var HeaderComprovante = function () {
  return (
    <Header>
      <h1>Comprovante de Pagamento</h1>
      <img src={LogoHP} alt="logo" width="30%" />
    </Header>
  )
}
