import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import { Box, HStack, Text } from '@chakra-ui/react'

// Box para visualizar os ícones do elemento.
function ElementBox({ title, info }) {
  return (
    <HStack>
      <Text fontWeight="bold" fontSize={'12px'}>
        {title}
      </Text>
      <Box color={info ? 'green.500' : 'red.500'} mr={2}>
        {info ? <CheckIcon /> : <CloseIcon />}
      </Box>
    </HStack>
  )
}

const ProfileIcons = ({ profiles }) => {
  return (
    <Box py={4}>
      <ElementBox title="Perfil está ativo?" info={profiles.isActive} />
      <ElementBox title="Permite criar usuários?" info={profiles.user_create} />
      <ElementBox title="Permite deletar pagamentos?" info={profiles.payments_delete} />
      <ElementBox title="Possuir perfil de administrador?" info={profiles.isAdmin} />
      <ElementBox title="Permite visualiar os contatos?" info={profiles.contatosVisiveis} />
      <ElementBox title="Pode visualizar os pagamentos?" info={profiles.payments_view} />
      <ElementBox title="Pode editar os pagamentos?" info={profiles.payments_edit} />
    </Box>
  )
}

export { ProfileIcons }
