import { Box, Center, Heading, Input, Select, SimpleGrid, VStack } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { PagamentoPorID } from '../../Components/Pagamentos'
import { Context as ContextUser } from '../../Context/UserContext'
import GerarData from '../../Functions/Others/Gerardata'
import { FindPagamentosPeriodo } from '../../Functions/Pagamentos/FindPagamentosPeriodo'
import { ContainerPageTemplate } from '../../styles/ContainerPageTemplate'
import { TextBgTemplate, TextInfoTemplate } from '../../styles/FontsTemplate'

const Pagamentos = function () {
  const { setLoading } = useContext(ContextUser)
  const [dataInicial, setdataInicial] = useState(GerarData())
  const [dataFinal, setdataFinal] = useState(GerarData())
  const [sn_pago, setsn_pago] = useState('N')
  const [Pagamentos, setPagamentos] = useState([])
  const [id_pagamento, setid_pagamento] = useState('')
  const [cd_atendimento, setcd_atendimento] = useState('')
  const [isOpenPagamentoID, setisOpenPagamentoID] = useState(false)

  useEffect(() => {
    if (sn_pago) {
      setLoading(true)
      FindPagamentosPeriodo(sn_pago, dataInicial, dataFinal)
        .then((res) => {
          setPagamentos(res)
          setLoading(false)
        })
        .catch(() => {
          setPagamentos([])
          setLoading(false)
        })
    }
  }, [dataInicial, dataFinal, sn_pago])

  const handleOpenPagamentoID = (data) => {
    if (data) {
      setid_pagamento(data.id)
      setcd_atendimento(data.cd_atendimento)
    }
    setisOpenPagamentoID(!isOpenPagamentoID)
  }

  return (
    <ContainerPageTemplate>
      <Center>
        <Input type="date" onChange={(e) => setdataInicial(e.target.value)} value={dataInicial} />
        <Input type="date" onChange={(e) => setdataFinal(e.target.value)} value={dataFinal} />
        <Select onChange={(e) => setsn_pago(e.target.value)}>
          <option value="DEFAULT" selected disabled>
            Selecione uma opção
          </option>
          <option value="N" selected="">
            PENDENTES
          </option>
          <option value="S">PAGOS</option>
        </Select>
      </Center>

      <Heading size="sm" mt={4}>
        {Pagamentos.length} pagamentos encontrados.
      </Heading>

      <SimpleGrid columns={[1, 3, 4]} spacing={8} marginTop={4}>
        {Pagamentos.map((pagamento, index) => (
          <Box
            key={index}
            onClick={() => handleOpenPagamentoID(pagamento)}
            w="100%"
            boxShadow="md"
            p={3}
            rounded="md"
            cursor="pointer"
          >
            <VStack alignItems={'left'}>
              <TextBgTemplate info={`R$ ${pagamento.vl_pago}`} />
              <TextInfoTemplate info={pagamento.nm_paciente.substr(0, 30)} />
              <TextInfoTemplate info={'Atendimento: ' + pagamento.cd_atendimento} />
              <TextInfoTemplate info={pagamento.nm_prestador.substr(0, 30)} />
              <TextInfoTemplate info={'Comprovante: ' + pagamento.id_pagamento} />
            </VStack>
          </Box>
        ))}
      </SimpleGrid>

      <PagamentoPorID
        isOpen={isOpenPagamentoID}
        id_pagamento={id_pagamento}
        handleChangeModalIsOpen={() => setisOpenPagamentoID(!isOpenPagamentoID)}
        cd_atendimento={cd_atendimento}
      />
    </ContainerPageTemplate>
  )
}

export default React.memo(Pagamentos)
