import styled from 'styled-components'

export const Container = styled.div`
  margin: 16px;
  padding: 16px;
  small {
    color: var(--text-body);
  }
`

export const ValorTotal = styled.div`
  display: flex;
  justify-content: center;
  place-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--primary-color) !important;
  min-height: 3rem;
  h1 {
    color: var(--white);
  }
`

export const Header = styled.header`
  min-height: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 16px;
  padding: 16px;
`
