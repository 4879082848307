/* eslint-disable */
import { NotificaError } from '../Toast/error'
export async function MensagensProntasNotificaMedicoPacienteInternado(
  nm_prestador,
  nm_paciente,
  numero_medico,
  ds_leito
) {
  const cd_cliente = localStorage.getItem('cd_cliente')
  if ((ds_leito != null) & (numero_medico != null)) {
    const mensagem =
      'Dr(a) ' +
      nm_prestador +
      ' seu paciente *' +
      nm_paciente +
      '* está internado(a) no *' +
      ds_leito +
      '*\n\n*HOSPITAL PAULISTA* \n\n https://dragendamento.com'

      // TODO: PAUSADO PROVISORIAMENTE
    // SendDireto(numero_medico, mensagem, cd_cliente)

    return mensagem
  } else {
    return NotificaError(
      'O atendimento deve ser internação e o telefone deve estar cadastrado.'
    )
  }
}
