require('dotenv').config()
import {
  Button,
  Center,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Select,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react'
import Axios from 'axios'
import { useContext, useState } from 'react'
import { BiTime } from 'react-icons/bi'
import { FiCalendar, FiLogIn, FiSave } from 'react-icons/fi'
import InputMask from 'react-input-mask'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import LogoHP from '../../assets/clientes/1.png'
import { Context as ContextAgendamentoWeb } from '../../Context/AgendamentosWebContext'
import { AgendamentosWebResetPasswordPaciente } from '../../Functions/Agendamentos/agendamentosWebData'
import { ContainerAgendamentosWebTemplate } from '../../styles/ContainerPageTemplate'

export default function AgendamentoWeb() {
  const { cd_cliente } = useParams()
  const [nm_paciente_agendado, setnm_paciente_agendado] = useState('')
  const [nmResponsavel, setnmResponsavel] = useState('')
  const [File, setFile] = useState('')

  const {
    settpConvenio,
    tpConvenio,
    setcodCliente,
    convenios,
    setcodConvenio,
    codConvenio,
    planos,
    setcodPlano,
    codPlano,
    Loading,
    itensAgendamento,
    codItemAgendamento,
    setcodItemAgendamento,
    agendasDisponiveis,
    handleHorarioSelecionado,
    mensagemPadrao,
    nmPrestador,
    isOpenLogin,
    setNumCelular,
    setSenha,
    numCelular,
    handleLoginPaciente,
    isLogged,
    dataAgendamento,
    horaAgendamento,
    user,
    dsItemAgendamentoSelecionado,
    codAgendamentoMV,
    setLoading,
  } = useContext(ContextAgendamentoWeb)
  setcodCliente(cd_cliente)

  function normalizeText(input) {
    return input.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }

  async function handleConfirmarAgendamento(e) {
    e.preventDefault()
    setLoading(true)

    const token = localStorage.getItem('tokenPaciente') || ''

    const api = Axios.create({
      baseURL: process.env.REACT_APP_URL_API,
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })

    const api2 = Axios.create({
      baseURL: process.env.REACT_APP_URL_API,
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
    const data = {
      nm_responsavel: nmResponsavel,
      image: File,
      email: user?.email,
      celular: numCelular.replace(/\D/g, ''), // remove tudo que não seja dígito
      cd_cliente: cd_cliente,
      cd_it_agenda_central: codAgendamentoMV,
      nm_paciente: nm_paciente_agendado.normalize('NFD').replace(/[\u0300-\u036f]/g),
      cd_item_agendamento: codItemAgendamento,
      cd_convenio: codConvenio,
      cd_con_pla: codPlano,
      nr_fone: numCelular.replace(/\D/g, ''),
      ds_observacao_geral: 'AGE WEB',
      dt_agendamento: dataAgendamento,
      hr_agendamento: horaAgendamento,
      nm_prestador: nmPrestador,
      endereco: user?.endereco || 'Não informado',
    }

    api
      .post('agendamentosweb/confirmacao', data)
      .then(() => {
        setLoading(false)
        toast({
          title: 'Sucesso.',
          description: `Agendamento realizado com sucesso.`,
          position: 'top',
          status: 'success',
          duration: 9000,
        })

        var formData = new FormData()
        formData.append('id', localStorage.getItem('id_painel'))
        formData.append('image', File)
        formData.append('cd_it_agenda_central', codAgendamentoMV)
        formData.append('nm_convenio', 'nm_convenio')
        formData.append('nm_prestador', nmPrestador)
        formData.append('ds_item_agendamento', dsItemAgendamentoSelecionado || 'Não informado')
        formData.append('nm_paciente', nm_paciente_agendado)
        formData.append('dt_agendamento', dataAgendamento)
        formData.append('hr_agendamento', horaAgendamento)

        api2
          .post('agendamentos/online/atualizar/painel', formData, {})
          .then(() => {
            window.location.href = `/agendamentos/web/${cd_cliente}/confirmacao`
          })
          .catch(() => {
            toast({
              title: 'Erro.',
              description: `Erro ao atualizar painel.`,
              position: 'top',
              status: 'error',
              duration: 9000,
            })
          })
      })
      .catch((err) => {
        setLoading(false)
        window.location.reload()

        toast({
          title: 'Erro.',
          description: `Erro ao salvar agendamento.`,
          position: 'top',
          status: 'error',
          duration: 9000,
        })
      })
  }

  function handleEsqueciSenha() {
    if (!numCelular) {
      alert('Celular deve ser informado')
    } else {
      AgendamentosWebResetPasswordPaciente(numCelular).then(() => {})
    }
  }

  return (
    <ContainerAgendamentosWebTemplate logoImg={LogoHP}>
      {/* Modal para o convênio CUIDAR.ME 262 */}
      <Modal isOpen={codConvenio === '262'} size="full" bg="blue.500">
        <ModalOverlay />
        <ModalContent>
          <ModalBody justifyContent="center">
            <VStack placeContent="left" justifyContent="center" minH={'100vh'} textAlign={'left'}>
              <Heading size="md">Atenção, beneficiário cuidar.me!</Heading>
              <Text color="reg">
                Por favor, entre em contato com a Central de Atendimento de seu convênio para dar continuidade ao
                agendamento!
              </Text>

              <Button colorScheme="blue" onClick={() => window.location.reload()}>
                Voltar
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={Loading} size="full" bg="blue.500">
        <ModalOverlay />
        <ModalContent>
          <ModalBody justifyContent="center">
            <VStack placeContent="center" justifyContent="center" minH={'100vh'}>
              <Spinner />
              <Text color="reg">Carregando...</Text>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenLogin} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalBody justifyContent="center">
            <Center placeContent="center" justifyContent="center" minH="100vh">
              <VStack>
                <Heading as="h2" size="lg">
                  Login
                </Heading>
                <form onSubmit={handleLoginPaciente}>
                  <VStack>
                    <FormControl>
                      <FormLabel>Digite o seu celular com DDD</FormLabel>
                      <Input
                        as={InputMask}
                        mask="(**) *****-****"
                        maskChar={null}
                        onChange={(e) => setNumCelular(e.target.value)}
                      />
                    </FormControl>

                    <FormControl isRequired marginTop={2}>
                      <FormLabel>Digite sua senha</FormLabel>
                      <Input
                        placeholder="Senha"
                        type="password"
                        onChange={(e) => setSenha(e.target.value)}
                        marginTop={2}
                      />
                    </FormControl>
                    <Button leftIcon={<FiLogIn />} colorScheme="blue" p={4} type="submit" w="100%">
                      Logar
                    </Button>
                    <HStack justifyContent={'space-between'} w="100%">
                      <Link fontSize="10px" onClick={() => handleEsqueciSenha()}>
                        Esqueci a minha senha
                      </Link>
                      <Link fontSize="10px" onClick={() => (window.location.href = `./${cd_cliente}/cadastro`)}>
                        Primeiro acesso
                      </Link>
                    </HStack>
                  </VStack>
                </form>
              </VStack>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isLogged} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontSize={'sm'}>Resumo do agendamento</Text>
            <Text fontSize={'10px'}>#{codAgendamentoMV}</Text>
          </ModalHeader>
          <ModalBody justifyContent="center">
            <Center placeContent="center" justifyContent="center">
              <VStack justifyContent={'space-around'} spacing={16} alignItems={'left'}>
                <Text>
                  Sr(a) {user?.nm_paciente?.toUpperCase()} para FINALIZAR o agendamento do(a){' '}
                  {dsItemAgendamentoSelecionado}, no dia {dataAgendamento}, às {horaAgendamento}, com Dr(a){' '}
                  {nmPrestador}, envie a documentação solicitada abaixo:
                </Text>
                {tpConvenio == 'P' && <Text>Valor: {mensagemPadrao}</Text>}

                <form onSubmit={handleConfirmarAgendamento} encType="multipart/form-data">
                  <VStack>
                    <FormControl isRequired>
                      <FormLabel>Qual o nome do paciente?</FormLabel>
                      <FormHelperText>Se estiver agendando para outra pessoa, altere aqui o nome.</FormHelperText>
                      <Input
                        type="text"
                        value={nm_paciente_agendado.toUpperCase()}
                        onChange={(e) => setnm_paciente_agendado(normalizeText(e.target.value))}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel>Qual o nome do responsável?</FormLabel>
                      <Input
                        type="text"
                        value={nmResponsavel.toUpperCase()}
                        onChange={(e) => setnmResponsavel(normalizeText(e.target.value))}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      {tpConvenio == 'C' ? (
                        <FormLabel>Tire uma foto de sua carteira do convênio e anexe abaixo</FormLabel>
                      ) : (
                        <FormLabel>Tire uma foto do seu documento pessoal e anexe abaixo:</FormLabel>
                      )}

                      <input
                        type="file"
                        name="arquivo"
                        accept="image/png, image/jpeg"
                        required="Campo Obrigatório"
                        onChange={(e) => setFile(e.target.files[0])}
                      />
                      <FormHelperText>Limite de 02 MB.</FormHelperText>
                    </FormControl>
                    <Button colorScheme={'green'} type="submit" leftIcon={<FiSave />}>
                      Salvar Agendamento
                    </Button>
                  </VStack>
                </form>
              </VStack>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>

      <FormControl isRequired>
        <FormLabel>Agendamento com convênio ou particular? </FormLabel>
        <Select
          onChange={(e) => {
            settpConvenio(e.target.value)
          }}
          placeholder="Selecione uma opção."
        >
          <option value="C">Convênio</option>
          <option value="P">Particular</option>
        </Select>
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Qual o convênio? </FormLabel>
        <Select
          onChange={(e) => {
            setcodConvenio(e.target.value)
          }}
          placeholder="Selecione uma opção."
        >
          {convenios.map((convenio) => (
            <option value={convenio.CD_CONVENIO}>{convenio.NM_CONVENIO}</option>
          ))}
        </Select>
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Qual o plano? </FormLabel>
        <Select
          onChange={(e) => {
            setcodPlano(e.target.value)
          }}
          placeholder="Selecione uma opção."
        >
          {planos.map((plano) => (
            <option value={plano.CD_CON_PLA}>{plano.DS_CON_PLA}</option>
          ))}
        </Select>
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Qual item deseja agendar? </FormLabel>
        <Select
          onChange={(e) => {
            setcodItemAgendamento(e.target.value)
          }}
          placeholder="Selecione uma opção."
        >
          {itensAgendamento.map((info) => (
            <option value={info.CD_ITEM_AGENDAMENTO}>{info.DS_ITEM_AGENDAMENTO}</option>
          ))}
        </Select>
      </FormControl>
      <Progress w="100%" size="xs" bg="none" isIndeterminate={Loading ? true : false} />

      {agendasDisponiveis.map((dt, index) => (
        <VStack key={index} spacing={4} w="100%">
          <HStack mb={2} justifyContent="flex-start" bg="gray.100" w="100%" p={2} borderRadius={8}>
            <Icon as={FiCalendar} />
            <Heading size="xs">{dt.data}</Heading>
          </HStack>

          <VStack w="100%">
            {dt.medicos.map((dtMedicos, index2) => (
              <HStack key={index2} justifyContent="space-between" minW="100%" spacing={2}>
                <Text as="h3" size="xs">
                  {dt.nomes[index2].substring(0, 30)}
                </Text>
                <HStack spacing={4}>
                  {dtMedicos.slice(0, 3).map((ht, indht) => (
                    <HStack
                      cursor="pointer"
                      spacing={2}
                      onClick={() => handleHorarioSelecionado(ht)}
                      key={indht}
                      _hover={{ bg: 'gray.200', boxShadow: 'base' }}
                      p={2}
                      borderRadius={8}
                    >
                      <Icon as={BiTime} />
                      <Text>{ht.HR_AGENDAMENTO}</Text>
                    </HStack>
                  ))}
                </HStack>
              </HStack>
            ))}
          </VStack>
        </VStack>
      ))}

      <Text>{nmPrestador}</Text>
    </ContainerAgendamentosWebTemplate>
  )
}
