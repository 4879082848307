import React from 'react'

interface Action {
  info: string;
  title: string;
}

const InfoCriarPagamento = React.memo(function Info({ info, title }: Action) {
  return (
    <p>
      <b>{title}: </b>
      {info}
    </p>
  )
})
export { InfoCriarPagamento }
