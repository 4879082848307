import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Admin } from './pages/Admin/index'
import Presenca from './pages/Agendamentos/Presenca'
import { SolicitacoesAgendamentos } from './pages/Agendamentos/SolicitacoesAgendamentos'
import AgendamentosEnvioPaciente from './pages/Agendamentos/envioPaciente'
import { Faltantes } from './pages/Agendamentos/faltantes'
import { AgendamentosV2 } from './pages/Agendamentos/v2'
import AgendamentosWeb from './pages/AgendamentosWeb'
import AgendamentosWebBloqueio from './pages/AgendamentosWeb/bloqueio'
import AgendamentosWebCadastro from './pages/AgendamentosWeb/cadastro'
import AgendamentosWebConfirmacao from './pages/AgendamentosWeb/confirmacao'
import { AtendimentosDia } from './pages/Atendimentos'
import { AttendanceV2 } from './pages/Atendimentos/v2'
import AutorizacoesV2 from './pages/Autorizacoes/v2'
import Balcao from './pages/Balcao/index'
import ChatEnvioDocumentosPeloChat from './pages/Chat/EnvioDocumentosPeloChat'
import Chat from './pages/Chat/index'
import Cirurgias from './pages/Cirurgias'
import CirurgiasPreCadastro from './pages/Cirurgias/PreCadastroCirurgiasPaciente'
import ConferenciaAgeOnl from './pages/ConferenciaAgendamentoOnline'
import { DashboardPage } from './pages/Dashboard'
import { DoutoresExames } from './pages/Exames/Doutores'
import ExamesNoSetorExecutante from './pages/Exames/ExamesNoSetorExecutante'
import { SetorExecutantev2 } from './pages/Exames/SetorExecutantev2'
import Exames from './pages/Exames/index'
import { ExamesV2 } from './pages/Exames/v2'
import Laudos from './pages/Laudos/index'
import Login from './pages/Login/index'
import Logout from './pages/Logout/index'
import { Logs } from './pages/Logs'
import { LogsAdmin } from './pages/Logs/admin'
import { Neoh } from './pages/Neoh'
import PagamentosComprovante from './pages/Pagamentos/ComprovantePagamento'
import Pagamentos from './pages/Pagamentos/index'
import { PagamentosV2 } from './pages/Pagamentos/v2'
import PendenciasCreate from './pages/Pendencias/create'
import Pendencias from './pages/Pendencias/index'
import AgendamentosOnlinePrestadores from './pages/Prestadores/AgendamentosOnlinePrestadores'
import { FaltasInteresseRemarcar } from './pages/pacientes/FaltasInteresseRemarcar'
import { PainelAgendamentos } from './pages/Painel/painel-agendamentos'

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/dashboard" exact component={DashboardPage} />
        <Route path="/admin" exact component={Admin} />
        {/* Agendamentos! */}
        <Route path="/agendamentos" exact component={AgendamentosV2} />
        <Route path="/agendamentos/v2/:data_filter?" exact component={AgendamentosV2} />
        <Route path="/agendamentos/faltas" exact component={Faltantes} />
        {/* Solicitações feitas no prontuario - DRA-HIST-19 */}
        <Route path="/agendamentos/solicitacoes" exact component={SolicitacoesAgendamentos} />
        <Route path="/agendamentos/confirmar/presenca/:token/:id" exact component={Presenca} />
        <Route path="/agendamentos/web/:cd_cliente" exact component={AgendamentosWeb} />
        <Route path="/agendamentos/web/:cd_cliente/cadastro" exact component={AgendamentosWebCadastro} />
        <Route path="/agendamentos/web/:cd_cliente/info/:codBloqueio" exact component={AgendamentosWebBloqueio} />
        <Route path="/agendamentos/web/:cd_cliente/confirmacao" exact component={AgendamentosWebConfirmacao} />
        <Route path="/agendamentos/anexar/:id" exact component={AgendamentosEnvioPaciente} />
        <Route path="/agendamentos/online" exact component={AgendamentosOnlinePrestadores} />

        <Route path="/agendamentos/remarcar/:id/:token" exact component={FaltasInteresseRemarcar} />

        <Route path="/chat" exact component={Chat} />
        <Route path="/contatos/documentos/anexar/:id/:cd_cliente" exact component={ChatEnvioDocumentosPeloChat} />

        <Route path="/atendimentos" exact component={AtendimentosDia} />
        <Route path="/atendimentos/v2" exact component={AttendanceV2} />
        <Route path="/autorizacoes" exact component={AutorizacoesV2} />
        <Route path="/autorizacoes/v2" exact component={AutorizacoesV2} />
        <Route path="/balcao" exact component={Balcao} />
        <Route path="/exames" exact component={Exames} />
        {/* TODO: EM TESTES! */}
        <Route path="/exames/v2" exact component={ExamesV2} />
        <Route path="/pedidos/setor" exact component={ExamesNoSetorExecutante} />
        <Route path="/pedidos/setor/v2" exact component={SetorExecutantev2} />
        <Route path="/pedidos/doutor" exact component={DoutoresExames} />
        <Route path="/laudos" exact component={Laudos} />
        <Route path="/logs" exact component={Logs} />
        <Route path="/logs/admin" exact component={LogsAdmin} />
        <Route path="/logout" exact component={Logout} />
        <Route path="/cirurgias" exact component={Cirurgias} />
        <Route path="/cirurgias/atualizar/:id/:cd_convenio/:token" exact component={CirurgiasPreCadastro} />
        <Route path="/conferencia" exact component={ConferenciaAgeOnl} />
        <Route path="/pendencias" exact component={Pendencias} />
        <Route path="/pendencias/criar/:namePatient" exact component={PendenciasCreate} />
        <Route path="/pagamentos" exact component={Pagamentos} />
        <Route path="/pagamentos/v2" exact component={PagamentosV2} />
        <Route path="/pagamentos/:cd_atendimento/:id" exact component={PagamentosComprovante} />
        <Route path="/neoh" exact component={Neoh} />

        {/* Painel */}
        <Route path="/painel/agendamentos" exact component={PainelAgendamentos} />

        <Route path="/*" exact component={Logout} />
      </Switch>
    </BrowserRouter>
  )
}
