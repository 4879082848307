import { HStack, OrderedList } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { BiFilter } from 'react-icons/bi'
import { AgendamentoItemDefault } from '../../Components/Agendamentos/AgendamentoItemDefault'
import { AgendamentoSelecionadoModal } from '../../Components/Agendamentos/AgendamentoSelecionadoModal'
import { Context as ContextAgendamentos } from '../../Context/AgendamentosContext'
import { Context as ContextLoading } from '../../Context/LoaderContext'
import { Context as ContextUser } from '../../Context/UserContext'
import { AgendamentosFindBalcao, AgendamentosFindConferenciaMV } from '../../Functions/Agendamentos/agendamentosData'
import { ButtonPageTemplate } from '../../styles/ButtonPageTemplate'
import { ContainerPageTemplate } from '../../styles/ContainerPageTemplate'

const STATUS_PRESENCA = [
  {
    value: 'S',
    desc: 'Presentes',
  },
  {
    value: null,
    desc: 'Ausentes',
  },
]

function Balcao() {
  const { cd_cliente } = useContext(ContextLoading)
  const { data, setData, setagendamentosBalcao, agendamentosBalcao, setLoading } = useContext(ContextUser)
  setData(new Date().toISOString().slice(0, 10))
  const { setcd_it_agenda_central, cd_it_agenda_central } = useContext(ContextAgendamentos)
  const [isOpenAgendamento, setisOpenAgendamento] = useState(false)
  const [statusSelecionado, setStatusSelecionado] = useState('')
  const [agendamentosFiltro, setAgendamentosFiltro] = useState([])
  const [agendamentos, setagendamentos] = useState([])

  useEffect(() => {
    setLoading(true)
    setagendamentosBalcao(agendamentosBalcao || [])
    if (cd_cliente !== undefined && data !== null) {
      AgendamentosFindConferenciaMV(cd_cliente, data, setagendamentos).then(() => {
        AgendamentosFindBalcao(cd_cliente)
          .then((res) => {
            setagendamentosBalcao(res)
            setAgendamentosFiltro(res)
            setLoading(false)
          })
          .catch(() => {
            setagendamentosBalcao([])
            setAgendamentosFiltro([])
            setLoading(false)
          })
      })
    }
  }, [cd_cliente, isOpenAgendamento])

  useEffect(() => {
    var newArray = agendamentosBalcao.filter((d) => d.sn_paciente_local == statusSelecionado)
    setAgendamentosFiltro(newArray)
  }, [statusSelecionado])

  const handleChangeModalAgendamentoSelecionada = (data) => {
    setcd_it_agenda_central(data)
    setisOpenAgendamento(!isOpenAgendamento)
  }

  return (
    <ContainerPageTemplate>
      <HStack my={2} spacing={4}>
        {STATUS_PRESENCA.map((navItem, index) => (
          <ButtonPageTemplate
            key={index}
            title={
              navItem.value === statusSelecionado ? navItem.desc + ' (' + agendamentosFiltro.length + ')' : navItem.desc
            }
            onClick={() => {
              setStatusSelecionado(navItem.value)
            }}
            color={navItem.value === statusSelecionado ? 'gray' : ''}
            icon={<BiFilter />}
          />
        ))}
      </HStack>

      <OrderedList columns={[1, 2, 4]} spacing={8}>
        {agendamentosFiltro.map((agendamento) => (
          <AgendamentoItemDefault
            id={agendamento.cd_it_agenda_central}
            nm_paciente={agendamento.nm_paciente}
            nr_celular={agendamento.nr_celular}
            nm_prestador={agendamento.nm_prestador}
            ds_item_agendamento={agendamento.ds_item_agendamento}
            nm_convenio={agendamento.nm_convenio}
            hr_agendamento={agendamento.hr_agendamento}
            handleOpen={() => handleChangeModalAgendamentoSelecionada(agendamento.cd_it_agenda_central)}
            sn_paciente_local={agendamento.sn_paciente_local}
          />
        ))}
      </OrderedList>

      <AgendamentoSelecionadoModal
        isOpen={isOpenAgendamento}
        handleChangeModalIsOpen={handleChangeModalAgendamentoSelecionada}
        cd_it_agenda_central={cd_it_agenda_central}
        cd_cliente={cd_cliente}
      />
    </ContainerPageTemplate>
  )
}

export default React.memo(Balcao)
