// DRA-FUNC-25 - Visualizar os exames criados no dia.
// DRA-HIST-12 - Eu como recepcionista preciso ver e liberar os exames do dia.
import { ExternalLinkIcon } from '@chakra-ui/icons' // Importe o ícone que você deseja usar
import {
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  Image,
  Input,
  Link,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { FiCheck, FiFilter } from 'react-icons/fi'
import { GiBugleCall } from 'react-icons/gi'
import { MdCheckCircle } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import AgendamentosPresentes from '../../Components/Agendamentos/AgendamentosPresentes'
import { Context as ContextUser } from '../../Context/UserContext'
import api from '../../services/api'
import { supabase } from '../../services/supabase'
import { ContainerPageTemplate } from '../../styles/ContainerPageTemplate'

function InfoView({ label, value }) {
  return (
    <VStack fontSize={'xs'} alignItems={'left'} textAlign={'left'} spacing={0}>
      <Text fontSize="xs" fontWeight={'bold'}>
        {label?.toUpperCase()}
      </Text>
      <HStack>
        <Text fontSize="xs" fontWeight="medium">
          {value}
        </Text>
      </HStack>
    </VStack>
  )
}

// Apresenta o total de atendimentos por tipo.
function StatsCard({ title, stat }) {
  return (
    <Stat py={'2'} bg="gray.300" borderRadius={4} boxShadow="base">
      <VStack pl={{ base: 2, md: 2 }} justifyContent={'space-between'}>
        <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
          {stat}
        </StatNumber>
        <StatLabel fontWeight={'medium'} isTruncated>
          {title}
        </StatLabel>
      </VStack>
    </Stat>
  )
}

function AtendimentosView({ atendimentos, handleOpenModal, revemoAtendidos }) {
  let apenasPendentes = []

  // Remove os atendimentos que já foram liberados!
  if (revemoAtendidos) {
    apenasPendentes = atendimentos.filter((item) => !item.isAtendido)
  } else {
    apenasPendentes = atendimentos
  }

  // Primeiro, ordene os schedules pelo campo tp_atendimento
  const sortedSchedules = [...apenasPendentes].sort((a, b) => {
    if (a.doctors?.nm_prestador < b.doctors?.nm_prestador) return -1
    if (a.doctors?.nm_prestador > b.doctors?.nm_prestador) return 1
    return 0
  })

  // Agrupe os schedules pelo campo tp_atendimento
  const groupedSchedules = sortedSchedules.reduce((acc, schedule) => {
    const type = schedule.doctors?.nm_prestador
    if (!acc[type]) {
      acc[type] = []
    }
    acc[type].push(schedule)
    return acc
  }, {})

  // Crie um array de tipos de atendimento
  const types = Object.keys(groupedSchedules)
  return (
    <>
      <Grid templateColumns="repeat(auto-fill, minmax(250px, 1fr))" gap={2}>
        {types.map((type) => (
          <GridItem key={type} py={4} borderRadius={4} alignItems="center" justifyContent="center" textAlign="center">
            <StatsCard title={type} stat={groupedSchedules[type].length} />
            <VStack spacing={2} alignItems="left" w="100%" textAlign="left">
              {groupedSchedules[type].map((item, index) => (
                <VStack
                  index={index}
                  key={item.id}
                  onClick={() => handleOpenModal(item)}
                  boxShadow={'base'}
                  p={4}
                  w="full"
                  alignItems={'left'}
                  borderRadius={5}
                  cursor="pointer"
                  bg={item.isAtendido ? 'green.500' : 'gray.50'}
                  _hover={{ boxShadow: 'base' }}
                  textAlign={'center'}
                  border={'1px solid #ccc !important'}
                  color={item.isAtendido ? 'white' : 'gray.600'}
                >
                  <HStack w="100%">
                    {item?.isAtendido && (
                      <Icon as={FiCheck} w={3} h={3} color={item?.isAtendido ? 'green.600' : 'red.600'} />
                    )}
                    <InfoView label={'Paciente'} value={item?.patient?.name} />
                  </HStack>
                  {!item?.isAtendido && (
                    <InfoView label={'Liberado'} value={moment(item?.hr_liberacao).format('hh:mm:ss A')} />
                  )}
                </VStack>
              ))}
            </VStack>
          </GridItem>
        ))}
      </Grid>
    </>
  )
}

function ListaDocumentos({ documentos, isLoading }) {
  return (
    <VStack spacing={4}>
      {isLoading && <Spinner />}
      <HStack>
        {!isLoading &&
          documentos?.map((item) => (
            <VStack
              boxShadow={'base'}
              p={4}
              key={item.id}
              as={Link} // Transforma o VStack em um link clicável
              href={item.url} // Substitua por onde você quer que o link vá
              isExternal // Abre o link em uma nova aba
              _hover={{ bg: 'gray.200' }} // Efeito de hover para melhor UX
              textAlign={'center'}
              alignItems={'center'}
            >
              <Icon as={ExternalLinkIcon} w={6} h={6} /> {/* Ícone */}
              <Text fontSize="10px">{item.docs_types?.description}</Text>
            </VStack>
          ))}
      </HStack>
    </VStack>
  )
}

function SetorExecutantev2() {
  const [exames, setexames] = useState([])
  const [examesFull, setExamesFull] = useState([])
  const [data, setData] = useState(new Date().toISOString().slice(0, 10))
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()
  const [exameSelecionado, setExameSelecionado] = useState(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const { User } = useContext(ContextUser)
  const [isOpenConcluidos, setIsOpenConcluidos] = useState(false)
  const [mensagemAtendimentoAtualizado, setMensagemAtendimentoAtualizado] = useState('')
  const history = useHistory()
  const [isOpenPresentes, setisOpenPresentes] = useState(false)

  // Abre o modal com os detalhes do exame
  const handleOpenModal = (item) => {
    setExameSelecionado(item)
    setModalIsOpen(true)
    setIsLoading(false)
  }

  // Atualizar o campo isAtendido para true e atualizar a lista de exames
  // DD-2 - O prestador externo deve visualizar os exames da base nova, tela deve atualizar automaticamente.
  const handleConfirmarAtendimentoRemoveLista = (exame) => {
    setIsLoading(true)

    api
      .put('v2/pedidos/' + exame.id, {
        data: {
          isAtendido: !exame.isAtendido,
          dhAtendimento: new Date().toISOString(),
        },
      })
      .then(() => {
        exame.isAtendido = true
        setexames((old) => old.filter((item) => item.id !== exame.id))
      })
      .finally(() => {
        setIsLoading(false)
        setModalIsOpen(false)
      })
  }

  // Consultar os exames do dia
  useEffect(() => {
    setexames([])
    setIsLoading(true)
    api
      .get('v2/pedidos/doctors/' + data)
      .then((response) => {
        setexames(response.data)
        setExamesFull(response.data)
      })
      .catch((err) => {
        toast({
          title: 'Erro',
          description: err.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [data])

  // Atualização automática dos atendimentos.
  useEffect(() => {
    const channel = supabase
      .channel('attendance_exams')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'attendance_exams',
        },
        async (payload) => {
          api
            .get('v2/pedidos/doctors/' + data + '/pedido/' + payload.new.id)
            .then((response) => {
              setMensagemAtendimentoAtualizado(
                'Paciente ' + response.data?.patient?.name + ' atualizado em ' + new Date().toLocaleTimeString()
              )

              // Remove o atendimento existente pelo id
              setexames((old) => old.filter((item) => item.id !== payload.new.id))
              setExamesFull((old) => old.filter((item) => item.id !== payload.new.id))

              setexames((old) => [...old, response.data])
              setExamesFull((old) => [...old, response.data])
            })
            .catch((err) => {})
        }
      )
      .subscribe()
    return () => {
      supabase.removeChannel(channel)
    }
  }, [supabase, User, data])

  // DD-4 - Deve ficar registrado todas as tentivas de chamadas que o prestador fizer ao paciente.
  const handleChamarPaciente = (exame) => {
    setIsLoading(true)

    const message =
      `Chamar o paciente ` +
      exame.patient.name +
      ` para o atendimento ` +
      exame.attendance.cd_atendimento +
      ' ' +
      User?.name

    api
      .post('slack', {
        message,
        attendanceId: exame.attendanceId,
      })
      .then(() => {
        toast({
          title: 'Mensagem enviada com sucesso!',
          description: 'A mensagem foi enviada para o slack do setor.',
          status: 'success',
          duration: 1000,
          isClosable: true,
          position: 'top-right',
        })
      })
      .catch((err) => {
        toast({
          title: 'Erro',
          description: err.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const examesAguardando = exames.filter((item) => !item.isAtendido)

  return (
    <ContainerPageTemplate>
      <AgendamentosPresentes isOpen={isOpenPresentes} handleCloseModal={() => setisOpenPresentes(false)} />

      {/* Modal do atendimento selecionado */}
      <Modal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontFamily={'Montserrat'}>{exameSelecionado?.patient?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} textAlign={'left'} alignItems={'left'}>
              <HStack>
                <InfoView label={'Atendimento'} value={exameSelecionado?.attendance?.cd_atendimento} />
                <InfoView label={'Data'} value={moment(exameSelecionado?.dt_atendimento).format('DD/MM/YYYY')} />
              </HStack>
              <HStack>
                <InfoView label={'Liberado às'} value={moment(exameSelecionado?.dhLiberacao).format('hh:mm:ss A')} />
                <InfoView label={'Liberado por'} value={exameSelecionado?.userLiberacao} />
              </HStack>

              {/* A lista de exames */}
              <List size="sm" fontSize="sm">
                {exameSelecionado?.exames?.split(', ').map((exame, index) => (
                  <ListItem key={index} fontSize="sm">
                    <ListIcon as={MdCheckCircle} color={exameSelecionado?.isAtendido ? 'green.600' : 'gray.600'} />
                    {exame}
                  </ListItem>
                ))}
              </List>

              {/* Lista de documentos liberados para o prestador */}
              <ListaDocumentos documentos={exameSelecionado?.documentos} isLoading={isLoading} />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <HStack w="100%" justifyContent={'space-between'}>
              <Button
                size="xs"
                leftIcon={<GiBugleCall />}
                colorScheme={'blue'}
                onClick={() => handleChamarPaciente(exameSelecionado, User)}
              >
                Chamar Paciente
              </Button>
              <Button
                size="xs"
                leftIcon={<FiCheck />}
                colorScheme={exameSelecionado?.isAtendido ? 'red' : 'green'}
                onClick={() => handleConfirmarAtendimentoRemoveLista(exameSelecionado)}
              >
                {exameSelecionado?.isAtendido ? 'Desfazer atendimento' : 'Finalizar atendimento'}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal para visualizar os atendimentos concluidos */}
      <Modal isOpen={isOpenConcluidos} onClose={() => setIsOpenConcluidos(false)} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontFamily={'Montserrat'}></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AtendimentosView atendimentos={examesFull} handleOpenModal={handleOpenModal} revemoAtendidos={false} />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Flex alignItems={'baseline'} justifyContent={'end'}>
        <FormControl isRequired>
          <FormLabel htmlFor="date">Selecione a data</FormLabel>
          <Input type={'date'} onChange={(e) => setData(e.target.value)} value={data} />
        </FormControl>
      </Flex>
      <HStack justifyContent={'space-between'} py={4}>
        <Text id="txt-atendimento-atualizado" fontSize="10px">
          {mensagemAtendimentoAtualizado}
        </Text>
        <HStack>
          <Button size="sm" variant="link" leftIcon={<FiFilter />} onClick={() => setisOpenPresentes(true)}>
            Pacientes no Hospital
          </Button>
          <Button size="sm" variant="link" leftIcon={<FiFilter />} onClick={() => setIsOpenConcluidos(true)}>
            Atendidos
          </Button>
          <Button
            size="sm"
            variant="link"
            leftIcon={<FiFilter />}
            onClick={() => history.push('/pedidos/setor')}
            ml="4"
          >
            Exames Anteriores
          </Button>
        </HStack>
      </HStack>

      <Center w="100%" minH={'10vh'}>
        <VStack spacing={1}>
          {isLoading && <Spinner />}
          {examesAguardando.length === 0 && <Image src="/book.svg" alt="Pesquisar" h="300px" w="300px" />}
          {examesAguardando.length === 0 && <Text>Todos os pacientes liberados foram atendidos.</Text>}
        </VStack>
      </Center>

      {/* Apresentar os atendimentos selecionado */}
      <AtendimentosView atendimentos={exames} handleOpenModal={handleOpenModal} revemoAtendidos={true} />
    </ContainerPageTemplate>
  )
}
export { SetorExecutantev2 }
