import api from '../../services/api'
/* eslint-disable */
export async function FindDocumentosEnviados(id_contato, cd_cliente) {
  return await api
    .post('contatos/find/contato', { cd_cliente, id_contato })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}
