import { Text } from '@chakra-ui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { ChatDocumentosEnviados } from '../../Functions/Chat'
import { ModalComponent } from '../../_styles/ModalComponent '

moment.locale('pt')

export function ModalDocumentosRecebidos({ isOpen, onClose, id_contato }) {
  const [loading, setLoading] = useState(false)
  const [documentos, setdocumentos] = useState([])

  const cd_cliente = localStorage.getItem('cd_cliente')

  useEffect(() => {
    if (isOpen) setLoading(true)
    ChatDocumentosEnviados(id_contato, cd_cliente).then((res) => {
      setdocumentos(res)
      setLoading(false)
    })
  }, [id_contato, isOpen])

  async function handleViewDoc(data) {
    window.open(data.url_aws, '_blank')
  }

  return (
    <ModalComponent isOpen={isOpen} onClose={() => onClose(false)} title="DOCUMENTOS RECEBIDOS">
      {documentos.map((documento, index) => (
        <Text p={2} cursor="pointer" onClick={() => handleViewDoc(documento)} key={index}>
          {moment(documento.data).format('dddd, DD MMMM  YYYY')}
        </Text>
      ))}
    </ModalComponent>
  )
}
