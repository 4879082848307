import { Center, Heading } from '@chakra-ui/react'
import LogoHP from '../../assets/clientes/1.png'
import { ContainerAgendamentosWebTemplate } from '../../styles/ContainerPageTemplate'

export default function AgendamentoWebConfirmacao() {
  return (
    <ContainerAgendamentosWebTemplate logoImg={LogoHP}>
      <Center>
        <Heading>O seu agendamento foi concluído com sucesso.</Heading>
      </Center>
    </ContainerAgendamentosWebTemplate>
  )
}
