// DRA-FUNC-11 - Modal confirmações de presença
import {
  Box,
  FormControl,
  FormLabel,
  List,
  ListIcon,
  ListItem,
  Select,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { useState } from 'react'
import { FiCheckCircle } from 'react-icons/fi'
import { ModalComponent } from '../../_styles/ModalComponent '

const statusPresence = [
  { status: 'false', title: 'Cancelados', id: 1 },
  { status: 'true', title: 'Confirmados', id: 2 },
  { status: '', title: 'Todos', id: 3 },
]
// DRA-11 - Apresentar as confirmações de presença novamente.
export function ModalAgendamentosPresencas({ schedules }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [schedulesFilter, setschedulesFilter] = useState([])
  const countConfirmed = schedules.filter((a) => a.isConfirmedPresence === true).length
  const countDeclined = schedules.filter((a) => a.isConfirmedPresence === false).length
  const countNoResponse = schedules.filter((a) => a.isConfirmedPresence === null).length
  const isDeviceSmall = useBreakpointValue({ base: true, md: false })

  // Filtrar os agendamentos conforme selecionado (confirmação de presença).
  const handleFilterByStatus = (status) => {
    if (status) {
      const statusFilter = status === 'false' ? false : true

      const filtered = schedules.filter((schedule) => {
        return schedule.isConfirmedPresence === statusFilter
      })

      setschedulesFilter(filtered)
    } else {
      setschedulesFilter(schedules)
    }
  }

  return (
    <ModalComponent
      buttonLabel="Confirmações de Presença"
      title="Confirmações de Presença"
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      buttonView={true}
    >
      <Stack direction={isDeviceSmall ? 'column' : 'row'} spacing={3} w="100%" py={2}>
        <Box p={2} bg="green.500" color="white" textAlign={'center'} borderRadius="md">
          <Text fontSize="sm" fontWeight="bold">
            {countConfirmed} Confirmados
          </Text>
        </Box>
        <Box p={2} bg="red.500" color="white" textAlign={'center'} borderRadius="md">
          <Text fontSize="sm" fontWeight="bold">
            {countDeclined} Declinaram
          </Text>
        </Box>
        <Box bg="gray.400" p={2} borderRadius="md" textAlign={'center'}>
          <Text color="white" fontSize="sm">
            Não confirmados: {countNoResponse}
          </Text>
        </Box>
      </Stack>

      <VStack>
        <FormControl isRequired>
          <FormLabel>Selecione...</FormLabel>
          <Select
            placeholder="Selecione o status..."
            onChange={(e) => handleFilterByStatus(e.target.value)}
            cursor={'pointer'}
          >
            {statusPresence.map((status) => (
              <option value={status.status} key={status.id}>
                {status.title}
              </option>
            ))}
          </Select>
        </FormControl>

        <List id="loading-element" w="100%" spacing={2}>
          {schedulesFilter.map((schedule) => (
            <ListItem key={schedule.id} w="100%">
              <ListIcon as={FiCheckCircle} color={schedule.isConfirmedPresence ? 'green.500' : 'red.500'} />

              {schedule.nm_paciente ? schedule.nm_paciente : 'LIVRE'}
            </ListItem>
          ))}
        </List>
      </VStack>
    </ModalComponent>
  )
}
