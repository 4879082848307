import { Button, HStack, Icon, Text, useDisclosure, useToast, VStack } from '@chakra-ui/react'
import { CgUserlane } from 'react-icons/cg'
import { FaBusinessTime, FaHospital, FaPhone } from 'react-icons/fa'
import { FiKey } from 'react-icons/fi'
import { GoAlert } from 'react-icons/go'
import { ImProfile } from 'react-icons/im'
import { ModalComponent } from '../../_styles/ModalComponent '
import { HelpDeskButton } from '../HelpDeskButton'

export function ModalProfileUser({ User }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()

  return (
    <>
      <Button variant="links" onClick={onOpen}>
        <CgUserlane size="24" title="Perfil do usuário." />
      </Button>

      <ModalComponent isOpen={isOpen} onClose={onClose} title="Perfil do usuário.">
        <VStack alignItems={'left'} fontSize="12px">
          <HStack>
            <Icon as={ImProfile} />
            <Text>{User?.profiles?.desc_profile.toUpperCase()}.</Text>
          </HStack>
          <HStack>
            <Icon as={FaBusinessTime} />
            <Text>{User?.sectors?.nm_setor}</Text>
          </HStack>

          <HStack>
            <Icon as={FaPhone} />
            {User.nr_ramal > 0 ? <Text> {User?.nr_ramal}.</Text> : <Text>Você não possui ramal associado.</Text>}
          </HStack>

          <HStack>
            <Icon as={FaHospital} />
            <Text>{User?.doctors?.nm_prestador}</Text>
          </HStack>
          <HStack>
            <Icon as={FiKey} />
            <Button
              variant={'link'}
              size="sm"
              color="text"
              onClick={() => {
                toast({
                  title: 'Em breve...',
                  description: `Em breve você poderá alterar a sua senha...`,
                  position: 'top-right',
                  status: 'info',
                  duration: 4000,
                  isClosable: true,
                })
              }}
            >
              Alterar a minha senha.
            </Button>
          </HStack>
        </VStack>
        <HStack justifyContent={'space-between'} w="100%">
          <HStack>
            <Icon as={GoAlert} color="red" />
            <HelpDeskButton />
          </HStack>
          <VStack fontSize={'10px'} spacing={1} alignItems="right" textAlign={'right'}>
            <Text fontWeight={'bold'}>{User?.clients?.name}</Text>
            <Text>{User?.clients?.address}</Text>
            <Text fontSize={'10px'}>Versão 2023.05.24</Text>
          </VStack>
        </HStack>
      </ModalComponent>
    </>
  )
}
