import { Button, Center, FormControl, FormLabel, Input, VStack, useToast } from '@chakra-ui/react'
import moment from 'moment'
import React, { useState } from 'react'
import { FiSave } from 'react-icons/fi'

import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api'

moment.locale('pt')

export function ModalChatAlterarNome({ isOpen, handleChangeModalIsOpen, id, nm_paciente, phone }) {
  const [NovoNomePaciente, setNovoNomePaciente] = useState('')
  const toast = useToast()

  async function handleAlterarNomePaciente(e) {
    e.preventDefault()
    api
      .post('contatos/update/nome', {
        phone: phone,
        nome: NovoNomePaciente,
      })
      .then(() => {
        handleChangeModalIsOpen()
        toast({
          title: 'Nome alterado com sucesso',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
  }

  return (
    <ModalComponent isOpen={isOpen} onClose={handleChangeModalIsOpen} title="Alterar o nome">
      <Center>
        <VStack as="form" onSubmit={handleAlterarNomePaciente}>
          <FormControl isRequired>
            <FormLabel>Qual o nome?</FormLabel>
            <Input placeholder="" onChange={(e) => setNovoNomePaciente(e.target.value)} required />
          </FormControl>
          <Button variant="primary" type="submit" leftIcon={<FiSave />} w="100%">
            Salvar
          </Button>
        </VStack>
      </Center>
    </ModalComponent>
  )
}
