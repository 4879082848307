import * as moment from 'moment'
import 'moment/locale/pt-br'
import { Container } from './styles'

moment.locale('pt')

interface Action {
  infoGeral: Array;
}

export const InfoAtendimento = ({ infoGeral }: Action) => (
  <Container>
    <p>
      <b>Paciente: </b>
      {infoGeral.nm_paciente}
    </p>
    <p>
      <b>Atendimento:</b> {infoGeral.cd_atendimento}
    </p>
    <p>
      <b>Data:</b> {moment(infoGeral.dt_registro).locale('pt-br').format('dddd, DD MMMM  YYYY')}
    </p>
    <p>
      <b>Email:</b> {infoGeral.email}
    </p>
    <p>
      <b>CPF:</b> {infoGeral.cpf}
    </p>
    <p>
      <b>Prestador:</b> {infoGeral.nm_prestador}
    </p>
    <p>
      <b>Forma de Pagamento:</b> {infoGeral.desc_forma_pagamento}
    </p>

    <small>Obs.: {infoGeral.id_pagamento}</small>
  </Container>
)
