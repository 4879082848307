import { useToast } from '@chakra-ui/react'
import { setCookie } from 'nookies'
import { createContext, useEffect, useState } from 'react'
import api from '../services/api'

const Context = createContext()

const AgendamentoWebProvider = function ({ children }) {
  const [Loading, setLoading] = useState(false)
  const [procedimentoNaoPermitido, setprocedimentoNaoPermitido] = useState(false)
  const [isOpenLogin, setisOpenLogin] = useState(false)
  const [isLogged, setisLogged] = useState(false)
  const [mensagemPadrao, setmensagemPadrao] = useState('')
  const [tpConvenio, settpConvenio] = useState('')
  const [codCliente, setcodCliente] = useState('')
  const [codConvenio, setcodConvenio] = useState('')
  const [codItemAgendamento, setcodItemAgendamento] = useState('')
  const [codPlano, setcodPlano] = useState('')
  const [codAgendamentoMV, setcodAgendamentoMV] = useState('')
  const [codPrestador, setcodPrestador] = useState('')
  const [nmPrestador, setnmPrestador] = useState('')
  const [dataAgendamento, setdataAgendamento] = useState('')
  const [horaAgendamento, sethoraAgendamento] = useState('')
  const [convenios, setConvenios] = useState([])
  const [planos, setPlanos] = useState([])
  const [itensAgendamento, setitensAgendamento] = useState([])
  const [dsItemAgendamentoSelecionado, setdsItemAgendamentoSelecionado] = useState('')
  const [agendasDisponiveis, setagendasDisponiveis] = useState([])
  const [numCelular, setNumCelular] = useState('')
  const [senha, setSenha] = useState('')
  const [user, setUser] = useState([])
  const toast = useToast()

  const groupArray = (arr) => {
    const finalData = []
    const result = arr.reduce(function (r, a) {
      r[a.DT_AGENDAMENTO] = r[a.DT_AGENDAMENTO] || []
      r[a.DT_AGENDAMENTO].push(a)
      return r
    }, Object.create(null))
    const data = Object.entries(result)
    for (let i = 0; i < data.length; i++) {
      const result2 = data[i][1].reduce(function (r, a) {
        r[a.NM_PRESTADOR] = r[a.NM_PRESTADOR] || []
        r[a.NM_PRESTADOR].push(a)
        return r
      }, Object.create(null))
      const value = {
        data: data[i][0],
        nomes: Object.keys(result2),
        medicos: Object.values(result2),
      }
      finalData.push(value)
    }
    return finalData
  }

  const handleHorarioSelecionado = (horario) => {
    setcodAgendamentoMV(horario.CD_IT_AGENDA_CENTRAL)
    setcodPrestador(horario.CD_PRESTADOR)
    setnmPrestador(horario.NM_PRESTADOR)
    setdataAgendamento(horario.DT_AGENDAMENTO)
    sethoraAgendamento(horario.HR_AGENDAMENTO)
    setisOpenLogin(true)
  }

  async function RedirectToBlock(codBloqueio) {
    window.location.href = `./${codCliente}/info/${codBloqueio}`
  }

  // Itens Pre carregados
  useEffect(() => {
    if (codCliente) {
      api
        .post('agendamentosweb/itemagendamento', { cd_cliente: codCliente })
        .then((response) => {
          setitensAgendamento(response.data)
          setLoading(false)
        })
        .catch(() => {
          setitensAgendamento([])
          setLoading(false)
        })
    }
  }, [codCliente])

  // Validacoes constantes
  useEffect(() => {
    if (codCliente && codItemAgendamento && codConvenio && codPlano)
      api
        .post('agendamentosweb/proibicao', {
          cd_cliente: codCliente,
          cd_item_agendamento: codItemAgendamento,
          cd_convenio: codConvenio,
          cd_con_pla: codPlano,
        })
        .then((response) => {
          if (response.data.length > 0) {
            RedirectToBlock('NAO_AUTORIZADO')
          } else {
            setprocedimentoNaoPermitido(false)
          }

          return response.data
        })
        .catch((err) => {
          return err
        })

    if (codCliente && codItemAgendamento && codConvenio && codPlano)
      api
        .post('agendamentosweb/itemagendamento/valor', {
          cd_cliente: codCliente,
          cd_item_agendamento: codItemAgendamento,
          cd_prestador: codPrestador,
          cd_convenio: codConvenio,
        })
        .then((response) => {
          if (response.data.length > 0) {
            setmensagemPadrao(`R$ ${response.data[0].vl_total},00`)
          } else {
            setmensagemPadrao(``)
          }
        })
        .catch(() => {
          setmensagemPadrao(``)
        })
  }, [codCliente, codItemAgendamento, codConvenio, codPlano, codPrestador])

  // Apresenta a lista de convenios
  useEffect(() => {
    if (codCliente && tpConvenio) {
      setLoading(true)
      api
        .post('agendamentoweb/convenios', { cd_cliente: codCliente, tp_convenio: tpConvenio })
        .then((response) => {
          setConvenios(response.data)
          setLoading(false)
        })
        .catch(() => {
          setConvenios([])
          setLoading(false)
          alert('Erro ao buscar os convênios')
        })
    }
  }, [tpConvenio, codCliente])

  // Apresenta a lista de planos de acordo com o que foi selecionado no convênio
  useEffect(() => {
    if (codCliente && codConvenio) {
      setLoading(true)
      api
        .post('agendamentoweb/planos', { cd_cliente: codCliente, cd_convenio: codConvenio })
        .then((response) => {
          setPlanos(response.data)
          setLoading(false)
        })
        .catch(() => {
          setPlanos([])
          setLoading(false)
          toast({
            title: 'Erro.',
            description: `Erro ao buscar os planos.`,
            position: 'top',
            status: 'error',
            duration: 4500,
            isClosable: true,
          })
        })
    }
  }, [codConvenio])

  // Apresentar a lista de horarios disponiveis de todos os medicos
  useEffect(() => {
    if (codItemAgendamento == 583) {
      RedirectToBlock('CALL_CENTER')
    }

    /* Filtra o item selecionado */
    if (codItemAgendamento && itensAgendamento) {
      const itemSelecionado = itensAgendamento.filter((item) => {
        return item.CD_ITEM_AGENDAMENTO == codItemAgendamento
      })
      setdsItemAgendamentoSelecionado(itemSelecionado[0].DS_ITEM_AGENDAMENTO)
    }

    /* Apresenta os horários disponiveis de todos os medicos */
    if (codCliente !== null && codItemAgendamento !== '' && codConvenio !== '') {
      setLoading(true)
      setagendasDisponiveis([])
      api
        .post('agendamentosweb/horarios', {
          cd_cliente: codCliente,
          cd_prestador: '',
          cd_item_agendamento: codItemAgendamento,
          cd_convenio: codConvenio,
        })
        .then((response) => {
          const data = groupArray(response.data)
          setagendasDisponiveis(data)
          setLoading(false)
        })
        .catch(() => {
          setagendasDisponiveis([])
          setLoading(false)
        })
    }
  }, [codCliente, codItemAgendamento, codConvenio])

  function handleLoginPaciente(e) {
    e.preventDefault()
    api
      .post('pacientes/login', { celular: numCelular.replace(/\D/g, ''), senha })
      .then((res) => {
        setisLogged(true)
        setisOpenLogin(false)
        setUser(res.data.user[0])
        localStorage.setItem('tokenPaciente', `Bearer ${res.data.token}`)
        setCookie(undefined, 'dragendamentoPaciente.token', `Bearer ${res.data.token}`, {
          maxAge: 60 * 60 * 6, // 6 hour
        })
        api
          .post('agendamentos/online/add/painel', {
            cd_cliente: codCliente,
            nm_paciente: res.data.user[0].nm_paciente,
            nr_celular: res.data.user[0].celular,
            ds_item_agendamento: '',
            nm_convenio: '',
            nm_con_pla: '',
            nm_prestador: '',
            dt_agendamento: '',
            hr_agendamento: '',
            desc_motivo_nao_agendado: '',
          })
          .then((res) => {
            localStorage.setItem('id_painel', res.data[0].id)
            setCookie(undefined, 'dragendamentoPaciente.idPainel', res.data[0].id, {
              maxAge: 60 * 60 * 6, // 6 hour
            })
            // aqui
          })
          .catch(() => {
            toast({
              title: 'Erro.',
              description: `Erro ao criar painel.`,
              position: 'top',
              status: 'error',
              duration: 4500,
              isClosable: true,
            })
          })
      })
      .catch(() => {
        toast({
          title: 'Erro.',
          description: `Usuário ou senha inválidos.`,
          position: 'top',
          status: 'error',
          duration: 4500,
          isClosable: true,
        })
        setisLogged(false)
        setisOpenLogin(true)
      })
  }

  return (
    <Context.Provider
      value={{
        tpConvenio,
        settpConvenio,
        setcodCliente,
        convenios,
        setcodConvenio,
        codConvenio,
        setPlanos,
        planos,
        setcodPlano,
        codPlano,
        Loading,
        itensAgendamento,
        codItemAgendamento,
        setcodItemAgendamento,
        agendasDisponiveis,
        setcodAgendamentoMV,
        procedimentoNaoPermitido,
        mensagemPadrao,
        handleHorarioSelecionado,
        nmPrestador,
        dataAgendamento,
        horaAgendamento,
        isOpenLogin,
        setNumCelular,
        setSenha,
        numCelular,
        handleLoginPaciente,
        isLogged,
        user,
        dsItemAgendamentoSelecionado,
        codAgendamentoMV,
        setLoading,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export { AgendamentoWebProvider, Context }
