import {
  Button,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Select,
  Spinner,
  VStack,
  useToast,
} from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { BsCheckCircle } from 'react-icons/bs'
import { FiKey, FiPlus, FiSave } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'
import { CopyButton } from '../../Components/CopyButton'
import { ProfileIcons } from '../../Components/Profiles/ProfileIcons'
import { ModalCriarUsuario } from '../../Components/Users/ModalCriarUsuario'
import { Context as ContextUser } from '../../Context/UserContext'
import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api'
import { ContainerPageTemplate } from '../../styles/ContainerPageTemplate'

export const Admin = function () {
  const [Loading, setLoading] = useState(false)
  const [listaUsuarios, setlistaUsuarios] = useState([])
  const [profiles, setProfiles] = useState([])
  const [doctors, setdoctors] = useState([])
  const [isOpenCriarUsuario, setisOpenCriarUsuario] = useState(false)
  const { User, setUser } = useContext(ContextUser)
  const history = useHistory()
  const toast = useToast()
  const [usuarioSelecionado, setUsuarioSelecionado] = useState([])
  const [sn_update, setsn_update] = useState(false)
  const [modalIsOpenUpdatePassword, setModalIsOpenUpdatePassword] = useState(false)
  const [password, setPassword] = useState('')
  const [isModalOpenEditUser, setIsModalOpenEditUser] = useState(false)
  const [sectors, setSectors] = useState([])

  // Consulta os dados do usuário logado
  useEffect(() => {
    setLoading(true)
    if (!User.cd_cliente) return
    api
      .post('/me', { cd_cliente: User.cd_cliente })
      .then((res) => {
        if (!res.data.profiles.isAdmin) {
          history.push('/agendamentos')
          toast({
            title: 'Permissão Negada.',
            description: `Você não tem permissão para acessar esta página.`,
            position: 'top',
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        }
        setUser(res.data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)

        toast({
          title: 'Erro.',
          description: `Não foi possível verificar o seu acesso no momento.`,
          position: 'top',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
        history.push('/agendamentos')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  // Consultar todos os usuários
  useEffect(() => {
    if (User.profiles?.user_create) {
      setLoading(true)
      api
        .get('/api/v2/users')
        .then((res) => {
          const info = res.data.sort(function (a, b) {
            return a.usuario.localeCompare(b.usuario)
          })
          setlistaUsuarios(info)
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          setlistaUsuarios([])
        })
    }
  }, [User])

  // Lista de perfis de usuário
  useEffect(() => {
    api
      .get('/api/v2/profiles')
      .then((res) => {
        setProfiles(res.data)
      })
      .catch((err) => {
        toast({
          title: 'Erro.',
          description: `Não foi possível carregar os perfis de usuário.`,
          position: 'top',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      })

    api
      .get('api/v2/sectors')
      .then((res) => {
        setSectors(res.data)
      })
      .catch((err) => {
        setSectors([])
      })
  }, [])

  // Filtrar o usuário selecionado
  function handleFilterUserById(data) {
    setLoading(true)

    api
      .get(`/api/v2/users/${data}`)
      .then((res) => {
        setUsuarioSelecionado(res.data)
        setIsModalOpenEditUser(true)
      })
      .catch(() => {
        toast({
          title: 'Erro.',
          description: `Não foi possível carregar as informações do usuário.`,
          position: 'top',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      })
      .finally(() => {
        setLoading(false)
      })

    api
      .get(`/api/v2/doctors`)
      .then((res) => {
        setdoctors(res.data)
      })
      .catch((err) => {
        toast({
          title: 'Erro.',
          description: `Não foi possível carregar os médicos.`,
          position: 'top',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      })
  }

  // Função para atualizar as informações do usuário
  async function UpdateInfoUser(infoUpdate, column) {
    const info = {
      ...usuarioSelecionado,
      [column]: infoUpdate,
    }

    setUsuarioSelecionado(info)
    setsn_update(true)
  }

  // Função para salvar as atualizações
  async function handleSaveUpdates(array) {
    const info = {
      data: {
        id: usuarioSelecionado.id,
        profileId: usuarioSelecionado.profileId,
        sn_ativo: usuarioSelecionado.sn_ativo,
        nr_ramal: usuarioSelecionado.nr_ramal,
        doctorId: usuarioSelecionado.doctorId,
        sectorId: usuarioSelecionado.sectorId,
      },
    }

    api
      .post('/api/v2/users/update', info)
      .then((res) => {
        toast({
          title: 'Sucesso.',
          description: `As informações do usuário foram atualizadas com sucesso.`,
          position: 'top',
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
        setsn_update(false)
      })
      .catch((err) => {
        toast({
          title: 'Erro.',
          description: `Erro ao atualizar as informações do usuário.`,
          position: 'top',
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      })
  }

  // Função para atualizar a senha do usuário, usando o acesso do administrador.
  async function handleUpdatePasswordUser() {
    api
      .post('/api/v2/users/update/password', { userId: usuarioSelecionado.id, senha: password })
      .then((res) => {
        setModalIsOpenUpdatePassword(false)
        toast({
          title: 'Sucesso.',
          description: `Senha atualizada com sucesso.`,
          position: 'top',
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
      })
      .catch((err) => {
        toast({
          title: 'Erro.',
          description: `Erro ao atualizar a senha do usuário.`,
          position: 'top',
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      })
  }

  const sql =
    'SELECT u.CD_USUARIO ,p.NM_PRESTADOR , p.CD_PRESTADOR, ptc.DS_TIP_COMUN_PREST FROM PRESTADOR p, PRESTADOR_TIP_COMUN ptc, usuarios u WHERE p.CD_PRESTADOR = ptc.CD_PRESTADOR (+)  AND p.CD_PRESTADOR = u.CD_PRESTADOR (+) AND p.CD_PRESTADOR = 2222'

  return (
    <>
      {/* Modal para atualizar os pagamentos. */}
      <ModalComponent isOpen={isModalOpenEditUser} onClose={() => setIsModalOpenEditUser(false)}>
        <form>
          <VStack>
            <FormControl isRequired>
              <FormLabel>Senha</FormLabel>
              <Input type="password" onChange={(e) => setPassword(e.target.value)} min={8} id="input-password" />
            </FormControl>
            <Button colorScheme="blue" onClick={() => handleUpdatePasswordUser()}>
              Atualizar
            </Button>
          </VStack>
        </form>
      </ModalComponent>

      <ModalComponent
        isOpen={isModalOpenEditUser}
        onClose={() => {
          setUsuarioSelecionado([])
          setIsModalOpenEditUser(false)
        }}
      >
        <HStack>
          <Input value={usuarioSelecionado.usuario} isDisabled bg="white" />
          <Icon
            as={BsCheckCircle}
            w={5}
            h={5}
            color={usuarioSelecionado.sn_ativo ? 'green' : 'red'}
            title={usuarioSelecionado.sn_ativo ? 'Usuário Ativo' : 'Usuário Inativo'}
            cursor="pointer"
            onClick={() => {
              UpdateInfoUser(!usuarioSelecionado.sn_ativo, 'sn_ativo')
            }}
          />
          <Icon
            as={FiKey}
            w={5}
            h={5}
            title="Alterar a senha"
            cursor="pointer"
            onClick={() => {
              setModalIsOpenUpdatePassword(true)
            }}
          />
        </HStack>
        <FormControl isRequired>
          <FormLabel>Ramal</FormLabel>
          <Input
            placeholder={usuarioSelecionado.nr_ramal}
            bg="white"
            onChange={(e) => UpdateInfoUser(e.target.value, 'nr_ramal')}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Perfil</FormLabel>
          <Select bg="white" onChange={(e) => UpdateInfoUser(e.target.value, 'profileId')}>
            {profiles.map((profile, key) => (
              <option
                value={profile.id}
                key={key}
                selected={usuarioSelecionado.profiles?.id === profile.id ? true : false}
              >
                {profile.desc_profile}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Setor do usuário</FormLabel>
          <Select bg="white" onChange={(e) => UpdateInfoUser(e.target.value, 'sectorId')}>
            <option disabled>Selecione</option>
            {sectors.map((sector, key) => (
              <option value={sector.id} key={key} selected={usuarioSelecionado.sectorId === sector.id ? true : false}>
                {sector.nm_setor}
              </option>
            ))}
          </Select>
        </FormControl>

        {/* Dr. associado ao usuário. */}
        <HStack alignContent={'end'} justifyContent={'end'} textAlign={'end'} alignItems={'end'}>
          <FormControl isRequired>
            <FormLabel>Dr(a).</FormLabel>
            <Select bg="white" onChange={(e) => UpdateInfoUser(e.target.value, 'doctorId')}>
              {doctors.map((profile, key) => (
                <option
                  value={profile.id}
                  key={key}
                  selected={usuarioSelecionado.doctors?.id === profile.id ? true : false}
                >
                  {profile.nm_prestador} - {profile.cd_prestador}
                </option>
              ))}
            </Select>
          </FormControl>
          <CopyButton content={sql} title={'Copiar SQL'} />
        </HStack>

        <ProfileIcons profiles={usuarioSelecionado.profiles} />

        {sn_update && (
          <Button leftIcon={<FiSave />} colorScheme="blue" onClick={() => handleSaveUpdates(usuarioSelecionado)}>
            Salvar
          </Button>
        )}
      </ModalComponent>

      <ContainerPageTemplate>
        <VStack w="100%" spacing={8}>
          <HStack w="100%">
            {User.profiles?.user_create && (
              <Button leftIcon={<FiPlus />} variant="primary" onClick={() => setisOpenCriarUsuario(true)}>
                Novo usuário
              </Button>
            )}
          </HStack>

          <VStack w="100%" spacing={4}>
            <Center h={8}>{Loading && <Spinner />}</Center>
            <FormControl isRequired>
              <FormLabel>Selecione um usuário.</FormLabel>
              <Select onChange={(e) => handleFilterUserById(e.target.value)} placeholder="Selecione...">
                {listaUsuarios.map((usuario, key) => (
                  <option value={usuario.id} key={key}>
                    {usuario.usuario}{' '}
                  </option>
                ))}
              </Select>
            </FormControl>
          </VStack>
        </VStack>
        <ModalCriarUsuario isOpen={isOpenCriarUsuario} handleChangeModalIsOpen={() => setisOpenCriarUsuario(false)} />
      </ContainerPageTemplate>
    </>
  )
}
