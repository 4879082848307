import { Box, Button, HStack, Icon, Stack, Text, useBreakpointValue, useToast } from '@chakra-ui/react'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { FiClipboard, FiCopy } from 'react-icons/fi'
import { Link, useHistory } from 'react-router-dom'
import { Context as ContextUser } from '../../Context/UserContext'
import {
  AgendamentosCreateAgendamento,
  AgendamentosFindPorID,
  AgendamentosFindPorItAgendaCentral,
  AgendamentosUpdateCancelarPresenca,
} from '../../Functions/Agendamentos/agendamentosData'
import { CreateFuncoesLogs } from '../../Functions/FuncoesSistemaLog/CreateFuncoesLogs'
import { CriarProtocoloSistema } from '../../Functions/Protocolos/CriarChat'
import { CriarContato } from '../../Functions/Protocolos/CriarContato'
import { GridComponent, SimpleGridComponent } from '../../_styles/GridComponent'
import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api'
import { ModalPendenciasCriar } from '../Pendencias/ModalPendenciasCriar'
import { AgendamentoCriarTelemedicina } from './AgendamentoCriarTelemedicina'
import { AgendamentoSelecionadoItem } from './AgendamentoSelecionadoItem'
import { ModalAgendamentosAnexarAutorizacao } from './ModalAgendamentosAnexarAutorizacao'
import { ModalScheduleSendPreparo } from './ModalScheduleSendPreparo'

function InfoView({ label, value }) {
  return (
    <Box>
      <Text fontSize="xs" color="gray.500" fontWeight={'bold'}>
        {label?.toUpperCase()}
      </Text>
      <HStack>
        <Text fontSize="xs">{value}</Text>
      </HStack>
    </Box>
  )
}

function InfoViewCopy({ label, value }) {
  const toast = useToast()

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(function () {
      toast({
        title: 'Copiado com sucesso!',
        description: 'Pressione CTRL+V para colar.',
        status: 'info',
        duration: 1500,
        isClosable: true,
        position: 'top-right',
      })
    })
  }

  return (
    <Box>
      <Text fontSize="sm" color="gray.500">
        {label}
      </Text>
      <HStack>
        <Text fontSize="xs">{value}</Text>
        {value && <Icon as={FiCopy} onClick={() => copyToClipboard(value)} cursor={'pointer'} />}
      </HStack>
    </Box>
  )
}

export function AgendamentoSelecionadoModal({
  isOpen,
  handleChangeModalIsOpen,
  cd_it_agenda_central,
  cd_cliente,
  idSelected,
}) {
  const [agendamentoSelecionado, setAgendamentoSelecionado] = useState([])
  const toast = useToast()
  const [modalAnexarAutorizacao, setmodalAnexarAutorizacao] = useState(false)
  const [modalTeleMedicina, setmodalTeleMedicina] = useState(false)
  const [modalIsOpenSendPresenca, setmodalIsOpenSendPresenca] = useState(false)
  const [reload, setReload] = useState(false)
  const [info, setInfo] = useState([])
  const [nm_paciente, setnm_paciente] = useState('')
  const [id, setid] = useState('')
  const [modalCriarPendencia, setmodalCriarPendencia] = useState(false)
  const [nr_celular, setnr_celular] = useState('')
  const [ds_item_agendamento, setds_item_agendamento] = useState('')
  const [infAgendamentoOrigi, setinfAgendamentoOrigi] = useState([])
  const [observacao, setobservacao] = useState('')
  const { Loading, setLoading } = useContext(ContextUser)
  const history = useHistory()
  const [documentos, setDocumentos] = useState([])
  const direction = useBreakpointValue({ base: 'column', md: 'row' })

  const handleChagemodalAnexarAutorizacao = (data) => {
    setmodalAnexarAutorizacao(!modalAnexarAutorizacao)
    if (data) {
      setInfo(data)
      setid(data.id)
      setds_item_agendamento(data.ds_item_agendamento)
      setnm_paciente(data.nm_paciente)
      setnr_celular(data.nr_celular)
    }
    setReload(!reload)
  }

  const handleOpenCriarTelemedicina = () => {
    if (agendamentoSelecionado) {
      setnm_paciente(agendamentoSelecionado[0].nm_paciente)
      setid(agendamentoSelecionado[0].id)
    }
    setmodalTeleMedicina(!modalTeleMedicina)
    setReload(!reload)
  }

  const handleCancelarPresenca = () => {
    if (agendamentoSelecionado) {
      AgendamentosUpdateCancelarPresenca(agendamentoSelecionado[0].id).then(() => {
        setReload(!reload)
      })
    }
  }

  // Abrir o modal para enviar a presença para o paciente.
  const handleOpenModalSendPresenca = (data) => {
    if (data.length > 0) {
      setnr_celular(data[0]?.nr_celular)
      setid(data[0]?.id)
      setnr_celular(data[0]?.nr_celular)
      setid(data[0]?.id)
    }
    setmodalIsOpenSendPresenca(true)
    return
  }

  const handleCriarProtocolo = () => {
    if (agendamentoSelecionado) {
      const descricao = 'CRIACAO PROTOCOLO MODAL AGENDAMENTO ' + agendamentoSelecionado[0].nm_paciente
      CreateFuncoesLogs(cd_cliente, descricao, 'PROTOCOLO_MODAL_AGENDAMENTOS').then(() => {
        CriarContato(agendamentoSelecionado[0].nm_paciente, agendamentoSelecionado[0].nr_celular).then((res) => {
          CriarProtocoloSistema(res[0].id).then(() => {
            toast({
              title: 'Protocolo.',
              description: 'Protocolo criado com sucesso',
              status: 'success',
              position: 'top',
              duration: 9000,
              isClosable: true,
            })
          })
        })
      })
    }
  }

  const handleCriarPendencia = () => {
    if (agendamentoSelecionado) {
      setnm_paciente(agendamentoSelecionado[0].nm_paciente)
      setid(agendamentoSelecionado[0].id)
    }
    setmodalCriarPendencia(!modalCriarPendencia)
  }

  // Abrir o anexo do paciente.
  const handleAnexoPaciente = (data) => {
    const descricao = `Paciente ${nm_paciente}`
    CreateFuncoesLogs(cd_cliente, descricao, 'AGENDAMENTOS_VIEW_ANEXO_PACIENTE').then((res) => {
      if (data) {
        if (data.includes('data:image')) {
          let w = window.open('about:blank')
          let image = new Image()
          image.src = data
          setTimeout(function () {
            w.document.write(image.outerHTML)
          }, 0)
        } else {
          window.open(data, '_blank')
        }
      } else {
        toast({
          title: 'Documento não encontrado',
          description: 'Não foi possível encontrar o documento',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      }
    })
  }

  useEffect(() => {
    if (isOpen) {
      setLoading(true)
      AgendamentosFindPorItAgendaCentral(cd_cliente, cd_it_agenda_central).then((res) => {
        setAgendamentoSelecionado(res)
        if (res.length === 0) {
          AgendamentosFindPorID(cd_cliente, cd_it_agenda_central).then((res) => {
            setinfAgendamentoOrigi(res)
            if (res) {
              AgendamentosCreateAgendamento(
                res.CD_CLIENTE,
                res.CD_IT_AGENDA_CENTRAL,
                res.DT_AGENDA,
                res.CD_ITEM_AGENDAMENTO,
                res.CD_CONVENIO,
                res.NR_CELULAR,
                res.CD_PRESTADOR,
                res.NM_PACIENTE
              ).then((res) => {
                setReload(!reload)
              })
            }
          })
        }
        setLoading(false)
      })

      AgendamentosFindPorID(cd_cliente, cd_it_agenda_central).then((res) => {
        setinfAgendamentoOrigi(res)
        if (res) {
          setobservacao(res.DS_OBSERVACAO_GERAL)
        }
      })
    }
  }, [isOpen, reload])

  useEffect(() => {
    if (!isOpen) return
    api
      .get('/aws/documentos/schedule/' + agendamentoSelecionado[0]?.id)
      .then((res) => {
        setDocumentos(res.data)
      })
      .catch((err) => {})
  }, [isOpen, agendamentoSelecionado])

  return (
    <>
      <ModalComponent
        isOpen={isOpen}
        onClose={handleChangeModalIsOpen}
        size="full"
        closeOnOverlayClick={false}
        title={infAgendamentoOrigi.NM_PACIENTE?.toUpperCase()}
      >
        <HStack w="100%" justifyContent={'flex-end'}>
          <Link to="/agendamentos/v2">
            <Button variant="links">Confirmar Presença</Button>
          </Link>
        </HStack>
        {agendamentoSelecionado.map((agendamento) => (
          <AgendamentoSelecionadoItem
            info={agendamento}
            obs={observacao}
            nm_paciente={infAgendamentoOrigi.NM_PACIENTE}
            reload={() => setReload(!reload)}
          />
        ))}

        <Stack direction={direction} spacing={4}>
          <Button
            variant="links"
            onClick={() => handleAnexoPaciente(agendamentoSelecionado[0].img_base64)}
            leftIcon={<FiClipboard />}
          >
            Anexo do Paciente
          </Button>

          <Button variant="links" onClick={() => handleChagemodalAnexarAutorizacao(agendamentoSelecionado[0])}>
            Anexar Autorização
          </Button>

          <Link to="/agendamentos/v2">
            <Button variant="links">Anexar Documentos</Button>
          </Link>

          <Button variant="links" onClick={() => handleOpenCriarTelemedicina()}>
            Criar Telemedicina
          </Button>

          <Button onClick={() => handleCriarProtocolo()} variant="links">
            Criar Protocolo
          </Button>

          <Button onClick={() => handleOpenModalSendPresenca(agendamentoSelecionado)} variant="links">
            Preparo
          </Button>

          <Button onClick={() => handleCancelarPresenca(agendamentoSelecionado)} variant="links">
            Cancelar Presença
          </Button>

          <Button onClick={() => history.push('/pendencias/criar/' + infAgendamentoOrigi.NM_PACIENTE)} variant="links">
            Criar Pendência
          </Button>
        </Stack>

        <SimpleGridComponent>
          {documentos.map((item) => (
            <GridComponent>
              <Link
                href={item?.url}
                isExternal
                cursor={'pointer'}
                _hover={{ boxShadow: 'base', border: '2px', bg: 'gray.50' }}
              >
                <InfoViewCopy label={'Documento'} value={item?.docs_types.description} />
                <InfoView label={'Data: '} value={moment(item.created_at).format('DD/MM/YYYY')} />
                <InfoView label={'Usuário: '} value={item.userName} />
              </Link>
            </GridComponent>
          ))}
        </SimpleGridComponent>
      </ModalComponent>

      <ModalAgendamentosAnexarAutorizacao
        isOpen={modalAnexarAutorizacao}
        handleChangeModalIsOpen={handleChagemodalAnexarAutorizacao}
        cd_it_agenda_central={cd_it_agenda_central}
        cd_cliente={cd_cliente}
        id={id}
        ds_item_agendamento={ds_item_agendamento}
        nm_paciente={nm_paciente}
        nr_celular={nr_celular}
        info={info}
      />

      <AgendamentoCriarTelemedicina
        isOpen={modalTeleMedicina}
        handleChangeModalIsOpen={handleOpenCriarTelemedicina}
        nm_paciente={nm_paciente}
        id={id}
      />

      <ModalScheduleSendPreparo
        isOpen={modalIsOpenSendPresenca}
        onClose={() => setmodalIsOpenSendPresenca(false)}
        id={id}
      />

      <ModalPendenciasCriar
        isOpen={modalCriarPendencia}
        handleChangeModalIsOpen={handleCriarPendencia}
        nm_paciente={nm_paciente}
        id={id}
      />
    </>
  )
}
