import api from '../../services/api'
import { NotificaSuccess } from '../Toast/success'

export async function SendDireto(phone, mensagem, cd_cliente) {
  return await api
    .post('mensagens/send/direto', {
      phone,
      mensagem,
      cd_cliente,
    })
    .then((response) => {
      NotificaSuccess('Mensagem enviada com sucesso...')
      return response.data
    })
    .catch((err) => {
      return err
    })
}
