import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  VStack,
} from '@chakra-ui/react'
import sha256 from 'crypto-js/sha256'
import { useEffect, useState } from 'react'
import { FiSave } from 'react-icons/fi'
import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api'

export function ModalCriarUsuario({ isOpen, handleChangeModalIsOpen }) {
  const [validaSenha, setvalidaSenha] = useState(false)
  const [nm_usuario, setnm_usuario] = useState('')
  const [password, setpassword] = useState('')
  const toast = useToast()

  // Gera a senha do usuário
  useEffect(() => {
    const parts = nm_usuario.split('.')
    if (parts.length === 2) {
      const [nome, sobrenome] = parts
      const hash = sha256(nm_usuario, sobrenome, nome).toString()
      setpassword(`${nome}@${hash.substr(0, 8)}`)
      setvalidaSenha(true)
    } else {
      setpassword('')
      setvalidaSenha(false)
    }
  }, [nm_usuario])

  // Criar o usuário
  async function handleCreateUser(e) {
    e.preventDefault()

    api
      .post('/api/v2/users/create', {
        usuario: nm_usuario,
        senha: password,
      })
      .then(() => {
        toast({
          title: 'Usuário criado com sucesso...',
          position: 'top',
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
      })
      .catch(() => {
        toast({
          title: 'Erro ao criar o usuário...',
          position: 'top',
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      })
  }

  return (
    <ModalComponent isOpen={isOpen} onClose={() => handleChangeModalIsOpen()}>
      <form onSubmit={handleCreateUser}>
        <VStack spacing={4}>
          <FormControl isRequired>
            <FormLabel>Usuário</FormLabel>
            <Input type="text" onChange={(e) => setnm_usuario(e.target.value)} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Senha</FormLabel>
            <Input type="text" value={password} disabled />
          </FormControl>

          {validaSenha && (
            <Alert status="warning">
              <AlertIcon />
              <AlertDescription>Antes de clicar no botão criar usuario copie a senha.</AlertDescription>
            </Alert>
          )}

          {validaSenha && (
            <Button type="submit" variant={'outline'} colorScheme="blue" w="100%" leftIcon={<FiSave />}>
              Salvar
            </Button>
          )}
        </VStack>
      </form>
    </ModalComponent>
  )
}
