import { HStack } from '@chakra-ui/react'
import React from 'react'
import { LinkDefault } from '../../Components/Others/LinkDefault'
import { AddMensagem } from '../../Functions/Mensagens/AddMensagem'
import { MensagensProntasFinalizarProtocolo } from '../../Functions/MensagensProntas/MensagensProntasFinalizarProtocolo'
import { UpdateStatusProtocolo } from '../../Functions/Protocolos/UpdateStatusProtocolo'
import { ModalComponent } from '../../_styles/ModalComponent '

export function ChatFinalizarProtocolo({
  isOpen,
  handleChangeModalIsOpen,
  handleChageModalMain,
  handleReload,
  id,
  nm_paciente,
  phone,
}) {
  const handleConfirmaFinalizarProtocolo = () => {
    confirm('Tem certeza que deseja finalizar o protocolo?')
    handleChageModalMain()
    UpdateStatusProtocolo(id, 'N').then(() => {
      handleChangeModalIsOpen()
      MensagensProntasFinalizarProtocolo().then((res) => {
        AddMensagem(phone, id, '', res).then(() => {
          handleReload()
        })
      })
    })
  }

  return (
    <ModalComponent isOpen={isOpen} onClose={handleChangeModalIsOpen} title="Finalizar Protocolo">
      <p>Protocolo: {id}</p>
      <p>Telefone: {phone}</p>
      <p>Tem certeza que deseja finalizar o protocolo do paciente {nm_paciente} ? </p>

      <HStack>
        <LinkDefault
          titleLink="Sim, finalizar protocolo..."
          FunctionClick={handleConfirmaFinalizarProtocolo}
          tipoBotao="link-primary"
        />
        <LinkDefault
          titleLink="Não, voltar para o chat..."
          FunctionClick={handleChangeModalIsOpen}
          tipoBotao="link-green"
        />
      </HStack>
    </ModalComponent>
  )
}
