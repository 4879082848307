import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import React from 'react'
import { FiExternalLink } from 'react-icons/fi'
import { CreateLog } from '../Functions/logs'

const ModalComponent = ({ buttonLabel, title, children, onOpen, isOpen, onClose, leftIcon, buttonView }) => {
  const logsCreate = () => {
    onOpen()
    const dataLog = {
      info: 'Modal - ' + title,
    }

    CreateLog(7, null, dataLog)
  }

  return (
    <>
      {buttonView && (
        <Button onClick={logsCreate} leftIcon={leftIcon ? leftIcon : <FiExternalLink />} variant="links">
          {buttonLabel}
        </Button>
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="full" fontFamily={'Montserrat'} id="modalView">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontFamily={'Montserrat'}>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontFamily={'Montserrat'}>{children}</ModalBody>

          <ModalFooter fontFamily={'Montserrat'}></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export { ModalComponent }
