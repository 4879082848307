import api from '../../services/api'
import { queryClient } from '../../services/queryClient'

export async function FindContatosPrestadores(cd_cliente) {
  const info = await queryClient.fetchQuery(
    ['prestadores_contato', cd_cliente],
    async () => {
      const response = await api.post('prestador/find/contato', {
        cd_cliente,
      })

      return response.data
    },
    {
      staleTime: 1000 * 60 * 20,
    }
  )
  return info
}
