import { Button, HStack, SimpleGrid, Text, VStack, useToast } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { BiMap } from 'react-icons/bi'
import AgendamentosPresentes from '../../Components/Agendamentos/AgendamentosPresentes'
import { ModalExameSelecionadoSetorExecutante } from '../../Components/Exames/ModalExameSelecionadoSetorExecutante'
import { Context as ContextUser } from '../../Context/UserContext'
import api from '../../services/api'
import { ContainerPageTemplate } from '../../styles/ContainerPageTemplate'

const ExamesNoSetorExecutante = function () {
  const { User, data, setLoading } = useContext(ContextUser)
  const [isOpenPresentes, setisOpenPresentes] = useState(false)
  const [exames, setexames] = useState([])
  const [exameSelecionado, setexameSelecionado] = useState([])
  const [reload, setreload] = useState(false)
  const [cd_atendimento, setcd_atendimento] = useState('')
  const [cd_ped_rx, setcd_ped_rx] = useState('')
  const [isOpenExameSelecionado, setisOpenExameSelecionado] = useState(false)
  const [nm_paciente, setnm_paciente] = useState(false)
  const toast = useToast()

  setTimeout(() => {
    isOpenExameSelecionado ? '' : setreload(!reload)
  }, 50000)

  /* Consultar os exames por data. */
  useEffect(() => {
    if (!User) return
    if (!data) return
    if (User.length === 0) return
    setLoading(true)

    const groupArray = (arr) => {
      const finalData = []
      const result = arr.reduce((r, a) => {
        r[a.nm_set_exa] = r[a.nm_set_exa] || []
        r[a.nm_set_exa].push(a)
        return r
      }, Object.create(null))
      const data = Object.entries(result)
      for (let i = 0; i < data.length; i++) {
        const result2 = data[i][1].reduce((r, a) => {
          r[a.nm_prestador] = r[a.nm_prestador] || []
          r[a.nm_prestador].push(a)
          return r
        }, Object.create(null))
        const value = {
          data: data[i][0],
          nomes: Object.keys(result2),
          medicos: Object.values(result2),
        }
        finalData.push(value)
      }
      return finalData
    }

    api
      .post('pedidos/data/setor', { cd_cliente: User.cd_cliente, cd_set_exa: User.cd_setor_exame, data })
      .then((response) => {
        const dataInfo = groupArray(response.data)
        setexames(dataInfo)
      })
      .catch(() => {
        toast({
          title: 'Erro ao carregar os exames.',
          description: 'Tente novamente mais tarde.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [User, data, reload])

  const handleOpenExameSelecionado = (data) => {
    if (data) {
      setexameSelecionado(data)
      setnm_paciente(data.nm_paciente)
      setcd_atendimento(data.cd_atendimento)
      setcd_ped_rx(data.cd_ped_rx)
    }
    setisOpenExameSelecionado(!isOpenExameSelecionado)
  }

  return (
    <ContainerPageTemplate isVisibleInputDate={true}>
      <HStack w="100%" mt={4}>
        <Button
          onClick={() => setisOpenPresentes(true)}
          variant="links"
          leftIcon={<BiMap />}
          id="ButtonPrestadorViewPresencas"
        >
          Presenças
        </Button>
      </HStack>

      {exames.map((dt, index) => (
        <SimpleGrid columns={[1, 2, 4]} spacing={4} key={index} marginTop={4} boxShadow="base" p="6">
          {dt.medicos.map((dtMedicos, index2) => (
            <VStack key={index2} className="examesNoSetorExecutante">
              <Text
                bgGradient="linear(to-l, #5b73e8, #2C5282)"
                marginBottom={4}
                bgClip="text"
                fontSize="1xl"
                fontWeight="extrabold"
              >
                {dt.nomes[index2]}
              </Text>
              {dtMedicos.slice(0, 50).map((ht, indht) => (
                <HStack
                  key={indht}
                  className={'hstack-exame-setor'}
                  onClick={() => handleOpenExameSelecionado(ht)}
                  cursor={'pointer'}
                  _hover={{ boxShadow: 'base' }}
                  borderRadius={16}
                  p={4}
                  title={ht.sn_laudado === 'S' ? 'Laudado' : 'Sem Laudo'}
                  w="100%"
                  justifyContent={'space-between'}
                >
                  <Button
                    marginRight={2}
                    color={ht.sn_laudado === 'S' ? 'green.500' : 'red.500'}
                    bg={'none'}
                    _hover={{ bg: 'none' }}
                  >
                    {ht.hr_liberacao}
                  </Button>
                  <VStack align={'right'} spacing={1} textAlign="right">
                    <Text fontSize={'sm'}>{ht.nm_paciente} </Text>
                    <Text fontSize={'8'}>{ht.exames} </Text>
                  </VStack>
                </HStack>
              ))}
            </VStack>
          ))}
        </SimpleGrid>
      ))}

      <ModalExameSelecionadoSetorExecutante
        isOpen={isOpenExameSelecionado}
        handleChangeModalIsOpen={() => setisOpenExameSelecionado(false)}
        info={exameSelecionado}
        cd_cliente={User.cd_cliente}
        nm_paciente={nm_paciente}
        cd_atendimento={cd_atendimento}
        cd_ped_rx={cd_ped_rx}
      />

      <AgendamentosPresentes isOpen={isOpenPresentes} handleCloseModal={() => setisOpenPresentes(false)} />
    </ContainerPageTemplate>
  )
}

export default React.memo(ExamesNoSetorExecutante)
