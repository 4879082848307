import { Center, GridItem, SimpleGrid, Spinner, Text } from '@chakra-ui/react'
import React from 'react'

const GridComponent = ({ children, index, title, onClick, p }) => {
  return (
    <GridItem
      key={index}
      boxShadow={'base'}
      p={p ? p : 4}
      borderRadius={8}
      title={title}
      onClick={onClick}
      cursor={'pointer'}
      border={'1px solid'}
      borderColor={'gray.50'}
      _hover={{ border: '1px solid', borderColor: 'gray.200' }}
      fontFamily={'Montserrat'}
    >
      {children}
    </GridItem>
  )
}

const SimpleGridComponent = ({ children, title, isLoading }) => {
  return (
    <>
      <Text>{title}</Text>
      <Center>{isLoading && <Spinner />}</Center>

      <SimpleGrid columns={[1, 1, 2, 4]} spacing={2} align="left" justifyContent={'left'} w="100%" py={2}>
        {children}
      </SimpleGrid>
    </>
  )
}

export { GridComponent, SimpleGridComponent }
