import { Button, FormControl, FormLabel, Input, Select, VStack, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FiSave } from 'react-icons/fi'
import { CreatePendenciaSetor } from '../../Functions/Pendencias/CreatePendenciaSetor'
import { FindPendenciasMotivos } from '../../Functions/Pendencias/FindPendenciasMotivos'
import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api'

export function ModalPendenciasCriar({ isOpen, handleChangeModalIsOpen, nm_paciente, id }) {
  const [Loading, setLoading] = useState(false)
  const cd_cliente = localStorage.getItem('cd_cliente')
  const [id_motivo, setid_motivo] = useState('')
  const [cd_setor_destino, setcd_setor_destino] = useState('')
  const [motivos, setmotivos] = useState([])
  const [setores, setsetores] = useState([])
  const [obs_cricacao, setobs_cricacao] = useState('')
  const toast = useToast()

  useEffect(() => {
    if (isOpen) {
      setLoading(false)
      FindPendenciasMotivos(cd_cliente, 'S').then((res) => {
        const info = res.sort(function (a, b) {
          return a.desc_motivo.localeCompare(b.desc_motivo)
        })
        setmotivos(info)
      })

      api.post('setores', { cd_cliente }).then((response) => {
        setsetores(response.data)
      })
    }
  }, [isOpen])

  async function handleAnexar(e) {
    e.preventDefault()
    setLoading(true)
    CreatePendenciaSetor(cd_cliente, id_motivo, id, nm_paciente, cd_setor_destino, obs_cricacao)
      .then(() => {
        setLoading(false)
        toast({
          title: 'Sucesso!',
          description: 'Pendência criada com sucesso...',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
        handleChangeModalIsOpen()
      })
      .catch(() => {
        toast({
          title: 'Erro!',
          description: 'Erro ao criar pendência...',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
  }

  return (
    <ModalComponent isOpen={isOpen} onClose={() => handleChangeModalIsOpen} title={nm_paciente}>
      <VStack>
        <form onSubmit={handleAnexar} encType="multipart/form-data">
          <VStack spacing={4} w="100%">
            <FormControl isRequired>
              <FormLabel>Qual o setor de destino?</FormLabel>
              <Select onChange={(e) => setcd_setor_destino(e.target.value)}>
                <option value="DEFAULT" selected disabled>
                  Selecione o setor.
                </option>
                {setores.map((setor) => (
                  <option value={setor.id}>{setor.nm_setor}</option>
                ))}
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Qual o motivo?</FormLabel>
              <Select onChange={(e) => setid_motivo(e.target.value)}>
                <option selected disabled>
                  Selecione o motivo
                </option>
                {motivos.map((motivo) => (
                  <option value={motivo.id}>{motivo.desc_motivo}</option>
                ))}
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Descreva detalhadamente o motivo da pendência.</FormLabel>
              <Input
                type="text"
                placeholder="Digite aqui uma observação..."
                onChange={(e) => setobs_cricacao(e.target.value)}
              />
            </FormControl>
            <Button colorScheme={'blue'} size="lg" w="100%" type="submit" leftIcon={<FiSave />}>
              SALVAR PENDÊNCIA
            </Button>
          </VStack>
        </form>
      </VStack>
    </ModalComponent>
  )
}
