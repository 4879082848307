import { Button, Center, FormControl, FormLabel, Input, useToast, VStack } from '@chakra-ui/react'
import { useContext, useState } from 'react'
import { FiSave } from 'react-icons/fi'
import { ModalComponent } from '../../_styles/ModalComponent '
import { Context as ContextUser } from '../../Context/UserContext'
import { CreateFuncoesLogs } from '../../Functions/FuncoesSistemaLog/CreateFuncoesLogs'
import api from '../../services/api'

export function ModalAtendimentoSolicitaInternacao({
  isOpen,
  cd_atendimento,
  nm_prestador,
  handleChangeModalIsOpen,
  nm_paciente,
  setIsLoading,
  attendanceId,
  patientId,
}) {
  const [observacoes, setObservacoes] = useState('')
  const [File, setFile] = useState('')
  const toast = useToast()
  const { User } = useContext(ContextUser)

  async function handleAnexar(e) {
    e.preventDefault()
    setIsLoading(true)

    const descricao = `Paciente ${nm_paciente} - Prestador ${nm_prestador} Observação ${observacoes}`
    CreateFuncoesLogs(User.cd_cliente, descricao, 'SOLICITA_INTERNACAO').then(() => {
      const formData = new FormData()
      formData.append('image', File)
      formData.append('nm_prestador', nm_prestador)
      formData.append('nm_paciente', nm_paciente)
      formData.append('cd_atendimento', cd_atendimento)
      formData.append('attendanceId', attendanceId)
      formData.append('patientId', patientId)
      formData.append('observacoes', observacoes)
      formData.append('isSolicitaInternacao', true)

      api
        .post('solicita/internacao', formData, {})
        .then(() => {
          toast({
            title: 'Solicitação de Internação.',
            position: 'top-right',
            description: 'Internação foi solicitada com sucesso.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        })
        .catch(() => {
          toast({
            title: 'Solicitação de Internação.',
            position: 'top-right',
            description: 'Erro para solicitar internação.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        })
        .finally(() => {
          handleChangeModalIsOpen()
          setIsLoading(false)
        })
    })
  }

  return (
    <ModalComponent isOpen={isOpen} onClose={() => handleChangeModalIsOpen()} title={'Solicitar Internação'}>
      <Center>
        <VStack spacing={4} align="left" as="form" onSubmit={handleAnexar} encType="multipart/Form-data">
          <FormControl isRequired>
            <FormLabel>Anexe o documento</FormLabel>
            <Input type="file" name="arquivo" required="" size="lg" onChange={(e) => setFile(e.target.files[0])} />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Observações.</FormLabel>
            <Input
              type="text"
              required=""
              onChange={(e) => setObservacoes(e.target.value)}
              placeholder="Observações..."
              size="lg"
            />
          </FormControl>

          <Button type="submit" variant="primary" w="100%" leftIcon={<FiSave />} size="lg">
            Anexar
          </Button>
        </VStack>
      </Center>
    </ModalComponent>
  )
}
