import React, { useState } from 'react'

import { useParams } from 'react-router-dom'
import { Form } from './styles'
// import Loader from '../../Components/Loader/check'
import { NotificaError } from '../../Functions/Toast/error'
import api from '../../services/api'

function AgendamentosEnvio() {
  const [Loading, setLoading] = useState(false)
  const { id, cd_convenio } = useParams()

  const [Rg, setRg] = useState([])
  const [CPF, setCPF] = useState([])
  const [EstadoCivil, setEstadoCivil] = useState([])
  const [nrCEP, setnrCEP] = useState([])
  const [ds_endereco, setds_endereco] = useState([])
  const [email, setemail] = useState([])
  const [nr_token, setnr_token] = useState([])
  const [nm_acompanhante, setnm_acompanhante] = useState([])

  async function handleAnexar(e) {
    e.preventDefault()

    try {
      api
        .post('cirurgias/atualizar/aviso', {
          id,
          rg: Rg,
          cpf: CPF,
          tp_estado_civil: EstadoCivil,
          nr_cep: nrCEP,
          ds_endereco,
          email,
          nr_token,
          nm_acompanhante,
        })
        .then((res) => {
          setLoading(true)
          localStorage.clear()
        })
        .catch((err) => {
          setLoading(false)
          NotificaError('Preencha todos os campos.')
        })
    } catch (error) {
      NotificaError('Erro ao enviar arquivo! Tente novamente.')
    }
  }

  return (
    <>
      {/* <Loader display={Loading} /> */}
      <Form onSubmit={handleAnexar} encType="multipart/form-data">
        <input placeholder="RG" onChange={(e) => setRg(e.target.value)} />
        <input placeholder="CPF" required="Campo Obrigatório" onChange={(e) => setCPF(e.target.value)} />
        <input
          placeholder="Estado Civil"
          required="Campo Obrigatório"
          onChange={(e) => setEstadoCivil(e.target.value)}
        />

        <input placeholder="CEP" required="Campo Obrigatório" onChange={(e) => setnrCEP(e.target.value)} />

        <input placeholder="Endereço" required="Campo Obrigatório" onChange={(e) => setds_endereco(e.target.value)} />

        <input placeholder="Email" required="Campo Obrigatório" onChange={(e) => setemail(e.target.value)} />
        <div className="inf-token">
          {cd_convenio == 254 ? (
            <p>Digite o token fornecido pela Amil</p>
          ) : (
            <p>Caso não tenha o token digite: Não tenho</p>
          )}
        </div>
        <input placeholder="Token fornecido pelo convênio." onChange={(e) => setnr_token(e.target.value)} />

        <input
          placeholder="Nome do acompanhante no dia da cirurgia"
          required="Campo Obrigatório"
          onChange={(e) => setnm_acompanhante(e.target.value)}
        />
        <button className="button" id="buttonDefault" type="submit">
          Anexar
        </button>
      </Form>
    </>
  )
}

export default React.memo(AgendamentosEnvio)
