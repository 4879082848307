import React from 'react'
import { FiCheck } from 'react-icons/fi'

const ConferenciaValidaConferidos = function ({ info }) {
  let color
  let title
  if (info.sn_conferido == 'S') {
    color = 'green'
    title = 'Conferido'
  } else {
    color = 'red'
    title = 'Não Conferido'
  }

  return <FiCheck size={20} color={color} title={title} />
}

export default React.memo(ConferenciaValidaConferidos)
