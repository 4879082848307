/* eslint-disable */
import api from '../../services/api'

export async function FindAgendadosOnline(cd_cliente, data) {
  return await api
    .post('agendamentos/online/conferencia/painel', {
      cd_cliente,
      data,
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}
