import api from '../../services/api'

export async function FindPagamentosPorAtendimento(cd_atendimento) {
  if (cd_atendimento === '') return []
  return await api
    .post('pagamentos/busca/atendimento', { cd_atendimento })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}
