import { Button, FormControl, FormLabel, Input, Select, VStack, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { AddMensagem } from '../../Functions/Mensagens/AddMensagem'
import { MensagensProntasAgendamentoAutorizado } from '../../Functions/MensagensProntas/AgendamentoAutorizado'
import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api'
import { AgendamentoSelecionadoItem } from './AgendamentoSelecionadoItem'

export function ModalAgendamentosAnexarAutorizacao({ isOpen, handleChangeModalIsOpen, info }) {
  const [File, setFile] = useState('')
  const [status, setStatus] = useState('')
  const [loading, setloading] = useState(false)
  const toast = useToast()

  // Anexar autorização e enviar mensagem ao paciente!
  async function handleAnexar(e) {
    setloading(true)
    e.preventDefault()
    alert('Anexando autorização...')
    alert('info.id: ' + info.id)
    const formData = new FormData()
    formData.append('image', File)
    formData.append('id', info.id)
    formData.append('status', status)
    api
      .post('autorizacoes/anexar', formData, {})
      .then(() => {
        toast({
          title: 'Autorização anexada com sucesso...',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })

        handleChangeModalIsOpen()

        if (status === 'A') {
          MensagensProntasAgendamentoAutorizado(info.nm_paciente, info.ds_item_agendamento).then((res) => {
            AddMensagem(info.nr_celular, '', info.id, res)
            toast({
              title: 'Mensagem enviada com sucesso.',
              description: res,
              status: 'success',
              duration: 3000,
              isClosable: true,
              position: 'top-right',
            })
          })
        }
      })
      .catch((err) => {
        console.log(err)
        toast({
          title: 'Erro ao anexar autorização...',
          description: err.response.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
      .finally(() => {
        setloading(false)
      })
  }

  return (
    <ModalComponent isOpen={isOpen} onClose={handleChangeModalIsOpen} title={'Anexar Autorização'}>
      <AgendamentoSelecionadoItem info={info} />
      <form onSubmit={handleAnexar} encType="multipart/form-data">
        <VStack spacing={8}>
          <FormControl isRequired>
            <FormLabel> Status</FormLabel>
            <Select onChange={(e) => setStatus(e.target.value)}>
              <option value="DEFAULT" selected disabled>
                Escolha uma opção...
              </option>
              <option value="A">Autorizado</option>
              <option value="N">Negado</option>
              <option value="S">Sem Guia</option>
              <option value="E">Em Análise</option>
            </Select>
          </FormControl>

          <FormControl isRequired>
            <FormLabel> Documento da autorização</FormLabel>
            <Input type="file" name="arquivo" required="" onChange={(e) => setFile(e.target.files[0])} />
          </FormControl>

          <Button colorScheme={'blue'} type="submit" isLoading={loading}>
            ANEXAR AUTORIZAÇÃO DO PACIENTE {info.nm_paciente}
          </Button>
        </VStack>
      </form>
    </ModalComponent>
  )
}
