import {
  Button,
  GridItem,
  HStack,
  Icon,
  SimpleGrid,
  Tag,
  Text,
  VStack,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaBriefcaseMedical } from 'react-icons/fa'
import { FiCopy, FiSend } from 'react-icons/fi'
import { CreateFuncoesLogs } from '../../Functions/FuncoesSistemaLog/CreateFuncoesLogs'
import { SendSlack } from '../../Functions/Mensagens/SendSlack'
import { FindPedidosAtendimento } from '../../Functions/Pedidos/FindPedidosAtendimento'
import { ModalComponent } from '../../_styles/ModalComponent '
import { LaudosLaudar } from './LaudosLaudar'

export function ModalExameSelecionadoSetorExecutante({
  isOpen,
  handleChangeModalIsOpen,
  info,
  cd_cliente,
  cd_atendimento,
  nm_paciente,
  cd_ped_rx,
}) {
  const [modalLaudar, setmodalLaudar] = useState(false)
  const [atendimento, setatendimento] = useState([])
  const toast = useToast()
  const isDeviceSmall = useBreakpointValue({ sm: 1, md: 1, lg: 1 })
  const sizeGap = useBreakpointValue({ sm: 1, md: 1, lg: 4 })

  useEffect(() => {
    if (isOpen) {
      FindPedidosAtendimento(cd_cliente, cd_ped_rx).then((res) => {
        setatendimento(res)
      })
    }
  }, [isOpen, modalLaudar])

  const CopyClipboard = (text) => {
    navigator.clipboard.writeText(text).then(function () {
      toast({
        title: 'Copiado com sucesso!',
        description: 'Pressione CTRL+V para colar.',
        status: 'info',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      })
    })
  }

  const handleOpenURL = (url) => {
    window.open(url, '_blank')
  }

  const handleChamarPaciente = () => {
    const mensagem =
      '📢 Por favor chamar: 📢\n\n*Paciente:* ' + nm_paciente + '\n*Setor:* ' + localStorage.getItem('usuario')
    SendSlack(mensagem).then((messageSend) => {
      if (!messageSend) {
        toast({
          title: 'Erro ao enviar mensagem!',
          description: mensagem,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      }

      if (messageSend) {
        CreateFuncoesLogs(cd_cliente, mensagem, 'MENSAGENS_CHAMAR_PACIENTE').then(() => {})
        toast({
          title: 'Mensagem enviada com sucesso!',
          description: 'A mensagem foi enviada para o slack do setor.',
          status: 'success',
          duration: 10000,
          isClosable: true,
          position: 'top-right',
        })
      }
    })
  }

  const IconCopy = ({ text }) => {
    return <Icon as={FiCopy} onClick={() => CopyClipboard(text)} />
  }

  return (
    <>
      <ModalComponent isOpen={isOpen} onClose={() => handleChangeModalIsOpen()} title="Informações do exame">
        <HStack>
          {info.id && (
            <>
              <Button
                leftIcon={<FiSend />}
                variant="primary"
                onClick={() => {
                  handleChangeModalIsOpen()
                  handleChamarPaciente()
                }}
              >
                Chamar
              </Button>
              {info.sn_laudado === 'N' && (
                <Button
                  variant="links"
                  onClick={() => {
                    handleChangeModalIsOpen()
                    setmodalLaudar(true)
                  }}
                >
                  Laudar
                </Button>
              )}
            </>
          )}
        </HStack>
        <>
          <VStack align={'left'} mt={4}>
            <HStack>
              <Text>{nm_paciente}</Text>
              <IconCopy text={nm_paciente} />
            </HStack>

            <HStack>
              <Text> Atendimento: {cd_atendimento}</Text>
              <IconCopy text={cd_atendimento} />
            </HStack>

            <HStack>
              <Text> Pedido: {cd_ped_rx}</Text>
              <IconCopy text={cd_ped_rx} />
            </HStack>

            <HStack>
              <Text>{info?.nm_convenio}</Text>
              <IconCopy text={info?.nm_convenio} />
            </HStack>
          </VStack>

          <SimpleGrid templateColumns={`repeat(${isDeviceSmall}, 1fr)`} gap={sizeGap} borderRadius={4} py={4} mt={4}>
            {atendimento.map((exame, key) => (
              <GridItem
                key={key}
                boxShadow={'base'}
                p={4}
                borderRadius={8}
                onClick={() => handleOpenURL(exame.img_pedido)}
              >
                <VStack>
                  <Icon as={FaBriefcaseMedical} />

                  <Text fontSize={'bold'}>{exame.nm_set_exa}</Text>

                  <Text fontSize={'12px'}>{exame.exames}</Text>
                  {!exame.url_laudo && <Tag colorScheme="red">EXAME SEM LAUDO</Tag>}
                </VStack>
              </GridItem>
            ))}
          </SimpleGrid>
        </>
      </ModalComponent>

      <LaudosLaudar isOpen={modalLaudar} handleChangeModalIsOpen={() => setmodalLaudar(false)} info={info} />
    </>
  )
}
