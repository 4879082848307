import { Button, Flex, FormControl, FormLabel, Image, Input, SimpleGrid, useBreakpointValue } from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react'
import { Context as ContextLoading } from '../../Context/UserContext'

export default function Login() {
  const { HandleLogin } = useContext(ContextLoading)
  const [cd_cliente, setCliente] = useState('')
  const [usuario, setUsuario] = useState('')
  const [senha, setSenha] = useState('')
  const [loading, setloading] = useState(false)
  const isDeviceSmall = useBreakpointValue({ sm: 1, md: 1, lg: 1 })

  useEffect(() => {
    window.location.replace('https://app.dragendamento.com')
  }, [])

  async function HandleLoginUser(e) {
    e.preventDefault()
    setloading(true)

    await HandleLogin(cd_cliente, usuario, senha)
    setloading(false)
  }

  return (
    <SimpleGrid
      templateColumns={`repeat(${isDeviceSmall}, 1fr)`}
      p={4}
      alignItems={'center'}
      h="100vh"
      w="100vw"
      fontFamily={'Montserrat'}
    >
      <Flex alignItems="center" placeContent={'left'} flexDirection="column" justifyContent="center">
        <Image src="/logo.png" alt="Logo" width={200} height={200} />
        <Flex bg="gray.50" boxShadow={'lg'} p={6} maxW="90%" borderRadius={4} id="boxLogin">
          <form onSubmit={HandleLoginUser}>
            <FormControl id="cd_cliente" isRequired marginBottom={2}>
              <FormLabel>Código do Hospital</FormLabel>
              <Input type="number" onChange={(e) => setCliente(e.target.value)} id="cd_cliente" />
            </FormControl>

            <FormControl id="usuario" isRequired marginBottom={2}>
              <FormLabel>Usuário</FormLabel>
              <Input type="text" onChange={(e) => setUsuario(e.target.value)} id="user" />
            </FormControl>

            <FormControl id="senha" isRequired marginBottom={4}>
              <FormLabel>Senha</FormLabel>
              <Input type="password" onChange={(e) => setSenha(e.target.value)} autoComplete="on" id="password" />
            </FormControl>
            <Button type="submit" isLoading={loading} id="buttonLogin" variant="primary" w="100%">
              ENTRAR
            </Button>
          </form>
        </Flex>
      </Flex>
    </SimpleGrid>
  )
}
