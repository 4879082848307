import { Button, FormControl, FormLabel, HStack, Input, VStack } from '@chakra-ui/react'
import { useState } from 'react'
import { FiHome, FiSave } from 'react-icons/fi'
import InputMask from 'react-input-mask'
import { useParams } from 'react-router-dom'
import LogoHP from '../../assets/clientes/1.png'
import { AgendamentosWebInsertCadastroPaciente } from '../../Functions/Agendamentos/agendamentosWebData'
import { ContainerAgendamentosWebTemplate } from '../../styles/ContainerPageTemplate'

export default function AgendamentoWeb() {
  const { cd_cliente } = useParams()
  const [numeroCelular, setnumeroCelular] = useState([])
  const [numeroDocumento, setnumeroDocumento] = useState([])
  const [nomeResponsavel, setnomeResponsavel] = useState([])
  const [nomeMae, setnomeMae] = useState([])
  const [endereco, setEndereco] = useState([])
  const [email, setEmail] = useState([])
  const [nomePaciente, setnomePaciente] = useState([])
  const [numeroCarteira, setnumeroCarteira] = useState([])
  const [dataNascimento, setDataNascimento] = useState('')

  async function Redirect(cd_cliente) {
    window.location.href = `/agendamentos/web/${cd_cliente}`
  }

  const FormataCelular = (data) => {
    var NumeroFormatado = data.replace(/\D+/g, '')
    setnumeroCelular(NumeroFormatado)
  }

  async function handleCadastro(e) {
    e.preventDefault()
    AgendamentosWebInsertCadastroPaciente(
      nomePaciente,
      numeroCarteira,
      numeroCelular,
      numeroDocumento,
      nomeResponsavel,
      endereco,
      email,
      nomeMae,
      dataNascimento
    ).then((result) => {})
  }
  return (
    <ContainerAgendamentosWebTemplate logoImg={LogoHP}>
      <form onSubmit={handleCadastro} encType="multipart/form-data">
        <VStack spacing={4}>
          <FormControl isRequired>
            <FormLabel>Nome do Paciente</FormLabel>
            <Input placeholder="Nome Paciente" onChange={(e) => setnomePaciente(e.target.value)} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Responsável pelo Paciente</FormLabel>
            <Input placeholder="Responsável" onChange={(e) => setnomeResponsavel(e.target.value)} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Data de Nascimento</FormLabel>
            <Input placeholder="Data de Nascimento" type="date" onChange={(e) => setDataNascimento(e.target.value)} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Nome da Mãe</FormLabel>
            <Input placeholder="Responsável" onChange={(e) => setnomeMae(e.target.value)} />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Endereço completo</FormLabel>
            <Input placeholder="Endereço" onChange={(e) => setEndereco(e.target.value)} />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Celular</FormLabel>
            <Input
              as={InputMask}
              mask="(**) *****-****"
              maskChar={null}
              onChange={(e) => FormataCelular(e.target.value)}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel> Email</FormLabel>
            <Input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
          </FormControl>

          <HStack>
            <Button colorScheme={'blue'} type="submit" leftIcon={<FiSave />}>
              Salvar
            </Button>
            <Button leftIcon={<FiHome />} colorScheme={'gray'} onClick={() => Redirect(cd_cliente)}>
              Voltar
            </Button>
          </HStack>
        </VStack>
      </form>
    </ContainerAgendamentosWebTemplate>
  )
}
