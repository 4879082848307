// DS-18 - Visualizar agendamentos feito no dia
import {
  HStack,
  Icon,
  List,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FiSend } from 'react-icons/fi'
import { LoadingModal } from '../../Components/LoadingModal'
import { ModalScheduleSelected } from '../../Components/Schedules'
import api from '../../services/api'
import { ContainerPageTemplate } from '../../styles/ContainerPageTemplate'

export function SchedulesItem({ schedule, index, onClick }) {
  const data = new Date(schedule.dt_agenda)
  if (data < new Date()) return null

  return (
    <Tr key={index} onClick={() => onClick(schedule)} cursor={'pointer'} _hover={{ bg: 'gray.50' }}>
      <Td>
        <Icon as={FiSend} color={schedule.isEnvioConfirmacao ? 'green.500' : 'red.500'} />
      </Td>
      <Td>{schedule.nm_paciente}</Td>
      <Td>{schedule.schedule_item?.ds_item_agendamento}</Td>
      <Td>
        {schedule.dt_agenda} - {schedule.hr_agenda}
      </Td>
    </Tr>
  )
}

const PainelAgendamentos = function () {
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [agendamentos, setAgendamentos] = useState([])
  const [isOpenModalEdit, setisOpenModalEdit] = useState(false)
  const [agendamentoSelecionado, setAgendamentoSelecionado] = useState({})
  const [timeUntilNextUpdate, setTimeUntilNextUpdate] = useState(120) // tempo em segundos

  // Função para buscar agendamentos
  const fetchAgendamentos = () => {
    setIsLoading(true)

    api
      .get('/api/v2/schedules/updated')
      .then((response) => {
        setAgendamentos(response.data)
      })
      .catch((error) => {
        toast({
          title: 'Erro ao carregar agendamentos',
          description: error.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  // Abrir o modal para visualizar o agendamento.
  const handleOpenModal = (data) => {
    setAgendamentoSelecionado(data)
    setisOpenModalEdit(true)
  }

  useEffect(() => {
    fetchAgendamentos() // Buscar agendamentos na montagem do componente

    const intervalId = setInterval(() => {
      fetchAgendamentos() // Buscar agendamentos a cada 2 minutos
    }, 120000) // 120000 ms = 2 minutos

    return () => {
      clearInterval(intervalId) // Limpar o intervalo quando o componente for desmontado
    }
  }, [])

  // Temporizador para a próxima atualização
  useEffect(() => {
    const timerId = setInterval(() => {
      setTimeUntilNextUpdate((prevTime) => (prevTime > 0 ? prevTime - 1 : 120))
    }, 1000) // Atualizar a cada 1 segundo

    return () => {
      clearInterval(timerId) // Limpar o temporizador quando o componente for desmontado
    }
  }, [])

  return (
    <>
      {isLoading && <LoadingModal isLoading={isLoading} />}

      <ModalScheduleSelected
        isOpen={isOpenModalEdit}
        onClose={() => setisOpenModalEdit(false)}
        scheduleSelected={agendamentoSelecionado}
        setScheduleSelected={setAgendamentoSelecionado}
        setArrayAgendamentos={setAgendamentos}
        arrayAgendamentos={agendamentos}
      />

      <ContainerPageTemplate isLoading={isLoading} isVisibleInputDate={false}>
        <HStack justifyContent={'flex-end'}>
          <Text> Próxima atualização em: {timeUntilNextUpdate} segundos</Text>
        </HStack>
        <TableContainer>
          <Table variant="simple" size="xs">
            <TableCaption>Mostrando os últimos 30 agendamentos atualizados</TableCaption>
            <Thead>
              <Tr>
                <Th>Confirmação</Th>
                <Th>Paciente</Th>
                <Th>Item</Th>
                <Th>Data</Th>
              </Tr>
            </Thead>
            <Tbody>
              {agendamentos.map((schedule, index) => (
                <SchedulesItem schedule={schedule} key={index} onClick={() => handleOpenModal(schedule)} />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <List spacing={2}></List>
      </ContainerPageTemplate>
    </>
  )
}

export { PainelAgendamentos }
