import { Button, useBreakpointValue } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { Context as ContextUser } from '../Context/UserContext'

export function ButtonPageTemplate({ title, onClick, color, icon, size, variant, id }) {
  const { Loading } = useContext(ContextUser)
  const titleIsVisible = useBreakpointValue({ sm: true, md: false })

  return (
    <Button
      onClick={onClick}
      colorScheme={color || 'blue'}
      size={size || 'sm'}
      variant={variant || 'outline'}
      leftIcon={icon}
      title={title}
      id={id}
    >
      {!titleIsVisible && title}
    </Button>
  )
}

export function ButtonPageTemplateSubmit({ title, color, icon, size, variant }) {
  const { Loading } = useContext(ContextUser)
  return (
    <Button
      type="submit"
      colorScheme={color || 'blue'}
      size={size || 'sm'}
      variant={variant || 'outline'}
      leftIcon={icon}
      isLoading={Loading}
    >
      {title}
    </Button>
  )
}
