import api from '../../services/api'

export async function FindProtocolosPendentes(user) {
  const id_setor = localStorage.getItem('cd_setor')
  return await api
    .post('protocolos/find/idsetor', {
      id_setor: user?.cd_setor || id_setor,
      sn_pendente: 'S',
    })
    .then((response) => {
      return response.data
    })
    .catch(() => {
      return []
    })
}
