/* eslint-disable */
import api from '../../services/api'

export async function UpdateStatusProtocolo(cd_protocolo, sn_pendente) {
  return await api
    .post('protocolos/update', { cd_protocolo, sn_pendente })
    .then((res) => {
      return res.data
    })
}
