import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { AgendamentoProvider } from './Context/AgendamentosContext'
import { AgendamentoWebProvider } from './Context/AgendamentosWebContext'
import { LoadingProvider } from './Context/LoaderContext'
import { UserProvider } from './Context/UserContext'
import Routes from './routes'
import { queryClient } from './services/queryClient'
import theme from './styles/theme'
const mytheme = extendTheme(theme)

const App = function () {
  return (
    <ChakraProvider resetCSS theme={mytheme}>
      <QueryClientProvider client={queryClient}>
        <LoadingProvider>
          <UserProvider>
            <AgendamentoProvider>
              <AgendamentoWebProvider>
                <Routes />
              </AgendamentoWebProvider>
            </AgendamentoProvider>
            <ToastContainer />
          </UserProvider>
          <ReactQueryDevtools />
        </LoadingProvider>
      </QueryClientProvider>
    </ChakraProvider>
  )
}

export default App
