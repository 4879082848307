import { Button, HStack, Icon, Image, OrderedList, Text, VStack, useDisclosure, useToast } from '@chakra-ui/react'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { FiCheck, FiClock } from 'react-icons/fi'
import { Context as ContextAgendamento } from '../../Context/AgendamentosContext'
import { Context as ContextUser } from '../../Context/UserContext'
import { SendDireto } from '../../Functions/Mensagens/SendDireto'
import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api.js'
import { ContainerPageTemplate } from '../../styles/ContainerPageTemplate'

function AgendamentosOnlinePrestador() {
  const [calendar, setCalendar] = useState([])
  const [schedules, setSchedules] = useState([])
  const { User, data, Loading, setLoading } = useContext(ContextUser)
  const { setcd_it_agenda_central, cd_it_agenda_central } = useContext(ContextAgendamento)
  const interval = 30 // Intervalo de 30 minutos
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [scheduleSelected, setScheduleSelected] = useState([])
  const [agendamento, setagendamento] = useState([])
  const [examesAtendimento, setExamesAtendimento] = useState([])
  const [informacoesAtendimento, setInformacoesAtendimento] = useState([])
  const [codAgendamento, setCodAgendamento] = useState(null)
  const [urlReuniao, setUrlReuniao] = useState(null)

  /* Abrir o modal de agendamento */
  const handleAbrirAgendamento = (data) => {
    const agendamentos = schedules.filter((schedule) => schedule.CD_IT_AGENDA_CENTRAL === data)[0]
    setScheduleSelected(agendamentos)

    onOpen()

    setCodAgendamento(agendamentos.CD_IT_AGENDA_CENTRAL)

    api
      .post('/agendamentos/busca/cd_it_agenda_central', {
        cd_it_agenda_central: agendamentos.CD_IT_AGENDA_CENTRAL,
        cd_cliente: '1',
      })
      .then((res) => {
        toast({
          title: 'Sucesso.',
          description: 'Agendamento carregado com sucesso.',
          status: 'success',
          duration: 1000,
          isClosable: true,
        })
        setUrlReuniao(res.data[0].url_reuniao)
      })
      .catch((err) => {
        toast({
          title: 'Erro.',
          description: 'Não foi possível carregar os agendamentos, tente novamente.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      })
  }

  const localizer = momentLocalizer(moment)

  const MyToolbar = ({ label }) => {
    return (
      <div className="rbc-toolbar">
        <span className="rbc-toolbar-label">{label}</span>
      </div>
    )
  }

  // Consultar a data do agendamento.
  useEffect(() => {
    if (!User?.cd_cliente) return
    if (!data) return

    if (data !== null && User.cd_prestador) {
      setLoading(true)
      setCalendar([])
      api
        .post('agendamentos/conferencia/mv/prestador', {
          cd_cliente: `${User.cd_cliente}`,
          data,
          cd_prestador: `${User.cd_prestador}`,
        })
        .then((res) => {
          setSchedules(res.data)
          const eventos = res.data.map((agendamento) => {
            const { CD_IT_AGENDA_CENTRAL, DT_AGENDA, NM_PACIENTE, HR_FIM, CD_ATENDIMENTO } = agendamento
            const start = new Date(DT_AGENDA)
            const end = HR_FIM ? new Date(HR_FIM) : new Date(start.getTime() + 20 * 60000)

            return {
              id: CD_IT_AGENDA_CENTRAL,
              title: NM_PACIENTE,
              start,
              end,
              cor: CD_ATENDIMENTO ? 'green' : '',
            }
          })
          setCalendar(eventos)
          setLoading(false)
        })
        .catch(() => {
          toast({
            title: 'Erro.',
            description: 'Não foi possível carregar os agendamentos, tente novamente.',
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
          setLoading(false)
        })
    }
  }, [data, User])

  useEffect(() => {
    if (!scheduleSelected) return
    if (!scheduleSelected.CD_ATENDIMENTO) return
    if (!User) return

    api
      .post('/agendamentos/busca/cd_it_agenda_central', {
        cd_cliente: User?.clients.cd_cliente,
        cd_it_agenda_central: scheduleSelected.CD_IT_AGENDA_CENTRAL,
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err)
      })
  }, [scheduleSelected, User])

  const eventStyleGetter = (event) => {
    const backgroundColor = event.cor // Cor do evento

    return {
      className: 'event-filled',
      style: {
        backgroundColor,
        borderColor: 'white',
      },
    }
  }

  // DRA-FUNC-12 - Telemedicina - Atendimento Médico
  async function handleOpenTelemedicina(data) {
    api
      .post('api/v2/schedules/calendar/idMv', {
        idMv: data.CD_IT_AGENDA_CENTRAL,
      })
      .then((res) => {
        // Se for telemedicina
        if (res.data.isOnline) {
          const url = res.data.urlOnline
          let mensagem = `Sr(a) ${scheduleSelected.NM_PACIENTE}.\n\nSegue abaixo o link de sua tele consulta. \n\n${url}.`
          SendDireto(`55${scheduleSelected.NR_CELULAR}@c.us`, mensagem, scheduleSelected.CD_CLIENTE)
          window.open(url, '_blank')
        } else {
          toast({
            title: 'Erro.',
            description: 'Esse agendamento não possui teleconsulta criada, entre em contato com o atendimento.',
            status: 'error',
            duration: 4000,
            isClosable: true,
            position: 'top-right',
          })
        }
      })
      .catch(() => {
        toast({
          title: 'Erro.',
          description: 'Não foi possível carregar os agendamentos, tente novamente.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      })
  }

  return (
    <ContainerPageTemplate isVisibleInputDate={true}>
      <ModalComponent isOpen={isOpen} onClose={onClose}>
        <HStack justifyContent={'space-between'}>
          <Button
            colorScheme={'blue'}
            size="sm"
            variant="outline"
            onClick={() => handleOpenTelemedicina(scheduleSelected)}
          >
            Ir para telemedicina..
          </Button>
        </HStack>
        <VStack spacing={2}>
          <Text id="modalNomePaciente">{scheduleSelected?.NM_PACIENTE}</Text>
          <Text fontSize={'12px'} fontWeight={'bold'} id="modalItemAgendamento">
            {scheduleSelected?.DS_ITEM_AGENDAMENTO}
          </Text>
          <HStack>
            <Icon
              as={scheduleSelected?.CD_ATENDIMENTO ? FiCheck : FiClock}
              color={scheduleSelected?.CD_ATENDIMENTO ? 'green' : 'gray'}
            />
            <Text fontSize={'12px'}>
              {scheduleSelected?.CD_ATENDIMENTO
                ? 'Atendimento ' + scheduleSelected?.CD_ATENDIMENTO
                : 'Aguardando atendimento'}
            </Text>
          </HStack>
          <VStack>
            <Text fontSize={'12px'}>Exames encontrados: {examesAtendimento.length}</Text>
            <Text fontSize={'12px'}>Atendimentos encontrados: {informacoesAtendimento.length}</Text>
            <Text fontSize={'12px'}>Informações do agendamento: {agendamento.length}</Text>
          </VStack>
          <OrderedList>
            {examesAtendimento.map((atendimento, index) => (
              <ListItem key={index}>
                {atendimento.exames} - {atendimento.hr_liberacao}
              </ListItem>
            ))}
          </OrderedList>
        </VStack>
      </ModalComponent>

      {!Loading && (
        <Text id="qtdAgendamentosPrestador" fontSize="10px">
          {calendar?.length} agendamentos encontrados.
        </Text>
      )}

      {calendar.length === 0 && (
        <VStack justifyContent={'center'} w="100%" p={8} alignItems={'center'} textAlign={'center'}>
          <Image src="/book.svg" alt="Pesquisar" h="300px" w="300px" id="imagem-agendamento-nao-encontrado" />
          <Text id="msgSemAgendamentos">Não foram encontrados novos agendamentos.</Text>
        </VStack>
      )}

      {calendar.length > 0 && (
        <Calendar
          localizer={localizer}
          events={calendar}
          startAccessor="start"
          endAccessor="end"
          defaultView="day"
          date={data} // Define a data inicial do calendário
          components={{
            toolbar: MyToolbar, // Substitui o componente Toolbar personalizado
          }}
          step={interval}
          timeslots={1}
          onSelectEvent={(event) => handleAbrirAgendamento(event.id)}
          eventPropGetter={eventStyleGetter}
        />
      )}
    </ContainerPageTemplate>
  )
}

export default React.memo(AgendamentosOnlinePrestador)
