import { Box, SimpleGrid, Text } from '@chakra-ui/react'
import moment from 'moment'
import React, { useEffect } from 'react'
import { ModalComponent } from '../../_styles/ModalComponent '
moment.locale('pt')

export function ModalChatDocumentosRecebidos({ isOpen, handleChangeModalIsOpen, documentos, nm_paciente }) {
  const [docsPendentesView, setdocsPendentesView] = React.useState([])
  const [docsVisualizadosView, setdocsVisualizadosView] = React.useState([])

  useEffect(() => {
    setdocsVisualizadosView([])
    setdocsPendentesView(documentos)
  }, [documentos, isOpen])

  async function handleViewDoc(data) {
    let osRemoveElement = docsPendentesView.filter((item) => {
      return item.id !== data.id
    })
    setdocsPendentesView(osRemoveElement)
    setdocsVisualizadosView([...docsVisualizadosView, data])
    window.open(data.url_aws, '_blank')
  }

  return (
    <ModalComponent isOpen={isOpen} onClose={handleChangeModalIsOpen}>
      <SimpleGrid columns={2} spacing={2}>
        <Box>
          <Text>{`Pendentes de visualização (${docsPendentesView.length})`}</Text>

          {docsPendentesView.map((documento, index) => (
            <Text key={index} onClick={() => handleViewDoc(documento)} t>
              {moment(documento.data).format('dddd, DD MMMM  YYYY')}
            </Text>
          ))}
        </Box>
        <Box>
          <Text>{`Visualizados (${docsVisualizadosView.length})`}</Text>

          {docsVisualizadosView.map((documento, index) => (
            <Text key={index} onClick={() => handleViewDoc(documento)} t>
              {moment(documento.data).format('dddd, DD MMMM  YYYY')}
            </Text>
          ))}
        </Box>
      </SimpleGrid>
    </ModalComponent>
  )
}
