/* eslint-disable */
import api from '../../services/api'

export async function FindProcedimentosCadastrados(cd_cliente, ds_pro_fat) {
  return await api
    .post('cirurgias/lista', { cd_cliente, ds_pro_fat })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}
