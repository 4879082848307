import { Button, Center, Heading, HStack, Icon, Spinner, Text, useToast, VStack } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { FiClock, FiPlus, FiSend } from 'react-icons/fi'
import { ModalComponent } from '../../_styles/ModalComponent '
import { Context as ContextUser } from '../../Context/UserContext'
import { AddMensagem } from '../../Functions/Mensagens/AddMensagem'
import { MensagensProntasPreparoCirurgia } from '../../Functions/MensagensProntas/PreparoCirurgias'
import { CriarContatoEProtocolo } from '../../Functions/Protocolos/CriarContatoEProtocolo'
import api from '../../services/api'

function ButtonTemplate({ title, icon, click }) {
  return (
    <Button colorScheme={'gray'} size="sm" variant="ghost" leftIcon={icon} onClick={click}>
      {title}
    </Button>
  )
}

export function ModalCirurgiaSelecionada({ isOpen, handleChangeModalIsOpen, cd_aviso_cirurgia }) {
  const { User } = useContext(ContextUser)
  const [cirurgias, setcirurgias] = useState([])
  const [cirurgiasMV, setcirurgiasMV] = useState([])
  const [Loading, setLoading] = useState(false)
  const [modalOpenPendencia, setmodalOpenPendencia] = useState(false)
  const toast = useToast()

  // Encontrar as cirurgias por id e os documentos anexados.
  useEffect(() => {
    if (isOpen && cd_aviso_cirurgia) {
      setLoading(true)
      // Verificar as cirurgias do aviso.
      api
        .post('cirurgias/aviso', { cd_cliente: User?.cd_cliente, cd_aviso_cirurgia })
        .then((response) => {
          setcirurgias(response.data[0])
          // Buscar os documentos da cirurgia.
        })
        .catch((err) => {
          handleChangeModalIsOpen()
          toast({
            title: 'Não foi possível carregar as informações do agendamento',
            status: 'error',
            duration: 2000,
            position: 'top',
          })
          return err
        })
    }
  }, [isOpen])

  // Buscar cirurgias por aviso...
  useEffect(() => {
    if (cd_aviso_cirurgia)
      api
        .post('cirurgias/busca/aviso', {
          cd_cliente: User?.cd_cliente,
          cd_aviso_cirurgia,
        })
        .then((res) => {
          setcirurgiasMV(res.data[0])
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
          setcirurgiasMV([])
          toast({ title: 'Não foi possível carregar as informações do agendamento', status: 'error', duration: 2000 })
        })
  }, [cd_aviso_cirurgia])

  const sendMensagemPreparoCirurgia = (data) => {
    MensagensProntasPreparoCirurgia(data.nm_paciente).then((res) => {
      AddMensagem(data.celular_paciente, '', '', res)
    })
  }

  function openModalPendencia() {
    handleChangeModalIsOpen()
    setmodalOpenPendencia(true)
  }

  return (
    <>
      {/* Modal das cirurgias selecionada  */}
      <ModalComponent isOpen={isOpen} onClose={() => handleChangeModalIsOpen()}>
        {Loading && (
          <Center h="100vh">
            <Spinner isIndeterminate size={'lg'} colorScheme="blue" />
          </Center>
        )}
        {!Loading && (
          <>
            <Heading as="h2" size={'sm'}>
              {cirurgias?.nm_paciente}
            </Heading>

            <HStack spacing={4} fontSize="12px" py={4}>
              {/* Verifica se a cirurgia está autorizada. */}

              {/* Apresenta o horário da cirurgia */}
              <HStack>
                <Icon as={FiClock} color={'gray'} w={5} h={5} />
                <Text color={'gray'}> {cirurgias?.hora}</Text>{' '}
              </HStack>

              {/* Tipo de acomodação da cirurgia */}
              <HStack>
                <Text color={'gray'}> {cirurgiasMV?.DS_TIP_ACOM}</Text>{' '}
              </HStack>

              {/* Apresenta o aviso de cirurgia  */}
              <Text color={'gray'}> {cirurgias?.cd_aviso_cirurgia}</Text>
            </HStack>
          </>
        )}

        <HStack>
          <ButtonTemplate
            title="Criar Protocolo"
            icon={<FiPlus />}
            click={() => CriarContatoEProtocolo(cirurgias?.nm_paciente, cirurgias?.celular_paciente)}
          />
          <ButtonTemplate
            title="Preparo de Cirurgia"
            icon={<FiSend />}
            click={() => sendMensagemPreparoCirurgia(cirurgias)}
          />
        </HStack>

        <VStack alignItems={'left'} mt={4} fontSize="12px">
          <Text> {cirurgiasMV?.DS_IDADE} anos </Text>
          <Text> Celular: {cirurgias?.celular_paciente} </Text>
          <Text> CPF: {cirurgias?.cpf} </Text>
          <Text> RG: {cirurgias?.rg} </Text>
          <Text> Email: {cirurgias?.email} </Text>
          <Text> Estado Cívil: {cirurgias?.tp_estado_civil} </Text>
          <Text> CEP: {cirurgias?.nr_cep} </Text>
          <Text> Endereço: {cirurgias?.ds_endereco} </Text>
          <Text> Acompanhante: {cirurgias?.nm_acompanhante} </Text>
          <Text> Convênio: {cirurgias?.nm_convenio} </Text>
          <Text> Token: {cirurgias?.nr_token} </Text>
          <Text> Plano: {cirurgias?.ds_con_pla} </Text>
          <Text> Cirurgia Principal:: {cirurgias?.ds_cirurgia} </Text>
          <Text> Prestador: {cirurgias?.nm_prestador} </Text>
        </VStack>
      </ModalComponent>
    </>
  )
}
