/* eslint-disable */
import api from '../../services/api'

export async function SendSlack(mensagem) {
  if (!mensagem) return false
  return await api
    .post('slack/enviar', {
      message: mensagem,
      url: 'https://hooks.slack.com/services/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',
    })
    .then(() => {
      return true
    })
    .catch(() => {
      return false
    })
}
