import { Box, Button, FormControl, FormLabel, Heading, Input, VStack } from '@chakra-ui/react'
import { useState } from 'react'
import { FiSave } from 'react-icons/fi'
import { CreateFuncoesLogs } from '../../Functions/FuncoesSistemaLog/CreateFuncoesLogs'
import { NotificaError } from '../../Functions/Toast/error'
import { NotificaSuccess } from '../../Functions/Toast/success'
import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api'

export function ModalExamesCorrigirDocumento({ isOpen, handleChangeModalIsOpen, id, nm_paciente }) {
  const [Loading, setLoading] = useState(false)
  const [File, setFile] = useState('')
  const cd_cliente = localStorage.getItem('cd_cliente')

  async function handleAnexarPedidos(e) {
    setLoading(true)
    e.preventDefault()
    const formData = new FormData()
    formData.append('image', File)
    formData.append('id', id)
    const descricao = 'CORREÇÃO PEDIDO ' + nm_paciente + ' ID ' + id
    CreateFuncoesLogs(cd_cliente, descricao, 'EXAMES_CORRECAO_ANEXO').then((result) => {
      api
        .post('pedidos/atualizar', formData, {})
        .then(() => {
          handleChangeModalIsOpen()
          NotificaSuccess('Pedido atualizado com sucesso')
          setLoading(false)
        })
        .catch(() => {
          NotificaError('Erro ao atualizar pedido médico')
          setLoading(false)
        })
    })
  }

  return (
    <ModalComponent isOpen={isOpen} onClose={handleChangeModalIsOpen}>
      <Box marginTop={4}>
        <form onSubmit={handleAnexarPedidos} encType="multipart/form-data">
          <VStack spacing={8} align="left">
            <Heading fontWeight={'bold'} fontSize="sm">
              {nm_paciente}
            </Heading>
            <FormControl isRequired>
              <FormLabel>Anexe o documento</FormLabel>
              <Input type="file" name="arquivo" required="" onChange={(e) => setFile(e.target.files[0])} />
            </FormControl>
            <Button leftIcon={<FiSave />} type="submit" colorScheme={'blue'} w="100%" variant="outline">
              Corrigir documento
            </Button>
          </VStack>
        </form>
      </Box>
    </ModalComponent>
  )
}
