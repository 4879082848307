import { Box, Heading, SimpleGrid, Text } from '@chakra-ui/react'
import React from 'react'

export const LigacoesItem = ({ dt, handleOpen }) => (
  <SimpleGrid marginTop={4} columns={[1, 2, 5]} spacing={8}>
    {dt.medicos.map((dtMedicos, index2) => (
      <Box bg="white" boxShadow="md" p="6" rounded="md" cursor={'pointer'} _hover={{ boxShadow: 'dark-lg' }}>
        <Heading size="sm">{dt.nomes[index2]} </Heading>
        {dtMedicos.slice(0, 50).map((ht) => (
          <Text onClick={() => handleOpen(ht)} marginBottom={4} cursor={'pointer'} _hover={{ fontWeight: 'bold' }}>
            {ht.num_origem}
          </Text>
        ))}
      </Box>
    ))}
  </SimpleGrid>
)
