import { Container } from './styles'
import { FiPrinter } from 'react-icons/fi'
import React from 'react'

interface Action {
  info: Array;
  handleChangeModalIsOpen: () => void;
}

export const PagamentoItem = ({ handleChangeModalIsOpen, info }: Action) => (
  <Container onClick={() => handleChangeModalIsOpen(info)}>
    {info.sn_pago === 'N' ? (
      <p>Pendente</p>
    ) : (
      <FiPrinter size="24" color="gray" />
    )}
    <p>
      Usuário: <b></b> {info.usuario_atendimento}{' '}
    </p>
    <p>
      <b>R$ </b>
      {info.vl_pago},00
    </p>
  </Container>
)
