import api from '../../services/api'

export async function CreatePagamento(
  cd_paciente,
  cd_atendimento,
  cd_ped_rx,
  cd_aviso_cirurgia,
  cd_it_agenda_central,
  cd_prestador,
  sn_retorno,
  nm_convenio,
  ds_con_pla,
  nm_paciente,
  email,
  cpf,
  nm_prestador,
  phone_prestador
) {
  return await api
    .post('pagamentos/add', {
      cd_cliente: localStorage.getItem('cd_cliente'),
      cd_paciente,
      cd_atendimento,
      cd_ped_rx,
      cd_aviso_cirurgia,
      cd_it_agenda_central,
      cd_prestador,
      sn_retorno,
      nm_convenio,
      ds_con_pla,
      nm_paciente,
      email,
      cpf,
      nm_prestador,
      phone_prestador,
      usuario_atendimento: localStorage.getItem('usuario'),
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}
