import { Box, Button, HStack, Icon, SimpleGrid, Text, VStack, useToast } from '@chakra-ui/react'
import React from 'react'
import { FiClipboard, FiCopy, FiSend, FiShield } from 'react-icons/fi'
import { CreateFuncoesLogs } from '../../Functions/FuncoesSistemaLog/CreateFuncoesLogs'
import { SendDireto } from '../../Functions/Mensagens/SendDireto'
import { ButtonPageTemplate } from '../../styles/ButtonPageTemplate'
import { InfoStatusAutorizacao } from '../InfoStatusAutorizacao'

function redirectPage(url) {
  window.open(url, '_blank')
}

function InfoViewCopy({ label, value }) {
  const toast = useToast()

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(function () {
      toast({
        title: 'Copiado com sucesso!',
        description: 'Pressione CTRL+V para colar.',
        status: 'info',
        duration: 1500,
        isClosable: true,
        position: 'top-right',
      })
    })
  }

  return (
    <Box size="xs">
      <Text fontSize="sm" color="gray.500">
        {label}
      </Text>
      <HStack>
        <Text fontSize="sm">{value}</Text>
        {value && <Icon as={FiCopy} onClick={() => copyToClipboard(value)} cursor={'pointer'} />}
      </HStack>
    </Box>
  )
}

function AgendamentoSelecionadoItem({ info, obs, nm_paciente }) {
  const cd_cliente = localStorage.getItem('cd_cliente')
  const toast = useToast()

  let mensagemTelemedicina = `Sr(a) ${nm_paciente}.\n\nSegue abaixo o link de sua tele consulta. \n\n${info.url_reuniao}\n\nNão se preocupe, assim que o Dr(a) iniciar o atendimento você será notificado(a) novamente.`

  function SendMensagemTelemedicina(mensagem, info) {
    SendDireto(`55${info.nr_celular}@c.us`, mensagem, cd_cliente)
  }

  // Abrir o anexo do paciente.
  const handleAnexoPaciente = (data) => {
    const descricao = `Paciente ${nm_paciente}`
    CreateFuncoesLogs(cd_cliente, descricao, 'AGENDAMENTOS_VIEW_ANEXO_PACIENTE').then((res) => {
      if (data) {
        if (data.includes('data:image')) {
          let w = window.open('about:blank')
          let image = new Image()
          image.src = data
          setTimeout(function () {
            w.document.write(image.outerHTML)
          }, 0)
        } else {
          window.open(data, '_blank')
        }
      } else {
        toast({
          title: 'Documento não encontrado',
          description: 'Não foi possível encontrar o documento',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      }
    })
  }

  return (
    <Box fontSize={'12px'}>
      <HStack w="100%" justifyContent="flex-end">
        <InfoStatusAutorizacao
          status={info.status_autorizacao}
          sn_paciente_local={info.sn_paciente_local}
          sn_presenca_confirmada={info.sn_presenca_confirmada}
        />
      </HStack>
      <VStack alignItems={'left'} spacing={1}>
        <HStack spacing={8} w="100%">
          <InfoViewCopy label="Cód." value={info.cd_it_agenda_central} />
          <InfoViewCopy label="Id" value={info.id} />
        </HStack>
        <HStack spacing={8} w="100%">
          <InfoViewCopy label="Celular" value={info.nr_celular} />
          <InfoViewCopy label="Endereço" value={info.endereco} />
        </HStack>

        <HStack spacing={8} w="100%">
          <InfoViewCopy label="Prestador" value={info.nm_prestador} />
          <InfoViewCopy label="Item" value={info.ds_item_agendamento} />
        </HStack>
        <HStack spacing={8} w="100%">
          <InfoViewCopy label="Convênio" value={info.nm_convenio} />
          <InfoViewCopy label="Carteira" value={info.numeroCarteira} />
        </HStack>
        <HStack spacing={8} w="100%">
          <InfoViewCopy label="Telemedicina" value={info.url_reuniao} />
          <ButtonPageTemplate
            icon={<FiSend />}
            onClick={() => SendMensagemTelemedicina(mensagemTelemedicina, info, nm_paciente)}
            variant="link"
          />
        </HStack>
        <InfoViewCopy label="Observação" value={obs} />
      </VStack>

      <SimpleGrid columns={[1, 2, 2, 5]} align="left" justifyContent={'left'} w="100%" py={1}>
        <Button variant="links" onClick={() => handleAnexoPaciente(info.img_base64)} leftIcon={<FiClipboard />}>
          Anexo do Paciente
        </Button>

        {!info.img_autorizacao?.includes('dragendamentofiles.s3') && (
          <Button variant="links" onClick={() => redirectPage(info.img_autorizacao)} leftIcon={<FiShield />}>
            Autorização
          </Button>
        )}
      </SimpleGrid>
    </Box>
  )
}

export { AgendamentoSelecionadoItem }
