// DRA-HIST-15 - 10 anos fazendo do mesmo jeito, eu preciso de uma forma mais fácil para conferir as agendas.
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FiSend } from 'react-icons/fi'
import { CreateLog } from '../../Functions/logs'
import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api'

export function ModalEnvioMensagemManual({ isOpen, onClose, celular, User, scheduleId, mensagem }) {
  const { handleSubmit, register } = useForm()
  const toast = useToast()
  const [protocolos, setProtocolos] = useState(null)

  // Enviar mensagem manualmente
  const handleSendMensagem = (data) => {
    const phone = '55' + celular + '@c.us'
    const mensagem = data.mensagem
    const cd_cliente = User.cd_cliente

    // Prepara os logs para serem inseridos.
    const dataLog = {
      phone: phone,
      cd_cliente: cd_cliente,
      mensagem: mensagem,
      sn_cria_protocolo: data.sn_cria_protocolo,
    }

    // Se criar protocolo, cria o protocolo e envia a mensagem.
    if (data.sn_cria_protocolo === 'S') {
      api
        .post('/api/chat/create/protocolo', {
          phone,
          sn_manual: 'S',
          id_setor: User.sectors.cd_setor,
        })
        .then((res) => {
          api
            .post('mensagens/add', {
              phone: phone,
              cd_cliente: cd_cliente,
              id_protocolo: res.data.id,
              id_cirurgia: '',
              id_agendamento: '',
              id_atendimento: '',
              sn_paciente: 'N',
              mensagem: mensagem,
              nm_usuario: User.name,
            })
            .then(() => {})
            .catch(() => {
              toast({
                title: 'Atenção',
                description: 'Ocorreu um erro ao enviar a mensagem.',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
              })
            })
            .finally(async () => {
              await CreateLog(4, scheduleId, dataLog)
            })

          toast({
            title: 'Protocolo criado com sucesso.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            description: 'Protocolo: ' + res.data.id,
            position: 'top-right',
          })
        })
        .catch((err) => {
          toast({
            title: 'Não foi possível criar o protocolo.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top-right',
          })
        })
      onClose()
      return
    } else {
      // Se não criar protocolo, envia a mensagem.
      api
        .post('mensagens/send/direto', {
          phone: phone,
          mensagem: mensagem,
          cd_cliente: cd_cliente,
        })
        .then(() => {
          toast({
            title: 'Mensagem enviada com sucesso.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top-right',
          })
        })
        .catch(() => {
          toast({
            title: 'Não foi possível enviar a mensagem.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top-right',
          })
        })
        .finally(async () => {
          await CreateLog(4, scheduleId, dataLog)
          onClose()
          return
        })
    }
  }

  useEffect(() => {
    if (!isOpen) return
    if (!celular) return
    if (!User) return

    api
      .get(`/api/protocolos/55${celular}@c.us/${User.cd_cliente}`)
      .then((res) => {
        setProtocolos(res.data)
      })
      .catch((err) => {
        toast({
          title: 'Não foi possível buscar o protocolo.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
  }, [isOpen, celular, User])

  return (
    <ModalComponent isOpen={isOpen} onClose={onClose}>
      <VStack mb={4} w="100%">
        {protocolos?.map((protocolo, index) => (
          <Alert status="error" key={index} w="100%">
            <AlertIcon />
            <AlertTitle>
              Protocolo Existente: {protocolo.id} ({protocolo.nm_setor})
            </AlertTitle>
          </Alert>
        ))}
      </VStack>
      <VStack as="form" onSubmit={handleSubmit(handleSendMensagem)} pb={8}>
        <FormControl isRequired>
          <FormLabel> Celular</FormLabel>
          <Input value={celular} isDisabled />
        </FormControl>
        <FormControl isRequired>
          <FormLabel> Digite a mensagem</FormLabel>
          <Textarea {...register('mensagem')} />
        </FormControl>
        <FormControl isRequired>
          <FormLabel> Criar protocolo?</FormLabel>
          <Select placeholder="Selecione..." {...register('sn_cria_protocolo')}>
            <option value="S">Sim</option>
            <option value="N">Não</option>
          </Select>
        </FormControl>
        <Button colorScheme="blue" type="submit" w="100%" leftIcon={<FiSend />}>
          Enviar
        </Button>
      </VStack>
    </ModalComponent>
  )
}
