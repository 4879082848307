/* eslint-disable */
import { NotificaError } from './../Toast/error'
import api from '../../services/api'

export async function DeleteItemPagamento(sn_pago, id_pagamento, id, vl_item) {
  if (sn_pago === 'N') {
    return await api
      .post('pagamentos/itens/del', { id_pagamento, id, vl_item })
      .then((response) => {
        return response.data
      })
      .catch((err) => {
        return err
      })
  } else {
    NotificaError('Conta Fechada, proibido remover item...')
  }
}
