import {
  Avatar,
  Box,
  Center,
  Flex,
  GridItem,
  HStack,
  IconButton,
  SimpleGrid,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react'
import moment from 'moment'
import { FiCheck, FiEye, FiTrash } from 'react-icons/fi'
import api from '../services/api'

function DocumentCard({ document }) {
  const toast = useToast()

  // if (document.isAtivo === false) return null

  // Inativar documentos anexados no atendimento. 📝 DR-37
  const handleUpdatedocumentoStatus = async (id, status, url) => {
    if (window.confirm('Tem certeza que deseja alterar este documento?')) {
      api
        .put('/aws/documentos/update/' + id, {
          data: {
            isAtivo: !status,
          },
        })
        .then(() => {
          window.open(url, '_blank')
          toast({
            title: 'Documento atualizado com sucesso',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top-right',
          })
        })
        .catch(() => {
          toast({
            title: 'Erro ao atualizar documento',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top-right',
          })
        })
    }
  }

  return (
    <GridItem borderWidth="1px" overflow="hidden" p={2} mb={3} bg="white" boxShadow={'base'} borderRadius={8}>
      <Flex align="center">
        <Avatar size="sm" src={document.userName} name={document.userName} title={document.userName} mr={4} />
        <HStack w="100%" justifyContent={'space-between'}>
          <Box>
            {document.isAtivo ? (
              <Text fontSize="12px" fontWeight="semibold">
                {document.docs_types.description}{' '}
              </Text>
            ) : (
              <Text fontSize="12px" fontWeight="semibold" color="red.500">
                DOCUMENTO INATIVO
              </Text>
            )}

            <Text fontSize="10px">{document.userName}</Text>
          </Box>
          <HStack>
            {document.isAtivo && (
              <IconButton
                size="sm"
                variant="outline"
                aria-label="Abrir Documento"
                icon={<FiEye />}
                onClick={() => window.open(document.url, '_blank')}
              />
            )}
            <IconButton
              size="sm"
              variant="outline"
              aria-label="Inativar documento"
              title={document.isAtivo ? 'Inativar documento' : 'Ativar documento'}
              icon={document.isAtivo ? <FiTrash /> : <FiCheck />}
              onClick={() => {
                handleUpdatedocumentoStatus(document.id, document.isAtivo, document.url)
              }}
            />
          </HStack>
        </HStack>
      </Flex>
    </GridItem>
  )
}

export function ListDocumentsComponent({ documentos, isLoading }) {
  if (!documentos) return null

  // Lentidão na apresentação dos documentos 📝 DRSOL-12
  // Agrupar documentos por data
  const groupedDocuments = documentos.reduce((groups, document) => {
    const date = moment(document.created_at).format('DD/MM/YYYY')
    if (!groups[date]) {
      groups[date] = []
    }
    groups[date].push(document)
    return groups
  }, {})

  return (
    <Box py={2} fontFamily={'Montserrat'}>
      <Center>{isLoading && <Spinner />}</Center>
      {Object.entries(groupedDocuments).map(([date, documentsForDate]) => (
        <Box my={2} key={date}>
          <Box fontWeight="semibold" mb={2}>
            {date}
          </Box>
          <SimpleGrid columns={[1, 1, 4]} spacing={2} mt={2} id="list-documentos">
            {documentsForDate.map((document) => (
              <DocumentCard key={document.id} document={document} />
            ))}
          </SimpleGrid>
        </Box>
      ))}
    </Box>
  )
}
