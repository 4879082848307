import { Button, Heading, Text, useDisclosure, VStack } from '@chakra-ui/react'
import { FaWhatsapp } from 'react-icons/fa'
import { ModalComponent } from '../../_styles/ModalComponent '

export function ModalWhatsAppInformacoes({ User }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button variant="links" onClick={onOpen}>
        <FaWhatsapp color={!User?.clients?.whatsappAtivo ? 'reg' : 'gray'} size="24" title="Status do WhatsApp" />
      </Button>

      <ModalComponent isOpen={isOpen} onClose={onClose} title="Status do WhatsApp">
        <VStack alignItems={'left'} fontSize="12px">
          <Heading size="sm">Status do WhatsApp</Heading>
          <Heading size="xs" color={User?.clients?.whatsappAtivo ? 'green' : 'red'}>
            {User?.clients?.whatsappAtivo ? 'Ativo' : 'Inativo'}
          </Heading>
          <Text>{User?.clients?.phoneWhatsApp}</Text>
        </VStack>
      </ModalComponent>
    </>
  )
}
