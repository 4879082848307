import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  Image,
  List,
  ListItem,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import { parseCookies } from 'nookies'
import React, { useContext, useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { BiMessageDots, BiMessageError, BiRocket } from 'react-icons/bi'
import { FiCalendar, FiExternalLink, FiHome, FiMenu, FiPhoneCall } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'
import { Context as ContextLoading } from '../../Context/LoaderContext'
import { Context as ContextUser } from '../../Context/UserContext'
import { ModalLigacoesPorData } from '../Ligacoes/ModalLigacoesPorData'
import { ModalProfileUser } from '../Users/ModalProfileUser'
import { ModalWhatsAppInformacoes } from '../Users/ModalWhatsAppInformacoes'

const Header = function () {
  const history = useHistory()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { qtdPacientesAguardandoChat } = useContext(ContextLoading)
  const { User } = useContext(ContextUser)
  const [menuOpenLigacoes, setmenuOpenLigacoes] = useState(false)

  const isDoctor = User?.doctors?.cd_prestador === '0' ? false : true

  // Verifica se existe o token e se o usuário está logado
  useEffect(() => {
    const {
      'dragendamento.clienteId': clienteId,
      'dragendamento.token': token,
      'dragendamento.userId': userId,
    } = parseCookies()

    if (!token | !clienteId | !userId) {
      history.push('/')
    }
  }, [])

  // Verifica se o usuário é prestador e se tem o perfil de prestador
  const handleOpenUrlAgendamentoOnline = () => {
    window.location.href = '/agendamentos/online'
  }
  // Abrir a página de pedidos
  const handleOpenUrlPedidos = () => {
    window.location.href = '/pedidos/setor/v2'
  }

  // Redireciona para a página
  const handleRedirect = (page) => {
    window.location.href = '/' + page
  }

  // Todas as rotas do sistema
  const NAV_ITEMS = [
    {
      title: 'Agendamentos',
      url: '/agendamentos/v2/' + new Date().toISOString().slice(0, 10),
    },
    {
      title: 'Cirurgias',
      url: '/cirurgias',
    },
    {
      title: 'Neoh',
      url: '/neoh',
    },
    {
      title: 'Autorizações',
      url: '/autorizacoes',
    },
    {
      title: 'Atendimentos',
      url: '/atendimentos',
    },
    {
      title: 'Atendimentos (TESTE)',
      url: '/atendimentos/v2',
    },
    {
      title: 'Conferência',
      url: '/conferencia',
    },
    {
      title: 'Exames',
      url: '/exames',
    },
    {
      title: 'Faltas',
      url: '/agendamentos/faltas',
    },
    {
      title: 'Laudos',
      url: '/laudos',
    },
    {
      title: 'Pagamentos',
      url: '/pagamentos',
    },
    {
      title: 'Pendências',
      url: '/pendencias',
    },
    {
      title: 'Prontuarios',
      url: '/agendamentos/solicitacoes',
    },
    {
      title: 'Usuários',
      url: '/admin',
    },
  ]

  return (
    <Flex
      position="initial"
      w="100%"
      borderBottom={1}
      align="center"
      justifyContent="space-between"
      bg={'white'}
      px={8}
    >
      <Image src="/logo.png" w="90" h="90" />

      <HStack isInline spacing={1} py={2}>
        {isDoctor && (
          <Button variant="links">
            <FiCalendar
              onClick={() => handleOpenUrlAgendamentoOnline('agendamentos/online')}
              size="24"
              title="Minha agenda"
              id="btnMinhaAgenda"
            />
          </Button>
        )}

        {isDoctor && (
          <Button bg={'none'} id="buttonGoToExames" variant="links">
            <FiHome onClick={() => handleOpenUrlPedidos('pedidos/setor/v2')} size="24" title="Exames" />
          </Button>
        )}

        <ModalProfileUser User={User} />
        <ModalWhatsAppInformacoes User={User} />

        {!isDoctor && (
          <Button variant="links" onClick={onOpen}>
            <BiMessageDots size="24" title="Existem mensagens pendentes.." color={'orange'} bg="orange.500" />
          </Button>
        )}

        <Button variant="links" id="button-menu">
          <FiMenu onClick={onOpen} size="24" title="Abrir Menu de Navegação" />
        </Button>

        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />

            <DrawerBody fontFamily={'Montserrat'}>
              <VStack py={4} spacing={1}>
                <HStack>
                  <Icon as={BiRocket} />
                  <Text size="xs">v2023.10-18</Text>
                </HStack>
                <Text fontSize={'10px'}>Atualizado em 18.10.2023 06:00</Text>
              </VStack>
              <List>
                <ListItem>
                  <Button
                    variant={'link'}
                    size="sm"
                    leftIcon={<AiOutlinePlus color="orange" />}
                    onClick={() => handleRedirect('painel/agendamentos')}
                  >
                    Painel de Agendamentos
                  </Button>

                  <Button variant={'link'} size="sm" leftIcon={<FiHome />} onClick={() => handleRedirect('dashboard')}>
                    Dashboard
                  </Button>
                </ListItem>
                <ListItem>
                  <Button
                    variant={'link'}
                    size="sm"
                    leftIcon={<BiMessageError />}
                    onClick={() => handleRedirect('chat')}
                  >
                    Chat ({qtdPacientesAguardandoChat || 0} )
                  </Button>
                </ListItem>
                <ListItem>
                  <Button
                    variant={'link'}
                    size="sm"
                    leftIcon={<FiPhoneCall />}
                    onClick={() => setmenuOpenLigacoes(true)}
                  >
                    Ligações
                  </Button>
                </ListItem>
                {NAV_ITEMS.map((navItem, index) => (
                  <ListItem key={index}>
                    <Button
                      variant={'link'}
                      onClick={() => history.push(navItem.url)}
                      leftIcon={<FiExternalLink />}
                      size="sm"
                    >
                      {navItem.title}
                    </Button>
                  </ListItem>
                ))}
              </List>
            </DrawerBody>
            <DrawerFooter>
              <HStack w="100%" justifyContent={'flex-end'}>
                <Button colorScheme="red" onClick={() => history.push('sair')}>
                  Sair
                </Button>
              </HStack>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </HStack>

      <ModalLigacoesPorData isOpen={menuOpenLigacoes} handleChangeModalIsOpen={() => setmenuOpenLigacoes(false)} />
    </Flex>
  )
}

export default React.memo(Header)
