import { Button, FormControl, FormLabel, VStack, useToast } from '@chakra-ui/react'
import { Fragment, useContext, useState } from 'react'
import { FiFilePlus, FiPlusCircle } from 'react-icons/fi'
import { InputCelular } from '../../Components/Inputs/InputCelular'
import { Context as ContextUser } from '../../Context/UserContext'
import { CreateFuncoesLogs } from '../../Functions/FuncoesSistemaLog/CreateFuncoesLogs'
import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api'

export function ProtocoloManual({ chatArray, setChat }) {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [numeroCelular, setnumeroCelular] = useState('')
  const cd_cliente = localStorage.getItem('cd_cliente')
  const { setLoading, Loading, User } = useContext(ContextUser)
  const toast = useToast()

  const handleCloseModal = () => {
    setModalIsOpen(false)
  }

  async function handleProtocoloManual(e) {
    e.preventDefault()

    if (!User.sectors?.cd_setor) {
      toast({
        title: 'Você não tem setor associado!',
        status: 'warning',
        duration: 3000,
        position: 'top-right',
        isClosable: true,
      })
      return
    }

    setLoading(true)

    const descricao = 'Protocolo Manual ' + numeroCelular
    CreateFuncoesLogs(cd_cliente, descricao, 'PROTOCOLO_MANUAL').then(() => {
      api
        .post('api/chat/create/protocolo', {
          phone: '55' + numeroCelular + '@c.us',
          sn_manual: 'S',
          id_setor: User.sectors?.cd_setor,
        })
        .then((res) => {
          const data = {
            id: res.data.id,
            id_contato: res.data.Contato[0].id,
            id_setor: User.sectors?.cd_setor,
            nm_usuario_atendimento: null,
            nome: res.data.Contato[0].nome,
            phone: res.data.Contato[0].phone,
            sn_atendido: 'N',
            sn_manual: 'S',
            sn_pendente: 'S',
            ultimo_envio: 'H',
          }
          setChat([...chatArray, data])
          handleCloseModal()
          toast({
            title: `Protocolo ${res.data.id} criado com sucesso!`,
            status: 'success',
            duration: 3000,
            position: 'top-right',
            isClosable: true,
          })
        })
        .catch(() => {
          toast({
            title: 'Erro ao criar protocolo!',
            status: 'error',
            duration: 3000,
            position: 'top-right',
            isClosable: true,
          })
        })
        .finally(() => {
          setLoading(false)
        })
    })
  }

  return (
    <Fragment>
      <Button variant="links" onClick={() => setModalIsOpen(true)}>
        <FiFilePlus />
      </Button>

      <ModalComponent
        isOpen={modalIsOpen}
        onClose={() => handleCloseModal(false)}
        title="Criar Protocolo"
        buttonLabel="Criar Protocolo"
        buttonView={false}
        leftIcon={<FiPlusCircle />}
      >
        <VStack>
          <form onSubmit={handleProtocoloManual}>
            <VStack spacing={4}>
              <FormControl id="tel" isRequired>
                <FormLabel>Digite o celular com DDD</FormLabel>
                <InputCelular handleSetNumber={setnumeroCelular} />
              </FormControl>

              <Button type="submit" variant="primary" w="100%" leftIcon={<FiPlusCircle />} isLoading={Loading}>
                Criar Protocolo
              </Button>
            </VStack>
          </form>
        </VStack>
      </ModalComponent>
    </Fragment>
  )
}
