import { Box, Button, HStack, Icon, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { FiClock, FiCommand, FiEye, FiSave } from 'react-icons/fi'
import ConferenciaValidaAgendado from '../../Components/ConferenciaAgendamentoOnline/ConferenciaValidaAgendado'
import ConferenciaValidaConferidos from '../../Components/ConferenciaAgendamentoOnline/ConferenciaValidaConferidos'
import ConferenciaValidaImagem from '../../Components/ConferenciaAgendamentoOnline/ConferenciaValidaImagem'
import { UpdateDocumentoNoAgendamento } from '../../Functions/Conferencia/UpdateDocumentoNoAgendamento'

const ConferenciaAgendamentoOnlineItem = React.memo(function ConferenciaAgendamentoOnlineItem({
  info,
  handleViewAgendamento,
  handleConferencia,
}) {
  return (
    <Box p={4} bg="white" boxShadow="md" rounded="md">
      <HStack justifyContent={'space-between'}>
        <ConferenciaValidaAgendado info={info} />
        <ConferenciaValidaConferidos info={info} />
        <ConferenciaValidaImagem info={info} />

        {info.url_documento ? (
          <FiSave
            size={20}
            title="Salvar imagem no agendamento do paciente"
            onClick={() => UpdateDocumentoNoAgendamento(info.cd_it_agenda_central, info.url_documento)}
          />
        ) : (
          ''
        )}
        {info.cd_it_agenda_central ? (
          <FiEye size={20} onClick={() => handleViewAgendamento(info.cd_it_agenda_central)} />
        ) : (
          ''
        )}
      </HStack>
      <VStack alignItems={'left'} my={4}>
        <Text fontWeight={'bold'}>{info?.nm_paciente.toUpperCase()}</Text>
        <Text>{info.nr_celular}</Text>
        <Text>{info.ds_item_agendamento}</Text>

        <HStack>
          <Icon as={FiClock} size={20} />
          <Text>
            {info.dt_agendamento} -{info.hr_agendamento}
          </Text>
        </HStack>

        {info.sn_conferido != 'S' ? (
          <Button onClick={() => handleConferencia(info)} leftIcon={<FiCommand />} colorScheme="blue">
            Editar
          </Button>
        ) : (
          ''
        )}
      </VStack>
    </Box>
  )
})
export { ConferenciaAgendamentoOnlineItem }
