/* eslint-disable */
import { NotificaError } from '../Toast/error'
import { NotificaSuccess } from '../Toast/success'
import api from '../../services/api'

export async function UpdateDocumentoNoAgendamento(
  cd_it_agenda_central,
  url_aws
) {
  return await api
    .post('agendamentos/update/conferencia', { cd_it_agenda_central, url_aws })
    .then((response) => {
      NotificaSuccess('Imagem vinculada com sucesso ao agendamento')
      return response.data
    })
    .catch((err) => {
      NotificaError('Já existe imagem no agendamento... ')
      return err
    })
}
