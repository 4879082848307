import { Button, useToast } from '@chakra-ui/react'
import React from 'react'

const CopyButton = ({ content, title }) => {
  const toast = useToast()
  const copyToClipboard = () => {
    navigator.clipboard.writeText(content)
    toast({
      title: 'Copiado',
      description: 'Conteudo copiado para a área de transferência.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    })
  }

  return (
    <Button onClick={copyToClipboard} variant="links">
      {title}
    </Button>
  )
}

export { CopyButton }
