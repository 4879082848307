import { Center, Input, Spinner, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import api from '../../services/api'
import { ContainerPageTemplate } from '../../styles/ContainerPageTemplate'

const DoutoresExames = function () {
  const [data, setData] = useState(new Date().toISOString().slice(0, 10))
  const [exames, setExames] = useState([])
  const toast = useToast()
  const [isLoading, setisLoading] = useState(false)

  /* Consultar todos os exames por data! */
  useEffect(() => {
    if (!data) return
    setisLoading(true)
    api
      .get(`/v2/pedidos/setor/${data}`)
      .then((response) => {
        console.log(response.data)
        setExames(response.data)
      })
      .catch(() => {
        toast({
          title: 'Erro ao carregar os exames.',
          description: 'Tente novamente mais tarde.',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        })
        setExames([])
      })
      .finally(() => {
        setisLoading(false)
      })
  }, [data])

  return (
    <ContainerPageTemplate>
      <Input type="date" value={data} onChange={(e) => setData(e.target.value)} />
      <Center minH={10}>{isLoading && <Spinner />}</Center>
      <Text>{exames.length}</Text>
    </ContainerPageTemplate>
  )
}

export { DoutoresExames }
