import {
  Button,
  HStack,
  Icon,
  ListItem,
  OrderedList,
  Select,
  Spinner,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FiCircle, FiExternalLink } from 'react-icons/fi'
import { GrRobot } from 'react-icons/gr'
import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api'

// Componente para configurar as intencoes do assistente virtual do cliente;
export function ModalIntencoesAssistenteVirtual() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)
  const [intencoesCliente, setIntencoesCliente] = useState([])
  const [isOpenModalIntencoes, setisOpenModalIntencoes] = useState(false)
  const [intencaoSelecionada, setintencaoSelecionada] = useState(null)
  const toast = useToast()
  const [setores, setSetores] = useState([])

  useEffect(() => {
    // Carregar as intencoes do assistente virtual do cliente apenas quando aberto!
    if (!isOpen) return

    setIsLoading(true)

    api
      .get('api/v2/intencoes')
      .then((response) => {
        setIntencoesCliente(response.data)
      })
      .catch((error) => {
        toast({
          title: 'Erro ao carregar as intenções do assistente virtual do cliente!',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
        console.log(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [isOpen])

  // Carregar os setores para o select
  useEffect(() => {
    api
      .get('api/v2/sectors')
      .then((response) => {
        setSetores(response.data)
      })
      .catch((error) => {
        toast({
          title: 'Erro ao carregar os setores!',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
  }, [])

  // Abrir o modal para apresentar as possiveis perguntas do paciente e incrementar novas
  const handleOpenIntencao = (intencao) => {
    console.log(intencao)
    setisOpenModalIntencoes(true)
    setintencaoSelecionada(intencao)
  }

  // Salvar as intencoes do assistente virtual do cliente;
  const handleSave = (id, element, value) => {
    const dataUpdated = {
      [element]: value,
    }

    api
      .put(`api/v2/intencoes/${id}`, {
        dataUpdated,
      })
      .then(() => {
        // Atualizar o estado da intencao;
        const intencoes = intencoesCliente.map((intencao) => {
          if (intencao.id === id) {
            return {
              ...intencao,
              ...dataUpdated,
            }
          }
          return intencao
        })

        setIntencoesCliente(intencoes)

        toast({
          title: 'Sucesso!',
          description: 'Intenção atualizada com sucesso!',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
      .catch((error) => {
        toast({
          title: 'Erro ao atualizar a intenção!',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
  }

  return (
    <>
      {/* Modal para configurar o assistente virtual do cliente  */}
      <ModalComponent isOpen={isOpen} onClose={() => onClose()} title="Assistente Virtual">
        <HStack w="100%" justifyContent={'space-between'}>
          <Button
            size="xs"
            leftIcon={<FiExternalLink />}
            onClick={() => window.open('https://api.whatsapp.com/send?phone=551197836-0521&text=Oi', '_blank')}
          >
            Testar o Assistente Virtual
          </Button>
          <Tag colorScheme="red">Em testes</Tag>
        </HStack>

        <HStack w="100%" justifyContent={'center'}>
          {isLoading && <Spinner />}
        </HStack>

        {!isLoading && (
          <Table>
            <Thead>
              <Tr>
                <Th>Intenção</Th>
                <Th>Cria Protocolo</Th>
                <Th>Setor</Th>
                <Th>Resposta</Th>
                <Th>Responde com Protocolo aberto</Th>
              </Tr>
            </Thead>
            <Tbody>
              {intencoesCliente.map((intencao) => (
                <Tr key={intencao.id}>
                  <Td>
                    <Button variant="link" onClick={() => handleOpenIntencao(intencao.intencoes)}>
                      {intencao.intencoes.intencao}
                    </Button>
                  </Td>
                  <Td>
                    <Icon
                      cursor={'pointer'}
                      as={FiCircle}
                      color={intencao.isCriaProtocolo ? 'green' : 'red'}
                      onClick={() => handleSave(intencao.id, 'isCriaProtocolo', !intencao.isCriaProtocolo)}
                    />{' '}
                    {intencao.isCriaProtocolo}
                  </Td>

                  <Td>
                    {/* Insira um select, caso exista intencao.sectorId deixe o selecionado*/}
                    <Select size="xs" onChange={(e) => handleSave(intencao.id, 'sectorId', e.target.value)}>
                      <option value="">Sem setor</option>
                      {setores.map((setor) => (
                        <option key={setor.id} value={setor.id} selected={intencao.sectorId === setor.id}>
                          {setor.nm_setor}
                        </option>
                      ))}
                    </Select>
                  </Td>

                  <Td fontSize={'xs'}>{intencao.resposta}</Td>
                  <Td>
                    <Icon
                      as={FiCircle}
                      color={intencao.isRespondeProtocolo ? 'green' : 'red'}
                      cursor={'pointer'}
                      onClick={() => handleSave(intencao.id, 'isRespondeProtocolo', !intencao.isRespondeProtocolo)}
                    />
                    {intencao.isRespondeProtocolo}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </ModalComponent>

      {/* Modal para apresentar a intenção selecionada */}
      <ModalComponent
        isOpen={isOpenModalIntencoes}
        onClose={() => setisOpenModalIntencoes(false)}
        title={intencaoSelecionada?.intencao?.toUpperCase()}
        size="xl"
      >
        {intencaoSelecionada && (
          <VStack spacing={2} p={4} w="100%">
            <Text>Exemplos de Perguntas:</Text>
            <OrderedList>
              {intencaoSelecionada.utterances.map((utterance) => (
                <ListItem>{utterance}</ListItem>
              ))}
            </OrderedList>
          </VStack>
        )}
      </ModalComponent>

      <Button onClick={onOpen} leftIcon={<GrRobot />} size="xs" variant="unstyled">
        Assistente Virtual
      </Button>
    </>
  )
}
