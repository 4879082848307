import { Button, FormControl, FormLabel, HStack, Text, Textarea, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FiSave } from 'react-icons/fi'
import { UpdatePainelAgendamentos } from '../../Functions/Conferencia/UpdatePainelAgendamentos'
import { ModalComponent } from '../../_styles/ModalComponent '

function ButtonTemplate({ title, icon, click, color }) {
  return (
    <Button colorScheme={color} size="sm" variant="outline" leftIcon={icon} onClick={click}>
      {title}
    </Button>
  )
}

export function ModalConferenciaConfirmarAgendamento({ isOpen, handleChangeModalIsOpen, id }) {
  const [sn_contato_call_center, setsn_contato_call_center] = useState('')
  const [sn_conferido, setsn_conferido] = useState('')
  const [sn_agendado, setsn_agendado] = useState('')
  const [Motivo, setMotivo] = useState([])

  const handleSalvarPainel = () => {
    UpdatePainelAgendamentos(id, sn_agendado, sn_contato_call_center, sn_conferido, Motivo).then(() => {
      handleChangeModalIsOpen()
    })
  }

  useEffect(() => {
    if (!isOpen) {
      setsn_contato_call_center('')
      setsn_conferido('')
      setsn_agendado('')
      setMotivo('')
    }
  }, [isOpen])

  return (
    <ModalComponent isOpen={isOpen} onClose={handleChangeModalIsOpen} title="Conferência">
      <VStack spacing={8} alignItems="left">
        {!sn_contato_call_center ? (
          <HStack justifyContent={'space-between'}>
            <Text>Você entrou em contato com o paciente? </Text>

            <HStack justifyContent={'space-between'}>
              <ButtonTemplate title="Sim" icon="" click={() => setsn_contato_call_center('S')} color="green" />

              <ButtonTemplate title="Não" icon="" click={() => setsn_contato_call_center('N')} color="red" />
            </HStack>
          </HStack>
        ) : (
          ''
        )}

        {!sn_agendado ? (
          <HStack justifyContent={'space-between'}>
            <Text>Você fez o agendamento do paciente? </Text>
            <HStack>
              <ButtonTemplate title="Sim" icon="" click={() => setsn_agendado('S')} color="green" />
              <ButtonTemplate title="Não" icon="" click={() => setsn_agendado('N')} color="red" />
            </HStack>
          </HStack>
        ) : (
          ''
        )}

        {!sn_conferido ? (
          <HStack justifyContent={'space-between'}>
            <Text>Deseja marcar como conferido esse contato? </Text>
            <HStack justifyContent={'space-between'}>
              <ButtonTemplate title="Sim" icon="" click={() => setsn_conferido('S')} color="green" />
              <ButtonTemplate title="Não" icon="" click={() => setsn_conferido('N')} color="red" />
            </HStack>
          </HStack>
        ) : (
          ''
        )}

        {sn_conferido && (
          <FormControl isRequired>
            <FormLabel>Descreva detalhadamente se o paciente teve dificuldades.</FormLabel>
            <Textarea required="" onChange={(e) => setMotivo(e.target.value)} />
          </FormControl>
        )}

        {Motivo.length > 1 && (
          <Button onClick={() => handleSalvarPainel()} colorScheme="blue" leftIcon={<FiSave />}>
            Salvar{' '}
          </Button>
        )}
      </VStack>
    </ModalComponent>
  )
}
