/* eslint-disable */
import api from '../../services/api'

export async function FindLigacoesPorData(sn_pendente, data, ramal, sn_gestor) {
  return await api
    .post('ligacoes/status', {
      sn_pendente,
      data,
      ramal,
      sn_gestor,
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}
