import { HStack, Text, useToast } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { FiFilter } from 'react-icons/fi'
import { CirurgiasItem } from '../../Components/Cirurgias/CirurgiasItem'
import { ModalCirurgiaSelecionada } from '../../Components/Cirurgias/ModalCirurgiaSelecionada'
import { Context as ContextLoading } from '../../Context/LoaderContext'
import { Context as ContextUser } from '../../Context/UserContext'
import api from '../../services/api'
import { ButtonPageTemplate } from '../../styles/ButtonPageTemplate'
import { ContainerPageTemplate } from '../../styles/ContainerPageTemplate'
import { GridPageTemplate } from '../../styles/GridTemplate'

const Cirurgias = function () {
  const { cd_cliente } = useContext(ContextLoading)
  const { data, setLoading, Loading } = useContext(ContextUser)
  const [agendamentos, setagendamentos] = useState([])
  const [cd_aviso_cirurgia, setcd_aviso_cirurgia] = useState('')
  const [orderBy, setorderBy] = useState('HORA')
  const [modalCirurgiaSelecionada, setmodalCirurgiaSelecionada] = useState(false)
  const toast = useToast()

  // Carrega as cirurgias por data
  useEffect(() => {
    const ArrayCodigosAgendamentosMV = []
    setLoading(true)
    api
      .post('cirurgias/data/mv', { cd_cliente, data })
      .then((response) => {
        // map() dos avisos de cirurgia
        response.data.map((cirurgia) => {
          ArrayCodigosAgendamentosMV.push(cirurgia.CD_AVISO_CIRURGIA)
        })

        // Seta o array de agendamentos no localStorage
        localStorage.setItem('list_cirurgias', ArrayCodigosAgendamentosMV)

        // Filtra as cirurgias que não foram canceladas
        const cirurgiasAtivas = response.data.filter((d) => d.TP_SITUACAO !== 'C')

        // Se não existir agendamentos, seta o array de agendamentos como vazio
        if (response.data.length === 0) {
          setagendamentos([])
          setLoading(false)
        }

        let infos = []
        // Ordena as cirurgias por hora
        if (orderBy === 'HORA') {
          if (cirurgiasAtivas.length > 0) {
            infos = cirurgiasAtivas.sort(function (a, b) {
              return a.HORA.localeCompare(b.HORA)
            })
          } else {
            return cirurgiasAtivas
          }
        }

        // Ordena as cirurgias por paciente
        if (orderBy === 'NM_PACIENTE') {
          if (cirurgiasAtivas.length > 0) {
            infos = cirurgiasAtivas.sort(function (a, b) {
              return a.NM_PACIENTE.localeCompare(b.NM_PACIENTE)
            })
          } else {
            return cirurgiasAtivas
          }
        }
        setagendamentos(infos)
        setLoading(false)
      })
      .catch(() => {
        setagendamentos([])
        setLoading(false)
        toast({
          title: 'Cirurgias.',
          description: `As cirurgias do dia ${data} não puderam ser carregadas.`,
          position: 'top',
          status: 'error',
          duration: 4500,
          isClosable: true,
        })
      })
  }, [data, orderBy])

  // Altera o status do modal de cirurgia selecionada
  const handleChangeModalIsOpen = (data) => {
    if (data) {
      setcd_aviso_cirurgia(data)
    }
    setmodalCirurgiaSelecionada(!modalCirurgiaSelecionada)
  }

  return (
    <>
      <ContainerPageTemplate isLoading={Loading} isVisibleInputDate>
        {/* Botoões da página  */}
        <HStack justifyContent={'flex-end'} w="100%" spacing={4} py={8}>
          <Text color="gray">{agendamentos.length} agendamentos</Text>

          {orderBy === 'HORA' ? (
            <ButtonPageTemplate
              onClick={() => setorderBy('NM_PACIENTE')}
              color="gray"
              icon={<FiFilter />}
              title="Por paciente"
              variant="link"
            />
          ) : (
            <ButtonPageTemplate
              onClick={() => setorderBy('HORA')}
              color="gray"
              icon={<FiFilter />}
              title="Por hora"
              variant="link"
            />
          )}
        </HStack>

        <GridPageTemplate>
          {agendamentos.map((agendamento) => (
            <CirurgiasItem
              nm_paciente={agendamento.NM_PACIENTE}
              cd_aviso_cirurgia={agendamento.CD_AVISO_CIRURGIA}
              ds_tip_acom={agendamento.DS_TIP_ACOM}
              nm_prestador={agendamento.NM_PRESTADOR}
              ds_idade={agendamento.DS_IDADE}
              ds_cirurgia={agendamento.DS_CIRURGIA}
              hora={agendamento.HORA}
              obs_guia={agendamento.OBS_GUIA}
              nm_convenio={agendamento.NM_CONVENIO}
              handleChangeModalIsOpen={handleChangeModalIsOpen}
            />
          ))}
        </GridPageTemplate>
      </ContainerPageTemplate>
      <ModalCirurgiaSelecionada
        isOpen={modalCirurgiaSelecionada}
        handleChangeModalIsOpen={handleChangeModalIsOpen}
        cd_aviso_cirurgia={cd_aviso_cirurgia}
      />
    </>
  )
}

export default React.memo(Cirurgias)
