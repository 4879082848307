import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Tag,
  TagLabel,
  TagRightIcon,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FiClock, FiImage } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'
import { ModalComponent } from '../../_styles/ModalComponent '
import { CreateFuncoesLogs } from '../../Functions/FuncoesSistemaLog/CreateFuncoesLogs'
import { OpenIframe } from '../../Functions/Others/OpenIframe'
import { FindPedidosAtendimento } from '../../Functions/Pedidos/FindPedidosAtendimento'
import { NotificaSuccess } from '../../Functions/Toast/success'
import api from '../../services/api'
import { LoadingModal } from '../LoadingModal'
import { LaudosLaudar } from './LaudosLaudar'
import { ModalExamesCorrigirDocumento } from './ModalExamesCorrigirDocumento'

export function ModalExameSelecionado({
  isOpen,
  handleChangeModalIsOpen,
  info,
  cd_cliente,
  cd_atendimento,
  nm_paciente,
  cd_ped_rx,
  hr_liberacao,
}) {
  const [Loading, setLoading] = useState(false)
  const [modalCorrigirDocumento, setmodalCorrigirDocumento] = useState(false)
  const [modalLaudar, setmodalLaudar] = useState(false)
  const [atendimento, setatendimento] = useState([])
  const [File, setFile] = useState('')
  const history = useHistory()

  useEffect(() => {
    if (isOpen) {
      setLoading(true)
      FindPedidosAtendimento(cd_cliente, cd_ped_rx)
        .then((res) => {
          setatendimento(res)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }, [isOpen, modalCorrigirDocumento, modalLaudar])

  const handleOpenURL = (url) => {
    OpenIframe(url)
  }

  // Anexar documento no pedido médico!
  async function handleAnexarPedidos(e) {
    setLoading(true)
    e.preventDefault()
    var today = new Date()
    var h = today.getHours()
    var m = (today.getMinutes() < 10 ? '0' : '') + today.getMinutes()
    var hora = h + ':' + m
    const formData = new FormData()
    formData.append('image', File)
    formData.append('cd_cliente', localStorage.getItem('cd_cliente'))
    formData.append('status', 'A')
    formData.append('hr_liberacao', hora)
    formData.append('cd_atendimento', info.CD_ATENDIMENTO)
    formData.append('cd_ped_rx', info.CD_PED_RX)
    formData.append('cd_prestador', info.CD_PRESTADOR)
    formData.append('dt_atendimento', info.DT_ATENDIMENTO)
    formData.append('cd_set_exa', info.CD_SET_EXA)
    formData.append('nm_set_exa', info.NM_SET_EXA)
    formData.append('cd_convenio', info.CD_CONVENIO)
    formData.append('nm_convenio', info.NM_CONVENIO)
    formData.append('nm_paciente', info.NM_PACIENTE)
    formData.append('nm_prestador', info.NM_PRESTADOR)
    formData.append('exames', info.EXAMES)
    formData.append('phone_paciente', info.PHONE_PACIENTE)

    api
      .post('atendimentos/exames/add', formData, {})
      .then(() => {
        NotificaSuccess('Paciente liberado com sucesso')
      })
      .catch((err) => {
        const descricao = 'ANEXO PACIENTE ' + info.NM_PACIENTE + ' atendimento ' + info.cd_atendimento
        CreateFuncoesLogs(cd_cliente, descricao, 'ANEXO_PEDIDO_EXAME').then((res) => {
          handleChangeModalIsOpen()
        })
      })
      .finally(() => {
        setLoading(true)
      })
  }

  function handleAlterarDocumento() {
    setmodalCorrigirDocumento(true)
    handleChangeModalIsOpen()
  }

  return (
    <>
      <ModalComponent isOpen={isOpen} onClose={handleChangeModalIsOpen} title="Exame Selecionado">
        <Flex align={'center'} justifyContent="space-between">
          <VStack align={'left'} spacing={'1px'}>
            <Heading fontWeight={'bold'} fontSize="sm">
              {nm_paciente}
            </Heading>
            <Text fontSize={'12px'}>Atendimento {cd_atendimento}</Text>
            <Text fontSize={'12px'}>Pedido: {cd_ped_rx}</Text>
            {info?.NM_PRESTADOR && <Text fontSize={'12px'}>Prestador: {info.NM_PRESTADOR}</Text>}
          </VStack>

          {hr_liberacao ? (
            <Tag size={'sm'} key={'sm'} variant="outline" colorScheme="green">
              <TagLabel>{hr_liberacao}</TagLabel>
              <TagRightIcon as={FiClock} />
            </Tag>
          ) : (
            <Tag size={'sm'} key={'sm'} variant="outline" colorScheme="red">
              <TagLabel>Pendente</TagLabel>
              <TagRightIcon as={FiClock} />
            </Tag>
          )}
        </Flex>

        {info.id ? (
          <HStack justifyContent={'flex-end'}>
            <Button colorScheme="blue" size="xs" variant="outline" onClick={() => handleAlterarDocumento()}>
              Alterar Documento
            </Button>
            {info.sn_laudado === 'N' ? (
              <Button colorScheme="blue" size="xs" variant="outline" onClick={() => setmodalLaudar(true)}>
                Laudar
              </Button>
            ) : (
              ''
            )}
          </HStack>
        ) : (
          ''
        )}

        <HStack spacing={2}>
          {atendimento.length >= 1 ? (
            <VStack spacing={2} w="100%">
              {atendimento.map((exame) => (
                <Flex
                  onClick={() => handleOpenURL(exame.img_pedido)}
                  w="100%"
                  boxShadow="md"
                  align={'center'}
                  spacing={2}
                  p="6"
                  cursor={'pointer'}
                  _hover={{ boxShadow: 'dark-lg' }}
                  marginTop={4}
                >
                  <FiImage size={'32'} />

                  <Text>{exame.exames}</Text>
                </Flex>
              ))}

              {atendimento.map((exame) => (
                <Box
                  bg="white"
                  boxShadow="md"
                  p="6"
                  w="100%"
                  rounded="md"
                  cursor={'pointer'}
                  _hover={{ boxShadow: 'dark-lg' }}
                  marginTop={4}
                  onClick={() => handleOpenURL(exame.url_laudo)}
                >
                  {!exame.url_laudo ? <FiImage size={'32'} color="red" /> : <FiImage size={'32'} />}
                  {'Laudo: ' + exame.nm_set_exa}
                </Box>
              ))}
            </VStack>
          ) : (
            <Box marginTop={2} w="100%">
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>Atenção</AlertTitle>
                <AlertDescription>
                  A liberação de exame por aqui não está disponível, para liberar o exame clique no link a seguir
                  <Button
                    variant="icon"
                    onClick={() => {
                      history.push('/atendimentos/v2')
                    }}
                    title="Liberar exames"
                  >
                    Clique aqui para liberar
                  </Button>
                </AlertDescription>
              </Alert>
              {/* <form onSubmit={handleAnexarPedidos} encType="multipart/form-data">
                <VStack spacing={4}>
                  <Input type="file" name="arquivo" required="" onChange={(e) => setFile(e.target.files[0])} />
                  <Button leftIcon={<FiSave />} type="submit" colorScheme={'blue'} w="100%" variant="outline">
                    SALVAR
                  </Button>
                </VStack>
              </form> */}
            </Box>
          )}
        </HStack>

        {/* Modal de carregamento */}
        {Loading && <LoadingModal isLoading={Loading} />}
      </ModalComponent>
      <ModalExamesCorrigirDocumento
        id={info.id}
        nm_paciente={nm_paciente}
        isOpen={modalCorrigirDocumento}
        handleChangeModalIsOpen={() => setmodalCorrigirDocumento(false)}
      />
      <LaudosLaudar isOpen={modalLaudar} handleChangeModalIsOpen={() => setmodalLaudar(false)} info={info} />
    </>
  )
}
