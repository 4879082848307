import { Input } from '@chakra-ui/react'

export function InputCelular({ handleSetNumber }) {
  const FormatarCelular = (data) => {
    var NumeroFormatado = data.replace(/\D+/g, '')
    handleSetNumber(NumeroFormatado)
  }

  return <Input placeholder="(00) 00000-0000" onChange={(e) => FormatarCelular(e.target.value)} required />
}
