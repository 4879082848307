import md5 from 'md5'
import { createContext, useEffect, useState } from 'react'

const Context = createContext()

const AgendamentoProvider = function ({ children }) {
  const [cd_it_agenda_central, setcd_it_agenda_central] = useState('')
  const [agendamentos, setAgendamentos] = useState([])
  const arrayCodigosAgendamentosMV = []
  const arrayCodigosAgendamentosMVID = []

  useEffect(() => {
    agendamentos.map((agendamento) => {
      if (agendamento?.CD_IT_AGENDA_CENTRAL) {
        arrayCodigosAgendamentosMV.push(agendamento.CD_IT_AGENDA_CENTRAL)

        arrayCodigosAgendamentosMVID.push(
          md5(md5(agendamento.CD_IT_AGENDA_CENTRAL + process.env.REACT_APP_APP_CRIPTO_ID_AGENDAMENTO))
        )
        localStorage.setItem('list_itens', arrayCodigosAgendamentosMV)
        localStorage.setItem('list_itens_ids', arrayCodigosAgendamentosMVID)
      }
    })
  }, [agendamentos])

  return (
    <Context.Provider
      value={{
        cd_it_agenda_central,
        setcd_it_agenda_central,
        agendamentos,
        setAgendamentos,
        arrayCodigosAgendamentosMV,
        arrayCodigosAgendamentosMVID,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export { Context, AgendamentoProvider }
