import { Avatar, HStack, Icon, Tag, TagLabel, Td, Text, Tr, useMediaQuery } from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'
import { BiCalendar, BiChat, BiHealth, BiMessageAdd } from 'react-icons/bi'
import { CgFileDocument } from 'react-icons/cg'
import { FaRegHandPointUp } from 'react-icons/fa'
import { RiTimeLine } from 'react-icons/ri'

export function ChatItem({ info, handleOpenChatID, ViewdocumentosRecebidos, id }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  return (
    <Tr _hover={{ bg: 'gray.100' }} mt={isMobile ? '4' : '0'} key={id}>
      <Td>
        <HStack spacing={2}>
          <Avatar name={info.nome} size="sm" />
          {!isMobile && <Text>{info.nome}</Text>}
        </HStack>
      </Td>

      <Td>
        {info.nm_usuario_atendimento?.length > 0 && (
          <Tag size="sm" borderRadius="full" bg="none">
            <Avatar size="sm" name={info.nm_usuario_atendimento} ml={-1} mr={2} />
            {!isMobile && <TagLabel>{info.nm_usuario_atendimento}</TagLabel>}
          </Tag>
        )}
      </Td>
      {!isMobile && (
        <Td>
          {info.ultimo_envio === 'P' ? (
            <Tag size="sm" colorScheme="blue">
              <RiTimeLine />
              <Text ml={2} color="gray.800">
                Contato Aguardando Resposta
              </Text>
            </Tag>
          ) : (
            <Tag size="sm" colorScheme="green">
              <BiHealth />
              <Text ml={2} color="gray.700">
                Hospital Aguardando Resposta
              </Text>
            </Tag>
          )}
        </Td>
      )}
      {!isMobile && (
        <Td>
          <HStack spacing={4}>
            <Tag size="sm" colorScheme="blue">
              <BiMessageAdd />
              <Text ml={2} color="gray.800">
                {info.id}
              </Text>
            </Tag>

            {info.sn_manual === 'S' && (
              <Tag size="sm" colorScheme="teal">
                <FaRegHandPointUp />
                <Text ml={2} color="gray.800">
                  Protocolo Manual
                </Text>
              </Tag>
            )}
          </HStack>
        </Td>
      )}
      <Td>
        <HStack spacing={2}>
          <Icon
            as={BiCalendar}
            w={6}
            h={6}
            cursor="pointer"
            title={moment(info.data).format('ddd, DD/MM/YYYY HH:mm')}
          />
          <Icon
            onClick={() => ViewdocumentosRecebidos(info)}
            as={CgFileDocument}
            w={6}
            h={6}
            cursor="pointer"
            title="Documentos Recebidos"
          />
          <Icon
            onClick={() => handleOpenChatID(info)}
            as={BiChat}
            w={6}
            h={6}
            cursor="pointer"
            title="Abrir conversa"
          />
        </HStack>
      </Td>
    </Tr>
  )
}
