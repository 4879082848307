/*
  DRA-HIST-15 - 10 anos fazendo do mesmo jeito, eu preciso de uma forma mais fácil para conferir as agendas.
  DRA-HIST-24 - Durante a conferência de agendamento preciso saber se o paciente fez o envio da documentação necessária.
*/
import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tag,
  Text,
  Textarea,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FiCalendar, FiUser } from 'react-icons/fi'
import { HiDocumentDownload } from 'react-icons/hi'
import { GetSchedulesById } from '../../Functions/Agendamentos/schedules'
import api from '../../services/api'

function ViewInfoIcon({ icon, text }) {
  return (
    <HStack w="100%">
      <Icon as={icon} />
      <Text>{text}</Text>
    </HStack>
  )
}

function InformacoesAgendamentoOld({ agendamentos, isLoading }) {
  return (
    <VStack alignItems={'left'}>
      {agendamentos?.map((agendamento, index) => (
        <Box key={index} py={4}>
          <Button
            onClick={() => handleOpenDocumentoEnviadoPaciente(agendamento?.img_base64)}
            isDisabled={!agendamento?.img_base64}
            leftIcon={<HiDocumentDownload />}
            colorScheme={agendamento?.img_base64 ? 'green' : 'red'}
            isLoading={isLoading}
            w='100%'
          >
            Documentos do Paciente
          </Button>
        </Box>
      ))}
    </VStack>
  )
}

const handleOpenDocumentoEnviadoPaciente = (data) => {
  // Se não existir documento, não faz nada.
  if (!data.includes('data:image')) return

  let w = window.open('about:blank')
  let image = new Image()
  image.src = data
  setTimeout(function () {
    w.document.write(image.outerHTML)
  }, 0)
}

export function ModalViewScheduleId({ isOpen, onClose, id }) {
  const [schedule, setSchedule] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingOld, setIsLoadingOld] = useState(false)
  const [agendamentosOld, setAgendamentosOld] = useState([])
  const toast = useToast()

  // Consultar os dados sempre que for atualizado.
  useEffect(() => {
    if (!isOpen) return
    if (!id) return

    setIsLoading(true)
    GetSchedulesById(id)
      .then((res) => {
        if (!res) return
        if (res) {
          setSchedule(res)
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false)
      })

    setIsLoadingOld(true)
    api
      .post('agendamentos/busca/id', {
        id,
      })
      .then((res) => {
        setAgendamentosOld(res.data)
      })
      .catch(() => {
        toast({
          title: 'Erro ao buscar agendamentos antigos',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        })
      })
      .finally(() => {
        setIsLoadingOld(false)
      })
  }, [isOpen, id])

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) hue-rotate(90deg)" />
        <ModalContent>
          <ModalHeader>
            <ViewInfoIcon icon={FiUser} text={schedule?.cd_it_agenda_central} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center py={8}>
              {isLoading ? (
                <Spinner />
              ) : (
                <VStack mb={4} w="100%" alignItems={'left'}>
                  <ViewInfoIcon icon={FiUser} text={schedule?.nm_paciente} />
                  <ViewInfoIcon icon={FiCalendar} text={schedule?.dt_agenda} />
                  <ViewInfoIcon icon={FiCalendar} text={schedule?.doctors?.nm_prestador} />
                  <ViewInfoIcon icon={FiCalendar} text={schedule?.schedule_item?.ds_item_agendamento} />
                  <ViewInfoIcon icon={FiCalendar} text={schedule?.covenants?.nm_convenio} />
                  <ViewInfoIcon icon={FiCalendar} text={schedule?.schedules_status?.status} />

                  <Textarea size="xs">{schedule?.observacao}</Textarea>

                  {schedule?.schedules_docs?.map((doc, index) => (
                    <Box key={index} p={4} boxShadow={'base'}>
                      <Link href={doc.url} isExternal>
                        Documento Anexado <ExternalLinkIcon mx="2px" />
                      </Link>
                    </Box>
                  ))}
                </VStack>
              )}
            </Center>

            <InformacoesAgendamentoOld agendamentos={agendamentosOld} isLoading={isLoadingOld} />
          </ModalBody>
          <ModalFooter>
            <HStack w="100%" justifyContent={'space-between'}>
              <Tag colorScheme="orange">Em teste</Tag>
              <Text fontSize={'xs'}>{id}</Text>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
