import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { FiCheck, FiPlus, FiPrinter, FiSearch, FiTrash } from 'react-icons/fi'
import { Context as ContextUser } from '../../Context/UserContext'
import { CreateFuncoesLogs } from '../../Functions/FuncoesSistemaLog/CreateFuncoesLogs'
import { DeleteItemPagamento } from '../../Functions/Pagamentos/DeleteItemPagamento'
import { DeletePagamentos } from '../../Functions/Pagamentos/DeletePagamentos'
import { FindItensDoPagamento } from '../../Functions/Pagamentos/FindItensDoPagamento'
import { FindPagamentosPorID } from '../../Functions/Pagamentos/FindPagamentosPorID'
import { RedirectViewPagamentos } from '../../Functions/Pagamentos/RedirectViewPagamentos'
import { FindProcedimentosCadastrados } from '../../Functions/Procedimentos/FindProcedimentosCadastrados'
import { NotificaError } from '../../Functions/Toast/error'
import api from '../../services/api'
import { LoadingPage } from '../LoadingPage'
import { PagamentoFinalizar } from './PagamentoFinalizar'
import { ProcedimentosCadastrados } from './ProcedimentosCadastrados'
import { ModalComponent } from '../../_styles/ModalComponent '

const PagamentoPorID = React.memo(function PagamentoPorID({
  isOpen,
  handleChangeModalIsOpen,
  id_pagamento,
  cd_atendimento,
}) {
  const [pagamentoSelecionado, setpagamentoSelecionado] = useState([])
  const [procedimentosCadastrados, setprocedimentosCadastrados] = useState([])
  const [itensPagamento, setitensPagamento] = useState([])
  const [valorItem, setvalorItem] = useState([])
  const [ds_pro_fat, setds_pro_fat] = useState('')
  const [ds_pro_fat_selecionado, setds_pro_fat_selecionado] = useState('')
  const [reload, setreload] = useState(false)
  const [isOpenFinalizarPagamento, setisOpenFinalizarPagamento] = useState(false)
  const { setLoading, User } = useContext(ContextUser)

  const cd_cliente = User.clients?.cd_cliente

  useEffect(() => {
    if (isOpen) {
      setLoading(true)
      FindPagamentosPorID(cd_atendimento, id_pagamento).then((res) => {
        setpagamentoSelecionado(res[0])
      })
      FindItensDoPagamento(id_pagamento).then((res) => {
        setitensPagamento(res)
        setLoading(false)
      })
    }
  }, [isOpen, reload, isOpenFinalizarPagamento])

  useEffect(() => {
    if (isOpen) {
      if (ds_pro_fat.length > 3) {
        FindProcedimentosCadastrados(User.cd_cliente, ds_pro_fat.toUpperCase()).then((res) => {
          setprocedimentosCadastrados(res)
        })
      } else {
        setprocedimentosCadastrados([])
        setds_pro_fat_selecionado('')
      }
    }
  }, [ds_pro_fat, User])

  const handleSetValueProcedimentoSelecionado = (data) => {
    setds_pro_fat_selecionado(data)
  }

  const handleInsertItemPagamento = (e) => {
    e.preventDefault()
    api
      .post('pagamentos/itens/add', {
        id_pagamento: id_pagamento,
        ds_procedimento: ds_pro_fat_selecionado,
        vl_item: valorItem,
        usuario_lancamento: User.usuario,
      })
      .then(() => {
        setreload(!reload)
        setds_pro_fat_selecionado('')
        setds_pro_fat('')
        setvalorItem('')
      })
      .catch(() => {
        NotificaError('Item não foi adicionado, confira todos os campos...')
      })
  }

  const handleDeleteItemPagamento = (data) => {
    setLoading(true)
    DeleteItemPagamento(pagamentoSelecionado.sn_pago, data.id_pagamento, data.id, data.vl_item)
      .then(() => {
        setreload(!reload)
        setLoading(false)
        NotificaError('Item removido com sucesso...')
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const handleFinalizarPagamento = () => {
    setisOpenFinalizarPagamento(!isOpenFinalizarPagamento)
  }

  const handleDeletePagamentoGestor = (data) => {
    const descricao = `Pagamento deletado ${data.nm_paciente} Valor pago R$${data.vl_pago} Prestador ${data.nm_prestador}`
    CreateFuncoesLogs(cd_cliente, descricao, 'DELETE_PAGAMENTOS').then(() => {
      DeletePagamentos(data.id).then(() => {
        handleChangeModalIsOpen()
      })
    })
  }

  return (
    <>
      {/* Modal de pagamentos por ID */}
      <ModalComponent isOpen={isOpen} onClose={() => handleChangeModalIsOpen()} size="full" closeOnOverlayClick={false}>
        <LoadingPage />
        <Heading as="h3" fontSize={'sm'}>
          {pagamentoSelecionado.nm_paciente}
        </Heading>

        {/* Header do Modal */}
        <HStack>
          <Flex w="100%" justifyContent={'space-between'}>
            <VStack alignItems={'left'}>
              <Heading fontWeight={'bold'}>R$ {pagamentoSelecionado.vl_pago},00</Heading>
              <Text>Atendimento: {cd_atendimento}</Text>
            </VStack>
          </Flex>
          {/* <Loader display={loading} /> */}

          {/* Botões do Modal de pagamento por ID */}
          <HStack p={4} spacing={4}>
            {/* O perfil deve estar liberado para deletar pagamentos */}
            {User.profiles?.payments_delete && (
              <Icon
                w={6}
                h={6}
                cursor={'pointer'}
                color="red"
                as={FiTrash}
                title="Deletar pagamento"
                onClick={() => handleDeletePagamentoGestor(pagamentoSelecionado)}
              />
            )}

            <Icon
              w={6}
              h={6}
              cursor={'pointer'}
              as={FiPrinter}
              title="Imprimir"
              onClick={() => RedirectViewPagamentos(pagamentoSelecionado.cd_atendimento, pagamentoSelecionado.id)}
            />

            {pagamentoSelecionado.sn_pago === 'N' && (
              <Icon
                w={6}
                h={6}
                cursor={'pointer'}
                as={FiCheck}
                title="Concluir"
                onClick={() => handleFinalizarPagamento()}
              />
            )}
          </HStack>
        </HStack>

        {/* Informações para adicionar itens */}
        {pagamentoSelecionado.sn_pago === 'N' ? (
          <Box marginTop={4}>
            <form onSubmit={handleInsertItemPagamento} encType="multipart/form-data">
              <FormControl isRequired marginBottom={4}>
                <FormLabel>Pesquise aqui o item, em seguida selecione-o logo abaixo</FormLabel>
                <InputGroup bg={'gray.200'} borderRadius={8}>
                  <InputLeftElement pointerEvents="none" children={<FiSearch color="gray.300" />} />
                  <Input onChange={(e) => setds_pro_fat(e.target.value)} id="input-procedimento" value={ds_pro_fat} />
                </InputGroup>
              </FormControl>

              {procedimentosCadastrados.length >= 1 ? (
                <ProcedimentosCadastrados
                  info={procedimentosCadastrados}
                  handleSetValue={handleSetValueProcedimentoSelecionado}
                />
              ) : (
                ''
              )}

              {ds_pro_fat_selecionado ? (
                <FormControl isRequired>
                  <FormLabel>Qual o valor do item?</FormLabel>
                  <Input onChange={(e) => setvalorItem(e.target.value)} />
                </FormControl>
              ) : (
                ''
              )}

              {ds_pro_fat_selecionado ? (
                <Button variant="primary" type="submit" leftIcon={<FiPlus size="24" color="white" />} marginTop={4}>
                  ADICIONAR ITEM
                </Button>
              ) : (
                ''
              )}
            </form>
          </Box>
        ) : (
          ''
        )}

        {/* Informações dos itens do orçamento */}

        <Box marginTop={4} marginBottom={4}>
          <Text fontWeight={'bold'}>Itens inseridos ({itensPagamento.length})</Text>
          <Table>
            <Thead>
              <Tr>
                <Th>Item</Th>
                <Th>Valor</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {itensPagamento
                ? itensPagamento.map((item, index) => (
                    <Tr key={index}>
                      <Td>{item.ds_procedimento} </Td>
                      <Td fontWeight={'bold'}>{' R$ ' + item.vl_item}</Td>
                      <Td>
                        {pagamentoSelecionado.sn_pago === 'N' ? (
                          <FiTrash onClick={() => handleDeleteItemPagamento(item)} />
                        ) : (
                          ''
                        )}
                      </Td>
                    </Tr>
                  ))
                : ''}
            </Tbody>
          </Table>
        </Box>
      </ModalComponent>
      <PagamentoFinalizar
        isOpen={isOpenFinalizarPagamento}
        handleChangeModalIsOpen={handleFinalizarPagamento}
        id_pagamento={id_pagamento}
        cd_atendimento={cd_atendimento}
        nm_paciente={pagamentoSelecionado.nm_paciente}
        phone_prestador={pagamentoSelecionado.phone_prestador}
        vl_pago={pagamentoSelecionado.vl_pago}
      />
    </>
  )
})

export { PagamentoPorID }
