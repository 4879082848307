/* eslint-disable */
import api from '../../services/api'

export async function CreatePendenciaSetor(
  cd_cliente,
  id_motivo,
  id,
  nm_paciente,
  cd_setor_destino,
  obs_cricacao
) {
  return await api
    .post('pendencias/add', {
      cd_cliente,
      id_motivo,
      id_agendamento: id,
      nm_paciente,
      cd_setor_criacao: localStorage.getItem('cd_setor'),
      cd_setor_destino,
      nm_usuario_criacao: localStorage.getItem('usuario'),
      obs_cricacao,
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}
