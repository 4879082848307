import { Center, Modal, ModalBody, ModalContent, ModalOverlay, Spinner, Text } from '@chakra-ui/react'

export function LoadingModal(isLoading) {
  return (
    <Modal isOpen={isLoading} size="full">
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) hue-rotate(90deg)" />
      <ModalContent>
        <ModalBody fontFamily={'Montserrat'}>
          <Center minH={'100vh'} flexDir={'column'}>
            <Spinner size="md" id="spinner-loading" />
            <Text id="msgCarregando">Carregando...</Text>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
