import { Container } from './styles'

interface Action {
  infoGeral: Array;
}

export const InfoRodape = ({ infoGeral }: Action) => (
  <Container>
    <p>
      <b>Usuário do Atendimento: </b>
      {infoGeral.usuario_atendimento}
    </p>
    <p>
      <b>Usuário do Financeiro:</b> {infoGeral.usuario_financeiro}
    </p>
  </Container>
)
