import api from '../../services/api'
import { queryClient } from '../../services/queryClient'

export async function FindOpcoesBotDdata(dataInicial, dataFinal) {
  const info = await queryClient.fetchQuery(
    ['bot-opcoes-report', dataInicial + dataFinal],
    async () => {
      const response = await api.post('bot/opcoes/find/logs', {
        DataInicial: dataInicial,
        DataFinal: dataFinal,
      })

      return response.data
    },
    {
      staleTime: 1000 * 30 * 5,
    }
  )

  return info
}
