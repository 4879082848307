/* eslint-disable */
import api from '../../services/api'

export async function FindPagamentosPeriodo(sn_pago, dataInicial, dataFinal) {
  return await api
    .post('pagamentos/find/status', { sn_pago, dataInicial, dataFinal })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}
