/* eslint-disable */
export async function GroupArrayLigacoes(arr) {
  const finalData = []
  const result = arr.reduce(function (r, a) {
    r[a.grupo] = r[a.grupo] || []
    r[a.grupo].push(a)
    return r
  }, Object.create(null))
  const data = Object.entries(result)
  for (let i = 0; i < data.length; i++) {
    const result2 = data[i][1].reduce(function (r, a) {
      r[a.nome] = r[a.nome] || []
      r[a.nome].push(a)
      return r
    }, Object.create(null))
    const value = {
      data: data[i][0],
      nomes: Object.keys(result2),
      medicos: Object.values(result2),
    }
    finalData.push(value)
  }
  return finalData
}
