import { Box, Center, Grid, Icon, Link, List, ListItem, Spinner, Text, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FaHospital, FaUser } from 'react-icons/fa'
import { FiCheck, FiShield } from 'react-icons/fi'
import { GiCancel } from 'react-icons/gi'
import { GetSchedulesByDateDashboard } from '../Functions/app/Schedules'
import { ContainerPageTemplate } from '../styles/ContainerPageTemplate'

const DashboardPage = function () {
  const [schdulesFull, setschdulesFull] = useState([])
  const [isLoading, setisLoading] = useState(false)
  const qtdPresencas = schdulesFull.filter((item) => item.isConfirmedPresence === true)
  const qtdPresencasCanceladas = schdulesFull.filter((item) => item.isConfirmedPresence === false)
  const qtdAtendidos = schdulesFull.filter((item) => item.attendanceId !== null)
  const statusCounts = {}

  schdulesFull.forEach((obj) => {
    const status = obj.schedules_status.status
    if (statusCounts[status]) {
      statusCounts[status]++
    } else {
      statusCounts[status] = 1
    }
  })

  const statusArray = Object.entries(statusCounts).map(([status, count]) => ({
    status,
    count,
  }))

  // Consultar os agendamentos do dia.
  useEffect(() => {
    setisLoading(true)
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0')
    const day = String(today.getDate()).padStart(2, '0')

    const formattedDate = `${year}-${month}-${day}`

    GetSchedulesByDateDashboard(formattedDate)
      .then((res) => {
        res && setschdulesFull(res)
      })
      .catch(() => {
        setschdulesFull([])
      })
      .finally(() => {
        setisLoading(false)
      })
  }, [])

  const boxes = [
    {
      quantidade: schdulesFull.length,
      titulo: 'Agendamentos',
      link: '/agendamentos',
      icone: FaUser,
      color: 'blue.500',
      id: 'boxAgendamentos',
    },
    {
      quantidade: qtdPresencas.length,
      titulo: 'Presenças Confirmadas',
      link: '/agendamentos',
      icone: FiCheck,
      color: 'green.500',
      id: 'boxPresencasConfirmadas',
    },
    {
      quantidade: qtdPresencasCanceladas.length,
      titulo: 'Presenças Canceladas',
      link: '/agendamentos',
      icone: GiCancel,
      color: 'red.500',
      id: 'boxPresencasCanceladas',
    },
    {
      quantidade: qtdAtendidos.length,
      titulo: 'Atendidos',
      link: '/atendimentos',
      icone: FaHospital,
      color: 'green.500',
      id: 'boxAtendidos',
    },
  ]

  const configBox = {
    bg: 'gray.50',
    p: 4,
    borderRadius: 8,
    boxShadow: 'base',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  }

  return (
    <ContainerPageTemplate>
      {isLoading && (
        <Center minH="50vh">
          <VStack>
            <Spinner />
            <Text id="msgCarregando"> Carregando...</Text>
          </VStack>
        </Center>
      )}

      {!isLoading && (
        <Grid templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} gap={4}>
          {boxes.map((box, index) => (
            <Box key={index} {...configBox} id={box.id}>
              <Icon as={box.icone} boxSize={8} mb={2} color={box.color || 'gray.500'} />
              <VStack spacing={1}>
                <Text fontWeight={'bold'}>{box.quantidade}</Text>
                <Text>{box.titulo}</Text>
              </VStack>

              <Link href={box.link} color="blue.500">
                Ver detalhes
              </Link>
            </Box>
          ))}
          <Box {...configBox}>
            <Icon as={FiShield} boxSize={8} mb={2} color={'gray.500'} />
            <VStack spacing={1}>
              <Text>Autorizações</Text>
            </VStack>
            <List spacing={1} alignItems={'left'} textAlign={'left'}>
              {statusArray.map((item, index) => (
                <ListItem key={index}>
                  {item.status} - {item.count}
                </ListItem>
              ))}
            </List>
            <Link href={'/autorizacoes/v2'} color="blue.500">
              Ver detalhes
            </Link>
          </Box>
        </Grid>
      )}
    </ContainerPageTemplate>
  )
}

export { DashboardPage }
