import { Center, FormControl, FormHelperText, FormLabel, Spinner, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { InputCelular } from '../../Components/Inputs/InputCelular'
import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api'
import { ModalChatMensagensAnteriores } from './ModalChatMensagensAnteriores'

export function ChatModalDigitarPhone({ isOpen, handleCloseModal }) {
  const [numeroCelular, setnumeroCelular] = useState('')
  const [loading, setloading] = useState(false)
  const [isOpenMensagensAnteriores, setisOpenMensagensAnteriores] = useState(false)
  const [contatos, setContatos] = useState([])
  const [id_contato, setid_contato] = useState('')

  // Consultar as mensagens do contato.
  useEffect(() => {
    // Se o número de celular não tiver 11 dígitos, não faz nada.
    if (numeroCelular.length !== 11) return
    if (isOpen) setloading(true)
    api
      .post('api/chat/find/contato/by/phone', {
        phone: '55' + numeroCelular + '@c.us',
      })
      .then((res) => {
        setContatos(res.data)
        if (res.data.length > 0) {
          setid_contato(res.data[0].id)
          setisOpenMensagensAnteriores(true)
          setnumeroCelular('')
        } else {
          setid_contato('')
        }
      })
      .catch(() => {})
      .finally(() => {
        setloading(false)
      })
  }, [numeroCelular])

  return (
    <>
      <ModalComponent isOpen={isOpen} onClose={() => handleCloseModal(false)}>
        <VStack alignItems={'left'}>
          <FormControl>
            <FormLabel>Pesquisar mensagens </FormLabel>
            <InputCelular handleSetNumber={setnumeroCelular} />
            <FormHelperText>Contatos encontrados: {contatos.length}.</FormHelperText>
          </FormControl>

          <Center>{loading && <Spinner />}</Center>
        </VStack>
      </ModalComponent>

      <ModalChatMensagensAnteriores
        id_contato={id_contato}
        isOpen={isOpenMensagensAnteriores}
        handleChangeModalIsOpen={() => setisOpenMensagensAnteriores(false)}
      />
    </>
  )
}
