require('dotenv').config()
import axios from 'axios'

const token = localStorage.getItem('token') || ''
const api = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
  headers: {
    Authorization: token,
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
})

export default api
