import { Button } from '@chakra-ui/react'

export const HelpDeskButton = function HelpDeskButton() {
  function open() {
    window.open('https://dragendamento.atlassian.net/servicedesk/customer/portals', '_blank')
  }

  return (
    <Button onClick={() => open()} title="Abertura de chamados." variant={'link'} size="sm" color="red">
      Abrir chamado.
    </Button>
  )
}
