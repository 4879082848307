/* eslint-disable */
import { NotificaError } from '../Toast/error'
import { NotificaSuccess } from '../Toast/success'
import api from '../../services/api'

export async function UpdatePainelAgendamentos(
  id,
  sn_agendado,
  sn_contato_call_center,
  sn_conferido,
  desc_motivo_nao_agendado
) {
  return await api
    .post('agendamentos/online/atualizar/status/painel', {
      id,
      sn_agendado,
      sn_contato_call_center,
      sn_conferido,
      desc_motivo_nao_agendado,
      usuario: localStorage.getItem('usuario'),
    })
    .then((response) => {
      NotificaSuccess('Conferência realizada com sucesso...')
      return response.data
    })
    .catch((err) => {
      NotificaError('Já existe imagem no agendamento... ')
      return err
    })
}
