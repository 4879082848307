import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  fonts: {
    body: 'Montserrat, sans-serif',
    heading: 'Montserrat, sans-serif',
    mono: 'Montserrat, monospace',
  },
  colors: {
    primary: {
      500: '#2B6CB0',
      600: '#3182CE',
    },
    icons: '#718096',
    iconsHover: '#EDF2F7',
  },
  styles: {
    global: () => ({
      body: {
        backgroundColor: 'white',
        lineHeight: 'tall',
        color: '#29292e',
        fontFamily: 'Open Sans, sans-serif',
      },
    }),
  },
  components: {
    Button: {
      baseStyle: {
        fontSize: 'sm',
        px: 4,
      },
      variants: {
        primary: {
          bg: 'primary.500',
          color: 'white',
          _hover: {
            bg: 'primary.600',
          },
          _active: {
            bg: 'primary.600',
          },
        },
        secondary: {
          bg: 'gray.500',
          color: 'white',
          _hover: {
            bg: 'gray.600',
          },
          _active: {
            bg: 'gray.600',
          },
        },
        subtle: {
          bg: 'gray.300',
          color: 'white',
          _hover: {
            bg: 'gray.50',
            color: 'gray.600',
          },
          _active: {
            bg: 'gray.100',
            color: 'gray.700',
          },
        },
        icon: {
          bg: 'transparent',
          color: 'gray.500',
          _hover: {
            bg: 'gray.50',
            color: 'gray.600',
          },
          _active: {
            bg: 'gray.100',
            color: 'gray.700',
          },
        },
        links: {
          bg: 'transparent',
          color: 'gray.400',
          fontSize: 'sm',
          _hover: {
            bg: 'transparent',
            color: 'gray.600',
          },
          _active: {
            bg: 'transparent',
            color: 'gray.700',
          },
        },
      },
    },
    GridItem: {
      baseStyle: {
        borderRadius: 'md',
        boxShadow: 'base',
        p: 4,
        bg: '#fff',
      },
    },
  },
})

export default theme
