import { Progress, SimpleGrid } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { AgendamentoSelecionadoModal } from '../../Components/Agendamentos/AgendamentoSelecionadoModal'
import { ConferenciaAgendamentoOnlineItem } from '../../Components/Agendamentos/ConferenciaAgendamentoOnlineItem'
import { ModalConferenciaConfirmarAgendamento } from '../../Components/Agendamentos/ModalConferenciaConfirmarAgendamento'
import { DateInput } from '../../Components/DateInput'
import { Context as ContextLoading } from '../../Context/LoaderContext'
import { Context as ContextUser } from '../../Context/UserContext'
import { FindAgendadosOnline } from '../../Functions/Conferencia/FindAgendadosOnline'
import { ContainerPageTemplate } from '../../styles/ContainerPageTemplate'

const ConferenciaAgendamento = function () {
  const { cd_cliente } = useContext(ContextLoading)
  const { data, setPaginaAtual, setinformacaoComplementar } = useContext(ContextUser)
  const [agendamentos, setagendamentos] = useState([])
  const [id, setid] = useState('')
  const [isOpenAgendamento, setisOpenAgendamento] = useState(false)
  const [Loading, setLoading] = useState(false)
  const [isOpenConferencia, setisOpenConferencia] = useState(false)

  const handleOpenConferenciaID = (data) => {
    if (data) {
      setid(data)
    }
    setisOpenAgendamento(!isOpenAgendamento)
  }

  const handleOpenConferencia = (data) => {
    if (data) {
      setid(data.id)
    }
    setisOpenConferencia(!isOpenConferencia)
  }

  useEffect(() => {
    setLoading(true)
    FindAgendadosOnline(cd_cliente, data)
      .then((res) => {
        setagendamentos(res)
        setLoading(false)
      })
      .catch(() => {
        setagendamentos([])
        setLoading(false)
      })
  }, [data, isOpenAgendamento])

  return (
    <ContainerPageTemplate>
      <DateInput />

      {Loading && <Progress isIndeterminate />}

      <SimpleGrid columns={[1, 3, 4]} spacing={8} marginTop={4}>
        {agendamentos.map((agendamento) => (
          <ConferenciaAgendamentoOnlineItem
            info={agendamento}
            handleViewAgendamento={handleOpenConferenciaID}
            handleConferencia={handleOpenConferencia}
          />
        ))}
      </SimpleGrid>

      <AgendamentoSelecionadoModal
        isOpen={isOpenAgendamento}
        cd_it_agenda_central={id}
        handleChangeModalIsOpen={handleOpenConferenciaID}
        cd_cliente={cd_cliente}
      />

      <ModalConferenciaConfirmarAgendamento
        isOpen={isOpenConferencia}
        handleConferencia={handleOpenConferencia}
        handleChangeModalIsOpen={handleOpenConferencia}
        id={id}
      />
    </ContainerPageTemplate>
  )
}

export default React.memo(ConferenciaAgendamento)
