import api from '../../services/api'
import { queryClient } from '../../services/queryClient'

async function GetSchedulesByDate(data) {
  if (!data) return false

  const info = await queryClient.fetchQuery(
    ['schedules-data', data],
    async () => {
      const response = await api.post('api/v2/schedules/calendar', {
        data,
      })

      return response.data
    },
    {
      staleTime: 1000 * 30 * 3,
    }
  )

  return info
}

async function GroupSchedulesByDoctor(array: any) {
  const arr = array.sort(function (a, b) {
    return a.hr_agenda.localeCompare(b.hr_agenda)
  })

  const finalData = []
  const result = arr.reduce((r: any, a: any) => {
    r[a.doctors?.doctorVip] = r[a.doctors?.doctorVip] || []
    r[a.doctors?.doctorVip].push(a)
    return r
  }, Object.create(null))
  const data = Object.entries(result)
  for (let i = 0; i < data.length; i++) {
    const result2 = data[i][1].reduce((r: any, a: any) => {
      r[a.doctors?.nm_prestador] = r[a.doctors?.nm_prestador] || []
      r[a.doctors?.nm_prestador].push(a)
      return r
    }, Object.create(null))
    const value = {
      data: data[i][0],
      nomes: Object.keys(result2),
      medicos: Object.values(result2),
    }
    finalData.push(value)
  }

  return finalData
}

async function GroupSchedulesByStatus(array: any) {
  const arr = array.sort(function (a, b) {
    return a.hr_agenda.localeCompare(b.hr_agenda)
  })

  const finalData = []
  const result = arr.reduce((r: any, a: any) => {
    r[a.clientId] = r[a.clientId] || []
    r[a.clientId].push(a)
    return r
  }, Object.create(null))
  const data = Object.entries(result)
  for (let i = 0; i < data.length; i++) {
    const result2 = data[i][1].reduce((r: any, a: any) => {
      r[a.schedules_status?.status] = r[a.schedules_status?.status] || []
      r[a.schedules_status?.status].push(a)
      return r
    }, Object.create(null))
    const value = {
      data: data[i][0],
      nomes: Object.keys(result2),
      medicos: Object.values(result2),
    }
    finalData.push(value)
  }

  return finalData
}

async function GroupSchedules(array: any) {
  const finalData = []
  const result = array.reduce((r: any, a: any) => {
    r[a.clientId] = r[a.clientId] || []
    r[a.clientId].push(a)
    return r
  }, Object.create(null))
  const data = Object.entries(result)
  for (let i = 0; i < data.length; i++) {
    const result2 = data[i][1].reduce((r: any, a: any) => {
      r[a.schedules_status?.status] = r[a.schedules_status?.status] || []
      r[a.schedules_status?.status].push(a)
      return r
    }, Object.create(null))
    const value = {
      data: data[i][0],
      nomes: Object.keys(result2),
      medicos: Object.values(result2),
    }
    finalData.push(value)
  }

  return finalData
}

async function GetSchedulesChanges(qtd: number) {
  return api
    .get('/api/v2/schedules/changes/' + qtd)
    .then((res) => {
      return res.data
    })
    .catch(() => {
      return false
    })
}

async function GetDocs(scheduleId: string) {
  return api
    .post(`/api/v2/schedules/docs`, { scheduleId })
    .then((res) => {
      return res.data
    })
    .catch(() => {
      return false
    })
}

async function GetDocsTypes() {
  return api
    .get(`/api/v2/schedules/docs/types`)
    .then((res) => {
      return res.data
    })
    .catch(() => {
      return false
    })
}

async function GetSchedulesByPeriod(date_start: date, date_end: date) {
  const schedules = await api
    .post('/api/v2/schedules/calendar/period', {
      date_start,
      date_end,
    })
    .then((res) => {
      return res.data
    })
    .catch(() => {
      return false
    })
  return schedules
}

async function GetSchedulesById(id: string) {
  const schedules = await api
    .post('/api/v2/schedules/calendar/id', { id })
    .then((res) => {
      return res.data
    })
    .catch(() => {
      return false
    })
  return schedules
}

async function GetRequestByPeriod(start, end) {
  const schedules = api
    .get('api/v2/schedules/request/' + start + '/' + end)
    .then((res) => {
      return res.data
    })
    .catch(() => {
      return false
    })

  return schedules
}

async function UpdatedInfoSchedule(id, data) {
  const schedules = api
    .put('api/v2/schedules/' + id, { data: data })
    .then((res) => {
      return res.data
    })
    .catch(() => {
      return false
    })

  return schedules
}

export {
  GetDocs,
  GetDocsTypes,
  GetRequestByPeriod,
  GetSchedulesByDate,
  GetSchedulesById,
  GetSchedulesByPeriod,
  GetSchedulesChanges,
  GroupSchedules,
  GroupSchedulesByDoctor,
  GroupSchedulesByStatus,
  UpdatedInfoSchedule,
}
