import { Button, Container, Heading, HStack, useToast, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../../services/api'

function Presenca() {
  const { id, token } = useParams()
  const [isConfirmed, setisConfirmed] = useState(false)
  const toast = useToast()

  function handleConfirmaPresenca(status) {
    api
      .post('agendamentos/confirma/presenca/id', { id, status })
      .then(() => {
        setisConfirmed(true)
        toast({
          title: 'Presença confirmada',
          description: 'Sua presença foi confirmada com sucesso',
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        })
      })
      .catch(() => {
        toast({
          title: 'Erro ao confirmar presença',
          description: 'Houve um erro ao confirmar presença, tente novamente mais tarde',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        })
      })
  }

  return (
    <VStack>
      <Container p={4} bg="white" fontSize={'sm'} minW="90vw">
        {!isConfirmed ? (
          <VStack py={16}>
            <Heading>Deseja confirmar a sua presença ?</Heading>
            <HStack>
              <Button colorScheme={'green'} onClick={() => handleConfirmaPresenca('S')} size="lg">
                Sim
              </Button>
              <Button colorScheme={'red'} onClick={() => handleConfirmaPresenca('N')} size="lg">
                Não
              </Button>
            </HStack>
          </VStack>
        ) : (
          <VStack>
            <Heading>Agradecemos a resposta....</Heading>
          </VStack>
        )}
      </Container>
    </VStack>
  )
}

export default React.memo(Presenca)
