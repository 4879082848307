import styled from 'styled-components'

export const ContainerButtons = styled.div`
  margin: 16px;
  display: flex;
  justify-content: space-around;
`
export const Container = styled.li`
  small {
    color: var(--text-body);
    font-size: 0.8rem;
  }
`
