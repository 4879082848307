import api from '../../services/api'

export async function GetSchedulesByDate(data) {
  const schedules = await api
    .post('/api/v2/schedules/calendar', { data })
    .then((res) => {
      return res.data
    })
    .catch(() => {
      return false
    })
  return schedules
}

export async function GetSchedulesByDateDashboard(data) {
  const schedules = await api
    .post('/api/v2/schedules/calendar/dashboard', { data })
    .then((res) => {
      return res.data
    })
    .catch(() => {
      return false
    })
  return schedules
}

export async function GroupSchedulesByDoctor(array) {
  const arr = array.sort(function (a, b) {
    return a.hr_agenda.localeCompare(b.hr_agenda)
  })

  const finalData = []
  const result = arr.reduce((r, a) => {
    r[a.doctors?.doctorVip] = r[a.doctors?.doctorVip] || []
    r[a.doctors?.doctorVip].push(a)
    return r
  }, Object.create(null))
  const data = Object.entries(result)
  for (let i = 0; i < data.length; i++) {
    const result2 = data[i][1].reduce((r, a) => {
      r[a.doctors?.nm_prestador] = r[a.doctors?.nm_prestador] || []
      r[a.doctors?.nm_prestador].push(a)
      return r
    }, Object.create(null))
    const value = {
      data: data[i][0],
      nomes: Object.keys(result2),
      medicos: Object.values(result2),
    }
    finalData.push(value)
  }

  console.log(finalData)

  return finalData
}
