import { Container } from './styles'

interface Action {
  itensPagamento: Array;
}

export const InfoItensPagamento = ({ itensPagamento }: Action) => (
  <Container>
    {itensPagamento.map((itemPagamento, index) => (
      <p>
        {index + 1} - {itemPagamento.ds_procedimento + ' R$ ' + itemPagamento.vl_item}
      </p>
    ))}
  </Container>
)
