import React from 'react'
import nookies from 'nookies'
import { Center, Container, Heading } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'

const Logout = function Logout() {
  const history = useHistory()
  localStorage.clear()
  nookies.destroy(undefined, 'dragendamento.token')
  history.push('/')

  return (
    <Container maxW="7xl" py={12}>
      <Center>
        <Heading>Desconectado com sucesso...</Heading>
      </Center>
    </Container>
  )
}

export default Logout
