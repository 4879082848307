/* eslint-disable */
import api from '../../services/api'

export async function FindMotivosLigacoes() {
  return await api
    .post('ligacoes/motivo', {
      sn_ativo: 'S',
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}
