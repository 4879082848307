import { Button, FormControl, FormLabel, HStack, Input } from '@chakra-ui/react'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FiSearch } from 'react-icons/fi'
import { Context } from '../../Context/UserContext'

export const DateInput = function DateInput() {
  const { setData, Loading } = useContext(Context)
  const { handleSubmit, register } = useForm()
  const [dataSelecionadaInput, setDataSelecionadaInput] = useState('')

  const handleFindSchedules = () => {
    setData(dataSelecionadaInput)
  }

  return (
    <form onSubmit={handleSubmit(handleFindSchedules)}>
      <HStack align="center" alignItems={'flex-end'}>
        <FormControl isRequired>
          <FormLabel htmlFor="date">Selecione a data</FormLabel>
          <Input
            type={'date'}
            id="dataSelecionadaInput"
            {...register('dataSelecionadaInput')}
            onChange={(e) => setDataSelecionadaInput(e.target.value)}
          />
        </FormControl>
        <Button type="submit" pr={4} leftIcon={<FiSearch />} colorScheme="blue" size={'md'} isLoading={Loading}>
          Pesquisar
        </Button>
      </HStack>
    </form>
  )
}
