/* eslint-disable */
import api from '../../services/api'

export async function FindPedidosLiberados(cd_cliente, data) {
  return await api
    .post('atendimentos/exames/completo', { cd_cliente, data })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}
