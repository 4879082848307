/* eslint-disable */
import api from '../../services/api'

export async function AddMensagem(phone, id_protocolo, id_agendamento, mensagem) {
  return await api
    .post('mensagens/add', {
      phone,
      cd_cliente: localStorage.getItem('cd_cliente'),
      id_protocolo,
      id_cirurgia: '',
      id_agendamento,
      id_atendimento: '',
      sn_paciente: 'N',
      mensagem,
      nm_usuario: localStorage.getItem('usuario'),
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })
}
