import api from '../services/api'
import { queryClient } from '../services/queryClient'

export async function ChatDocumentosEnviados(id_contato, cd_cliente) {
  const info = await queryClient.fetchQuery(
    ['chat-documentos', id_contato],
    async () => {
      const response = await api.post('contatos/find/contato', {
        cd_cliente,
        id_contato,
      })

      return response.data
    },
    {
      staleTime: 1000 * 30 * 5,
    }
  )

  return info
}

export async function ChatProtocolosPorData(dataInicial, dataFinal) {
  const id_setor = localStorage.getItem('cd_setor')
  const info = await queryClient.fetchQuery(
    ['chat-protocolos', dataFinal],
    async () => {
      const response = await api.post('api/chat/find/protocolos/data', {
        phone: '1',
        dataInicial,
        dataFinal,
        id_setor,
      })

      return response.data
    },
    {
      staleTime: 1000 * 30 * 5,
    }
  )

  return info
}
