import {
  Button,
  Center,
  Flex,
  HStack,
  Input,
  Select,
  Spinner,
  Table,
  Th,
  Thead,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react'
import { FiFilter, FiPieChart, FiSearch } from 'react-icons/fi'
import { ChatGroup } from '../../Components/Chat/ChatGroup'
import { ChatModalDigitarPhone } from '../../Components/Chat/ChatModalDigitarPhone'
import { DashboardProtocolos } from '../../Components/Chat/DashboardProtocolos'
import { ModalChatselecionado } from '../../Components/Chat/ModalChatselecionado'
import { ModalDocumentosRecebidos } from '../../Components/Chat/ModalDocumentosRecebidos'
import { ModalIntencoesAssistenteVirtual } from '../../Components/Chat/ModalIntencoesAssistenteVirtual'
import { ModalOpcoesBotData } from '../../Components/Chat/ModalOpcoesBotData'
import { ProtocoloManual } from '../../Components/Chat/ProtocoloManual'
import { Context as ContextUser } from '../../Context/UserContext'
import { ChatProtocolosPorData } from '../../Functions/Chat'
import api from '../../services/api'
import { ContainerPageTemplate } from '../../styles/ContainerPageTemplate'

export default function Dashboard() {
  const toast = useToast()
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  const [qtdProtocolosManuais, setqtdProtocolosManuais] = useState(0)
  const { User, Loading, setLoading } = useContext(ContextUser)
  const [filterMeusAtendimentos, setfilterMeusAtendimentos] = useState(false)
  const [isOpenDocumentosRecebidos, setisOpenDocumentosRecebidos] = useState(false)
  const [Chat, setChat] = useState([])
  const [ChatFull, setChatFull] = useState([])
  const [reload, setreload] = useState(true)
  const [idSelecionado, setidSelecionado] = useState('')
  const [id_contato, setid_contato] = useState('')
  const [nm_paciente, setnm_paciente] = useState('')
  const [phone, setphone] = useState('')
  const [isOpenModalDigitarPhone, setIsOpenModalDigitarPhone] = useState(false)
  const [modalIsOpenOpcoesBot, setmodalIsOpenOpcoesBot] = useState(false)
  const [modalIsOpenChatSelecionado, setmodalIsOpenChatSelecionado] = useState(false)
  const [ChatPacientesAguardando, setChatPacientesAguardando] = useState([])
  const [data, setData] = useState(new Date().toISOString().slice(0, 10))

  const handleChageModalIsOpenChatSelecionado = (data) => {
    setmodalIsOpenChatSelecionado(!modalIsOpenChatSelecionado)
    setreload(!reload)
    if (data) {
      api
        .post('api/chat/update/protocolo/atendente', {
          id: data.id,
          nm_usuario_atendimento: localStorage.getItem('usuario'),
          phone: '1',
        })
        .then(() => {
          const newOs = Chat.map((p) =>
            p.id === data.id
              ? {
                  ...p,
                  nm_usuario_atendimento: localStorage.getItem('usuario'),
                }
              : p
          )
          setChat(newOs)
        })
      setidSelecionado(data.id)
      setnm_paciente(data.nome)
      setphone(data.phone)
      setid_contato(data.id_contato)
    }
  }

  setTimeout(() => {
    setreload(!reload)
  }, 600000)

  const handleReload = () => {
    setreload(!reload)
  }

  useEffect(() => {
    ChatProtocolosPorData(data, data).then((res) => {
      res.map((p) => {
        if (p.sn_manual === 'S') {
          setqtdProtocolosManuais(qtdProtocolosManuais + 1)
        }
      })
    })
  }, [data])

  // Consultar as mensagens por setor.
  useEffect(() => {
    // Se o usuário não for informado retorna.
    if (!User) return

    // Se o usuário não tiver setores vinculados retorna.
    if (User?.sectors === null) {
      toast({
        title: 'Setor.',
        description: 'Você não está vinculado em nenhum setor, entre em contato com a TI.',
        position: 'top',
        status: 'error',
        duration: 9000,
        isClosable: false,
      })
      return
    }
    setLoading(true)
    api
      .post('protocolos/find/idsetor', {
        id_setor: User.sectors?.cd_setor || null,
        sn_pendente: 'S',
      })
      .then((response) => {
        setChatFull(response.data)
        const info = response.data.reverse(function (a, b) {
          return a.ultimo_envio.localeCompare(b.ultimo_envio)
        })

        setChat(info)
        return response.data
      })
      .catch(() => {
        return
      })
      .finally(() => {
        setLoading(false)
      })
  }, [reload, User])

  // Visualizar os documentos que foram recebidos.
  const ViewdocumentosRecebidos = (data) => {
    setisOpenDocumentosRecebidos(true)
    setid_contato(data.id_contato)
  }

  const handleFilterMeusAtendimentos = () => {
    if (filterMeusAtendimentos) {
      setfilterMeusAtendimentos(false)
      setChat(ChatFull)
      return
    } else {
      setfilterMeusAtendimentos(true)
      const protocolos = Chat.filter((d) => d.nm_usuario_atendimento == User.usuario)
      setChat(protocolos)
    }
  }

  // DRA-HIST-14
  const handleFilterOrigemProtocolo = (value) => {
    // Filtrar campo de protocolos manuais sn_manual

    if (value === 'S') {
      const protocolos = ChatFull.filter((d) => d.sn_manual == 'S')
      setChat(protocolos)
      return
    }

    if (value === 'N') {
      const protocolos = ChatFull.filter((d) => d.sn_manual == 'N')
      setChat(protocolos)
      return
    }

    setChat(ChatFull)
  }

  return (
    <>
      <ContainerPageTemplate isLoading={Loading}>
        <ModalDocumentosRecebidos
          isOpen={isOpenDocumentosRecebidos}
          onClose={() => setisOpenDocumentosRecebidos(false)}
          id_contato={id_contato}
        />

        {/* Se for aberto no celular não deve aspresentar as estatisticas */}
        {!isMobile && (
          <HStack justifyContent={'center'} alignItems={'center'} placeContent={'center'}>
            <Input value={data} type="date" onChange={(e) => setData(e.target.value)} maxW="30%" />

            {data !== '' && <DashboardProtocolos dataInicial={data} dataFinal={data} />}
          </HStack>
        )}

        {/* Menus do chat */}
        <HStack justifyContent="flex-end" pt={4}>
          {/* Modal para configurar o assistente virtual */}
          <ModalIntencoesAssistenteVirtual />

          <ProtocoloManual handleReload={handleReload} chatArray={Chat} setChat={setChat} />

          <Button onClick={() => setIsOpenModalDigitarPhone(!isOpenModalDigitarPhone)} variant="links">
            <FiSearch />
          </Button>

          <Button onClick={() => setmodalIsOpenOpcoesBot(true)} variant="links">
            <FiPieChart />
          </Button>

          {/* FiFilter */}
          <Button
            variant="icon"
            leftIcon={<FiFilter />}
            onClick={() => handleFilterMeusAtendimentos()}
            color={filterMeusAtendimentos ? 'green.800' : 'gray.800'}
          />
          <Select
            onChange={(e) => handleFilterOrigemProtocolo(e.target.value)}
            placeholder="Filtrar tipo de protocolo"
            size="sm"
            maxW={'20%'}
            borderRadius={8}
          >
            <option value="" selected>
              Todos
            </option>
            <option value="S">Ativos (Manuais)</option>
            <option value="N">Receptivos</option>
          </Select>
        </HStack>

        <Center>{Loading && <Spinner />}</Center>

        {!Loading && (
          <Flex bg="white">
            <Table mt={4} variant="simple" size="sm" p={8} colorScheme="facebook">
              <Thead>
                <Th color="white">CONTATO</Th>
                <Th color="white">ATENDENTE</Th>
                {!isMobile && <Th color="white">STATUS</Th>}
                {!isMobile && <Th color="white">INFORMAÇÕES</Th>}
                <Th color="white">AÇÕES</Th>
              </Thead>
              <ChatGroup
                info={Chat}
                handleOpenChatID={handleChageModalIsOpenChatSelecionado}
                loading={Loading}
                ViewdocumentosRecebidos={ViewdocumentosRecebidos}
              />
            </Table>
          </Flex>
        )}

        <ModalChatselecionado
          isOpen={modalIsOpenChatSelecionado}
          handleChangeModalIsOpen={handleChageModalIsOpenChatSelecionado}
          id={idSelecionado}
          id_contato={id_contato}
          nm_paciente={nm_paciente}
          phone={phone}
          allProtocolos={Chat}
          allProtocolosPacientesAguardando={ChatPacientesAguardando}
          setChat={setChat}
          setChatPacientesAguardando={setChatPacientesAguardando}
        />

        <ModalOpcoesBotData
          isOpen={modalIsOpenOpcoesBot}
          handleChangeModalIsOpen={() => setmodalIsOpenOpcoesBot(false)}
        />

        <ChatModalDigitarPhone
          isOpen={isOpenModalDigitarPhone}
          handleCloseModal={() => setIsOpenModalDigitarPhone(false)}
        />
      </ContainerPageTemplate>
    </>
  )
}
