import React from 'react'
import { FiCalendar } from 'react-icons/fi'

const ConferenciaValidaAgendado = function ({ info }) {
  let color
  if (info.hr_agendamento) {
    color = 'green'
  } else {
    color = 'red'
  }

  return <FiCalendar size={20} color={color} />
}

export default React.memo(ConferenciaValidaAgendado)
