import { Progress } from '@chakra-ui/react'
import { createContext, useEffect, useState } from 'react'
import { FindProtocolosPendentes } from '../Functions/Chat/FindProtocolosPendentes'

const Context = createContext()

const LoadingProvider = function ({ children }) {
  const [loading, setLoading] = useState(false)
  const [qtdPacientesAguardandoChat, setqtdPacientesAguardandoChat] = useState('')
  const [cd_cliente, setcd_cliente] = useState(localStorage.getItem('cd_cliente'))
  const ChatPacientesAguardandoArray = []
  const ChatPacientesPendentesArray = []

  useEffect(() => {
    setcd_cliente(localStorage.getItem('cd_cliente'))
    if (cd_cliente)
      FindProtocolosPendentes().then((res) => {
        let i = 0
        while (i < res.length) {
          if (res[i].ultimo_envio === 'P') {
            ChatPacientesAguardandoArray.push(res[i])
          } else {
            ChatPacientesPendentesArray.push(res[i])
          }
          i++
        }
        setqtdPacientesAguardandoChat(ChatPacientesAguardandoArray.length)
      })
  }, [loading])

  return (
    <Context.Provider
      value={{
        loading,
        setLoading,
        cd_cliente,
        qtdPacientesAguardandoChat,
      }}
    >
      {loading && <Progress isIndeterminate />}

      {children}
    </Context.Provider>
  )
}

export { Context, LoadingProvider }
