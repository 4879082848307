import api from '../../services/api'
import { SendSMS } from '../Mensagens/SendSMS'
import { NotificaError } from '../Toast/error'
import { NotificaSuccess } from '../Toast/success'

export async function AgendamentosWebCreatePainelAgendamentoOnline(
  cd_cliente,
  nm_paciente,
  nr_celular,
  ds_item_agendamento,
  nm_convenio,
  nm_con_pla,
  nm_prestador,
  dt_agendamento,
  hr_agendamento,
  desc_motivo_nao_agendado
) {
  return await api
    .post('agendamentos/online/add/painel', {
      cd_cliente,
      nm_paciente,
      nr_celular,
      ds_item_agendamento,
      nm_convenio,
      nm_con_pla,
      nm_prestador,
      dt_agendamento,
      hr_agendamento,
      desc_motivo_nao_agendado,
    })
    .then((response) => {
      NotificaSuccess('oi')
      return response.data
    })
    .catch((err) => {
      NotificaSuccess('erro')
      return err
    })
}

export async function AgendamentosWebFindWebConvenios(cd_cliente, tp_convenio) {
  return await api
    .post('agendamentoweb/convenios', { cd_cliente, tp_convenio })
    .then((response) => {
      return response.data
    })
    .catch(() => {
      return []
    })
}

export async function AgendamentosWebFindWebHorariosDisponiveis(
  cd_cliente,
  cd_prestador,
  cd_item_agendamento,
  cd_convenio
) {
  console.log(cd_cliente)
  console.log(cd_prestador)
  console.log(cd_item_agendamento)
  console.log(cd_convenio)

  const groupArray = (arr) => {
    const finalData = []
    const result = arr.reduce(function (r, a) {
      r[a.dt_agendamento] = r[a.dt_agendamento] || []
      r[a.dt_agendamento].push(a)
      return r
    }, Object.create(null))
    const data = Object.entries(result)
    for (let i = 0; i < data.length; i++) {
      const result2 = data[i][1].reduce(function (r, a) {
        r[a.nm_prestador] = r[a.nm_prestador] || []
        r[a.nm_prestador].push(a)
        return r
      }, Object.create(null))
      const value = {
        data: data[i][0],
        nomes: Object.keys(result2),
        medicos: Object.values(result2),
      }
      finalData.push(value)
    }
    return finalData
  }

  return await api
    .post('agendamentosweb/horarios', {
      cd_cliente,
      cd_prestador: '',
      cd_item_agendamento,
      cd_convenio,
    })
    .then((response) => {
      const data = groupArray(response.data)
      return data
    })
    .catch((err) => {
      alert('erro agenda')
      return []
    })
}

export async function AgendamentosWebFindWebHorariosDisponiveisPrestador(
  cd_cliente,
  cd_prestador,
  cd_item_agendamento,
  cd_convenio
) {
  const groupArray = (arr) => {
    const finalData = []
    const result = arr.reduce(function (r, a) {
      r[a.dt_agendamento] = r[a.dt_agendamento] || []
      r[a.dt_agendamento].push(a)
      return r
    }, Object.create(null))
    const data = Object.entries(result)
    for (let i = 0; i < data.length; i++) {
      const result2 = data[i][1].reduce(function (r, a) {
        r[a.nm_prestador] = r[a.nm_prestador] || []
        r[a.nm_prestador].push(a)
        return r
      }, Object.create(null))
      const value = {
        data: data[i][0],
        nomes: Object.keys(result2),
        medicos: Object.values(result2),
      }
      finalData.push(value)
    }
    return finalData
  }

  return await api
    .post('agendamentosweb/disponivel/medico', {
      cd_cliente,
      cd_prestador,
      cd_item_agendamento,
      cd_convenio,
    })
    .then((response) => {
      const data = groupArray(response.data)
      return data
    })
    .catch((err) => {
      return err
    })
}

export async function AgendamentosWebFindWebItemAgendamento(cd_cliente) {
  return await api
    .post('agendamentosweb/itemagendamento', { cd_cliente })
    .then((response) => {
      return response.data
    })
    .catch(() => {
      return []
    })
}

export async function AgendamentosWebFindWebMedicosPesquisa(cd_cliente, cd_item_agendamento) {
  return await api
    .post('agendamentosweb/pesquisa/medicos', {
      cd_cliente,
      cd_item_agendamento,
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return []
    })
}

export async function AgendamentosWebFindWebPlanos(cd_cliente, cd_convenio) {
  return await api
    .post('agendamentoweb/planos', { cd_cliente, cd_convenio })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return []
    })
}

export async function AgendamentosWebFindWebProibicaoProcedimento(
  cd_cliente,
  cd_item_agendamento,
  cd_convenio,
  cd_con_pla
) {
  return await api
    .post('agendamentosweb/proibicao', {
      cd_cliente,
      cd_item_agendamento,
      cd_convenio,
      cd_con_pla,
    })
    .then((response) => {
      return response.data
    })
    .catch(() => {
      return []
    })
}

export async function AgendamentosWebFindWebValorItemAgendamento(
  cd_cliente,
  cd_item_agendamento,
  cd_prestador,
  cd_convenio
) {
  return await api
    .post('agendamentosweb/itemagendamento/valor', {
      cd_cliente,
      cd_item_agendamento,
      cd_prestador,
      cd_convenio,
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return []
    })
}

export async function AgendamentosWebInsertCadastroPaciente(
  nomePaciente,
  numeroCarteira,
  numeroCelular,
  numeroDocumento,
  nomeResponsavel,
  endereco,
  email,
  nomeMae,
  dataNascimento
) {
  return await api
    .post('pacientes/cadastro', {
      nomePaciente,
      numeroCarteira,
      numeroCelular,
      numeroDocumento,
      nomeResponsavel,
      endereco,
      email,
      nomeMae,
      dataNascimento,
    })
    .then((response) => {
      NotificaSuccess('Senha enviada por SMS')
      return response.data
    })
    .catch((err) => {
      NotificaError('Você já possui cadastro!')
      return []
    })
}

export async function AgendamentosWebInsertConfirmacaoAgendamento(
  cd_cliente,
  cd_it_agenda_central,
  nm_paciente,
  cd_item_agendamento,
  cd_convenio,
  cd_con_pla,
  nr_fone,
  ds_observacao_geral,
  image,
  dt_agendamento,
  hr_agendamento,
  nm_prestador,
  endereco,
  celular,
  email,
  nm_responsavel,
  ds_item_agendamento
) {
  return await api
    .post('agendamentosweb/confirmacao', {
      cd_cliente,
      cd_it_agenda_central,
      nm_paciente,
      cd_item_agendamento,
      cd_convenio,
      cd_con_pla,
      nr_fone,
      ds_observacao_geral,
      image,
      dt_agendamento,
      hr_agendamento,
      nm_prestador,
      endereco,
      celular,
      email,
      nm_responsavel,
      ds_item_agendamento,
    })
    .then((response) => {
      const mensagem =
        'Sua ' +
        ds_item_agendamento +
        ' no Hospital Paulista foi agendada com sucesso para o dia ' +
        dt_agendamento +
        ' as ' +
        hr_agendamento +
        ' com Dr(a) ' +
        nm_prestador
      SendSMS(celular, mensagem)
      return response.data
    })
    .catch((err) => {
      return err
    })
}

export async function AgendamentosWebLoginPaciente(celular, senha) {
  return await api
    .post('pacientes/login', { celular, senha })
    .then((response) => {
      api
        .post('agendamentos/online/add/painel', {
          cd_cliente: '1',
          nm_paciente: response.data.user[0].nm_paciente,
          nr_celular: response.data.user[0].celular,
          ds_item_agendamento: '',
          nm_convenio: '',
          nm_con_pla: '',
          nm_prestador: '',
          dt_agendamento: '',
          hr_agendamento: '',
          desc_motivo_nao_agendado: '',
        })
        .then((response) => {
          localStorage.setItem('id_painel', response.data[0].id)
        })

      return response.data
    })
    .catch((err) => {
      NotificaError('Paciente não encontrado...')
      return err
    })
}

export async function AgendamentosWebPrestadorValidoConvenio(cd_prestador, cd_convenio) {
  if ((cd_prestador == 39) & (cd_convenio != 40)) {
    return false
  } else {
    return true
  }
}

export async function AgendamentosWebResetPasswordPaciente(numeroCelular) {
  return await api
    .post('pacientes/reset/senha', { numeroCelular: numeroCelular.replace(/\D/g, '') })
    .then((response) => {
      NotificaSuccess('Senha enviada por WhatsApp')
      return response.data
    })
    .catch((err) => {
      NotificaError('Telefone não encontrado')
      return err
    })
}

export async function AgendamentosWebValidaDocumentoNecessario(tp_convenio) {
  if (tp_convenio == 'C') {
    return <p>Por favor, tirar uma foto de sua carteira do convênio e anexar abaixo:</p>
  } else {
    return <p>Por favor, tirar uma foto do seu documento pessoal e anexar abaixo:</p>
  }
}

export async function AgendamentosWebValidaItensContatoCallCenter(cd_item_agendamento) {
  if (cd_item_agendamento == 583) {
    return true
  } else {
    return false
  }
}

export async function FindWebHorariosDisponiveis(cd_cliente, cd_prestador, cd_item_agendamento, cd_convenio) {
  const groupArray = (arr) => {
    const finalData = []
    const result = arr.reduce(function (r, a) {
      r[a.dt_agendamento] = r[a.dt_agendamento] || []
      r[a.dt_agendamento].push(a)
      return r
    }, Object.create(null))
    const data = Object.entries(result)
    for (let i = 0; i < data.length; i++) {
      const result2 = data[i][1].reduce(function (r, a) {
        r[a.nm_prestador] = r[a.nm_prestador] || []
        r[a.nm_prestador].push(a)
        return r
      }, Object.create(null))
      const value = {
        data: data[i][0],
        nomes: Object.keys(result2),
        medicos: Object.values(result2),
      }
      finalData.push(value)
    }
    return finalData
  }

  return await api
    .post('agendamentosweb/horarios', {
      cd_cliente,
      cd_prestador,
      cd_item_agendamento,
      cd_convenio,
    })
    .then((response) => {
      const data = groupArray(response.data)
      return data
    })
    .catch((err) => {
      return err
    })
}
export async function FindWebHorariosDisponiveisPrestador(cd_cliente, cd_prestador, cd_item_agendamento, cd_convenio) {
  const groupArray = (arr) => {
    const finalData = []
    const result = arr.reduce(function (r, a) {
      r[a.dt_agendamento] = r[a.dt_agendamento] || []
      r[a.dt_agendamento].push(a)
      return r
    }, Object.create(null))
    const data = Object.entries(result)
    for (let i = 0; i < data.length; i++) {
      const result2 = data[i][1].reduce(function (r, a) {
        r[a.nm_prestador] = r[a.nm_prestador] || []
        r[a.nm_prestador].push(a)
        return r
      }, Object.create(null))
      const value = {
        data: data[i][0],
        nomes: Object.keys(result2),
        medicos: Object.values(result2),
      }
      finalData.push(value)
    }
    return finalData
  }

  return await api
    .post('agendamentosweb/disponivel/medico', {
      cd_cliente,
      cd_prestador,
      cd_item_agendamento,
      cd_convenio,
    })
    .then((response) => {
      const data = groupArray(response.data)
      return data
    })
    .catch((err) => {
      return err
    })
}

export async function FindWebMedicosPesquisa(cd_cliente, cd_item_agendamento) {
  return await api
    .post('agendamentosweb/pesquisa/medicos', {
      cd_cliente,
      cd_item_agendamento,
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}

export async function FindWebProibicaoProcedimento(cd_cliente, cd_item_agendamento, cd_convenio, cd_con_pla) {
  return await api
    .post('agendamentosweb/proibicao', {
      cd_cliente,
      cd_item_agendamento,
      cd_convenio,
      cd_con_pla,
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}

export async function FindWebValorItemAgendamento(cd_cliente, cd_item_agendamento, cd_prestador, cd_convenio) {
  return await api
    .post('agendamentosweb/itemagendamento/valor', {
      cd_cliente,
      cd_item_agendamento,
      cd_prestador,
      cd_convenio,
    })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err
    })
}

export async function InsertConfirmacaoAgendamento(
  cd_cliente,
  cd_it_agenda_central,
  nm_paciente,
  cd_item_agendamento,
  cd_convenio,
  cd_con_pla,
  nr_fone,
  ds_observacao_geral,
  image,
  dt_agendamento,
  hr_agendamento,
  nm_prestador,
  endereco,
  celular,
  email,
  nm_responsavel,
  ds_item_agendamento
) {
  return await api
    .post('agendamentosweb/confirmacao', {
      cd_cliente,
      cd_it_agenda_central,
      nm_paciente,
      cd_item_agendamento,
      cd_convenio,
      cd_con_pla,
      nr_fone,
      ds_observacao_geral,
      image,
      dt_agendamento,
      hr_agendamento,
      nm_prestador,
      endereco,
      celular,
      email,
      nm_responsavel,
      ds_item_agendamento,
    })
    .then((response) => {
      const mensagem =
        'Sua ' +
        ds_item_agendamento +
        ' no Hospital Paulista foi agendada com sucesso para o dia ' +
        dt_agendamento +
        ' as ' +
        hr_agendamento +
        ' com Dr(a) ' +
        nm_prestador
      SendSMS(celular, mensagem)
      return response.data
    })
    .catch((err) => {
      return err
    })
}

export async function LoginPaciente(celular, senha) {
  return await api
    .post('pacientes/login', { celular, senha })
    .then((response) => {
      api
        .post('agendamentos/online/add/painel', {
          cd_cliente: '1',
          nm_paciente: response.data.user[0].nm_paciente,
          nr_celular: response.data.user[0].celular,
          ds_item_agendamento: '',
          nm_convenio: '',
          nm_con_pla: '',
          nm_prestador: '',
          dt_agendamento: '',
          hr_agendamento: '',
          desc_motivo_nao_agendado: '',
        })
        .then((response) => {
          localStorage.setItem('id_painel', response.data[0].id)
        })

      return response.data
    })
    .catch((err) => {
      NotificaError('Paciente não encontrado...')
      return err
    })
}

export async function PrestadorValidoConvenio(cd_prestador, cd_convenio) {
  if ((cd_prestador == 39) & (cd_convenio != 40)) {
    return false
  } else {
    return true
  }
}

export function ValidaDocumentoNecessario(tp_convenio) {
  if (tp_convenio == 'C') {
    return <p>Por favor, tirar uma foto de sua carteira do convênio e anexar abaixo:</p>
  } else {
    return <p>Por favor, tirar uma foto do seu documento pessoal e anexar abaixo:</p>
  }
}

export function ValidaItensContatoCallCenter(cd_item_agendamento) {
  if (cd_item_agendamento == 583) {
    return true
  } else {
    return false
  }
}
