import { Button } from '@chakra-ui/react'
import React from 'react'

interface Action {
  titleLink: string;
  FunctionClick: () => void;
}

export const LinkDefault = ({ titleLink, FunctionClick }: Action) => (
  <Button onClick={FunctionClick} colorScheme="blue" variant="outline">
    {titleLink}
  </Button>
)
