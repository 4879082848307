/* eslint-disable */
import api from '../../services/api'
import { NotificaSuccess } from '../Toast/success'

export async function DeletePagamentos(id) {
  return await api
    .post('pagamentos/del', { id })
    .then((response) => {
      NotificaSuccess('Pagamento deletado com sucesso')
      return response.data
    })
    .catch((err) => {
      return err
    })
}
