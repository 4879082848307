import {
  Button,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Input,
  List,
  ListItem,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { GridComponent, SimpleGridComponent } from '../../_styles/GridComponent'
import { ModalComponent } from '../../_styles/ModalComponent '
import api from '../../services/api'
import { ContainerPageTemplate } from '../../styles/ContainerPageTemplate'

function LogsAdmin() {
  const [dataInicial, setdataInicial] = useState(new Date().toISOString().slice(0, 10))
  const [dataFinal, setdataFinal] = useState(new Date().toISOString().slice(0, 10))
  const [isLoading, setIsLoading] = useState(false)
  const [typesLogs, setTypesLogs] = useState([])
  const [typeLogSelected, setTypeLogSelected] = useState(null)
  const [logsV2, setlogsV2] = useState([])
  const [isOpenModalLogsV2, setisOpenModalLogsV2] = useState(false)
  const toast = useToast()
  const { handleSubmit, register } = useForm()

  useEffect(() => {
    setIsLoading(true)
    api
      .get('/logs/types')
      .then((res) => {
        setTypesLogs(res.data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const handleOpenModal = (item) => {
    setisOpenModalLogsV2(true)
    setTypeLogSelected(item.id)
  }

  const handleFindLogs = (data) => {
    setIsLoading(true)
    setdataFinal(data.dataFinal)
    setdataInicial(data.dataInicial)

    api
      .get(`/logs/types/${typeLogSelected}/${data.dataInicial}/${data.dataFinal}`)
      .then((res) => {
        setlogsV2(res.data)
      })
      .catch(() => {
        toast({
          title: 'Erro ao buscar os logs!',
          description: 'Tente novamente mais tarde.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const closeModal = () => {
    setisOpenModalLogsV2(false)
    setlogsV2([])
  }

  return (
    <ContainerPageTemplate>
      {/* Modal dos logs v2 */}
      <ModalComponent isOpen={isOpenModalLogsV2} onClose={() => closeModal()} title="LOGS">
        <HStack as="form" onSubmit={handleSubmit(handleFindLogs)} pb={8} alignItems={'end'}>
          <FormControl>
            <FormLabel> Data Inicial</FormLabel>
            <Input type="date" {...register('dataInicial')} />
          </FormControl>
          <FormControl>
            <FormLabel> Data Final</FormLabel>
            <Input type="date" {...register('dataFinal')} />
          </FormControl>
          <Button type="submit">
            <strong>Buscar</strong>
          </Button>
        </HStack>

        {logsV2.length === 0 && <p>Nenhum log encontrado</p>}

        <Center p={6}>{isLoading && <p>Carregando...</p>}</Center>

        <Table>
          <Thead>
            <Tr>
              <Th>Paciente</Th>
              <Th>DT / HR agendamento</Th>
              <Th>Código MV</Th>
              <Th>Usuário</Th>
            </Tr>
          </Thead>
          <Tbody>
            {logsV2.map((item, index) => (
              <Tr key={index}>
                <Td>{item?.users.name}</Td>
                <Td>{item?.system_functions.name}</Td>
                <Td>
                  <List>
                    {item?.data?.data ? (
                      Object.entries(item.data.data).map(([key, value]) => (
                        <ListItem key={key}>
                          <strong>{key}: </strong>
                          {value}
                        </ListItem>
                      ))
                    ) : (
                      <ListItem>No Data</ListItem>
                    )}
                  </List>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </ModalComponent>

      <SimpleGridComponent title={'Tipos de Logs'} isLoading={isLoading}>
        {typesLogs.map((item, index) => (
          <GridComponent index={index} onClick={() => handleOpenModal(item)}>
            {item?.name}
          </GridComponent>
        ))}
      </SimpleGridComponent>
    </ContainerPageTemplate>
  )
}

export { LogsAdmin }
