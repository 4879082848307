/* eslint-disable */
import api from '../../services/api'

export async function CriarContato(nome, nr_celular) {
  return await api
    .post('contatos/add', { nome, phone: '55' + nr_celular + '@c.us' })
    .then((res) => {
      return res.data
    })
}
