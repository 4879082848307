/* eslint-disable */
import styled from 'styled-components'

export const Form = styled.form`
  padding: 32px;
  input {
    height: 4rem;
  }

  button {
    font-size: 1.5rem !important;
    background-color: var(--pink0);
    width: 100%;
    height: 5rem !important;
  }
`
