import { HStack, Spinner } from '@chakra-ui/react'
import { useContext } from 'react'
import { Context as ContextUser } from '../Context/UserContext'

export function LoadingPage() {
  const { Loading } = useContext(ContextUser)

  if (!Loading) return null

  return (
    <HStack w="100%" justifyContent={'center'}>
      <Spinner />
    </HStack>
  )
}
