import { Avatar, Box, Heading, HStack, Icon, Text, useBreakpointValue } from '@chakra-ui/react'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { AiOutlineAlert } from 'react-icons/ai'
import { BsStar } from 'react-icons/bs'
import { MdToday, MdTouchApp } from 'react-icons/md'
import { ModalComponent } from '../../_styles/ModalComponent '

import api from '../../services/api'
import { ModalChatMensagensAnteriores } from './ModalChatMensagensAnteriores'

function DashboardItem({ title, qtd, icone, setModalIsOpen }) {
  return (
    <HStack
      textAlign="left"
      flex="1"
      flexDir="row"
      align="center"
      cursor="pointer"
      onClick={() => setModalIsOpen(true)}
      color="gray.400"
    >
      <Icon as={icone} h="6" w="6" mr={4} color="gray.400" />
      <Box>
        <Heading as="h4" size="md">
          {qtd}
        </Heading>
        <Text fontSize="sm">{title}</Text>
      </Box>
    </HStack>
  )
}

export function DashboardProtocolos({ dataInicial, dataFinal }) {
  const isDeviceSmall = useBreakpointValue({ base: true, md: false })
  const [qtdProtocolos, setqtdProtocolos] = useState(0)
  const [Chat, setChat] = useState([])
  const [qtdProtocolosManuais, setqtdProtocolosManuais] = useState([])
  const [qtdProtocolosPendentes, setqtdProtocolosPendentes] = useState([])
  const [qtdMeusAtendimentos, setqtdMeusAtendimentos] = useState([])
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalIsOpenMensagens, setmodalIsOpenMensagens] = useState(false)
  const [isOpenModalDigitarPhone, setisOpenModalDigitarPhone] = useState(false)
  const [loading, setloading] = useState(false)
  const [id_contato, setid_contato] = useState('')
  const id_setor = localStorage.getItem('cd_setor')
  const quantidadeProtocolosManuais = []
  const quantidadeProtocolosPendentes = []

  // Consultar os protocolos por data
  useEffect(() => {
    api
      .post('api/chat/find/protocolos/data', {
        phone: '1',
        dataInicial: dataInicial ? dataInicial : new Date().toISOString().slice(0, 10),
        dataFinal: dataFinal ? dataFinal : new Date().toISOString().slice(0, 10),
        id_setor,
      })
      .then((res) => {
        setChat(res.data)
        setqtdProtocolos(res.data.length)
        res.data.map((p) => {
          if (p.sn_manual === 'S') {
            quantidadeProtocolosManuais.push(res)
          }
          if (p.sn_pendente === 'S') {
            quantidadeProtocolosPendentes.push(res)
          }
        })
        const newArray = res.filter((d) => d.nm_usuario_atendimento == localStorage.getItem('usuario'))
        setqtdMeusAtendimentos(newArray)
        setqtdProtocolosManuais(quantidadeProtocolosManuais.length)
        setqtdProtocolosPendentes(quantidadeProtocolosPendentes.length)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [dataInicial, dataFinal])

  if (isDeviceSmall) {
    return <></>
  }

  const HandleOpenMensagens = (inf) => {
    setloading(true)
    setModalIsOpen(false)
    setid_contato(inf.id_contato)
    setisOpenModalDigitarPhone(true)
  }

  const closeMensagens = () => {
    setisOpenModalDigitarPhone(false)
    setModalIsOpen(true)
  }

  return (
    <>
      <ModalComponent
        isOpen={modalIsOpenMensagens}
        onClose={() => setmodalIsOpenMensagens(false)}
        title={'title: ' + moment(dataInicial).format('ddd, DD/MM/YYYY')}
      >
        <Box>
          {Chat.map((p) => (
            <HStack cursor="pointer" spacing={2} p={2} onClick={() => HandleOpenMensagens(p)}>
              <Avatar name={p.nm_usuario_atendimento} size="sm" />
              <Text>
                {p.nome} - {p.phone} - OK
              </Text>
            </HStack>
          ))}
        </Box>
      </ModalComponent>
      <ModalChatMensagensAnteriores
        isOpen={isOpenModalDigitarPhone}
        handleChangeModalIsOpen={() => closeMensagens()}
        id_contato={id_contato}
      />
      <ModalComponent isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)} title={'Procolos'}>
        <Box>
          {Chat.map((p) => (
            <HStack cursor="pointer" spacing={2} p={2} onClick={() => HandleOpenMensagens(p)}>
              <Avatar name={p.nm_usuario_atendimento} title={p.nm_usuario_atendimento} size="sm" />
              <Text>
                {p.nome} - {p.phone}
              </Text>
            </HStack>
          ))}
        </Box>
      </ModalComponent>

      {dataInicial && (
        <HStack bg="white" w="100%" justifyContent={'space-between'} mb={2}>
          <DashboardItem title="Protocolos" qtd={qtdProtocolos} icone={MdToday} setModalIsOpen={setModalIsOpen} />
          <DashboardItem
            title="Pendentes"
            qtd={qtdProtocolosPendentes}
            icone={AiOutlineAlert}
            setModalIsOpen={setModalIsOpen}
          />
          <DashboardItem
            title="Manuais"
            qtd={qtdProtocolosManuais}
            icone={MdTouchApp}
            setModalIsOpen={setModalIsOpen}
          />
          <DashboardItem title="Meus" qtd={qtdMeusAtendimentos.length} icone={BsStar} setModalIsOpen={setModalIsOpen} />
        </HStack>
      )}
    </>
  )
}
